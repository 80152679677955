<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>

      <!-- wizard content start start -->

      <div class="">
        <div class="total-lists">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item active">{{ $t('top-list' ) }}</li>
            </ol>
          </nav>
          <div class="row no_side_margin">
            <div class="col-lg-12">
              <div class="create-new-list">
                <p v-html="$t('lists-description-first' )"></p>
                <div class="row">
                  <div class="col-lg-6 text-center">
                    <button type="button" class="btn btn-primary btn-lg add-selection add" :disabled="loading" v-on:click="addNew()">+ {{ $t('lists-add-people' ) }}</button>
                    <br>
                    <a href="https://bit.ly/379yIjC" target="_blank">{{ $t('lists-sample-people' ) }}</a>
                  </div>
                  <div class="col-lg-6 text-center">
                    <button type="button" class="btn btn-primary btn-lg add-selection add" :disabled="loading" v-on:click="addWebNew()">+ {{ $t('lists-add-web' ) }}</button>
                    <br>
                    <a href="https://bit.ly/3faTJix" target="_blank">{{ $t('lists-sample-web' ) }}</a>
                  </div>
                </div>
              </div>

              <h3 class="h3title pt-5">{{$t('list-title')}}</h3>
              <p v-html="$t('lists-description' )"></p>
              <div style="overflow-x: auto;">
                <table class="table table-striped list-table" style="margin-bottom: 0px">
                  <thead>
                    <tr>
                      <th class="t-head">{{$t('name')}}</th>
                      <th class="t-head">{{$t('type')}}</th>
                      <th class="t-head">{{$t('total')}}</th>
                      <th class="t-head">{{$t('forwards')}}</th>
                      <th class="t-head" style="cursor: pointer" v-on:click="sortLists()">{{$t('date')}}</th>
                      <th class="t-head">{{$t('actions')}}</th>

                    </tr>
                  </thead>
                  <tbody v-if="this.lists.length">

                    <tr v-for="(list , index) in this.lists" v-bind:key="list.id">

                      <td class="table-col" v-if="list.name === 'default'">default</td>
                      <td class="table-col" :class="{'pointer': list.name !== 'default'}" v-if="list.name !== 'default' && editRow !== index" v-on:click="listEdit(list.name, index)">{{list.name}}</td>

                      <td class="table-col" v-if="editMode && editRow === index">
                        <input v-model="list.name" class="drop-down" value="Enter name of new list..." v-on:keyup.enter="saveName(list.id)" v-on:focus="$event.target.select()">
                        <svg v-on:click="saveName(list.id)" class="tick pointer" width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 5.21895L1.26512 4L4 6.5621L10.7349 0L12 1.21895L4 9L0 5.21895Z" fill="#0300e2" />
                        </svg>
                      </td>

                      <td class="table-col">{{list.type}}</td>
                      <td class="table-col">{{list.size}}</td>
                      <td class="table-col">{{list.forwards}}</td>
                      <td class="table-col">{{list.updatedAt | formatDate}}</td>
                      <td class="table-col">
                        <div class="total-icon">
                          <div class="icon">
                            <a :title="$t('view-list')" v-bind:href="'#/lists/'+list.id">
                              <font-awesome-icon icon="list" size="lg"/>
                            </a>
                          </div>

                          <!-- download -->
                          <div class="icon" v-if="list.size > 0">
                            <a href="#/lists" :title="$t('download-csv')" v-on:click="downloadAction(list.id)">
                              <font-awesome-icon color="#0300e2" icon="cloud-download-alt" size="lg"/>
                            </a>
                          </div>
                          <div class="icon disabled" v-if="list.size === 0">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
                          </div>

                          <!-- report -->
                          <div class="icon disabled" v-if="list.size === 0 || (list.size > 0 && list.type === 'web')">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
                          </div>
                          <div class="icon" v-if="list.size > 0 && list.type === 'people'">
                            <a :title="$t('view-report')" v-bind:href="'#/lists/'+list.id+'/report'">
                              <font-awesome-icon icon="chart-pie" size="lg"/>
                            </a>
                          </div>

                          <!-- delete -->
                          <div v-if="list.name !== 'default'" class="icon">
                            <a href="#/lists" :title="$t('delete-list')" v-on:click="deleteList(list.id)">
                              <font-awesome-icon icon="trash" size="lg"/>
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>

                  </tbody>
                </table>

                <div class="col-lg-12 no-list" v-if="!this.lists.length">
                  {{$t('no-lists')}}
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>


      <v-dialog />

      <Footer />

    </div>

  </div>

</div>
</template>

<script>
import Header from '@/components/Header.vue'

import Footer from '@/components/Footer.vue'

import Sidebar from '@/components/Sidebar.vue'

import axios from 'axios';

export default {
  name: 'lists',
  data() {
    return {

      isActive: false,
      isActiveIcon: '<',
      lists: [],
      list_sorted_asc: true,
      pendingRequest: false,
      editRow: -1,
      editMode: false,
      loading: true,
    }

  },
  mounted() {
      this.enableInterceptor();
      this.getUserDetails();
      this.getLists(true);
  },
  components: {

    Header,
    Sidebar,
    Footer

  },
  methods: {

    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        this.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        this.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      //console.log(this.isActive);
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },


    getUserDetails: function() {

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };


      var url = '/api/v2/profile';

      this.$http.get(url, config).then((results) => {

        this.user = results.data;


      });

    },

    getCredits: function() {

      return this.user ? this.user.credits : 0;


    },


    hasCards: function() {
      return this.user && this.user.cards.length;
    },

    hasAutoCredits: function() {
      return this.user && this.user.autocredits !== null ? this.user.autocredits : false;
    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },

    areCreditsNeeded: function() {
      return this.processing.processCredits > this.getCredits();
    },


    sortLists: function() {
      this.lists = this.lists.sort(function(a, b) {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
      this.list_sorted_asc = !this.list_sorted_asc
      if (!this.list_sorted_asc) {
        this.lists = this.lists.reverse();
      }
    },


    //FROM HERE BULKS AREA FUNCTIONS


    getLists: function(forced) {
      if (!this.pendingRequest && forced) {
        this.pendingRequest = true;
        this.$root.$emit('isLoading', true);

        var config = {
          headers: {
            "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
            "Content-Type": "application/json"
          }
        };

        var url = '/api/v2/lists';
        this.loading = true;
        this.$http.get(url, config).then((results) => {
          this.loading = false;
          this.lists = results.data;
          this.pendingRequest = false;
          this.loading = false;
          this.$root.$emit('isLoading', false);
        });
      } else {
        //return lists;
      }
    },

    deleteList: function(listId) {
      var that = this;
      this.$modal.show('dialog', {
        title: that.$t('list-delete'),
        text: that.$t('list-delete-question'),
        buttons: [
          {
            title: that.$t('accept'),
            handler: () => {
              that.pendingRequest = true;
              that.deleteListProcess(listId);
              that.$modal.hide('dialog');
            }
          },
          {
            title: that.$t('cancel')
          }
        ]
      })
    },

    deleteListProcess: function(name) {
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v2/list/' + name + '/delete?all=true';
      this.$http.get(url, config).then((results) => {
        this.lists = results.data;
        this.pendingRequest = false;
        this.$modal.hide('dialog');
      });
    },

    addGenericNew: function(type) {
      this.loading = true;
      var name = type + "-" + new Date().getTime();

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v2/list';
      this.$http.post(url, {
        name: name,
        type: type
      }, config).then((results) => {
        this.getLists(true);
        this.loading = false;
        this.$router.push("/lists/" + results.data._id)
      });
    },


    addNew: function() {
      this.addGenericNew('people')
    },

    addWebNew: function() {
      this.addGenericNew('web')
    },

    listEdit: function(name, index) {

      if (name !== 'default') {
        this.editMode = true;
        this.editRow = index;
      }

    },

    saveName: function(list_id) {
      this.loading = true;
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v2/lists';
      this.$http.post(url, {
        lists: this.lists.filter(function(l) {
          return l.id === list_id;
        }).map(function(l) {
          l.name = l.name.replace(/\//g, "").trim();
          return l;
        })
      }, config).then((results) => {
        this.loading = false;
        this.editMode = false;
        this.editRow = -1;
        this.lists = results.data;
      });
    },

    executeGenericAction: function(action, id) {
      this.downloaded = false
      this.forwarded = false
      this.loading = true

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v2/list/' + id + '/' + action;
      this.$http.get(url, config).then(() => {
        //var data = results.data;
        this.loading = false
        if (action === 'download') {
          this.downloaded = true
          this.$notify({
            group: 'system',
            type: 'success',
            title: this.$t('lists'),
            text: this.$t('list-downloaded')
          });
        } else {
          this.forwarded = true
        }
      });
    },

    forwardAction: function(id) {
      this.executeGenericAction('forward', id)
    },

    downloadAction: function(id) {
      this.executeGenericAction('download', id)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/list.css';
@import '../../assets/css/global.css';
</style>
