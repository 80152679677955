<template>
  <footer>
    <div class="container">
      <div class="row side-pad">
        <div class="col-12">
          <!--div class="single">
            <a class="icon-footer" href="https://uproc.io" target="_blank">
              <svg width="56" height="66" viewBox="0 0 56 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M33.9365 7.01358C33.9365 16.9016 28.2725 22.3736 18.7205 22.3736C17.0191 22.3736 15.441 22.2 13.9964 21.8587C16.385 27.69 22.1171 31.7976 28.8084 31.7976C37.645 31.7976 44.8084 24.6341 44.8084 15.7976C44.8084 8.7546 40.2578 2.77447 33.9365 0.637025V7.01358ZM13 13.3143C14.2852 14.9941 16.2521 15.7496 18.7685 15.7496C23.5205 15.7496 26.2565 13.1096 26.2565 6.72558V0C19.441 1.09244 14.0635 6.48922 13 13.3143Z"
                  fill="white" />
                <path
                  d="M8.54966 46.316V51.104C8.54966 52.886 7.61366 53.714 6.31766 53.714C5.07566 53.714 4.35566 52.994 4.35566 51.374V46.316H1.54766V51.788C1.54766 54.776 3.25766 56.144 5.68766 56.144C6.87566 56.144 7.95566 55.694 8.69366 54.848V56H11.3577V46.316H8.54966ZM19.6111 43.4H14.1571V56H17.0731V52.526H19.6111C22.9771 52.526 25.0831 50.78 25.0831 47.972C25.0831 45.146 22.9771 43.4 19.6111 43.4ZM19.4491 50.15H17.0731V45.776H19.4491C21.2311 45.776 22.1311 46.586 22.1311 47.972C22.1311 49.34 21.2311 50.15 19.4491 50.15ZM29.6489 47.594V46.316H26.9669V56H29.7749V51.428C29.7749 49.574 30.8009 48.728 32.3489 48.728C32.5649 48.728 32.7449 48.746 32.9789 48.764V46.172C31.4849 46.172 30.3329 46.658 29.6489 47.594ZM39.1686 56.144C42.2466 56.144 44.4786 54.074 44.4786 51.158C44.4786 48.242 42.2466 46.172 39.1686 46.172C36.0906 46.172 33.8406 48.242 33.8406 51.158C33.8406 54.074 36.0906 56.144 39.1686 56.144ZM39.1686 53.84C37.7646 53.84 36.6846 52.832 36.6846 51.158C36.6846 49.484 37.7646 48.476 39.1686 48.476C40.5726 48.476 41.6346 49.484 41.6346 51.158C41.6346 52.832 40.5726 53.84 39.1686 53.84ZM51.0356 56.144C53.0696 56.144 54.6716 55.244 55.3736 53.696L53.1956 52.508C52.6736 53.444 51.8996 53.84 51.0176 53.84C49.5956 53.84 48.4796 52.85 48.4796 51.158C48.4796 49.466 49.5956 48.476 51.0176 48.476C51.8996 48.476 52.6736 48.89 53.1956 49.808L55.3736 48.638C54.6716 47.054 53.0696 46.172 51.0356 46.172C47.8856 46.172 45.6356 48.242 45.6356 51.158C45.6356 54.074 47.8856 56.144 51.0356 56.144Z"
                  fill="white" />
              </svg>
            </a>
          </div-->

          <div class="single">
            <h5>{{$t('footer-solutions')}}</h5>
            <p><a v-bind:href="'https://uproc.io/#/solutions/sales'" target="_blank">{{$t('footer-sales')}}</a></p>
            <p><a v-bind:href="'https://uproc.io/#/solutions/marketing'" target="_blank">{{$t('footer-marketing')}}</a></p>
            <p><a v-bind:href="'https://uproc.io/#/solutions/development'" target="_blank">{{$t('footer-development')}}</a></p>
          </div>

          <div class="single">
            <h5>{{$t('footer-products')}}</h5>
            <p><a v-bind:href="'https://uproc.io/#/product/behindme'" target="_blank">BehindMe</a></p>
            <p><a v-bind:href="'https://uproc.io/#/product/catalog'" target="_blank">{{$t('footer-catalog')}}</a></p>
            <!--p><a v-bind:href="'https://uproc.io/#/product/formboost'" target="_blank">FormBoost</a></p-->
            <p><a v-bind:href="'https://uproc.io/#/product/linkedin'" target="_blank">LinkedIn</a></p>
            <!--p><a v-bind:href="'https://uproc.io/#/product/oscar'" target="_blank">Oscar</a></p>
            <p><a v-bind:href="'https://uproc.io/#/product/wetrack'" target="_blank">WeTrack</a></p-->
          </div>

          <div class="single">
            <h5>{{$t('footer-integrations')}}</h5>
            <p><a v-bind:href="'https://www.appypie.com/connect/apps/uproc/integrations'" target="_blank">AppyPie</a></p>
            <p><a v-bind:href="'https://uproc.io/#/product/googlesheets'" target="_blank">GoogleSheets</a></p>
            <p><a v-bind:href="'https://uproc.io/#/product/make'" target="_blank">Make</a></p>
            <p><a v-bind:href="'https://uproc.io/#/product/mautic'" target="_blank">Mautic</a></p>
            <p><a v-bind:href="'https://uproc.io/#/product/n8n'" target="_blank">N8n</a></p>
            <p><a v-bind:href="'https://uproc.io/#/product/wordpress'" target="_blank">Wordpress</a></p>
            <p><a v-bind:href="'https://uproc.io/#/product/zapier'" target="_blank">Zapier</a></p>
          </div>

          <div class="single">
            <h5>{{$t('footer-tools')}}</h5>
            <p><a v-bind:href="'https://uproc.io/#/tool/audio'" target="_blank">{{$t('group-audio-name')}}</a></p>
            <p><a v-bind:href="'https://uproc.io/#/tool/communication'" target="_blank">{{$t('group-communication-name')}}</a></p>
            <p><a v-bind:href="'https://uproc.io/#/tool/company'" target="_blank">{{$t('group-company-name')}}</a></p>
            <p><a v-bind:href="'https://uproc.io/#/tool/finance'" target="_blank">{{$t('group-finance-name')}}</a></p>
            <p><a v-bind:href="'https://uproc.io/#/tool/geographic'" target="_blank">{{$t('group-geographic-name')}}</a></p>
            <p><a v-bind:href="'https://uproc.io/#/tool/image'" target="_blank">{{$t('group-image-name')}}</a></p>
            <p><a v-bind:href="'https://uproc.io/#/tool/internet'" target="_blank">{{$t('group-internet-name')}}</a></p>
            <p><a v-bind:href="'https://uproc.io/#/tool/personal'" target="_blank">{{$t('group-personal-name')}}</a></p>
            <p><a v-bind:href="'https://uproc.io/#/tool/product'" target="_blank">{{$t('group-product-name')}}</a></p>
            <p><a v-bind:href="'https://uproc.io/#/tool/text'" target="_blank">{{$t('group-text-name')}}</a></p>
          </div>


          <div class="single">
            <h5>{{$t('footer-information')}}</h5>
            <p><a v-bind:href="'https://uproc.io/#/pricing'" target="_blank">{{$t('footer-pricing')}}</a></p>
            <p><a v-bind:href="'https://uproc.io/#/demo'" target="_blank">{{$t('footer-demo')}}</a></p>
            <p><a v-bind:href="'https://uproc.io/#/affiliate'" target="_blank">{{$t('footer-affiliate')}}</a></p>
            <p><a v-bind:href="'https://uproc.io/blog'" target="_blank">{{$t('footer-blog')}}</a></p>
            <!--p><a v-bind:href="'https://docs.uproc.io/pdf/resumen_del_servicio_EN.pdf'" target="_blank">{{$t('footer-portfolio')}}</a></p-->
            <p><a v-bind:href="'https://docs.uproc.io/api'" target="_blank">{{$t('footer-api')}}</a></p>
            <p><a href="https://www.youtube.com/user/mcolomer1975/videos?utm_source=webapp&utm_medium=referral&utm_campaign=webapp&utm_content=footer" target="_blank">{{$t('footer-tutorials')}}</a></p>
            <p><a v-bind:href="'https://status.uproc.io'" target="_blank">{{$t('footer-status')}}</a></p>
            <!--p><a href="javascript: typebot.open()">Roadmap</a></p-->
            <p><a v-bind:href="'https://trello.com/b/RG9Qf7Ba/uproc-roadmap'" target="_blank">Roadmap</a></p>
          </div>

          <div class="col-lg-12 text-center footer_preBottom">
            <div class="container">
              <p>
                <a href="https://uproc.io/#/terms" class="" target="_blank">{{$t('footer-terms')}}</a>
                <span class="preF"></span>
                <a href="https://uproc.io/#/cookies" class="" target="_blank">{{$t('footer-cookies')}}</a>
                <span class="preF"></span>
                <a href="https://uproc.io/#/privacy" class="" target="_blank">{{$t('footer-privacy')}}</a>
                <span class="preF"></span>
                <a href="https://uproc.io/#/gdpr" class="" target="_blank">{{$t('footer-gdpr')}}</a>
                <span class="preF"></span>
                <a href="mailto:hello@uproc.io?subject=question" target="_blank">hello@uproc.io</a>
                <span class="preF"></span>
                <a href="https://goo.gl/maps/MRxXx2s9jZNKmEQ19" target="_blank">Spain</a>
                <span class="preF"></span>
                <a href="tel:34605281220">+34 605 28 12 20</a>
              </p>
            </div>
          </div>
        </div>

        <!-- Old footer -->

        <!--div class="single">
          <h5>{{$t('footer-products')}}</h5>
          <p><a v-bind:href="'https://app.uproc.io/#/tools'" target="_blank">{{$t('footer-catalog')}}</a></p>
          <p><a v-bind:href="'https://uproc.io/uproc_for_sheets'" target="_blank">GoogleSheets</a></p>
          <p><a v-bind:href="'https://uproc.io/uproc_for_linkedin'" target="_blank">LinkedIn</a></p>
          <p><a v-bind:href="'https://uproc.io/uproc_for_mautic'" target="_blank">Mautic</a></p>
          <p><a v-bind:href="'https://uproc.io/uproc_for_wordpress'" target="_blank">Wordpress</a></p>
          <p><a v-bind:href="'https://uproc.io/uproc-for-zapier'" target="_blank">Zapier</a></p>
        </div>

        <div class="single">
          <h5>{{$t('footer-resources')}}</h5>
          <p><a v-bind:href="'https://docs.uproc.io/pdf/resumen_del_servicio_EN.pdf'" target="_blank">{{$t('footer-portfolio')}}</a></p>
          <p><a v-bind:href="'https://docs.uproc.io/api'" target="_blank">{{$t('footer-documentation')}}</a></p>
          <p><a v-bind:href="'https://status.uproc.io'" target="_blank">{{$t('footer-status')}}</a></p>
          <p><a v-bind:href="'https://uproc.io/blog'" target="_blank">{{$t('footer-blog')}}</a></p>
          <p><a v-bind:href="'https://www.youtube.com/channel/UCOUlLZwMY-J0s_HfyPxrI5A'" target="_blank">{{$t('footer-tutorials')}}</a></p>
        </div>

        <div class="single">
          <h5>{{$t('footer-legal')}}</h5>
          <p><a href="https://uproc.io/terms" class="" target="_blank">{{$t('footer-terms')}}</a></p>
          <p><a href="https://uproc.io/cookies" class="" target="_blank">{{$t('footer-cookies')}}</a></p>
          <p><a href="https://uproc.io/privacy" class="" target="_blank">{{$t('footer-privacy')}}</a></p>
          <p><a href="https://uproc.io/gdpr" class="" target="_blank">{{$t('footer-gdpr')}}</a></p>
          <p><a href="mailto:hello@uproc.io?subject=question" target="_blank">hello@uproc.io</a></p>
          <p><a href="https://goo.gl/maps/MRxXx2s9jZNKmEQ19" target="_blank">Spain</a></p>
          <p><a href="tel:34605281220">+34 605 28 12 20</a></p>
        </div-->


      </div>
    </div>
    <hr>
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h6>uProc © {{ new Date().getFullYear() }} · {{$t('all-rights')}}</h6>
        </div>
      </div>
    </div>
  </footer>
</template>


<script>
export default {
  name: 'Footer',
  data() {
    return {

    };
  },
  mounted() {

  },
  components: {

  },
  methods: {

    logOut: function() {

      localStorage.token = '';
      this.$router.push("/login");

    }

  }
}
</script>
