<template>
<div>

  <Header />

  <div class="row no_side_margin content">
    <Sidebar />
    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">
      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>
      <!-- wizard content start start -->
      <div class="">
        <div class="total-lists">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item "><a v-bind:href="'#/lists'">{{ $t('top-list' ) }}</a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('list-my' ) }}</li>
            </ol>
          </nav>
          <div class="row no_side_margin">
            <div class="col-lg-12 no_side_pad">
              <div class="list-people" style="margin-bottom: 0px;border-radius: 0px; box-shadow: none;   border-bottom: 1px solid #e3e7eb">
                <div class="row ">
                  <div class="col-md-9 col-md-9">
                    <h2 v-if="list.type === 'web' || (list.type === 'people' && list.name === 'default')">{{ $t('list' ) }}: {{list.name}}</h2>
                    <h2 v-if="list.type === 'people' && list.name !== 'default'">{{ $t('list' ) }}:
                      <span v-if="list.type === 'web' || (list.type === 'people' && list.name === 'default')">{{list.name}}</span>
                      <span v-if="!editMode">
                        <a v-on:click="showNameInput()">{{list.name}}</a>
                      </span>
                      <span v-if='editMode'>
                        <input v-model="list.name" class="drop-down col-md-6 input-sm" ref="list_name" placeholder="Enter name of new list..." v-on:keyup.enter="saveList()">
                        &nbsp;
                        <a v-on:click="saveList(); editMode = false">
                          <font-awesome-icon icon="check" size="1x" />
                        </a>
                        &nbsp;
                        <a v-on:click="editMode = false">
                          <font-awesome-icon icon="times" size="1x" />
                        </a>
                      </span>
                    </h2>
                  </div>
                  <div class="col-lg-3 col-md-3" style="text-align: right;">
                    <div v-if="list && list.elements && list.elements.length > 0 && list.type === 'people'">
                      <a type="button" class="btn btn-primary btn-lg" v-bind:href="'#/lists/' + list.id + '/report'" :disabled="this.loading">{{ $t('report' ) }} </a>
                      <br>
                      <br>
                    </div>
                    <!--a :disabled="this.loading" v-if="list.elements.length > 0" v-on:click="deleteElements()">
                      {{ $t('list-clean' ) }}
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M7.99984 8.97896L3.64514 13.3337L2.6665 12.355L7.0212 8.00033L2.6665 3.64563L3.64514 2.66699L7.99984 7.02169L12.3545 2.66699L13.3332 3.64563L8.97848 8.00033L13.3332 12.355L12.3545 13.3337L7.99984 8.97896Z"
                          fill="#0300e2" />
                      </svg>
                    </a-->
                  </div>
                </div>
                <div class="row list-contain">
                  <div class="col-lg-9 col-md-12 col-sm-12" v-if="list.type === 'web'">
                    <p class="ng-scope">{{$t('list-web-add')}}</p>
                    <br>
                    <pre class="ng-binding">
&lt;script src="{{script_uri}}"&gt;&lt;/script&gt;
&lt;script type="text/javascript"&gt;
//{{$t('list-add-client')}}
var data = {
  hostname: window.location.hostname,
  list: '{{list.id}}',
  cookie_days: 90,
  client: '',//client name
  email: '',//client email
  phone: ''//client phone
};
uproc_webtrack_custom(data);
&lt;/script&gt;
                    </pre>
                  </div>
                  <div class="col-lg-9 col-md-9 record" v-if="list.type === 'people' && list.name === 'default'" v-html="$t('list-default')"></div>
                  <div class="col-lg-9 col-md-9" v-if="list.elements.length > 0" v-html="$t('list-not-default', {total: list.total})"></div>
                  <div class="col-lg-9 col-md-9" v-if="list.elements.length > 0 && stats.not_added > 0" v-html="$t('list-stats', {not_added: stats.not_added, blacklist: stats.blacklist, balance: stats.balance, filter: stats.filter, duplicated: stats.duplicated})"></div>
                  <br><br>
                  <div class="col-lg-9 col-md-9" v-if="list.elements.length > 0" v-html="$t('list-pagination', {startItems: this.startItems + 1, finishItems: this.finishItems})"></div>
                  <div class="col-lg-9 col-md-9 record" v-if="list.elements.length === 0 && list.elements.length === 0 && list.name !== 'default' && list.type === 'people'" v-html="$t('list-actions-wizard-list')"></div>
                </div>

                <div class="col-lg-12 no_side_pad" v-if="list.elements && list.elements.length && list.totalPages > 1">
                  <br>
                  <div class="row no_side_margin">
                    <div class="col-lg-2 col-sm-4 col-4 no_side_pad pointer" v-on:click="moveBack('')">
                      <div class="nav-item"><a type="button" :class="{'disabled': isFirstPage() || this.loading, 'enabled': !isFirstPage() && this.loading}" v-on:click="moveBack()">{{ $t('previous' ) }}</a></div>
                    </div>
                    <div class="col-lg-8 col-sm-4 col-4 no_side_pad">
                      <div class="btn-group ">
                      </div>
                    </div>
                    <div class="col-lg-2 col-sm-4 col-4 no_side_pad pointer text-right" v-on:click="moveNext('')">
                      <div class="nav-item"><a type="button" :class="{'disabled': isLastPage() || this.loading, 'enabled': !isLastPage() && this.loading}" v-on:click="moveNext()">{{ $t('next' ) }}</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="list-scroller" style="overflow-x: auto;" v-if="list.elements.length > 0">
              <table v-if="list.type === 'web'" class="table table-striped list-table" style="margin-top: 0px">
                <thead>
                  <tr>
                    <th class="t-head">{{$t('server-date')}}</th>
                    <th class="t-head">{{$t('user-date')}}</th>
                    <th class="t-head">{{$t('client')}}</th>
                    <th class="t-head">{{$t('page')}}</th>
                    <th class="t-head">{{$t('ip-company')}}</th>
                    <th class="t-head">{{$t('ip-city')}}</th>
                    <th class="t-head">{{$t('ip-country')}}</th>
                    <th class="t-head">{{$t('ip-phone')}}</th>
                  </tr>
                </thead>
                <tbody v-if="!list.elements.length">
                  <tr>
                    <td colspan="6" class="text-center" translate>no-results</td>
                  </tr>
                </tbody>
                <tbody v-if="list.elements && list.elements.length">
                  <tr v-for='item in list.elements' v-bind:key="item.date">
                    <td class="table-col">{{item.date | formatDateSeconds}}</td>
                    <td class="table-col">{{item.output.timezone.datetime | formatDateSeconds}}</td>
                    <td class="table-col">{{item.input.client}}</td>
                    <td class="table-col">{{item.input.page.split("?")[0]}}</td>
                    <td class="table-col">{{item.output.company.name}}</td>
                    <td class="table-col">{{item.output.geographical.city}}</td>
                    <td class="table-col">{{item.output.geographical.country}}</td>
                    <td class="table-col">
                      <svg v-if="item.output.company.phone" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="12" fill="#0BE655" />
                        <path d="M7 13L10 16L18 8" stroke="white" stroke-width="2" />
                      </svg>
                      <svg v-if="!item.output.company.phone" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="12" fill="#F70C0C" />
                        <path d="M7.70711 17.7071L17.7071 7.70711L16.2929 6.29289L6.29289 16.2929L7.70711 17.7071ZM17.7071 16.2929L7.70711 6.29289L6.29289 7.70711L16.2929 17.7071L17.7071 16.2929Z" fill="white"></path>
                      </svg>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table v-if="list.type === 'people'" class="table table-striped list-table" style="margin-top: 0px">
                <thead>
                  <tr>
                    <th class="t-head">{{$t('date')}}</th>
                    <th class="t-head">{{$t('name')}}/{{$t('email')}}</th>
                    <th class="t-head">{{$t('company')}}/{{$t('domain')}}</th>
                    <th class="t-head">{{$t('country')}}</th>
                    <th class="t-head">{{$t('role')}}</th>
                    <th class="t-head">{{$t('email')}}</th>
                    <th class="t-head">{{$t('phone')}}</th>
                    <th class="t-head"></th>
                  </tr>
                </thead>
                <tbody v-if="!list.elements.length">
                  <tr>
                    <td colspan="7" class="text-center" translate>no-results</td>
                  </tr>
                </tbody>
                <tbody v-if="list.elements.length">
                  <tr v-for="item in list.elements" v-bind:key="item.profile + item.updatedAt">
                    <td class="table-col">{{item.updatedAt | formatDateSeconds}}</td>
                    <td class="table-col" v-if="item.name.length">{{item.name}} {{item.surname}}</td>
                    <td class="table-col" v-if="!item.name.length && !item.email_str.length">-</td>
                    <td class="table-col" v-if="!item.name.length && item.email_str.length">{{item.email_str}}</td>
                    <td class="table-col" v-if="item.company.length">{{item.company}}</td>
                    <td class="table-col" v-if="!item.company.length && item.domain && item.domain.length">{{item.domain}}</td>
                    <td class="table-col" v-if="!item.company.length && (!item.domain || !item.domain.length)">-</td>
                    <td class="table-col" v-if="item.country.length">{{item.country}}</td>
                    <td class="table-col" v-if="!item.country.length">-</td>
                    <td class="table-col">{{item.role}}</td>
                    <td class="table-col text-center" >
                      <span v-if="item.email === 'Yes' && item.confidence === 'verified'" title="verified">
                        <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="12" cy="12" r="12" fill="#0BE655" />
                          <path d="M7 13L10 16L18 8" stroke="white" stroke-width="2" />
                        </svg>
                      </span>

                      <span v-if="item.email === 'Yes' && item.confidence !== 'verified'" title="risky">
                        <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="12" cy="12" r="12" fill="#FA8C0C" />
                          <path d="M7 13L10 16L18 8" stroke="white" stroke-width="2" />
                        </svg>
                      </span>

                      <svg v-if="item.email === 'No'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="12" fill="#F70C0C" />
                        <path d="M7.70711 17.7071L17.7071 7.70711L16.2929 6.29289L6.29289 16.2929L7.70711 17.7071ZM17.7071 16.2929L7.70711 6.29289L6.29289 7.70711L16.2929 17.7071L17.7071 16.2929Z" fill="white"></path>
                      </svg>
                    </td>
                    <td class="table-col text-center">
                      <svg v-if="item.phone === 'Yes'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="12" fill="#0BE655" />
                        <path d="M7 13L10 16L18 8" stroke="white" stroke-width="2" />
                      </svg>
                      <svg v-if="item.phone === 'No'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="12" fill="#F70C0C" />
                        <path d="M7.70711 17.7071L17.7071 7.70711L16.2929 6.29289L6.29289 16.2929L7.70711 17.7071ZM17.7071 16.2929L7.70711 6.29289L6.29289 7.70711L16.2929 17.7071L17.7071 16.2929Z" fill="white"></path>
                      </svg>
                    </td>
                    <td class="table-col text-center">
                      <div class="icon">
                        <a class="pointer" :title="$t('list-element-delete-title')" v-on:click="deleteElement(item.id)">
                          <font-awesome-icon icon="trash" size="lg"/>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--div class="col-lg-12 no_side_pad">
              <div class="total-contact" v-if="list.type === 'people' && (list.elements.length > 0 || list.elements.length > 0)">
                <p v-if="list.name === 'default'">Existing elements will remain 30 days in this list before deletion.</p>
                <p v-if="list.elements.length > 0 || list.elements.length > 0">You can filter current list by:</p>
                <div class="row">
                  <div class="col-lg-2">
                    <div class=" col-12 no_side_pad  select_period total-select cntct-select">
                      <div class="btn-group ">
                        <a v-if="this.search.area" class="btn btn-primary dropdown-toggle drp_btn drp_btn_ctct" data-toggle="dropdown" href="#">{{this.search.area}} <span class="caret"></span></a>
                        <a v-if="!this.search.area" class="btn btn-primary dropdown-toggle drp_btn drp_btn_ctct" data-toggle="dropdown" href="#">Area <span class="caret"></span></a>
                        <ul class="dropdown-menu">
                          <li class="dropdown-item"><a v-on:click="selectArea('any')">any</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('ceo')">ceo</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('communications')">communications</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('consulting')">consulting</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('customer_service')">customer_service</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('education')">education</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('finance')">finance</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('engineering')">engineering</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('health_professional')">health_professional</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('human_resources')">human_resources</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('information_technology')">information_technology</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('legal')">legal</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('marketing')">marketing</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('operations')">operations</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('owner')">owner</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('president')">president</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('product')">product</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('public_relations')">public_relations</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('real_estate')">real_estate</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('recruiting')">recruiting</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('research')">research</a></li>
                          <li class="dropdown-item"><a v-on:click="selectArea('sales')">sales</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class=" col-12 no_side_pad  select_period total-select cntct-select">

                      <div class="btn-group ">
                        <a v-if="!this.search.seniority" class="btn btn-primary dropdown-toggle drp_btn drp_btn_ctct" data-toggle="dropdown" href="#">Seniority <span class="caret"></span></a>
                        <a v-if="this.search.seniority" class="btn btn-primary dropdown-toggle drp_btn drp_btn_ctct" data-toggle="dropdown" href="#">{{this.search.seniority}} <span class="caret"></span></a>
                        <ul class="dropdown-menu">
                          <li class="dropdown-item"><a v-on:click="selectSeniority('any')">any</a></li>
                          <li class="dropdown-item"><a v-on:click="selectSeniority('apprentice')">apprentice</a></li>
                          <li class="dropdown-item"><a v-on:click="selectSeniority('director')">director</a></li>
                          <li class="dropdown-item"><a v-on:click="selectSeniority('executive')">executive</a></li>
                          <li class="dropdown-item"><a v-on:click="selectSeniority('intermediate')">intermediate</a></li>
                          <li class="dropdown-item"><a v-on:click="selectSeniority('manager')">manager</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class=" col-12 no_side_pad  select_period total-select cntct-select">

                      <div class="btn-group ">

                        <a v-if="!this.search.email" class="btn btn-primary dropdown-toggle drp_btn drp_btn_ctct " data-toggle="dropdown" href="#">Email <span class="caret"></span></a>

                        <a v-if="this.search.email" class="btn btn-primary dropdown-toggle drp_btn drp_btn_ctct " data-toggle="dropdown" href="#">{{this.Emails}} <span class="caret"></span></a>
                        <ul class="dropdown-menu">

                          <li class="dropdown-item"><a v-on:click="selectEmail('any')">any</a></li>
                          <li class="dropdown-item"><a v-on:click="selectEmail('yes')">yes</a></li>
                          <li class="dropdown-item"><a v-on:click="selectEmail('yes-verified')">Yes (verified)</a></li>
                          <li class="dropdown-item"><a v-on:click="selectEmail('yes-risky')">Yes (risky)</a></li>
                          <li class="dropdown-item"><a v-on:click="selectEmail('no')">no</a></li>

                        </ul>

                      </div>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <div class=" col-12 no_side_pad  select_period total-select cntct-select">

                      <div class="btn-group ">
                        <a v-if="!this.search.phone" class="btn btn-primary dropdown-toggle drp_btn drp_btn_ctct " data-toggle="dropdown" href="#">Phone <span class="caret"></span></a>

                        <a v-if="this.search.phone" class="btn btn-primary dropdown-toggle drp_btn drp_btn_ctct " data-toggle="dropdown" href="#">{{this.search.phone}} <span class="caret"></span></a>
                        <ul class="dropdown-menu">

                          <li class="dropdown-item"><a v-on:click="selectPhone('any')">any</a></li>
                          <li class="dropdown-item"><a v-on:click="selectPhone('yes')">yes</a></li>
                          <li class="dropdown-item"><a v-on:click="selectPhone('no')">no</a></li>

                        </ul>

                      </div>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <button type="button" class="btn btn-outline-primary cntct-btn" :disabled="list.elements.length === 0 || list.elements.length === list.elements.length" v-on:click="resetFilters()">Reset</button>
                  </div>

                </div>
              </div>


            </div-->
          </div>

          <div v-if="list.elements.length > 0">
            <div class="col-md-9 col-md-9">
              <h2>{{$t('actions')}}</h2>
            </div>
            <div class="data">
              <div v-if="list.type === 'people'">
                <h6 class="text-left"><span v-html="$t('list-actions-fields-people')"></span><a href="javascript: void()" v-on:click="showPeopleFields()"><span v-if="shownPeopleFields">{{$t('list-actions-hide')}}</span><span v-if="!shownPeopleFields">{{$t('list-actions-show')}}</span></a> {{$t('list-actions-returned-fields')}}
                </h6>
                <div v-if="shownPeopleFields" class="text-center">
                  <ListTypePerson/>
                </div>
              </div>
              <div v-if="list.type === 'web'">
                <h6 class="text-left"><span v-html="$t('list-actions-fields-web')"></span><a href="javascript: void()" v-on:click="showWebFields()"><span v-if="shownWebFields">{{$t('list-actions-hide')}}</span><span v-if="!shownWebFields">{{$t('list-actions-show')}}</span></a> {{$t('list-actions-returned-fields')}}</h6>
                <div v-if="shownWebFields">
                  <ListTypeWeb/>
                </div>
              </div>
              <h6 class="text-left">{{$t('list-actions-download-free')}}</h6>

              <div class="row">
                <div class="col-lg-6 col-sm-12 text-center">
                  <button type="button" class="btn btn-primary" :disabled="this.loading" v-on:click="downloadAction()">{{$t('list-download-csv')}}</button>
                </div>
                <div class="col-lg-6 col-sm-12 text-center">
                  <button type="button" class="btn btn-primary" :disabled="isNoActiveWebhook" v-on:click="forwardAction()">{{$t('list-forward-webhooks')}}</button>
                </div>
              </div>

              <div class="row" v-if="downloaded || forwarded">
                <div class=" col-lg-12 text-center text-success">
                  <br>
                  <div v-if="downloaded" v-html="$t('list-downloaded')"></div>
                  <div v-if="forwarded" v-html="$t('list-forwarded')"></div>
                </div>
              </div>
            </div>


          </div>


          <div>
            <div class="col-md-12 col-md-12">
              <h2>Webhooks</h2>
            </div>
            <div class="data text-left">
              <h6 class="text-left" v-html="$t('list-zapier-howto')"></h6>
              <div class="row">
                <div class="col-lg-12 text-center">
                  <button type="button" class="btn btn-primary add-selection add" :disabled="this.loading" v-on:click="addForward()">{{$t('webhook-add')}}</button>
                </div>
              </div>

              <div class="row no_side_margin" v-if="!new_forward" >
                <div class="list-scroller" style="overflow-x: auto;">
                  <table class="table table-striped list-table" style="margin-top: 0px">
                    <thead>
                      <tr>
                        <th class="t-head">{{$t('enabled')}}</th>
                        <th class="t-head">{{$t('url')}}</th>
                        <th class="t-head">{{$t('actions')}}</th>
                      </tr>
                    </thead>
                    <tbody v-if="!list.forwards.length">
                      <tr>
                        <td colspan="3" class="text-center">{{$t('no-webhooks')}}</td>
                      </tr>
                    </tbody>
                    <tbody v-if="list.forwards && list.forwards.length">
                      <tr v-for='forward in list.forwards' v-bind:key="forward.createdAt">

                        <td class="table-col text-left">
                          <a v-on:click="toggleForward(forward)" role="button">
                            <Status name="enabled" :value="forward.enabled" />
                          </a>
                        </td>
                        <td class="table-col">{{forward.to_webhook}}</td>
                        <td class="table-col">
                          <div class="total-icon">
                            <div class="icon" v-show="!isZapierWebhook(forward.to_webhook)">
                              <a :title="$t('view')" v-bind:href="'#/lists/'+list.id" v-on:click="editForward(forward)">
                                <font-awesome-icon icon="edit" size="lg"/>
                              </a>
                            </div>
                            <div class="icon" v-show="!isZapierWebhook(forward.to_webhook)">
                              <a :title="$t('delete')" v-bind:href="'#/lists/'+list.id" v-on:click="deleteForward(forward._id)">
                                <font-awesome-icon icon="trash" size="lg"/>
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>


              <div class="row no_side_margin" v-if="new_forward">
                <div class="col-lg-12 total-bg no_side_pad text-left">
                  <h4>{{$t('webhook-new')}}</h4>

                  <div class="profile-settings">


                    <div class="fileld">
                      <div class="row">
                        <label class="col-lg-1">{{$t('enabled')}}</label>
                        <div class="col-lg-11">
                          <input type="checkbox" name="optradio" v-model='forward.enabled' v-on:change="hasToBeSaved()" />
                        </div>
                      </div>
                    </div>

                    <div class="fileld">
                      <div class="row">
                        <label class="col-lg-1">{{$t('url')}}</label>
                        <div class="col-lg-11"><input type="text" class="form-control" id="webhook" v-model='forward.to_webhook' placeholder="Your webhook url" v-on:keyup="hasToBeSaved()" v-on:click="$event.target.select()"></div>
                      </div>
                    </div>

                    <div class="fileld">
                      <div class="row">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-3"><button type="button" class="btn btn-danger btn-sm btn-block add-selection add" v-on:click="cancelForward()">{{$t('cancel')}}</button></div>
                        <div class="col-lg-3"><button :disabled="!this.hasToBeSent" type="button" class="btn btn-primary btn-sm btn-block add-selection add no-margin" v-on:click="saveForward()">{{$t('save')}}</button></div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>



            </div>
          </div>

        </div>
      </div>


      <!-- wizard content start end -->




      <Footer />

    </div>


  </div>

</div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/Sidebar.vue'
import Status from '@/components/Status.vue'
import ListTypePerson from '@/components/Lists/ListTypePerson.vue'
import ListTypeWeb from '@/components/Lists/ListTypeWeb.vue'

import axios from 'axios';

export default {
  name: 'lists',
  data() {
    return {
      script_uri: "https://wetrack.uproc.io/javascripts/main.js",
      isActive: false,
      isActiveIcon: '<',
      lists: [],
      pendingRequest: false,
      editRow: -1,
      editMode: false,
      loading: true,
      startItems: '-',
      finishItems: '-',
      list: {
        elementsFiltered: '',
        elements: '',
        forwards: [],
        currentPage: 0
      },
      search: {
        area: "",
        seniority: "",
        email: "",
        phone: ""
      },
      Emails: '',
      processing: [],
      downloaded: false,
      forwarded: false,
      new_forward: false,
      forward: null,
      forwardOriginal: null,
      hasToBeSent: false,

      shownPeopleFields: false,
      shownWebFields: false
    }

  },
  mounted() {
      this.enableInterceptor();
      this.getUserDetails();
      this.updateListData(true);
      this.getProcessingValues();
  },
  components: {

    Header,
    Sidebar,
    Footer,
    Status,

    ListTypePerson,
    ListTypeWeb

  },
  computed: {
    isNoActiveWebhook: function() {
      var noWebhook = this.loading || !this.list.forwards.length;
      if (this.list && this.list.forwards && this.list.forwards.length) {
        var filtered = this.list.forwards.filter(function(f) {
          return f.enabled;
        });
        if (!filtered.length) {
          noWebhook = true;
        }
      }

      return noWebhook;
    },

    stats: function() {
      var local_stats = {};
      if (this.list.trackStats) {
        local_stats = this.list.trackStats.not_added;
      } else if (this.list.stats) {
      local_stats = this.list.stats.not_added;
      }

      return {
        not_added: local_stats.total ? local_stats.total : 0,
        blacklist: local_stats.blacklist ? local_stats.blacklist : 0,
        filter: local_stats && local_stats.filter ? local_stats.filter : 0,
        balance: local_stats && local_stats.balance ? local_stats.balance : 0,
        duplicated: local_stats && local_stats.duplicated ? local_stats.duplicated : 0
      }
    }
  },

  methods: {
    showPeopleFields() {
      this.shownPeopleFields = !this.shownPeopleFields
    },
    showWebFields() {
      this.shownWebFields = !this.shownWebFields
    },

    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        this.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        this.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    isFirstPage: function() {
      return this.list.currentPage == 0;
    },

    moveBack: function() {
      if(!this.isFirstPage())
      {
        this.moveBackProcess();
        this.updateListData(true);
      }
    },

    moveBackProcess: function() {
      this.list.currentPage--;
    },

    isLastPage: function() {
      return this.list.currentPage == this.list.totalPages-1;
    },

    moveNext: function() {
      if(!this.isLastPage())
      {
        this.moveNextProcess();
        this.updateListData(true);
      }
    },

    moveNextProcess: function() {
      this.list.currentPage++;
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      //console.log(this.isActive);
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },


    getUserDetails: function() {

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };


      var url = '/api/v2/profile';

      this.$http.get(url, config).then((results) => {

        this.user = results.data;


      });

    },

    getCredits: function() {
      return this.user ? this.user.credits : 0;
    },


    hasCards: function() {
      return this.user && this.user.cards.length;
    },

    hasAutoCredits: function() {
      return this.user && this.user.autocredits !== null ? this.user.autocredits : false;
    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },

    areCreditsNeeded: function() {
      return this.processing.processCredits > this.getCredits();
    },

    getBalance: function() {

      return this.user ? this.getCredits() * this.getCostPerCredit() : 0;

    },

    getCostPerCredit: function() {

      return this.processing.costPerCredit;

    },

    getProcessingValues: function() {

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v2/processing';

      this.$http.get(url, config).then((results) => {

        this.processing = results.data;

      });

    },

    showNameInput: function() {
      this.editMode = true;
      this.$nextTick(() => {
        this.$refs.list_name.focus();
        this.$refs.list_name.select();
      });
    },

    //FROM HERE CURRENT LIST AREA FUNCTIONS
    updateListData: function(forced) {
      this.getList(this.$route.params.name, forced);
    },

    getList: function(name, forced) {
      if (!this.pendingRequest && forced) {
        this.pendingRequest = true;
        this.loading = true;
        var config = {
          headers: {
            "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
            "Content-Type": "application/json"
          }
        };
        var url = '/api/v2/list/' + name + '?page=' + this.list.currentPage;
        this.$http.get(url, config).then((results) => { //console.log(this.getCurrentReport(results.data));
          this.list = this.getCurrentReport(results.data); //console.log(this.list);
          this.pendingRequest = false;
          this.loading = false;
          console.log(this.list);
          this.startItems = this.list.pageSize * this.list.currentPage;
          this.finishItems = this.startItems + this.list.elements.length;
        });
      }
    },

    getCurrentReport: function(l) {

      var graphs = [];
      var self = this;
      l.elements.forEach(function(e) {
        graphs = self.updateGraphs(graphs, e, 'area');
        graphs = self.updateGraphs(graphs, e, 'seniority');
        graphs = self.updateGraphs(graphs, e, 'country');
        graphs = self.updateGraphs(graphs, e, 'gender');
        graphs = self.updateGraphs(graphs, e, 'email');
        graphs = self.updateGraphs(graphs, e, 'confidence');
        graphs = self.updateGraphs(graphs, e, 'phone');
      });
      l.graphs = graphs;

      return l;

    },

    updateGraphs: function(graphs, element, name) {
      var currentGraph = graphs.filter(function(i) {
        return i.key == name;
      });
      var newGraphs = graphs.filter(function(i) {
        return i.key != name;
      });
      var currentGraphItem = null;
      if (!currentGraph.length) {
        currentGraphItem = {
          key: name,
          data: []
        };
      } else {
        currentGraphItem = currentGraph[0];
      }

      var currentValue = element && element[name] && element[name].length ? element[name].toLowerCase() : "unknown";

      if (name === 'email') {
        currentValue = currentValue === 'unknown' ? 'false' : 'true';
      }

      if (name === 'phone') {
        currentValue = currentValue === 'unknown' ? 'false' : 'true';
      }

      var currentData = currentGraphItem.data.filter(function(i) {
        return i.key == currentValue;
      });
      var oldData = currentGraphItem.data.filter(function(i) {
        return i.key !== currentValue;
      });

      var item = {};
      if (!currentData.length) {
        item = {
          key: currentValue,
          value: 0
        };
      } else {
        item = currentData[0];
      }

      item.value++;
      oldData.push(item);

      currentGraphItem.data = oldData;
      newGraphs.push(currentGraphItem);

      return newGraphs;
    },


    deleteElements: function() {
      this.loading = true;
      this.pendingRequest = true;

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v2/list/' + this.$route.params.name + '/delete';
      this.$http.get(url, config).then((results) => {
        this.pendingRequest = false
        this.resetFilters()
        this.list = results.data;
        //this.getList(this.$route.params.name, true);
      });
    },

    isZapierWebhook: function(forward) {
      return forward.indexOf("zapier.com") !== -1;
    },


    hasToBeSaved: function() {
      if (this.forward !== null && this.forwardOriginal !== null) {
        this.hasToBeSent = JSON.stringify(this.forward) !== JSON.stringify(this.forwardOriginal)
      } else {
        this.hasToBeSent = false
      }
    },

    addForward: function() {
      this.hasToBeSent = true
      this.new_forward = true
      this.forward = {
        enabled: true,
        to_webhook: "https://beeceptor.com/",
        filters: {
          area: "",
          seniority: "",
          email: "",
          phone: ""
        }
      }
      this.forwardOriginal = {}
    },

    cancelForward: function() {
      this.hasToBeSent = false
      this.new_forward = false
    },

    deleteForward: function(id) {
      var new_forwards = []
      this.list.forwards.filter(function(f) {
        if (f._id !== id) {
          new_forwards.push(f)
        }
      });

      this.list.forwards = new_forwards
      this.saveList()
    },


    saveForward: function() {
      var new_forwards = []
      var aux_forward = JSON.parse(JSON.stringify(this.forward))
      aux_forward.filters = []

      var that = this
      //Save filters
      Object.keys(this.forward.filters).forEach(function(key) {
        aux_forward.filters.push({
          name: key,
          value: that.forward.filters[key]
        })
      });

      this.list.forwards.filter(function(f) {
        if (f._id !== aux_forward._id) {
          new_forwards.push(f)
        } else {
          new_forwards.push(aux_forward)
        }
      });

      if (!aux_forward._id) {
        new_forwards.push(aux_forward)
      }

      this.list.forwards = new_forwards
      this.loading = true
      this.saveList()
    },

    editForward: function(forward) {
      var str = JSON.stringify(forward)
      this.forward = JSON.parse(str)
      this.forwardOriginal = JSON.parse(str)
      this.new_forward = true
      this.hasToBeSent = false
    },

    saveList: function() {
      this.loading = true
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      this.list.name = this.list.name.replace(/\//g, "").trim();

      var url = '/api/v2/list/' + this.list.id;
      this.$http.post(url, this.list, config).then((results) => {
        this.list.forwards = results.data.forwards
        this.forward = null
        this.new_forward = false
        this.loading = false
      });
    },

    executeGenericAction: function(action) {
      this.downloaded = false
      this.forwarded = false
      var filters = this.search
      filters.confidence = filters.email.indexOf("-") !== -1 ? filters.email.split("-")[1] : ""
      filters.email = filters.email.indexOf("-") !== -1 ? filters.email.split("-")[0] : ""
      this.loading = true

      var filtersStr = Object.keys(filters).map(function(key) {
        return key + "=" + filters[key];
      }).join("&")


      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v2/list/' + this.$route.params.name + '/' + action;
      if (this.list.type === 'people') {
        url += '?' + filtersStr
      }

      this.$http.get(url, config).then(() => {
        //var data = results.data;
        this.loading = false
        if (action === 'download') {
          this.downloaded = true
        } else {
          this.forwarded = true
        }
      });
    },

    forwardAction: function() {
      this.executeGenericAction('forward')
    },

    downloadAction: function() {
      this.executeGenericAction('download')
    },

    deleteElementProcess: function(element_id) {
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };
      var url = '/api/v2/list/' + this.list.name + '/' + element_id + '/delete';
      console.log(url);
      this.$http.get(url, config).then(() => {
        this.getList(this.$route.params.name, true);
      });
    },

    deleteElement: function(element_id) {
      //var that = this;
      this.deleteElementProcess(element_id);
      /*
      that.$modal.show('dialog', {
        title: that.$t('list-element-delete-title'),
        text: that.$t('list-element-delete-description'),
        buttons: [
          {
            title: that.$t('accept'),
            handler: () => {
              that.pendingRequest = true;
              //that.deleteListProcess(listId);
              that.$modal.hide('dialog');
            }
          },
          {
            title: that.$t('cancel')
          }
        ]
      });*/
    },

    toggleForward: function(forward) {
      console.log(forward);
      this.forward = forward;
      this.forward.enabled = !this.forward.enabled;
      console.log(this.forward);
      this.saveForward();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/list.css';
@import '../../assets/css/global.css';
</style>
