<template>
<div>
  <div v-if="language === 'en'">
    I see that you want to <strong>improve your sales funnel</strong> ;)<br><br>
    Do you know that with uProc you can get <strong>lots of contacts</strong> for your sales team in a moment?
    <br><br>
    Just follow the next steps:
    <ul>
      <li><strong><a href="/#/settings/profile" target="_blank">Identify</a></strong> yourself for legal purposes.</li>
      <li>Extract and complete <strong>1000 contacts</strong> from <strong>25€</strong>. <strong><a href="/#/purchase/packs" target="_blank">Choose</a></strong> the pack that suits your needs.</li>
      <li><strong><a href="/#/lists" target="_blank">Create</a></strong> the list you want.</li>
      <li>Go to <strong><a href="/#/settings/profile" target="_blank">Profile</a> > Lists</strong> and define the behaviour of your lists.</li>
      <li><strong><a href="https://chrome.google.com/webstore/detail/uproc-for-linkedin/odfhegllgcagejmjbanonlofmllejfea?hl=en&authuser=0" target="_blank">Install</a></strong> the extension and <strong><a href="/#/settings/integration"
            target="_blank">complete</a></strong> the Email and API Key (real) in the bot popup.</li>
      <li><strong>Search</strong> the desired contacts on <strong><a href="https://www.linkedin.com/search/results/people" target="_blank">LinkedIn</a></strong> or <strong><a href="https://www.linkedin.com/sales/search/people" target="_blank">Sales Navigator</a></strong> (reload if bot popup doesn't appear).</li>
      <li><strong>Select</strong> the created list previously in the bot popup.</li>
      <li>Mark <strong>"Enrich"</strong> checkbox to complete contact with personal and company data.</li>
      <li><strong>Click start</strong> to run the bot (don't close/minimize your browser window).</li>
      <li><strong><a href="/#/lists" target="_blank">Check</a></strong> the contacts saved in your list.</li>
    </ul>
    <br>
    Doubts? <strong><a href="https://uproc.io/blog/boost-your-sales-with-uproc-for-linkedin" target="_blank">Follow this tutorial</a></strong> to understand how to extract contacts from LinkedIn.
    <br><br>
    <div class="row">
      <div class="col-lg-4">
      Looking for a fast sample? Check this video to view our bot in action on LinkedIn:
      </div>
      <div class="col-lg-8">
        <div class="video-responsive">
        <iframe width="100%" height="360" src="https://www.youtube.com/embed/VGv7BVNM5rg?VQ=HD720" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
        </div>
      </div>
    </div>
  </div>
  <div v-if="language === 'es'">
    He visto que quieres <strong>mejorar tu embudo de ventas</strong> ;)<br><br>
    ¿Sabes que con uProc puedes obtener <strong>muchos contactos</strong> para tu equipo de ventas en un momento?
    <br><br>
    Solo necesitas seguir los siguientes pasos:
    <ul>
    <li><strong><a href="/#/settings/profile" target="_blank">Identifícate</a></strong> para utilizar la plataforma de forma legal.</li>
      <li>Extrae y completa <strong>1000 contactos</strong> desde <strong>25€</strong>. <strong><a href="/#/purchase/packs" target="_blank">Selecciona</a></strong> el pack que mejor se ajusta a tus necesidades.</li>
      <li><strong><a href="/#/lists" target="_blank">Crea</a></strong> la lista que quieras.</li>
      <li>Accede a <strong><a href="/#/settings/profile" target="_blank">Perfil</a> > Listas</strong> y configura el comportamiento por defecto de tus listas.</li>
      <li><strong><a href="https://chrome.google.com/webstore/detail/uproc-for-linkedin/odfhegllgcagejmjbanonlofmllejfea?hl=en&authuser=0" target="_blank">Instala</a></strong> la extensión y <strong><a href="/#/settings/integration"
            target="_blank">completa</a></strong> el correo y la llave API (real) en el popup del bot.</li>
      <li><strong>Busca</strong> los contactos que quieras en <strong><a href="https://www.linkedin.com/search/results/people" target="_blank">LinkedIn</a></strong> o <strong><a href="https://www.linkedin.com/sales/search/people" target="_blank">Sales Navigator</a></strong> (recarga la página si el bot no aparece).</li>
      <li><strong>Selecciona</strong> la lista creada previamente en el popup del bot.</li>
      <li>Marca el checkbox <strong>"Enrich"</strong> para completar el contacto con datos personales y de empresa.</li>
      <li><strong>Haz click en start</strong> para arrancar el bot (no cierres/minimices la ventana de tu navegador).</li>
      <li><strong><a href="/#/lists" target="_blank">Comprueba</a></strong> los contactos guardados en tu lista.</li>
    </ul>
    <br>
    Dudas? <strong><a href="https://uproc.io/blog/es/boost-your-sales-with-uproc-for-linkedin" target="_blank">Sigue este tutorial</a></strong> para entender como extraer contactos desde LinkedIn.
    <br><br>
    <div class="row">
      <div class="col-lg-4">
      ¿Quieres ver un ejemplo rápido? Comprueba el video para ver el bot en acción en LinkedIn:
      </div>
      <div class="col-lg-8">
        <div class="video-responsive">
        <iframe width="100%" height="360" src="https://www.youtube.com/embed/VGv7BVNM5rg?VQ=HD720" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'WelcomeSales',
  data() {
    return {
      language: this.$i18n.locale
    };
  },
  created() {

  },
  mounted() {}
}
</script>
