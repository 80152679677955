<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>

      <div id="modes" v-if="modePurchase === ''">
        <div class="total-wizard">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item active">{{ $t('menu-purchase' ) }}</li>
            </ol>
          </nav>

          <div class="row no_side_margin">
            <div class="col-lg-12">
              <router-link to="/purchase/packs">
                <div class="item">
                  <div class="icon">
                    <font-awesome-icon icon="cubes" size="3x" />
                  </div>
                  <h3>{{ $t('purchase-packs' ) }}</h3>
                  <p>{{ $t('purchase-packs-subtitle' ) }}</p>
                </div>
              </router-link>
            </div>
            <!--div class="col-lg-6">
              <router-link to="/purchase/custom">
                <div class="item">
                  <div class="icon">
                    <font-awesome-icon icon="file-invoice-dollar" size="3x" />

                  </div>
                  <h3>{{ $t('purchase-custom' ) }}</h3>
                  <p>{{ $t('purchase-custom-subtitle' ) }}</p>
                </div>
              </router-link>
            </div-->
          </div>
        </div>
      </div>

      <div id="purchase_packs" v-if="modePurchase === 'packs'">

        <div class="total-wizard">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <!--li class="breadcrumb-item active">
                <router-link to="/purchase">{{ $t('menu-purchase' ) }}</router-link>
              </li-->
              <li class="breadcrumb-item" aria-current="page">{{ $t('menu-purchase' ) }} {{ $t('menu-packs') }}</li>
            </ol>
          </nav>
          <div class="row">
              <div class="col-sm-9 col-7 col-title">
                  <h3 class="h3title">{{ $t('purchase-pay' ) }}</h3>
                  <div class="create-new-list">
                      <p>{{ $t('purchase-amount' ) }}</p>
                  </div>
              </div>
          </div>


          <!--div class="row no_side_margin text-center">
            <select class="form-control d-block d-lg-none" v-model="amount">
              <option value="10">10 €</option>
              <option value="25">25 €</option>
              <option value="50">50 €</option>
              <option value="100">100 €</option>
              <option value="250">250 €</option>
              <option value="500">500 €</option>
            </select>
          </div-->
          <div class="row mt-5 pt-5text-center">
            <!--div class="col-lg-2">
              <router-link to="/purchase/packs/10">
                <div class="item pricing_tab" v-bind:class="{ selectedPrice: amount === '10' }">
                  <p class="amount">10€</p>
                </div>
              </router-link>
            </div-->
            <div class="col-lg-3">
              <router-link to="/purchase/packs/25">
                <div class="item pricing_tab" v-bind:class="{ selectedPrice: amount === '25' }">
                  <p class="amount">25€</p>
                </div>
              </router-link>
            </div>
            <div class="col-lg-3">
              <router-link to="/purchase/packs/50">
                <div class="item pricing_tab" v-bind:class="{ selectedPrice: amount === '50' }">
                  <p class="amount">50€</p>
                </div>
              </router-link>
            </div>
            <div class="col-lg-3">
              <router-link to="/purchase/packs/100">
                <div class="item pricing_tab" v-bind:class="{ selectedPrice: amount === '100' }">
                  <p class="amount">100€</p>
                </div>
              </router-link>
            </div>
            <div class="col-lg-3">
              <router-link to="/purchase/packs/250">
                <div class="item pricing_tab" v-bind:class="{ selectedPrice: amount === '250' }">
                  <p class="amount">250€</p>
                </div>
              </router-link>
            </div>
            <!--div class="col-lg-2">
              <router-link to="/purchase/packs/500">
                <div class="item pricing_tab" v-bind:class="{ selectedPrice: amount === '500' }">
                  <p class="amount">500€</p>
                </div>
              </router-link>
            </div-->
          </div>
        </div>
      </div>

      <div id="purchase_custom_amount" class="total-wizard" v-if="modePurchase === 'packs' && amount !== ''">
        <div class="row">

        <div class="col-sm-9 col-7 col-title">
          <div class="col-md-12 mb-5">
            <h3 class="h3title">{{ $t('purchase-get' ) }}</h3>
            <p class="h6 grey">{{ $t('purchase-amount-alltools' ) }}</p>
          </div>
        </div>


          <div class="row px-5 mx-5 row-cols-md-4 row-cols-2 text-center">
            <div class="col">
               <div class="icn_hold">
                   <img src="@/assets/images/i1-negative.png" alt="">
               </div>
               <div class="icn_details">
                   <p class="h5 tableDetails black text-center" v-html="$t('purchase-email-verifications', {amount: parseInt((amount / 0.0025) * ratio, 10)})"></p>
               </div>
           </div>
           <div class="col">
               <div class="icn_hold">
                   <img src="@/assets/images/i1-negative.png" alt="">
               </div>
               <div class="icn_details">
                   <p class="h5 tableDetails black text-center" v-html="$t('purchase-email-discovers', {amount: parseInt((amount / 0.01) * ratio, 10)})"></p>
               </div>
           </div>

           <div class="col">
               <div class="icn_hold">
                   <img src="@/assets/images/t1-negative.png" alt="">
               </div>
               <div class="icn_details">
                   <p class="h5 tableDetails black text-center" v-html="$t('purchase-mobile-verifications', {amount: parseInt((amount / 0.008) * ratio, 10)})"></p>
               </div>
           </div>


           <div class="col">
               <div class="icn_hold">
                   <img src="@/assets/images/t2-negative.png" alt="">
               </div>
               <div class="icn_details">
                   <p class="h5 tableDetails black text-center" v-html="$t('purchase-extract-linkedin', {amount: parseInt((amount / 0.025) * ratio, 10)})"></p>
               </div>
           </div>


           <div class="col">
               <div class="icn_hold">
                <img src="@/assets/images/t3-negative.png" alt="">
               </div>
               <div class="icn_details">
                   <p class="h5 tableDetails black text-center" v-html="$t('purchase-enrich-contacts', {amount: parseInt((amount / 0.02) * ratio, 10)})"></p>
               </div>
           </div>


           <div class="col">
               <div class="icn_hold">
                   <img src="@/assets/images/t3-negative.png" alt="">
               </div>
               <div class="icn_details">
                   <p class="h5 tableDetails black text-center" v-html="$t('purchase-enrich-company', {amount: parseInt((amount / 0.02) * ratio, 10)})"></p>
               </div>
           </div>

           <div class="col">
               <div class="icn_hold">
                   <img src="@/assets/images/t4-negative.png" alt="">
               </div>
               <div class="icn_details">
                   <p class="h5 tableDetails black text-center" v-html="$t('purchase-split-address', {amount: parseInt((amount / 0.02) * ratio, 10)})"></p>
               </div>
           </div>

           <div class="col">
               <div class="icn_hold">
                   <img src="@/assets/images/t10-negative.png" alt="">
               </div>
               <div class="icn_details">
                   <p class="h5 tableDetails black text-center" v-html="$t('purchase-generate-audio', {amount: parseInt((amount / 0.02) * ratio, 10)})"></p>
               </div>
           </div>
          </div>
          <!-- toggle button end-->
          <div class="col-lg-12 no_side_pad">
            <div class="tool-select">
              <div>
                <div class="data">
                  <div class="row" v-if="this.hasAutoCredits()">
                    <div class="col-lg-12 col-md-12">
                      <strong>{{ $t('purchase-pay2' ) }} <div class="amount">{{this.cost.total | numFormat("0.00")}}€</div>
                      <div class="calc">{{this.cost.base | numFormat("0.00")}}€ + {{this.processing.vat}}% VAT</div>{{ $t('purchase-pay-with' ) }}
                      </strong>
                    </div>
                  </div>

                  <div class="row" v-if="!this.hasAutoCredits()">
                    <div class="col-lg-3 col-sm-12">
                      <form class="">
                        <div>
                          <p>{{ $t('purchase-promocode' ) }}</p>
                          <input type="text" class="form-control" id="formGroupExampleInput" v-model="promo_code.requested" v-on:click="$event.target.select()" :placeholder="$t('purchase-code')">
                          <button type="button" class="btn btn-primary" :disabled="!promo_code.requested.length" v-on:click="checkPromoCode()">{{$t('apply')}}</button>
                          <div class="text-success" v-if="code_sent && promo_code.accepted.length">{{$t('promocode-accepted')}}: <strong>{{promo_code.accepted}}</strong>.<br><p class="text-success" v-html="$t('promocode-get', {amount: promo_code.amount.toFixed(2)})"></p></div>
                          <div class="text-danger" v-if="code_sent && !promo_code.accepted.length">{{$t('promocode-invalid')}}</div>
                        </div>
                      </form>
                    </div>

                    <div class="col-lg-6 col-md-12">

                      <strong>{{ $t('purchase-pay2' ) }} <div class="amount">{{this.cost.total | numFormat("0.00")}}€</div>
                      <div class="calc">{{this.cost.base | numFormat("0.00")}}€ + {{this.processing.vat}}% VAT</div>{{ $t('purchase-pay-with' ) }}
                      </strong>
                    </div>
                  </div>

                  <div>
                    <div class="payment-process row text-center">

                      <div class="col-lg-6 offset-lg-3"  v-bind:class="{disabled: this.loading}">
                        <div id="paypal-button-container"></div>
                        <button :disabled="this.user.autocredits || this.amount === 0" type="button" class="btn btn-primary btn-lg btn-block" v-on:click="setMethod('creditcard'); buy()"><font-awesome-icon icon="credit-card" size="1x"/> {{ $t('wizard-budget-creditcard' ) }}</button>
                        <!--button :disabled="this.user.autocredits || this.amount === 0" type="button" class="btn btn-primary btn-lg btn-block" v-on:click="setMethod('bank'); buy()"><font-awesome-icon icon="money-check" size="1x"/> {{ $t('wizard-budget-bank' ) }}</button-->
                      </div>

                      <div class="container" v-if="this.purchaseDone">
                        <br>
                        <div class="col-lg-12 text-center text-success">
                          <div v-if="this.purchaseResult && this.oldMethod === 'creditcard'"><strong v-html="$t('purchase-ok-creditcard')"></strong></div>
                          <div v-if="this.purchaseResult && this.oldMethod === 'paypal'"><strong v-html="$t('purchase-ok-paypal')"></strong></div>
                          <div v-if="this.purchaseResult && this.oldMethod === 'bank'"><strong>{{$t('purchase-ok-email')}}</strong></div>
                          <div v-if="!this.purchaseResult"><strong>{{$t('purchase-ko')}}</strong></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- puchase section end -->
          </div>
        </div>
      </div>

      <div id="purchase_custom" v-if="modePurchase === 'custom'">

        <div class="row no_side_margin" style="margin-left: 2px;">

          <div class="col-lg-12 total-wizard">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active">
                  <router-link to="/purchase">{{ $t('menu-purchase' ) }}</router-link>
                </li>
                <li class="breadcrumb-item" aria-current="page">{{ $t('menu-custom') }}</li>
              </ol>
            </nav>
          </div>
          <div class="col-lg-12  total-purchase">
            <div class="title">

              <div class="text">{{ $t('wizard-budget-volume' ) }}</div>
              <div class="field">
                <div class="input-group">
                  <div class="pur-icon">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M2.5 4C3.32843 4 4 3.32843 4 2.5C4 1.67157 3.32843 1 2.5 1C1.67157 1 1 1.67157 1 2.5C1 3.32843 1.67157 4 2.5 4ZM2.5 5C3.88071 5 5 3.88071 5 2.5C5 1.11929 3.88071 0 2.5 0C1.11929 0 0 1.11929 0 2.5C0 3.88071 1.11929 5 2.5 5Z"
                        fill="#0300e2" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17 3H7V2H17V3Z" fill="#0300e2" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M2.5 10C3.32843 10 4 9.32843 4 8.5C4 7.67157 3.32843 7 2.5 7C1.67157 7 1 7.67157 1 8.5C1 9.32843 1.67157 10 2.5 10ZM2.5 11C3.88071 11 5 9.88071 5 8.5C5 7.11929 3.88071 6 2.5 6C1.11929 6 0 7.11929 0 8.5C0 9.88071 1.11929 11 2.5 11Z"
                        fill="#0300e2" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17 9H7V8H17V9Z" fill="#0300e2" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M2.5 16C3.32843 16 4 15.3284 4 14.5C4 13.6716 3.32843 13 2.5 13C1.67157 13 1 13.6716 1 14.5C1 15.3284 1.67157 16 2.5 16ZM2.5 17C3.88071 17 5 15.8807 5 14.5C5 13.1193 3.88071 12 2.5 12C1.11929 12 0 13.1193 0 14.5C0 15.8807 1.11929 17 2.5 17Z"
                        fill="#0300e2" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17 15H7V14H17V15Z" fill="#0300e2" />
                    </svg>
                  </div>
                  <input type="number" class="form-control records" v-model="volume" v-on:change="recalculate()" v-on:click="$event.target.select()">
                </div>
              </div>
            </div>
          </div>

          <!-- toggle button -->


          <!-- toggle button end-->
          <div class="col-lg-12 no_side_pad">
            <div class="tool-select">
              <h4>{{ $t('wizard-budget-processors' ) }}</h4>
              <div class="row no_side_margin">
                <div class="category col-lg-12">
                  <div class="row">

                    <!-- select item start -->
                    <div class="col-lg-4">
                      <div class="item">
                        <div class="col-lg-12 no_side_pad">
                          <h2 class="number">1
                            <span v-if="mode == 'groups'">{{ $t('wizard-budget-category') }}</span>
                            <span v-if="mode == 'fields'">{{ $t('wizard-budget-field') }}</span>
                          </h2>

                          <div class="toggle-button">
                            <div class="tools-apply">
                              <div class="switch switch-blue">
                                <input type="radio" class="switch-input" name="view2" value="groups" id="month2" v-model="mode" :checked="mode == 'groups'">
                                <label for="month2" class="switch-label switch-label-off">{{ $t('menu-purchase-category') }}</label>
                                <input type="radio" class="switch-input" name="view2" value="fields" id="week2" v-model="mode" :checked="mode == 'fields'">
                                <label for="week2" class="switch-label switch-label-on">{{ $t('purchase-custom-field') }}</label>
                                <span class="switch-selection"></span>
                              </div>
                            </div>
                          </div>
                          <div class=" col-12 no_side_pad  select_period col-text">

                            <div class="btn-group ">


                              <a v-if="mode == 'fields' && this.groupView !== 'select-option'" class="btn btn-primary dropdown-toggle drp_btn" data-toggle="dropdown" href="#">{{ this.groupView }} <span class="caret"></span></a>
                              <a v-if="mode == 'groups' || this.groupView === 'select-option'" class="btn btn-primary dropdown-toggle drp_btn" data-toggle="dropdown" href="#">{{ $t(this.groupView) }} <span class="caret"></span></a>

                              <ul class="dropdown-menu">
                                <li class="dropdown-item" v-for="group in this.groups" v-bind:key="group.name"><a v-on:click="selectCategory(group.translated , group.name)">{{group.translated}}</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- select item start end -->


                    <!-- select item start -->
                    <div :class="this.selectType ">
                      <!-- disabled -->
                      <div class="item">
                        <div class="col-lg-12 no_side_pad">
                          <h1 class="number">2
                            <span>{{ $t('wizard-budget-action') }}</span>
                          </h1>

                          <div class=" col-12 no_side_pad  select_period col-text">

                            <div class="btn-group ">


                              <a class="btn btn-primary dropdown-toggle drp_btn" data-toggle="dropdown" href="#">{{ $t(this.typeView ) }} <span class="caret"></span></a>
                              <ul class="dropdown-menu">

                                <li class="dropdown-item" v-for="type in this.availableTypes" v-bind:key="type"><a v-on:click="changeType('type-' + type , type)">{{ $t('type-' + type) }}<!-- {{'type-' + type | typeFilter}} --></a></li>

                              </ul>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- select item start end -->

                    <!-- select item start -->
                    <div :class="this.selectTool ">
                      <div class="item">
                        <div class="col-lg-12 no_side_pad">
                          <h1 class="number">3
                            <span>{{ $t('wizard-budget-processor') }}</span>
                          </h1>

                          <div class=" col-12 no_side_pad  select_period col-text">
                            <!-- <div class="text">Select Period:</div> -->

                            <div class="btn-group ">



                              <a v-if="this.toolView === 'select-option'" class="btn btn-primary dropdown-toggle drp_btn" data-toggle="dropdown" href="#">{{ $t(this.toolView) }}<span class="caret"></span></a>
                              <a v-if="this.toolView !== 'select-option'" class="btn btn-primary dropdown-toggle drp_btn" data-toggle="dropdown" href="#">{{ this.toolView }}<span class="caret"></span></a>

                              <ul class="dropdown-menu">

                                <li class="dropdown-item" v-for="processor in this.processors" v-bind:key="processor.key"><a v-on:click="changeTool('type-' + processor.type + '-verb' , processor.description + ' '+  processor.price + '€/u', processor.key)">
                                    {{ $t('type-' + processor.type + '-verb') }} {{processor.description}} ({{processor.price }}€/u) </a></li>

                              </ul>



                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- select item start end -->
                  </div>
                  <button type="button" class="btn btn-primary btn-lg btn-block add-btn" :disabled="!this.processorD.length" v-on:click="setProcessor()">{{ $t('wizard-budget-add' ) }}</button>
                </div>


              </div>


              <!-- budget detail start -->

              <div class="budget-detail " v-if="selectedProcessors.length">
                <div class="row">
                  <div class="col-lg-8 col-md-8">
                    <h2>{{ $t('wizard-budget-details' ) }}</h2>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="clear-btn pointer" v-if="selectedProcessors.length" v-on:click="cleanAll()">{{ $t('clean-all' ) }}
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M6.00008 6.97848L1.64539 11.3332L0.666748 10.3545L5.02144 5.99984L0.666748 1.64514L1.64539 0.666504L6.00008 5.0212L10.3548 0.666504L11.3334 1.64514L6.97872 5.99984L11.3334 10.3545L10.3548 11.3332L6.00008 6.97848Z"
                          fill="#0300e2" />
                      </svg>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div style="overflow-x: auto;">
                      <table class="table table-striped list-table">
                        <thead>
                          <tr>
                            <th class="t-head">{{ $t('processor' ) }}</th>
                            <th class="t-head">{{ $t('unit' ) }}</th>
                            <th class="t-head">{{ $t('rows' ) }}</th>
                            <th class="t-head">{{ $t('subtotal' ) }}</th>
                            <th class="t-head"></th>
                          </tr>
                        </thead>
                        <tbody>


                          <tr v-for="processor in this.selectedProcessors" v-bind:key="processor.key">
                            <td class="table-col">{{processor.translatedGroup}} - {{ $t('type-' + processor.type + '-verb') }} {{processor.description}}</td>
                            <td class="table-col">{{processor.price_volume | numFormat("0.0000")}}€</td>
                            <td class="table-col">{{volume}}</td>
                            <td class="table-col">{{Math.ceil(processor.price_volume * volume * 100) / 100| numFormat("0.00") }}€</td>
                            <td class="table-col">
                              <a title="Delete Tool" v-on:click="removeProcessor(processor.key)">
                                <font-awesome-icon icon="trash" size="lg"/>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <!-- budget detail end -->
              <!-- puchase section start -->
              <div class="add-purchase" v-if="this.selectedProcessors.length">
                {{$t('purchase-add-another')}}
              </div>

              <div class="purchase" v-if="this.selectedProcessors.length">


                <div class="balance" v-if="this.selectedProcessors.length && this.getBalance()>0 && !this.hasAutoCredits()">
                  <p>{{ $t('your-balance' ) }}:</p>
                  <h4>{{this.getBalance() | numFormat("0.00")}}€</h4>
                  <p v-if="this.hasCash" v-html="$t('buy-enough-cash')"></p>
                </div>

                <div class="data" v-if="!this.hasCash">
                  <h6>{{ $t('payment-needed' ) }}:</h6>
                  <div class="row" v-if="this.hasAutoCredits()">
                    <div class="col-lg-12 col-md-12">

                      <div class="amount">{{this.cost.total | numFormat("0.00")}}€</div>
                      <div class="calc" v-if="this.cost.vat > 0">{{this.cost.base | numFormat("0.00")}}€ + {{this.processing.vat}}% VAT</div>
                    </div>

                    <div class="col-lg-12 text" v-if="this.hasDiscount && this.cost.base > 0 && !this.hasAutoCredits()">* {{ $t('buy-phrase-4' ) }} <b>{{(this.cost.base * (this.discountPercentage / 100)).toFixed(2)}}&euro;</b>
                      {{ $t('buy-phrase-5' ) }}
                    </div>

                    <div class="col-lg-12 text-center" v-if="this.cost.savings > 0">{{ $t('balance-updated' ) }} <b>{{this.cost.base_original | numFormat("0.00")}}€</b></div>

                  </div>
                  <div class="row" v-if="!this.hasAutoCredits()">
                    <div class="col-lg-3 col-sm-12">
                      <!--form class="">
                        <div>
                          <p>{{ $t('purchase-promocode' ) }}</p>
                          <input type="text" class="form-control" id="formGroupExampleInput" v-model="promo_code.requested" v-on:click="$event.target.select()" :placeholder="$t('purchase-code')">
                          <button type="button" class="btn btn-primary" :disabled="!promo_code.requested.length" v-on:click="checkPromoCode()">{{$t('apply')}}</button>
                          <div class="text-success" v-if="code_sent && promo_code.accepted.length">{{$t('promocode-accepted')}}: <strong>{{promo_code.accepted}}</strong>.<br><p class="text-success" v-html="$t('promocode-get', {amount: promo_code.amount.toFixed(2)})"></p></div>
                          <div class="text-danger" v-if="code_sent && !promo_code.accepted.length">{{$t('promocode-invalid')}}</div>
                        </div>
                      </form-->
                    </div>

                    <div class="col-lg-6 col-md-12">

                      <div class="amount">{{this.cost.total | numFormat("0.00")}}€</div>
                      <div class="calc" v-if="this.cost.vat > 0">{{this.cost.base | numFormat("0.00")}}€ + {{this.processing.vat}}% VAT</div>
                    </div>

                    <div class="col-lg-12 text" v-if="this.hasDiscount && this.cost.base > 0 && !this.hasAutoCredits()">* {{ $t('buy-phrase-4' ) }} <b>{{(this.cost.base * (this.discountPercentage / 100)).toFixed(2)}}&euro;</b>
                      {{ $t('buy-phrase-5' ) }}
                    </div>
                    <div class="col-lg-12 text" v-if="this.cost.savings > 0">{{ $t('balance-updated' ) }} <b>{{this.cost.base_original | numFormat("0.00")}}€</b></div>

                  </div>


                  <div>
                    <div class="payment-process row " v-if="this.logined && !this.hasAutoCredits() && !this.hasCash">
                      <div class="col-lg-6 offset-lg-3" v-bind:class="{disabled: this.loading}">
                        <router-link :to="'/purchase/packs/' + custom_pack" class="btn btn-primary btn-lg btn-block">{{$t('purchase-custom-pack', {amount: custom_pack})}}</router-link>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="this.hasAutoCredits && this.user.advanced">
                  <div class="col-lg-4 offset-lg-4">
                    Environment
                    <select class="form-control" v-model="environment">
                      <option value="api">API</option>
                      <option value="integromat">Make</option>
                      <option value="n8n">n8n</option>
                      <option value="sheets">Google Sheets</option>
                      <option value="wizard">Wizard</option>
                      <option value="zapier">Zapier</option>
                    </select>
                    <br>
                  </div>
                  <div class="col-lg-4 offset-lg-4">
                    Language
                    <select class="form-control" v-model="language">
                      <option value="en">English</option>
                      <option value="es">Spanish</option>
                    </select>
                  </div>
                  <div class="col-lg-6 offset-lg-3">
                    <button type="button" class="btn btn-primary btn-lg btn-block" v-on:click="sendProposal();">{{ $t('wizard-budget-proposal' ) }}</button>
                  </div>
                </div>

              </div>
            </div>


            <!-- puchase section end -->

          </div>

        </div>


      </div>


      <Footer />

    </div>


  </div>

</div>
</template>

<script>
import Vue from 'vue'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/Sidebar.vue'

import axios from 'axios';

export default {
  name: 'Purchase',
  data() {
    return {
      isActive: false,
      isActiveIcon: '<',
      volume: 50000,
      processorD: '',
      method: '',
      checkout: false,
      paymentResult: false,
      paymentDone: false,
      view: 'editor',
      groups: '',
      Allgroups: '',
      group: '',
      groupView: 'select-option',
      type: '',
      availableTypes: [],
      processors: [],
      processing: [],
      mode: 'fields',
      selectType: 'col-lg-4 disabled',
      typeView: 'select-option',
      selectTool: 'col-lg-4 disabled',
      toolView: 'select-option',
      selectedProcessors: [],
      cost: {
        total: 0,
        base: 0,
        vat: 0
      },
      hasCash: false,
      user: null,
      hasDiscount: false,
      discountPercentage: 0,
      logined: false,
      loading: false,
      purchaseDone: false,
      purchaseResult: '',
      hasCompletedFiscalData: false,
      config: {},

      modePurchase: 'packs',
      amount: '',
      ratio: 1,

      promo_code: {
        requested: "",
        accepted: "",
        type: "",
        amount: "",
        originalAmount: ""
      },
      code_sent: false,
      loadedPaypal: false,

      packs: [25, 50, 100, 250],

      paypalButton: null,

      environment: 'sheets',
      language: this.$i18n.locale

    };
  },
  created() {

  },
  mounted() {
      if (window && !window.paypal) {
        var locale = 'en_US';
        if (this.$i18n.locale === 'es') {
          locale = 'es_ES'
        }

        var paypalUrl = 'https://www.paypal.com/sdk/js?currency=EUR&disable-funding=sofort,credit,card&locale=' + locale + '&client-id=';
        paypalUrl += (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development2prod' ? 'ARW8ghcommWpJ67MgXSUGLTLq152aA-BxZqrZ2QD8BntwhAUurlCwhv2bLwIdopWx33O0RhoE8PbBVmY' : 'AbJQ8oz_yiu3y4qf7TgIaKJCOyqBRtKfZY5CsQhMf67hs1DSi1nMiP-0LfMc0v4E21YSGb8MCtvBqzYB');

        this.includeScript(paypalUrl, function(){
          this.configurePaypal();
        }.bind(this) );
      }
      this.logined = true;
      this.enableInterceptor();

      this.config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      this.getProcessorDetails();

  },
  components: {
    Header,
    Sidebar,
    Footer
  },
  watch: {
    '$route'() {
      if (this.paypalButton) {
        var paypalContainer = document.getElementById("paypal-button-container");
        if (paypalContainer) {
          paypalContainer.innerHTML = "";
        }
        this.loadedPaypal = false;
      }
      this.initPacks();
    },
    mode: function() {
      if (this.mode == 'fields') {
        this.getFieldDetails();
      } else {
        this.getCategoryDetails();
      }

      this.groupView = 'select-option';
      this.typeView = 'select-option';
      this.toolView = 'select-option';
      this.selectType = 'col-lg-4 disabled';
      this.selectTool = 'col-lg-4 disabled';
    }

  },
  methods: {
    includeScript( URL, callback ){
        let documentTag = document, tag = 'script',
            object = documentTag.createElement(tag),
            scriptTag = documentTag.getElementsByTagName(tag)[0];
        object.src = URL;
        if (callback) {
          object.addEventListener('load', function (e) { callback(null, e); }, false);
        }
        scriptTag.parentNode.insertBefore(object, scriptTag);
    },

    configurePaypal() {
      var that = this;
      var inPacks = this.$route.path.indexOf("packs") !== -1 && this.$route.path.indexOf("custom") === -1;

      if (!this.loadedPaypal && inPacks && this.cost.base > 0 && !this.hasAutoCredits()) {
        var purchaseUrl = this.$apiBase + '/api/v2/purchase_paypal';
        var config = this.config;
        var amount = (parseInt(this.cost.base * 100, 10) / 100);
        var amountVat = (parseInt(this.cost.total * 100, 10) / 100) + '';
        var promo = this.promo_code.accepted;
        var http = this.$http;
        var getUserDetailsFn = this.getUserDetails;

        if (window.paypal && window.paypal.Buttons) {
          that.loadedPaypal = true;
          that.paypalButton = window.paypal.Buttons({
            env: process.env.NODE_ENV === 'production' ? 'live' : 'sandbox',
            style: {
              layout:  'vertical',
              color:   'blue',
              shape:   'rect',
              label:   'paypal',
              size:    'large'
            },
            createOrder: function(data, actions) {
              that.loadedPaypal = true
              // This function sets up the details of the transaction, including the amount and line item details.
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: (parseInt(amountVat * 100, 10) / 100)
                  }
                }]
              });
            },
            onApprove: function(data, actions) {
              that.loadedPaypal = true;
              that.loading = true;
              // This function captures the funds from the transaction.
              return actions.order.capture().then(function(details) {
                // This function shows a transaction success message to your buyer.
                var data = {
                  purchase: details,
                  promo: promo,
                  amount: (parseInt(amount * 100, 10) / 100),
                  amountVat: (parseInt(amountVat * 100, 10) / 100),
                  env: process.env.NODE_ENV === 'production' ? 'live' : 'sandbox'
                };
                http.post(purchaseUrl, data, config).then((results) => {
                  that.loading = false;
                  that.oldMethod = that.method;
                  //reload user
                  that.purchaseResult = results.data.result;
                  that.purchaseDone = true;
                  getUserDetailsFn();
                  if (results.data.result) {
                    that.$router.push('/purchase/result/success')
                  } else {
                  that.$router.push('/purchase/result/cancel')
                  }
                });
              });
            },
            onError: function (e) {
              console.log(e);
              that.loadedPaypal = false;
              Vue.nextTick(function () {
                that.configurePaypal();
              });
            }
          }).render('#paypal-button-container');
        }
      }
    },

    checkPromoCode() {
      this.code_sent = false
      var url = '/api/v2/promo_code/' + this.promo_code.requested;
      this.$http.get(url, this.config).then((results) => {
        this.code_sent = true
        if (results.data.available) {
          this.promo_code.accepted = this.promo_code.requested;
          this.promo_code.requested = "";
          this.promo_code.type = results.data.type;
          this.promo_code.originalAmount = results.data.amount;
          this.recalculatePromo();
        }
      });
    },
    initPromoCode() {
      this.code_sent = false
      this.promo_code = {
        requested: "",
        accepted: "",
        type: "",
        amount: "",
        originalAmount: ""
      }
    },

    getNearerPack(goal) {
      var counts = this.packs;

      var closest = counts.reduce(function(prev, curr) {
        return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
      });

      return closest;
    },

    initPacks() {
      this.initPromoCode();
      this.modePurchase = this.$route.params.modePurchase || ''
      this.amount = this.$route.params.amount || ''
      if (this.amount && this.amount < 25) {
        if (this.user && this.user.email && this.user.email.indexOf('killia.com') === -1 && this.user.email.indexOf('uproc.io') === -1 && this.user.email.indexOf('mcolomer@gmail.com') === -1) {
          this.amount = "";
        }
      }

      if (this.modePurchase === 'packs' && this.amount === '') {
        this.amount = "50";
      }
      if (this.amount.length) {
        var found = this.packs.indexOf(this.amount) !== -1;
        if (!found) {
          var nearer = this.getNearerPack(this.amount);
          this.$router.push('/purchase/packs/' + nearer);
          this.amount = nearer + "";
        }

        this.cost.base = parseFloat(this.amount);
        this.ratio = 1;
        if (this.amount >= 500) {
          this.ratio = 1.03;
        } else if (this.amount >= 250) {
          this.ratio = 1.02;
        } else if (this.amount >= 100) {
          this.ratio = 1.01;
        }
        this.recalculateCosts();
        this.configurePaypal();
      }
    },

    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        this.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        this.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;

      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },

    resetSelectors: function() {
      this.typeView = 'select-option'
      this.toolView = 'select-option';

    },

    selectCategory: function(translation, name) {

      this.groupView = translation;

      this.group = name;

      this.type = '';
      this.selectType = 'col-lg-4';

      this.selectTool = 'col-lg-4 disabled';
      this.processorD = '';

      this.filterTypes();
      this.resetSelectors();
    },

    changeType: function(translation, type) {
      this.typeView = translation;
      this.toolView = 'select-option';
      this.selectTool = 'col-lg-4 disabled';

      this.processorD = '';

      if (this.group.length && type.length) {
        var fn = this.mode == 'groups' ? this.getFilteredProcessorsWizard : this.getFilteredProcessorsByParamNameWizard;
        this.processors = fn(this.group, type, this.processing);

        if (this.processors.length && type.length) {
          this.selectTool = 'col-lg-4';
        }
      }
    },

    changeTool: function(translation, rest, key) {

      this.toolView = this.$t(translation) + ' ' + rest;
      this.processorD = key;

    },

    getFilteredProcessors: function(group, type, processing) {

      var finalProcessors = [];
      if (group && group.length) {
        this.processor.forEach(function(processor) {
          var found = processor.groups.indexOf(group) !== -1;
          if (type && type.length) {
            found = found && processor.type === type;
          }
          if (found) {
            processor.price = processor.cost * processing.costPerCredit;
            finalProcessors.push(processor);
          }
        });
        return finalProcessors.sort(function(item1, item2) {
          return item1.description.localeCompare(item2.description);
        });

      } else {
        finalProcessors = this.processor;
      }

      return finalProcessors;

    },

    getUniqueAvailableParamNames: function(items) {

      var params = [];
      if (items) {

        items.forEach(function(p) {

          var paramNames = p.params.map(function(p) {
            return p.name.replace(/[0-9]+/g, '');
          });
          var firstParam = paramNames.length > 0 ? paramNames[0] : "";
          if (params.indexOf(firstParam) === -1) {
            params.push(firstParam);
          }

        });
      }
      return params.sort();
    },

    getCategoryDetails: function() {
      //var url = '/api/v2/group/search'
      var url = '/json/' + this.$locale + '/groups.json?' + this.$prefix;
      //this.$http.get(url, this.config).then((results) => {
      this.$http({method: 'GET', url: url, baseURL: '/'}).then((results) => {
        this.groups = results.data.groups.sort();
      });
    },

    getProcessorDetails: function() {
      //var url = '/api/v2/processor/search';
      var url = '/json/' + this.$locale + '/processors.json?' + this.$prefix;
      this.loading = true;
      //this.$http.get(url, this.config).then((results) => {
      this.$http({method: 'GET', url: url, baseURL: '/'}).then((results) => {
        this.loading = false;
        this.processor = results.data.processors;
        this.groups = this.getUniqueAvailableParamNames(this.processor).map(function(item) {
          return {
            name: item,
            translated: item
          }
        });
        this.getProcessingValues();
      });

    },

    getProcessingValues: function() {
      var url = '/api/v2/processing';
      this.$http.get(url, this.config).then((results) => {
        this.processing = results.data;
        if (this.processing.packages) {
          this.discountPercentage = parseFloat(this.processing.packages[this.getCurrentPlan()]);
          this.hasDiscount = this.discountPercentage > 0;
        }
        this.getUserDetails();
      });
    },

    getUserDetails: function() {
      var url = '/api/v2/profile';
      this.$http.get(url, this.config).then((results) => {
        this.user = results.data;
        if (this.$store.getters.needs_complete) {
          alert(this.$t('profile-complete'))
          this.$router.push('/settings/profile')
        }
        var url = '/json/' + this.$locale + '/groups.json?' + this.$prefix;
        //this.$http.get(url, this.config).then((results) => {
        this.$http({method: 'GET', url: url, baseURL: '/'}).then((results) => {
          this.Allgroups = results.data.groups.sort();
          this.initPacks()
        });
      });
    },

    getFieldDetails: function() {
      this.groups = this.getUniqueAvailableParamNames(this.processor).map(function(item) {
        return {
          name: item,
          translated: item
        }
      });

    },

    filterTypes: function() {
      this.availableTypes = [];
      if (this.group.length) {
        var fn = this.mode == 'groups' ? this.getFilteredProcessorsWizard : this.getFilteredProcessorsByParamNameWizard;
        if (fn(this.group, 'normalize', this.processing).length > 0) {
          this.availableTypes.push('normalize');
        }

        if (fn(this.group, 'check', this.processing).length > 0) {
          this.availableTypes.push('check');
        }

        if (fn(this.group, 'get', this.processing).length > 0) {
          this.availableTypes.push('get');
        }

        if (fn(this.group, 'send', this.processing).length > 0) {
          this.availableTypes.push('send');
        }
      }
    },

    getFilteredProcessorsWizard: function(group, type, processing) {
      var finalProcessors = [];
      if (group && group.length && type && type.length) {
        this.processor.forEach(function(processor) {
          if (processor.groups.indexOf(group) !== -1 && processor.type === type) {
            processor.price = processor.cost * processing.costPerCredit;
            finalProcessors.push(processor);
          }
        });
        return finalProcessors.sort(function(item1, item2) {
          return item1.description.localeCompare(item2.description);
        });

      } else {
        finalProcessors = this.processor;
      }

      return finalProcessors;
    },

    getFilteredProcessorsByParamNameWizard: function(param, type, processing) {
      var finalProcessors = [];

      if (param && param.length && type && type.length) {
        this.processor.forEach(function(processor) {
          var found = processor.params.filter(function(p) {
            return p.name.indexOf(param) !== -1;
          });
          if (found.length && processor.type === type) {
            processor.price = processor.cost * processing.costPerCredit;
            finalProcessors.push(processor);
          }
        });
        return finalProcessors.sort(function(item1, item2) {
          return item1.description.localeCompare(item2.description);
        });
      } else {
        finalProcessors = this.processor;
      }

      return finalProcessors;
    },

    setProcessor: function() {
      if (this.processorD.length) {
        var processor = this.processorD;
        var selected = this.processors.filter(function(item) {
          return item.key === processor;
        });

        if (!this.selectedProcessors.length) {
          this.loadedPaypal = false;
        }

        if (selected.length) {
          this.group = "";
          this.type = "";
          this.processorD = "";
          this.processors = [];
          this.availableTypes = [];
          selected[0].translatedGroup = this.getTranslatedGroupText(selected[0].groups[0]);
          this.selectedProcessors.push(selected[0]);
          this.recalculate();
          this.view = 'summary';
          this.groupView = 'select-option';
          this.selectType = 'col-lg-4 disabled';
          this.typeView = 'select-option';
          this.selectTool = 'col-lg-4 disabled';
          this.toolView = 'select-option';
          this.configurePaypal();
        }

      }

    },

    getTranslatedGroupText: function(name) {

      var translatedText = "";
      name = name || 'all';
      if (name && name.length && this.Allgroups) {
        var items = this.Allgroups.filter(function(g) {
          return g.name === name
        });
        translatedText = items.length === 1 ? items[0].translated : translatedText;
      }
      return translatedText;

    },

    recalculatePromo: function() {
      if (this.promo_code.accepted.length) {
        if (this.promo_code.type === 'amount') {
          this.promo_code.amount = this.promo_code.originalAmount;
        } else {
          this.promo_code.amount = parseInt((((parseInt(this.promo_code.originalAmount, 10) / 100) * this.cost.base) * 1000), 10) / 1000;
        }
      }
    },

    sendProposal: function() {
      var data = {
        email: this.user.email,
        tools: this.selectedProcessors.map(function(p) {
          return p.key;
        }).join(","),
        language: this.language,
        environment: this.environament,
      }
      var url = '/api/v2/send_proposal';
      this.loading = true;
      this.$http.post(url, data, this.config).then(() => {
        this.loading = false;
        this.proposalDone = true;
      });
    },

    recalculateCosts: function() {
      var vatMultiplier = this.getVatMultiplier();
      this.cost.total = this.cost.base * vatMultiplier;
      this.cost.vat = (vatMultiplier - 1) * 100;
      this.cost.savings = this.cost.base_original - this.cost.base;
      this.recalculatePromo();
    },

    recalculate: function() {
      var vatMultiplier = this.getVatMultiplier();

      var getPriceByVolume = function(obj) {
        var tiers = obj.tiers;
        var volume = that.volume;
        var price = obj.price;
        if (tiers.length) {
          tiers.forEach(function(t) {
            if (t.volume <= volume) {
              price = t.price / 10000;
            }
          });
        }

        return price;
      };

      var that = this;

      if (this.selectedProcessors.length) {
        that.selectedProcessors.forEach(function(obj) {
          if (obj.tiers && obj.tiers.length) {
            obj.price_volume = getPriceByVolume(obj);
          } else {
            obj.price_volume = obj.price;
          }
        });

        var subtotal_original = this.selectedProcessors.reduce(function(acc, obj) {
          return acc + obj.price;
        }, 0);

        var subtotal = this.selectedProcessors.reduce(function(acc, obj) {
          return acc + obj.price_volume;
        }, 0);

        this.cost.base_original = subtotal_original * this.volume;
        this.cost.base = Math.ceil(subtotal * this.volume * 100) / 100;
        this.cost.base = (parseInt(this.cost.base * 100, 10) / 100);//only two digits
        this.recalculateCosts()

        this.hasCash = !this.hasAutoCredits() && this.getBalance() > this.cost.base;
        if (!this.hasAutoCredits() && this.getBalance() > 0) {
          this.cost.base = this.cost.base - this.getBalance();
          this.cost.total = this.cost.base * vatMultiplier;
        }
      } else {
        this.cost.base = 0;
        this.cost.total = 0;
        this.cost.vat = 0;

        this.view = 'editor';
      }

      that.custom_pack = this.getCustomPackByTotal(this.cost.base);
    },

    getCustomPackByTotal: function(total) {
      if (total > this.packs[this.packs.length - 1]) {
        return total;
      } else {
        var filtered_packs = this.packs.filter(function(pack) {
          return total < pack;
        });

        return filtered_packs[0];
      }
    },

    getVatMultiplier: function() {
      return localStorage.token && this.processing && this.processing.vatMultiplier ? this.processing.vatMultiplier : 1;
    },

    hasAutoCredits: function() {
      return this.user && this.user.autocredits !== null ? this.user.autocredits : false;
    },

    getBalance: function() {
      return this.user ? this.getCredits() * this.getCostPerCredit() : 0;
    },

    getCredits: function() {
      return this.user ? this.user.credits : 0;
    },

    getCostPerCredit: function() {
      return this.processing.costPerCredit;
    },

    getCurrentPlan: function() {
      return this.user && this.user.plan && this.user.plan.current ? this.user.plan.current : 'basic';
    },

    hasCards: function() {
      return this.user && this.user.cards && this.user.cards.length;
    },

    initValues: function() {
      this.processorD = '';
      this.method = '';
      this.false = false;
      this.cost = {
        total: 0,
        base: 0
      };
      this.processing = this.getProcessingValues();
      this.view = 'editor';
      this.group = '';
      this.type = '';
      this.availableTypes = [];
      this.processors = [];
    },

    cleanAll: function() {

      this.selectedProcessors = [];
      this.initValues();
      this.recalculate();

    },

    removeProcessor: function(key) {

      this.selectedProcessors = this.selectedProcessors.filter(function(item) {
        return item.key !== key;
      });
      this.recalculate();

    },

    setMethod: function(key) {
      this.purchaseDone = false;
      this.purchaseResult = {};
      this.method = key;
      this.checkout = false;
      if (this.cost.total > 0 && this.method.length) {
        this.checkout = true;
      }
      if (this.method === 'paypal') {
        this.configurePaypal();
      }
    },

    buy: function() {
      this.sending = true;
      var method = this.method === 'creditcard' ? 'stripe' : this.method;
      this.buyCredits(this.cost.base, method);
    },

    enableMethod: function(method, data) {
      var that = this;
      if (method === 'stripe') {
        var stripe = window.Stripe(data.publishable_key);
        stripe.redirectToCheckout({
          sessionId: data.session
        }).then(function(result) {
          if (result && result.error && result.error.message && result.error.message.length) {
            console.log(result);
            this.purchaseDone = true;
            this.purchaseResult = false;
          } else {
            that.$router.push('/settings/billing')
          }
        });
      }
    },

    buyCredits: function(amount, method) {
      this.loading = true;
      var url = '/api/v2/buy';
      if (method === 'stripe') {
        url = url = '/api/v2/purchase_session';
      }

      //if (method === 'paypal') {
      var data = {
        method: method,
        amount: (parseInt(amount * 100, 10) / 100),
        promo: this.promo_code.accepted
      }

      this.$http.post(url, data, this.config).then((results) => {
        this.loading = false;
        var res = results.data;
        this.oldMethod = this.method;
        this.method = "";
        this.checkout = false;
        this.initPromoCode();

        if (method !== 'stripe') {
          this.purchaseDone = true;
          this.purchaseResult = res;
        } else {
          this.enableMethod(method, res);
        }
      });
      //}

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../assets/css/purchase.css';
@import '../../assets/css/global.css';
</style>
