<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>




      <!-- wizard content start start -->


      <div class="row no_side_margin">


        <div class="total col-lg-12 request">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item "><a href="#/bulks">{{ $t('bulks' ) }}</a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('report' ) }}</li>
            </ol>
          </nav>
          <div class="tab-content">


            <div id="report" class="tab-pane container active">

              <div class="row">
                <div class="col-sm-12 col-12 col-title ">
                  <h3>{{$t('summary')}}</h3>
                </div>
              </div>


              <div class="col-lg-12 no_side_pad">
                <div class="page-nav summary-tab-container">
                  <ul>
                    <li>
                      <button type="button" class="report-btn" v-on:click="downloadOriginalSigned(job.id)">{{$t('download-original')}}</button>
                    </li>
                    <li>
                      <button type="button" class="report-btn" v-on:click="downloadResultsSigned(job.id)">{{$t('download-results')}}</button>
                    </li>
                    <li>
                      <button type="button" class="report-btn" v-on:click="printReport()">{{$t('print-report')}}</button>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="row smry-status no_side_margin" style="overflow-x: auto;">
                <table class="table list-table" style="margin-top: 0px" v-if="this.job">
                  <thead>
                    <tr>
                      <th class="t-head"><strong>{{$t('report-entries')}}</strong></th>
                      <th class="t-head text-right"><strong>{{$t('quantity')}}</strong></th>
                      <th class="t-head text-right"><strong>%</strong></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-left">{{$t('report-total-rows')}}</td>
                      <td class="text-right">{{this.job.totalEntries}} </td>
                      <td class="text-right">{{Number((this.job.totalEntries / this.job.totalEntries) * 100).toFixed(2) | numberOnlyNaM }}</td>
                    </tr>
                    <tr>
                      <td class="text-left">&nbsp;<span class="pl-3">{{$t('report-total-rows-empty')}}</span></td>
                      <td class="text-right">{{this.job.totalEntriesEmpty}} </td>
                      <td class="text-right">{{Number((this.job.totalEntriesEmpty / this.job.totalEntries) * 100).toFixed(2) | numberOnlyNaM}}</td>
                    </tr>
                    <tr>
                      <td class="text-left">&nbsp;<span class="pl-3">{{$t('report-total-rows-processed')}}</span></td>
                      <td class="text-right">{{this.job.totalEntriesProcessed}} </td>
                      <td class="text-right">{{Number((this.job.totalEntriesProcessed / this.job.totalEntries) * 100).toFixed(2) | numberOnlyNaM}}</td>
                    </tr>
                    <tr v-if="this.job.totalEntriesNormalized > 0">
                      <td class="text-left">&nbsp;<span class="pl-5">{{$t('report-total-rows-normalized')}}</span></td>
                      <td class="text-right"></td>
                      <td class="text-right"></td>
                    </tr>
                    <tr v-if="this.job.totalEntriesNormalized > 0">
                      <td class="text-left">&nbsp;&nbsp;<span class="pl-7">Yes</span></td>
                      <td class="text-right">{{this.job.totalEntriesNormalized}} </td>
                      <td class="text-right">{{Number((this.job.totalEntriesNormalized / this.job.totalEntriesProcessed) * 100).toFixed(2) | numberOnlyNaM}}</td>
                    </tr>
                    <tr v-if="this.job.totalEntriesNormalized > 0">
                      <td class="text-left">&nbsp;&nbsp;<span class="pl-7">No</span></td>
                      <td class="text-right">{{this.job.totalEntriesProcessed - this.job.totalEntriesNormalized}} </td>
                      <td class="text-right">{{Number(((this.job.totalEntriesProcessed - this.job.totalEntriesNormalized) / this.job.totalEntriesProcessed) * 100).toFixed(2) | numberOnlyNaM }}</td>
                    </tr>
                    <tr>
                      <td class="text-left">&nbsp;<span class="pl-5">{{$t('report-rows-valid')}}</span></td>
                      <td class="text-right"></td>
                      <td class="text-right"></td>
                    </tr>
                    <tr>
                      <td class="text-left">&nbsp;&nbsp;<span class="pl-7">{{$t('yes')}}</span></td>
                      <td class="text-right">{{this.job.totalEntriesOk}} </td>
                      <td class="text-right">{{Number((this.job.totalEntriesOk / this.job.totalEntriesProcessed) * 100).toFixed(2) | numberOnlyNaM }}</td>
                    </tr>
                    <tr>
                      <td class="text-left">&nbsp;&nbsp;<span class="pl-7">{{$t('no')}}</span></td>
                      <td class="text-right">{{this.job.totalEntriesProcessed - this.job.totalEntriesOk}} </td>
                      <td class="text-right">{{Number(((this.job.totalEntriesProcessed - this.job.totalEntriesOk) / this.job.totalEntriesProcessed) * 100).toFixed(2) | numberOnlyNaM }}</td>
                    </tr>








                  </tbody>
                </table>


                <div class="digram-wrap no_side_margin">
                  <div class=" panel panel-default col-lg-12 col-xs-6 no_side_pad">
                    <form>
                      <div class="panel-body">
                        <div class="" v-for="(summary , index) in this.job.summaries" v-bind:key="'summary-' + index">
                          <h2 translate><strong>{{$t('tool')}}:</strong> <i>{{summary.name}}</i></h2>
                          <div class="panel-heading ">
                            <div class="panel-title ">
                              <h4 translate class="list-result">{{$t('result-graph')}}</h4>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-lg-4" v-for="(data, index) in summary.graphWrap" v-bind:key="'graph-' + summary.name + '-' + index">
                              <p class="check-list text-center"><strong>{{data.key}}</strong></p>
                              <pie-chart :chartData="data" :options="chartOptions"></pie-chart>
                            </div>
                          </div>

                          <div class="panel-heading ">
                            <div class="panel-title ">
                              <h4 translate class="list-result">{{$t('result-list')}}</h4>
                            </div>
                          </div>
                          <div>
                            <table class="table list-table">
                              <thead>
                                <tr>
                                  <th class=" text-left t-head"><strong>{{$t('property')}}</strong></th>
                                  <th class=" text-left t-head"><strong>{{$t('value')}}</strong></th>
                                  <th class=" text-right t-head"><strong>{{$t('count')}}</strong></th>
                                  <th class=" text-right t-head">%</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(res, index) in summary.results" v-bind:key="res.key + '-' + index">
                                  <td class=" level-2" v-if="index === 0"><i>{{res.key}}</i></td>
                                  <td class=" level-2" v-if="index > 0"><i>{{index > 0 && summary.results[index - 1].key !== res.key ? res.key : '' }}</i></td>
                                  <td class="">{{res.message}}</td>
                                  <td class=" text-right">{{res.count}} </td>
                                  <td class=" text-right">{{((res.count / summary.count)  * 100).toFixed(2) | numberOnlyNaM}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                </div>


              </div>
            </div>





          </div>
        </div>

        <div>


        </div>







      </div>

      <Footer />

    </div>


  </div>

</div>
</template>

<script>
import Header from '@/components/Header.vue'

import Footer from '@/components/Footer.vue'

import Sidebar from '@/components/Sidebar.vue'

import axios from 'axios';

import PieChart from "@/components/Charts/chart.js";

export default {
  name: 'BulksReport',
  data() {
    return {

      isActive: false,
      isActiveIcon: '<',
      dateOptions: {
        'format-year': 'yy',
        'starting-day': 1,
        'show-weeks': false,
        'show-button-bar': false
      },
      format: 'dd/MM/yyyy',
      orderByField: 'startDate',
      reverseSort: true,
      startDate: '',
      endDate: '',
      pendingRequest: false,
      currentPage: 0,
      totalRecords: 0,
      totalPages: 0,
      totalRows: 0,
      totalRowsOk: 0,
      totalCost: 0,
      totalCredits: 0,
      totalSavings: 0,
      prevStartDate: null,
      prevEndDate: null,
      jobs: [],
      requests: [],
      period: '1day',
      periodDisplay: 'Last 24 hours',
      loading: false,
      job: '',
      chartOptions: {
        hoverBorderWidth: 20
      },
      chartData: {

        labels: ["True"],
        datasets: [{

          backgroundColor: ["rgb(49, 119, 180)", "rgb(174, 199, 232)", "rgb(255, 127, 14)", "rgb(255, 187, 120)", "#003f5c", "#2f4b7c", "#665191", "#a05195", "#d45087", "#f95d6a", "#ff7c43", "#ffa600", "#0300e2", "#24dbe1", "#F00CE1", "#890CFA",
            "#0400E3", "#0C7DFA", "#24DBE1", "#020063", "#4845E8", "#0400E3", "#1F1E63", "#0300B0", "#110F96", "#E35E17", "#0400E3", "#42E317", "#269608", "#6B12B0", "#5C327D", "#0400E3", "#E6B73A", "#B07412"
          ],
          data: []
        }]
      },
      graphWrap: [],


    }

  },
  mounted() {
    this.enableInterceptor();
    this.config = {
      headers: {
        "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
        "Content-Type": "application/json"
      }
    };
    this.getUserDetails();
    this.startDate = new Date(Date.now() + -60 * 24 * 3600 * 1000);
    this.endDate = new Date();
  },
  watch: {

    jobs: function() {

      this.loadReport();

    }

  },
  components: {

    Header,
    Sidebar,
    Footer,
    PieChart

  },
  methods: {

    enableInterceptor() {
      var that = this;
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        that.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        that.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        that.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        that.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      //console.log(this.isActive);
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },


    getUserDetails: function() {

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };


      var url = '/api/v2/profile';

      this.$http.get(url, config).then((results) => {

        this.user = results.data;
        this.getJobs();
      });

    },

    //FROM HERE BULKS AREA FUNCTIONS

    getJobsTrigger: function() {

      this.currentPage = 0;
      this.totalPages = 0;
      this.getJobs();

    },

    getJobs: function(forced) {

      forced = this.areDatesDifferents() || forced || false;
      if (!this.pendingRequest && forced) {
        this.pendingRequest = true;

        this.$http.get('/api/v1/' + localStorage.token + '/jobs/' + this.startDate.getTime() + '/' + this.endDate.getTime() + '/' + this.currentPage).then((results) => {

          var result = results.data;
          this.jobs = result.results
          this.totalRecords = results.count;
          this.totalPages = results.totalPages;
          this.rowsPerPage = results.rowsPerPage;
          this.pendingRequest = false;
          this.areDatesDifferents(true);
          this.calculateTotals();

        });

      } else {
        this.calculateTotals();
      }

    },

    areDatesDifferents: function(setDates) {

      setDates = setDates || false;
      var resStart = this.isStartDateDifferent(),
        resEnd = this.isEndDateDifferent();
      if (setDates) {
        if (resStart) {
          this.prevStartDate = new Date(this.startDate);
        }
        if (resEnd) {
          this.prevEndDate = new Date(this.endDate);
        }
      }
      return resStart || resEnd;

    },

    isStartDateDifferent: function() {
      return (this.prevStartDate === null || this.startDate.toLocaleString() !== this.prevStartDate.toLocaleString());
    },

    isEndDateDifferent: function() {
      return (this.prevEndDate === null || this.endDate.toLocaleString() !== this.prevEndDate.toLocaleString());
    },

    calculateTotals: function() {

      if (this.jobs.length) {
        this.resetTotalsData();
        var self = this;

        this.jobs.forEach(function(job) {

          self.totalRows += job.totalRows;
          self.totalRowsOk += job.totalRowsOk;
          self.totalCredits += job.cost;
          //If we don't have finalPrice, used, estimated one
          job.price = job.price === 0 ? job.estimatedPrice : job.price;
          self.totalCost += job.price;
          self.totalSavings += (job.estimatedPrice - job.price);
        });


      }

    },

    resetTotalsData: function() {
      this.totalRows = 0;
      this.totalRowsOk = 0;
      this.totalCost = 0;
      this.totalCredits = 0;
      this.totalSavings = 0;
    },

    jobTotals: function() {

      this.updateTotalsData();

    },

    updateTotalsData: function() {

      this.totalRows = this.getTotalRows();
      this.totalRowsOk = this.getTotalRowsOk();
      this.totalCost = this.getTotalCost();
      this.totalSavings = this.getTotalSavings();
      this.totalCredits = this.getTotalCredits();

    },

    getTotalRows: function() {
      return this.totalRows;
    },

    getTotalRowsOk: function() {
      return this.totalRowsOk;
    },

    getTotalCost: function() {
      return this.totalCost;
    },

    getTotalSavings: function() {
      return this.totalSavings;
    },

    getTotalCredits: function() {
      return this.totalCredits;
    },

    downloadOriginal: function(id) {
      window.location.href = this.getOriginalFileUrl(id);
    },

    getOriginalFileUrl: function(jobId) {
      return this.$apiBase + "/api/v1/" + localStorage.token + "/job/download/" + jobId + "/original";
    },

    downloadResults: function(id) {
      window.location.href = this.getResultsFileUrl(id);
    },

    getResultsFileUrl: function(jobId) {
      return this.$apiBase + "/api/v1/" + localStorage.token + "/job/download/" + jobId + "/results-final";
    },

    downloadOriginalSigned: function(id) {
      this.getOriginalFileSignedUrl(id);
    },

    getOriginalFileSignedUrl: function(jobId) {
      var url = this.$apiBase + "/api/v2/job/" + jobId + "/downloadsigned/original";
      this.$http.get(url, this.config).then((results) => {
        if (results && results.data && results.data.url && results.data.url.length) {
          window.location.href = results.data.url;
        }
      });
    },

    downloadResultsSigned: function(id) {
      this.getResultsFileSignedUrl(id);
    },

    getResultsFileSignedUrl: function(jobId) {
      var url = this.$apiBase + "/api/v2/job/" + jobId + "/downloadsigned/results-final";
      this.$http.get(url, this.config).then((results) => {
        if (results && results.data && results.data.url && results.data.url.length) {
          window.location.href = results.data.url;
        }
      });
    },

    loadReport: function() {
      if (this.$route.params.task !== undefined) {
        var job = this.getJobById(this.$route.params.task);
        if (job) { //alert(this.$route.params.task);
          this.showJobReport(this.$route.params.task);
        }
      }

    },

    getJobById: function(jobId) {

      if (this.jobs && this.jobs.length && jobId.length) {
        var found = this.jobs.filter(function(item) {
          return item.id === jobId;
        });
        return found.length ? found[0] : null;
      } else {
        return null;
      }

    },

    showJobReport: function(jobId) {

      this.loading = true;
      this.$http.get('/api/v1/' + localStorage.token + '/job/report/' + jobId).then((results) => {
        this.job = results.data; //minimum data

        let that = this;
        results.data.graphs.forEach(item => {
          var properties = Object.keys(item.simple)
          properties.forEach(function(key) {
            var containers = new Array();
            var itemTitle = new Array();
            item.simple[key].data.forEach(items => {
              itemTitle.push(that.getCapitalizedText(items.key));
              //var value = items && items.value && items.value.length ? items.value : "unknown";
              containers.push(items.value);
            });

            that.chartData = {
              key: that.getCapitalizedText(item.simple[key].key),
              labels: itemTitle,
              datasets: [{
                title: itemTitle,
                backgroundColor: ["rgb(49, 119, 180)", "rgb(174, 199, 232)", "rgb(255, 127, 14)", "rgb(255, 187, 120)", "#003f5c", "#2f4b7c", "#665191", "#a05195", "#d45087", "#f95d6a", "#ff7c43", "#ffa600"],
                data: containers
              }]
            };
            that.job.summaries.forEach(function(summary) {
              if (summary.name === item.name) {
                if (!summary.graphWrap) {
                  summary.graphWrap = [];
                }
                summary.graphWrap.push(that.chartData)
              }
            });

          });
        });
      });
    },

    getCapitalizedText: function(name) {
      var newName = name && name.length > 0 ? name.charAt(0).toUpperCase() + name.slice(1) : "";

      return newName.replace(/[_-]/g, " ")
    },

    printReport: function() {

      this.printDiv("report");

    },

    printDiv: function(divName) {

      var headContents = document.getElementsByTagName('head')[0].innerHTML;
      var printContents = document.getElementById(divName).innerHTML;
      //var originalContents = document.body.innerHTML;
      var popupWin;
      if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
        popupWin = window.open('', '_blank', 'width=800,height=600,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWin.window.focus();

        popupWin.document.write('<!DOCTYPE html><html><head>' + headContents + '</head>' +
          '<body onload="window.print()"><p style="text-align: center"><a onclick="javascript:window.close()">Cerrar</a></p><div class="printSection">' + printContents + '</div></body></html>');
        popupWin.onbeforeunload = function() {
          popupWin.close();
          return '.\n';
        };
        popupWin.onabort = function() {
          popupWin.document.close();
          popupWin.close();
        };
      } else {
        popupWin = window.open('', '_blank', 'width=800,height=600');
        popupWin.document.open();
        popupWin.document.write('<html><head>' + headContents + '</head><body onload="window.print()"><p style="text-align: center"><a onclick="javascript:window.close()">Cerrar</a></p><div class="printSection">' + printContents +
          '</div></body></html>');
        popupWin.document.close();
      }
      popupWin.document.close();

      return true;

    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/global.css';
</style>
