<template>
<div>
  <div v-if="language === 'en'">
    I see that you want to <strong>improve your data quality</strong> ;)<br><br>
    Do you know that with uProc you can <strong>clean, verify, and enrich</strong> any data you have?
    <br><br>
    Just follow the next steps:
    <ul>
      <li><strong><a href="/#/settings/profile" target="_blank">Identify</a></strong> yourself for legal purposes.</li>
      <li><strong><a href="/#/tools" target="_blank">Search</a></strong> the tool you need by field or name ("I work on IT" unlocks all tools).</li>
      <li>Check the cost per <strong>1000 rows</strong> (CPM) and <strong><a href="/#/purchase/packs" target="_blank">choose</a></strong> the pack that suits your needs.</li>
      <li><strong><a href="/#/wizard/process/Files/Upload" target="_blank">Upload</a></strong> your file, choose the field name or category, Verify or Enrich, the tool, the data columns and start the process.</li>
      <li>You will receive the results file by email.</li>
    </ul>
    <br>
    You can also improve your data (like verifying your email addresses) from
    <strong><a href="https://uproc.io/blog/how-to-check-if-email-exists-extended#sheets" target="_blank">Google Sheets</a></strong>,
    <strong><a href="https://uproc.io/blog/how-to-check-if-email-exists-extended#n8n" target="_blank">n8n</a></strong>,
    <strong><a href="https://uproc.io/blog/how-to-check-if-email-exists-extended#integromat" target="_blank">Make</a></strong>,
    <strong><a href="https://uproc.io/blog/how-to-check-if-email-exists-extended#zapier" target="_blank">Zapier</a></strong> or
    <strong><a href="https://uproc.io/blog/how-to-check-if-email-exists-extended#api" target="_blank">API</a></strong>.


    <br>
    Choose the best method that suits your needs!
    <br><br>
    <div class="row">
      <div class="col-lg-4">
    Looking for a fast sample? Check this video to view the email verification tool in action using Google Sheets:
      </div>
      <div class="col-lg-8">
        <div class="video-responsive">
          <iframe width="100%" height="360" src="https://www.youtube.com/embed/f2IudtgqDRE?VQ=HD720" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
        </div>
      </div>
    </div>
  </div>

  <div v-if="language === 'es'">
    He visto que quieres <strong>mejorar la calidad de tus datos</strong> ;)<br><br>
    ¿Sabes que con uProc puedes <strong>limpiar, comprobar y enriquecer</strong> cualquier dato que tengas?
    <br><br>
    Solo necesitas seguir los siguientes pasos:
    <ul>
    <li><strong><a href="/#/settings/profile" target="_blank">Identifícate</a></strong> para utilizar la plataforma de forma legal.</li>
      <li><strong><a href="/#/tools" target="_blank">Busca</a></strong> por campo o nombre las herramientas que necesitas ("Trabajo en Técnico" desbloquea todas las herramientas).</li>
      <li>Comprueba el coste por <strong>1000 filas</strong> (CPM) y <strong><a href="/#/purchase/packs" target="_blank">elije el pack</a></strong> que encaja con tus necesdades.</li>
      <li><strong><a href="/#/wizard/process/Files/Upload" target="_blank">Sube</a></strong> tu fichero, elije el campo o categoria, Comprobar o Enriquecer, la herramienta, las columnas y empieza el proceso.</li>
      <li>Recibirás los resultados por correo.</li>
    </ul>
    <br>
    También puedes mejorar tus datos (como verificar tus correos) desde
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists-extended#sheets" target="_blank">Google Sheets</a></strong>,
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists-extended#n8n" target="_blank">n8n</a></strong>,
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists-extended#integromat" target="_blank">Make</a></strong>,
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists-extended#zapier" target="_blank">Zapier</a></strong> o
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists-extended#api" target="_blank">API</a></strong>.
    <br>
    Elije el método que mejor se adapta a tus necesidades.
    <br><br>
    <div class="row">
      <div class="col-lg-4">
      ¿Quieres ver un ejemplo rápido? Revisa este video tutorial para ver la herramienta de verificación de correos en acción utilizando Google Sheets:
      </div>
      <div class="col-lg-8">
        <div class="video-responsive">
        <iframe width="100%" height="360" src="https://www.youtube.com/embed/f2IudtgqDRE?VQ=HD720" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'WelcomeData',
  data() {
    return {
      language: this.$i18n.locale
    };
  },
  created() {

  },
  mounted() {}
}
</script>
