<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>




      <!-- wizard content start start -->

      <div class="">
        <div class="total-wizard">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb" v-if="this.stepNumber == 1">
              <li class="breadcrumb-item "><a v-bind:href="'#/wizard'">{{ $t('menu-wizard' ) }}</a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('wizard-process' ) }}</li>
            </ol>


            <ol class="breadcrumb" v-if="this.stepNumber == 2">
              <li class="breadcrumb-item "><a v-bind:href="'#/wizard'">{{ $t('menu-wizard' ) }}</a></li>
              <li class="breadcrumb-item" aria-current="page"><a v-bind:href="'#/wizard/process'">{{ $t('wizard-process' ) }}</a></li>
              <li class="breadcrumb-item active" aria-current="page">{{$t('source-' + selectedSource.toLowerCase())}}</li>
            </ol>

            <ol class="breadcrumb" v-if="this.stepNumber >= 3">
              <li class="breadcrumb-item "><a v-bind:href="'#/wizard'">{{ $t('menu-wizard' ) }}</a></li>
              <li class="breadcrumb-item" aria-current="page"><a v-bind:href="'#/wizard/process'">{{ $t('wizard-process' ) }}</a></li>
              <li class="breadcrumb-item" aria-current="page"><a v-bind:href="'#/wizard/process/' + selectedSource">{{$t('source-' + selectedSource.toLowerCase())}}</a></li>
              <li class="breadcrumb-item active" aria-current="page" v-if="selectedSecondarySource === 'Upload'">{{$t('source-upload')}}</li>
              <li class="breadcrumb-item active" aria-current="page" v-if="selectedSecondarySource !== 'Upload' && selectedSource !== 'Oscar'">{{selectedSecondarySource}}</li>
              <li class="breadcrumb-item active" aria-current="page" v-if="selectedSource === 'Oscar' && selectedSecondarySource.length > 0">{{selectedSecondarySource}}</li>
            </ol>
          </nav>


          <div class="row no_side_margin" v-if="this.stepNumber == 1">
            <h2>{{ $t('wizard-process-source' ) }}</h2>
            <div class="col-lg-12">
              <div class="total-data-source">
                <div class="row">

                  <div class="col-lg-4">
                    <a v-on:click="selectSource('Files')">
                      <div class="cate">
                        <div class="icon">
                          <font-awesome-icon icon="file" size="3x" />
                        </div>
                        <h3>{{ $t('source-files' ) }}</h3>
                      </div>
                    </a>
                  </div>

                  <div class="col-lg-4">
                    <a v-on:click="selectSource('Databases')">
                      <div class="cate">
                        <div class="icon">
                          <font-awesome-icon icon="database" size="3x" />
                        </div>
                        <h3>{{ $t('source-databases' ) }}</h3>
                      </div>
                    </a>
                  </div>

                  <div class="col-lg-4">
                    <a v-on:click="selectSource('Cloud')">
                      <div class="cate">
                        <div class="icon">
                          <font-awesome-icon icon="cloud" size="3x" />
                        </div>
                        <h3>{{ $t('source-cloud' ) }}</h3>
                      </div>
                    </a>
                  </div>



                </div>
              </div>

            </div>
          </div>

          <div v-if="this.stepNumber == 2">

            <div class="row no_side_margin" v-if="this.selectedSource == 'Files'">
              <h2>{{ $t('wizard-process-source-name' ) }}</h2>
              <div class="col-lg-12">
                <div class="total-data-source">
                  <div class="row">

                    <div class="col-lg-4 process_file">
                      <a v-on:click="selectSecondarySource('Upload')">
                        <div class="cate">
                          <div class="icon">
                            <font-awesome-icon icon="upload" size="3x" />
                          </div>
                          <h3>{{ $t('source-upload' ) }}</h3>
                        </div>
                      </a>
                    </div>

                    <div class="col-lg-4 process_file">
                      <a v-on:click="selectSecondarySource('Ftp')">
                        <div class="cate">
                          <div class="icon">
                            <font-awesome-icon icon="server" size="3x" />
                          </div>
                          <h3>{{ $t('source-ftp' ) }}</h3>
                        </div>
                      </a>
                    </div>

                    <div class="col-lg-4 process_file">
                      <a v-on:click="selectSecondarySource('Sftp')">
                        <div class="cate">
                          <div class="icon">
                            <font-awesome-icon icon="server" size="3x" />
                          </div>
                          <h3>{{ $t('source-sftp' ) }}</h3>
                        </div>
                      </a>
                    </div>

                    <div class="col-lg-4 process_file">
                      <a v-on:click="selectSecondarySource('s3')">
                        <div class="cate">
                          <div class="icon">
                            <font-awesome-icon icon="server" size="3x" />
                          </div>
                          <h3>{{ $t('source-s3' ) }}</h3>
                        </div>
                      </a>
                    </div>

                    <div class="col-lg-4 process_file">
                      <a v-on:click="selectSecondarySource('Url')">
                        <div class="cate">
                          <div class="icon">
                            <font-awesome-icon icon="globe" size="3x" />
                          </div>
                          <h3>{{ $t('source-url' ) }}</h3>
                        </div>
                      </a>
                    </div>



                  </div>
                </div>

              </div>
            </div>

            <div class="row no_side_margin" v-if="this.selectedSource == 'Cloud'">
              <h2>{{ $t('wizard-process-source-name' ) }}</h2>
              <div class="col-lg-12">
                <div class="total-data-source">
                  <div class="row">

                    <div class="col-lg-4 process_file">
                      <a v-on:click="selectSecondarySourcCloud('Mailchimp')">
                        <div class="cate">
                          <div class="icon">
                            <font-awesome-icon :icon="['fab', 'mailchimp']" size="3x" />
                          </div>
                          <h3>{{ $t('source-mailchimp' ) }}</h3>
                        </div>
                      </a>
                    </div>


                  </div>
                </div>

              </div>
            </div>

            <div class="row no_side_margin" v-if="this.selectedSource == 'Databases'">
              <h2>{{ $t('wizard-process-source-name' ) }}</h2>
              <div class="col-lg-12">
                <div class="total-data-source">
                  <div class="row">

                    <div class="col-lg-4 process_file">
                      <a v-on:click="selectSecondarySourceDb('Mysql')">
                        <div class="cate">
                          <div class="icon">
                            <font-awesome-icon icon="database" size="3x" />
                          </div>
                          <h3>{{ $t('source-mysql' ) }}</h3>
                        </div>
                      </a>
                    </div>

                    <div class="col-lg-4 process_file">
                      <a v-on:click="selectSecondarySourceDb('PostgresSQL')">
                        <div class="cate">
                          <div class="icon">
                            <font-awesome-icon icon="database" size="3x" />
                          </div>
                          <h3>{{ $t('source-postgres' ) }}</h3>
                        </div>
                      </a>
                    </div>

                    <div class="col-lg-4 process_file">
                      <a v-on:click="selectSecondarySourceDb('MSSQL Server')">
                        <div class="cate">
                          <div class="icon">
                            <font-awesome-icon icon="database" size="3x" />
                          </div>
                          <h3>{{ $t('source-sqlserver' ) }}</h3>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="this.stepNumber == 3 ">

            <div class="row no_side_margin" v-if="this.selectedSecondarySource === 'Upload'">
              <h2>{{ $t('wizard-description-access' ) }} {{selectedSecondarySource }}
              </h2>
              <div class="col-lg-12">

                <div class="total-upload-file">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="select-file">
                        <div class="icon">
                          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 6H27.4142L38 16.5858V42H10V6ZM12 8V40H36V18H26V8H12ZM28 9.41421L34.5858 16H28V9.41421Z" fill="#0300e2" />
                          </svg>
                        </div>
                        <input type="file" accept=".csv,.xls,.xlsx,.zip,.tgz" id="file" ref="file" class="custom-file-input upload-data" lang="es" v-on:change="uploadFile()">
                        <h3>{{ $t('wizard-file-computer' ) }}</h3>
                      </div>
                      <div class="error text-center" v-if="isError">
                        <strong v-html="$t('wizard-file-error')"></strong>
                        <br><br>
                      </div>
                      <div class="inform">
                        <p v-html="$t('wizard-file-format')"></p>
                      </div>
                    </div>

                    <div class="total-btn col-lg-12 no_side_pad">
                      <button type="button" class="btn btn-primary cancel-btn" v-on:click="clearSource()" :disabled="this.isProcessing">{{ $t('cancel' ) }}</button>
                    </div>
                  </div>


                </div>



              </div>
            </div>

            <div class="row no_side_margin" v-if="this.selectedSecondarySource === 'Ftp' || this.selectedSecondarySource === 'Sftp'">
              <h2>{{ $t('wizard-description-access' ) }} {{ selectedSecondarySource }}</h2>
              <div class="col-lg-12">
                <div class="total-data-source total-form-field">
                  <div class="row">



                    <div class="profile-settings col-lg-12">


                      <div v-if="this.errorMessage" class="error">{{ $t('error-getting-source' ) }}</div>

                      <div class="fileld">
                        <div class="row">
                          <label class="col-lg-3">{{ $t('wizard-server' ) }}</label>
                          <div class="col-lg-9"><input type="text" v-on:click="$event.target.select()" class="form-control" id="server" v-model="source.server" v-on:keyup="sourceCheck('4')"></div>
                        </div>
                      </div>

                      <div class="fileld">
                        <div class="row">
                          <label class="col-lg-3">{{ $t('wizard-username' ) }}</label>
                          <div class="col-lg-9"><input type="text" v-on:click="$event.target.select()" class="form-control" id="username" v-model="source.username" v-on:keyup="sourceCheck('4')"></div>
                        </div>
                      </div>

                      <div class="fileld">
                        <div class="row">
                          <label class="col-lg-3">{{ $t('wizard-password' ) }}</label>
                          <div class="col-lg-9"><input type="text" v-on:click="$event.target.select()" class="form-control" id="password" v-model="source.password" v-on:keyup="sourceCheck('4')"></div>
                        </div>
                      </div>

                      <div class="fileld">
                        <div class="row">
                          <label class="col-lg-3">{{ $t('wizard-file-path' ) }}</label>
                          <div class="col-lg-9"><input type="email" v-on:click="$event.target.select()" class="form-control" id="file_path" v-model="source.file_path" v-on:keyup="sourceCheck('4')"></div>
                        </div>
                      </div>

                      <div class="total-btn col-lg-12 no_side_pad">
                        <button type="button" class="btn btn-primary cancel-btn" v-on:click="clearSource()" :disabled="this.isProcessing">{{ $t('cancel' ) }}</button>
                        <button type="button" class="btn btn-primary process-btn" v-bind:disabled="!this.formcheck" v-on:click="previewSource()">{{ $t('process' ) }}</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row no_side_margin" v-if="this.selectedSecondarySource === 's3'">
              <h2>{{ $t('wizard-description-access' ) }} {{selectedSecondarySource }} <span v-if="selectedSecondarySource == 's3'">(amazon)</span></h2>
              <div class="col-lg-12">
                <div class="total-data-source total-form-field">
                  <div class="row">

                    <div class="profile-settings col-lg-12">
                      <div v-if="this.errorMessage" class="error">{{ $t('error-getting-source' ) }}</div>

                      <div class="fileld">
                        <div class="row">
                          <label class="col-lg-3">{{ $t('wizard-access-key' ) }}</label>
                          <div class="col-lg-9"><input type="text" v-on:click="$event.target.select()" class="form-control" id="accesskey" v-model="source.accesskey" v-on:keyup="sourceCheckS3('4')"></div>
                        </div>
                      </div>

                      <div class="fileld">
                        <div class="row">
                          <label class="col-lg-3">{{ $t('wizard-secret-key' ) }}</label>
                          <div class="col-lg-9"><input type="text" v-on:click="$event.target.select()" class="form-control" id="secretkey" v-model="source.secretkey" v-on:keyup="sourceCheckS3('4')"></div>
                        </div>
                      </div>

                      <div class="fileld">
                        <div class="row">
                          <label class="col-lg-3">{{ $t('wizard-bucket-name' ) }}</label>
                          <div class="col-lg-9"><input type="text" v-on:click="$event.target.select()" class="form-control" id="bucket" v-model="source.bucket" v-on:keyup="sourceCheckS3('4')"></div>
                        </div>
                      </div>

                      <div class="fileld">
                        <div class="row">
                          <label class="col-lg-3">{{ $t('wizard-file-path' ) }}</label>
                          <div class="col-lg-9"><input type="text" v-on:click="$event.target.select()" class="form-control" id="file_path" v-model="source.file_path" v-on:keyup="sourceCheckS3('4')"></div>
                        </div>
                      </div>

                      <div class="total-btn col-lg-12 no_side_pad">
                        <button type="button" class="btn btn-primary cancel-btn" :disabled="this.isProcessing" v-on:click="clearSource()">{{ $t('cancel' ) }}</button>
                        <button type="button" class="btn btn-primary process-btn" v-bind:disabled="!this.formcheck" v-on:click="previewSource()">{{ $t('process' ) }}</button>

                      </div>

                    </div>

                  </div>
                </div>

              </div>
            </div>


            <div class="row no_side_margin" v-if="this.selectedSecondarySource === 'Url'">
              <h2>{{ $t('wizard-description-access' ) }} {{ selectedSecondarySource }}</h2>
              <div class="col-lg-12">
                <div class="total-data-source total-form-field">
                  <div class="row">

                    <div class="profile-settings col-lg-12">

                      <div v-if="this.errorMessage" class="error">{{ $t('error-getting-source' ) }}</div>

                      <div class="fileld">
                        <div class="row">
                          <label class="col-lg-3">{{ $t('wizard-uri' ) }}</label>
                          <div class="col-lg-9"><input type="text" v-on:click="$event.target.select()" class="form-control" v-model="source.uri" v-on:keyup="sourceCheckURL('1')"></div>
                        </div>
                      </div>

                      <div class="total-btn col-lg-12 no_side_pad">
                        <button type="button" class="btn btn-primary cancel-btn" :disabled="this.isProcessing" v-on:click="clearSource()">{{ $t('cancel' ) }}</button>
                        <button type="button" class="btn btn-primary process-btn" :disabled="!this.formcheck" v-on:click="previewSource()">{{ $t('process' ) }}</button>

                      </div>

                    </div>

                  </div>
                </div>

              </div>
            </div>


            <div class="row no_side_margin" v-if="this.selectedSecondarySourceDb === 'Databases'">
              <h2>{{ $t('wizard-description-access' ) }} {{ selectedSecondarySource }} </h2>
              <div class="col-lg-12">
                <div class="total-data-source total-form-field">
                  <div class="row">

                    <div class="profile-settings col-lg-12">

                      <div v-if="this.errorMessage" class="error">{{ $t('error-getting-source' ) }}</div>

                      <div class="fileld">{{ $t('wizard-database-access' ) }}</div>

                      <div class="fileld">
                        <div class="row">
                          <label class="col-lg-3">{{ $t('wizard-server' ) }}</label>
                          <div class="col-lg-9"><input type="text" v-on:click="$event.target.select()" class="form-control" id="server" v-model="source.server" v-on:keyup="sourceCheckDB('5')"></div>
                        </div>
                      </div>

                      <div class="fileld">
                        <div class="row">
                          <label class="col-lg-3">{{ $t('wizard-username' ) }}</label>
                          <div class="col-lg-9"><input type="text" v-on:click="$event.target.select()" class="form-control" id="username" v-model="source.username" v-on:keyup="sourceCheckDB('4')"></div>
                        </div>
                      </div>

                      <div class="fileld">
                        <div class="row">
                          <label class="col-lg-3">{{ $t('wizard-password' ) }}</label>
                          <div class="col-lg-9"><input type="text" v-on:click="$event.target.select()" class="form-control" id="password" v-model="source.password" v-on:keyup="sourceCheckDB('5')"></div>
                        </div>
                      </div>

                      <div class="fileld">
                        <div class="row">
                          <label class="col-lg-3">{{ $t('wizard-database' ) }}</label>
                          <div class="col-lg-9"><input type="text" v-on:click="$event.target.select()" class="form-control" id="database" v-model="source.database" v-on:keyup="sourceCheckDB('5')"></div>
                        </div>
                      </div>

                      <div class="fileld">
                        <div class="row">
                          <label class="col-lg-3">{{ $t('wizard-table' ) }}</label>
                          <div class="col-lg-9"><input type="text" class="form-control" id="table" v-model="source.table" v-on:keyup="sourceCheckDB('5')"></div>
                        </div>
                      </div>

                      <div class="total-btn col-lg-12 no_side_pad">
                        <button type="button" class="btn btn-primary cancel-btn" :disabled="this.isProcessing" v-on:click="clearSource()">{{ $t('cancel' ) }}</button>
                        <button type="button" class="btn btn-primary process-btn" v-bind:disabled="!this.formcheck" v-on:click="previewSource()">{{ $t('process' ) }}</button>

                      </div>

                    </div>

                  </div>
                </div>

              </div>
            </div>


            <div class="row no_side_margin" v-if="this.selectedSecondarySourceCloud === 'Cloud'">
              <h2>{{ $t('wizard-description-access' ) }} {{ selectedSecondarySource }} </h2>
              <div class="col-lg-12">
                <div class="total-data-source total-form-field">
                  <div class="row">

                    <div class="profile-settings col-lg-12">


                      <div v-if="this.errorMessage" class="error">{{ $t('error-getting-source' ) }}</div>

                      <div class="fileld" v-if="this.selectedSource === 'Cloud' && this.externalProviderAccounts.length < 1 && !this.isProcessing">

                        <p>{{ $t('wizard-apps-access' ) }}</p>

                        <div class="col-lg-12 text-center">

                          <button class="btn btn-primary" v-on:click="loginApp()" translate>{{ $t('wizard-apps-login' ) }}</button>

                        </div>

                      </div>
                      <div class="fileld" v-if="this.selectedSource === 'Cloud' && this.externalProviderAccounts.length > 0 && this.externalProviderLists.length === 0">

                        <p>{{ $t('wizard-apps-select-account' ) }}</p>

                        <div class="col-lg-12 text-center cloudList no_side_pad">

                          <ul>
                            <li v-for="membership in this.externalProviderAccounts" v-bind:key="membership.account">
                              <a v-on:click="loadListsByAccount(membership.user)">{{membership.account}}</a>
                            </li>
                          </ul>

                        </div>

                      </div>


                      <div class="fileld" v-if="this.selectedSource === 'Cloud' && this.externalProviderAccounts.length > 0 && this.externalProviderLists.length > 0">

                        <p>{{ $t('wizard-apps-select-list' ) }}</p>

                        <div class="col-lg-12 text-center">

                          <ul style="list-style: none">
                            <li v-for="list in this.externalProviderLists" v-bind:key="list.name">
                              <a v-on:click="previewList(list.id)">{{list.name}} ({{list.count}})</a>
                            </li>
                          </ul>

                        </div>

                      </div>

                    </div>

                  </div>
                </div>

              </div>
            </div>

          </div>

          <div class="row no_side_margin" v-if="this.selectedSource === 'Oscar' && this.selectedSecondarySource.length > 0 && !workflow.executed">
            <h2>{{ $t('wizard-oscar-request' ) }} {{ selectedSecondarySource }}</h2>
            <div class="col-lg-12">
              <div class="data-count">
                <div class="row no_side_margin">
                  <div class="col-lg-6">
                    <div class="row">
                      <h5>{{$t('wizard-oscar-received-request')}}</h5>
                      <br><br>
                      <div class="col-lg-10 col-md-12 no_side_pad">
                        <span>{{$t('wizard-oscar-what')}}</span> {{workflow.description}}
                      </div>
                      <div class="col-lg-10 col-md-12 no_side_pad">
                        <span>{{$t('wizard-oscar-who')}}</span> {{oscar_request.who}}
                      </div>
                      <div class="col-lg-10 col-md-12 no_side_pad">
                        <span>{{$t('wizard-oscar-when')}}</span> {{oscar_request.createdAt | formatDate}}
                      </div>
                      <div class="col-lg-10 col-md-12 no_side_pad">
                        <span>{{$t('wizard-oscar-file')}}</span> {{oscar_request.filename}}
                      </div>
                      <div class="col-lg-10 col-md-12 no_side_pad">
                        <span>{{$t('wizard-oscar-rows')}}</span> {{oscar_request.rows}}
                      </div>
                      <div class="col-lg-10 col-md-12 no_side_pad">
                        <span>{{$t('wizard-oscar-prospects')}}</span> {{oscar_request.prospects_count}}
                      </div>

                      <!--div class="col-lg-10 col-md-12 no_side_pad">
                        <span>{{$t('wizard-oscar-columns')}}</span> {{oscar_request.headers}}
                      </div-->
                      <!--div class="col-lg-10 col-md-12 no_side_pad">
                        <span>{{$t('wizard-oscar-file-preview')}}</span>
                        <div v-for="row in oscar_request.preview" v-bind:key="row.url">
                          <div v-for="header in oscar_request.headers" v-bind:key="header">
                            {{row[header]}}
                          </div>
                        </div>
                      </div-->

                      <br><br>
                      <div class="col-lg-10 col-md-12 no_side_pad">
                        <span>{{$t('wizard-oscar-cost')}}</span> {{workflow.maximum_cost | numFormat("0.00")}}€
                      </div>
                      <div class="col-lg-10 col-md-12 no_side_pad">
                        <span>{{$t('wizard-oscar-cost-per-prospect')}}</span> {{workflow.prospect_cost | numFormat("0.00")}}€
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <h5>{{$t('wizard-oscar-available-parameters')}}</h5>
                    <div v-for="parameter in workflow.parameters" class="col-lg-10 col-md-12 no_side_pad" v-bind:key="parameter.name">
                      <div>
                        <span>{{$t("parameter-" + parameter.name)}}</span>
                        <input v-if="parameter.type === 'number'" type="number" v-model="parameter.value" v-on:click="$event.target.select()" v-on:keyup="updateWorkflowCost()" class="form-control row-no">

                        <input v-if="parameter.type === 'text'" type="text" v-model="parameter.value" v-on:click="$event.target.select()" v-on:keyup="updateWorkflowCost()" class="form-control">
                        <div v-if="parameter.type === 'text' && parameter.values && parameter.values.length">
                          <small>{{$t("wizard-oscar-click-on")}}:</small><br>
                          <small v-for="value in parameter.values" v-bind:key="value">
                            <a v-on:click="addToParameter(parameter, value)">"{{value}}"&nbsp;</a>
                          </small>
                        </div>

                        <select v-if="parameter.type === 'select'" type="text" v-model="parameter.value" v-on:change="updateWorkflowCost()" class="form-control row-no selector-width-150">
                          <option v-for="value in parameter.values" v-bind:key="value">{{value}}</option>
                        </select>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12" v-if="!workflow.executed">
                  <button type="button" class="btn btn-primary btn-lg btn-block add-selection add process" v-on:click="executeWorkflow()">{{ $t('process' ) }}</button>
                  <!--button type="button" class="btn btn-primary btn-lg btn-block add-selection add cancel" :disabled="this.isProcessing" v-on:click="resetWizard()">{{ $t('cancel' ) }}</button-->
                </div>
              </div>
            </div>
          </div>

          <div class="text-center col-lg-12" v-if="workflow.executed" v-html="$t('wizard-oscar-executed')"></div>


          <div class="row no_side_margin" v-if="this.stepNumber == 4 ">
            <h2>{{ $t('wizard-data-info' ) }} {{this.name}}</h2>
            <div class="col-lg-12">
              <div class="data-count">
                <div class="row no_side_margin">
                  <div class="col-lg-10 col-md-12 no_side_pad">
                    <span>{{ $t('total-rows', {rows: this.totalRows} ) }}.</span> {{ $t('start-row' ) }}
                    <input type="number" v-model="startRow" v-on:click="$event.target.select()" class="form-control row-no">.
                    {{ $t('total-rows-processed', { rows: this.totalRows - this.startRow + 1}) }}<!-- {{this.totalRows - this.startRow + 1}} rows will be processed -->
                  </div>
                  <div class="col-lg-2 col-md-12 no_side_pad hide-btn" v-on:click="previewData = !previewData">
                    <a v-if="this.previewData">{{ $t('hide' ) }}
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 13.781L7.26512 15L12 10.4379L16.7349 15L18 13.781L12 8L6 13.781Z" fill="#0300e2" />
                      </svg></a>
                    <a v-if="!this.previewData">{{ $t('preview-data' ) }}
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 13.781L7.26512 15L12 10.4379L16.7349 15L18 13.781L12 8L6 13.781Z" fill="#0300e2" />
                      </svg></a>
                  </div>
                </div>
              </div>
              <div style="overflow-x:auto;" v-if="this.previewData">
                <table class="table table-striped list-table no-mar">
                  <thead>
                    <tr>
                      <th class="t-head"><strong>{{ $t('row' ) }}</strong></th>

                      <th class="t-head" v-for="(row, index) in this.fileContents[0]" v-bind:key="index"><strong>COL{{index + 1}}</strong></th>

                    </tr>
                  </thead>
                  <tbody>

                    <tr v-for="(row, rowNumber) in this.fileContents" v-bind:key="rowNumber">

                      <td class="table-col">{{rowNumber + 1}}</td>
                      <td class="table-col" v-for="(col, colNumber) in row" v-bind:key="colNumber">{{fileContents[rowNumber][colNumber]}}</td>

                    </tr>

                  </tbody>
                </table>
              </div>


              <div class="col-lg-12 tools" v-if="!showCustomWizard">
                Detected fields
                <ul>
                  <li v-for="field in this.detectedFields" v-bind:key="field">{{field}}</li>
                </ul>
              </div>


              <div class="col-lg-12 tools" v-if="showCustomWizard">
                <h5>{{ $t('wizard-select-processors' ) }}</h5>
                <div class="row">
                  <div class="col-lg-3 no_side_pad">
                    <div class="item-tools">
                      <div class="dropdown field-select">
                        <button type="button" class="btn dropdown-toggle btn-drp" data-toggle="dropdown" v-if="this.mode == 'fields'">
                          {{$t('field')}}
                        </button>
                        <button type="button" class="btn dropdown-toggle btn-drp" data-toggle="dropdown" v-if="this.mode == 'groups'">
                          {{$t('category')}}
                        </button>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" v-on:click="modeChange('fields')">{{$t('field')}}</a>
                          <a class="dropdown-item" v-on:click="modeChange('groups')">{{$t('category')}}</a>
                        </div>
                      </div>
                      <h4>
                        <span class="number">1</span>&nbsp;&nbsp;
                        <span v-if="this.mode == 'fields'">{{ $t('wizard-budget-field') }}</span>
                        <span v-if="this.mode == 'groups'">{{ $t('wizard-budget-category') }}</span>
                      </h4>

                      <div class=" col-12 no_side_pad  select_period total-select">
                        <span v-if="this.mode == 'fields'">{{ $t('wizard-budget-field-help') }}</span>
                        <span v-if="this.mode == 'groups'">{{ $t('wizard-budget-category-help') }}</span>
                        <div class="btn-group ">

                          <a v-if="mode == 'fields' && this.groupView !== 'select-option'" class="btn btn-primary dropdown-toggle drp_btn" data-toggle="dropdown" href="#">{{ this.groupView }} <span class="caret"></span></a>
                          <a v-if="mode == 'groups' || this.groupView === 'select-option'" class="btn btn-primary dropdown-toggle drp_btn" data-toggle="dropdown" href="#">{{ $t(this.groupView) }} <span class="caret"></span></a>

                          <ul class="dropdown-menu">
                            <li class="dropdown-item" v-for="group in this.groups" v-bind:key="group.name"><a v-on:click="selectCategory(group.translated , group.name)">{{group.translated}}</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 no_side_pad">
                    <div :class="this.selectType ">
                      <h4>
                        <span class="number">2</span>&nbsp;&nbsp;
                        <span>{{ $t('wizard-budget-action') }} </span>
                      </h4>
                      <div class=" col-12 no_side_pad  select_period total-select">
                        <span>{{ $t('wizard-budget-type-help') }}</span>
                        <div class="btn-group ">


                          <a class="btn btn-primary dropdown-toggle drp_btn" data-toggle="dropdown" href="#">{{ $t(this.typeView ) }} <span class="caret"></span></a>
                          <ul class="dropdown-menu">

                            <li class="dropdown-item" v-for="type in this.availableTypes" v-bind:key="type"><a v-on:click="changeType('type-' + type , type)">{{ $t('type-' + type) }}<!-- {{'type-' + type | typeFilter}} --></a></li>

                          </ul>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 no_side_pad">
                    <div :class="this.selectTool ">
                      <h4>
                        <span class="number">3</span>&nbsp;&nbsp;
                        <span>{{ $t('wizard-budget-processor') }} </span>
                      </h4>
                      <div class=" col-12 no_side_pad  select_period total-select">
                        <span>{{ $t('wizard-budget-processor-help') }}</span>

                        <div class="btn-group ">


                          <a class="btn btn-primary dropdown-toggle drp_btn" data-toggle="dropdown" href="#">{{ $t(this.toolView ) }}<span class="caret"></span></a>


                          <ul class="dropdown-menu">

                            <li class="dropdown-item" v-for="processor in this.processors" v-bind:key="processor.key"><a v-on:click="selectProcessor(processor.key , processor.description)">{{ $t('type-' + processor.type + '-verb') }}
                                {{processor.description}} </a></li>

                          </ul>

                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="col-lg-3 no_side_pad">
                    <div :class="this.selectColumn ">
                      <h4>
                        <span class="number">4</span>&nbsp;&nbsp;
                        <span>{{$t('wizard-budget-column')}}</span>
                      </h4>
                      <div class=" col-12 no_side_pad  select_period total-select select-col-drp">
                        <span>{{ $t('wizard-budget-column-help') }}</span>

                        <div class="btn-group ">
                          <a v-if="!processor.params || !processor.params.length" class="btn btn-primary dropdown-toggle drp_btn" data-toggle="dropdown" href="#">{{ $t(that.columnView ) }} <span class="caret"></span></a>
                          <span v-if="processor.params && processor.params.length" v-bind:key="paramsKey">
                            <div v-for="(param, paramNumber) in processor.params" v-bind:class="'dropdownmenu' + paramNumber" v-bind:key="param.name">
                              <a class="btn btn-primary dropdown-toggle drp_btn" data-toggle="dropdown" href="#" v-bind:id="'dropdown'+paramNumber">{{ $t('select-column' ) }} <span class="caret"></span></a>
                              <ul class="dropdown-menu" v-bind:aria-labelledby="'dropdown'+paramNumber" v-if="!param.defaults || !param.defaults.length">
                                <li class="dropdown-item" v-on:click="setAssignedColNaN(paramNumber)"><a> {{ $t('select-param' ) }} {{param.name}} </a></li>
                                <li class="dropdown-item" v-for="(n) in makeRange(processing.totalCols)" v-bind:key="param.name + '-' + n"><a v-on:click="setAssignedCol(paramNumber, param.name, n+1)"> COL{{( n+1) }} </a></li>
                              </ul>
                              <ul class="dropdown-menu" v-bind:aria-labelledby="'dropdown'+paramNumber" v-if="param.defaults && param.defaults.length">
                                <li class="dropdown-item"><a> {{params.name}} </a></li>
                              </ul>
                            </div>

                          </span>

                        </div>
                      </div>
                    </div>
                  </div>

                  <button data-v-8c36869e="" :disabled="this.addSelection" type="button" class="btn btn-primary btn-lg btn-block add-selection add" v-on:click="enqueueProcessor()">{{ $t('wizard-budget-add' ) }}</button>

                </div>

              </div>
            </div>
          </div>


          <!-- budget detail start -->

          <div class="budget-detail " v-if="selectedProcessors.length">
            <div class="row">
              <div class="col-lg-8 col-md-8">
                <h2>{{ $t('wizard-budget-details' ) }}</h2>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="clear-btn " v-if="selectedProcessors.length" v-on:click="cleanAll()">{{ $t('clean-all' ) }}
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M6.00008 6.97848L1.64539 11.3332L0.666748 10.3545L5.02144 5.99984L0.666748 1.64514L1.64539 0.666504L6.00008 5.0212L10.3548 0.666504L11.3334 1.64514L6.97872 5.99984L11.3334 10.3545L10.3548 11.3332L6.00008 6.97848Z"
                      fill="#0300e2" />
                  </svg>
                </div>
              </div>
              <div class="col-lg-12">
                <div style="overflow-x: auto;">
                  <table class="table table-striped list-table">
                    <thead>
                      <tr>
                        <th class="t-head">{{ $t('processor' ) }}</th>
                        <th class="t-head">{{ $t('params' ) }}</th>
                        <th class="t-head">{{ $t('rows' ) }}</th>
                        <th class="t-head">{{ $t('unit' ) }}</th>
                        <th class="t-head">{{ $t('subtotal' ) }}</th>
                        <th class="t-head"></th>
                      </tr>
                    </thead>
                    <tbody>


                      <tr v-for="(processor , index) in this.selectedProcessors" v-bind:key="processor.key + index">
                        <td class="table-col">{{$t('group-' + processor.groups[0] + '-name')}} - {{ $t('type-' + processor.type + '-verb') }} {{processor.description}}</td>
                        <td class="table-col">
                          <div v-for="(col , colNumber) in processor.fileAssignedCols" v-bind:key="colNumber">
                            <div v-if="processor.fileAssignedCols[colNumber].length">{{processor.fileAssignedCols[colNumber] | uppercase}}: COL{{colNumber+1}}</div>
                          </div>

                        </td>
                        <td class="table-col">{{totalRows -  startRow + 1}}</td>
                        <td class="table-col">{{getProcessorCost(processor) | numFormat("0.0000") }}€</td>
                        <td class="table-col">{{getProcessedCostPerRow(processor) | numFormat("0.00")}}€</td>
                        <td class="table-col">
                          <a title="Delete Tool" v-on:click="deleteProcessor(index)">
                            <font-awesome-icon icon="trash" size="lg" />
                          </a>
                        </td>
                      </tr>



                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- budget detail end -->

          <!-- puchase section start -->
          <div class="add-purchase" v-if="selectedProcessors.length">
            {{$t('process-add-another')}}
          </div>

          <div class="purchase" v-if="selectedProcessors.length">

            <div class="data" v-if="areCreditsNeeded()">
              <h6 v-html="$t('purchase-required-description')"></h6>
              <div class="row ">
                <div class="col-lg-12">
                  <div class="amount">{{ this.getMinimumRequired() | numFormat("0.00")  }}€</div>
                </div>
              </div>
              <a class="btn btn-primary btn-lg btn-block add-selection add process" v-bind:href="'/#/purchase/packs/' + getMinimumRequired()">{{ $t('buy' ) }}</a>

            </div>

            <div class="data" v-else>
              <h6>{{ $t('payment-needed' ) }}:</h6>
              <div class="row ">
                <div class="col-lg-12">
                  <div class="amount">{{ this.getTotalCost() | numFormat("0.00")  }}€</div>
                </div>
                <div class="empty-text col-lg-12">* {{ $t('wizard-maximum-cost' ) }}</div>

              </div>

              <button type="button" class="btn btn-primary btn-lg btn-block add-selection add process" :disabled="this.isProcessing || !this.selectedProcessors.length" v-on:click="checkFile()">{{ $t('process' ) }}</button>
              <button type="button" class="btn btn-primary btn-lg btn-block add-selection add cancel" :disabled="this.isProcessing" v-on:click="resetWizard()">{{ $t('cancel' ) }}</button>
            </div>
          </div>

          <!-- puchase section end -->




          <div class="text-center col-lg-12" v-if="this.stepNumber == 5 " v-html="$t('wizard-preview-job-submitted')">


          </div>




        </div>
      </div>

      <!-- wizard content start end -->




      <Footer />

    </div>


  </div>

</div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/Sidebar.vue'
import axios from 'axios';

export default {
  name: 'wizard_process',
  data() {
    return {

      isActive: false,
      isActiveIcon: '<',
      stepNumber: 1,
      selectedSource: '',
      selectedSecondarySource: '',
      selectedSecondarySourceDb: '',
      selectedSecondarySourceCloud: '',
      maxFileUploadSize: this.getMaxFileUploadSize(),
      processing: {},
      files: '',
      formcheck: false,
      isError: false,
      isProcessing: false,
      option: '',
      selectedProcessors: [],
      groups: [],
      mode: 'fields',
      startRow: 1,
      totalRows: '',
      previewData: true,
      groupView: 'select-option',
      group: '',
      type: '',
      availableTypes: [],
      selectType: 'item-tools disabled',
      typeView: 'select-option',
      selectTool: 'item-tools disabled',
      selectColumn: 'item-tools brd-none disabled',
      processors: [],
      toolView: 'select-option',
      columnView: 'select-column',
      processorKey: '',
      processor: {},
      fieldsDetected: null,
      showCustomWizard: false,
      detectedFields: [],
      processorP: {
        current: {},
        defaultParams: {},
        relatedProcessors: []
      },
      user: null,
      totalCols: '',
      fileAssignedCols: [],
      addSelection: true,
      source: {},
      d: '',
      errorMessage: '',
      externalProviderAccounts: [],
      externalProviderLists: [],
      selectedExternalProviderAccount: "",
      processorOrg: {},
      that: this,
      colNumber: -1,

      purchaseRequired: false,
      purchaseAmount: 0,

      oscar_request: {
        file: "",
        rows: 0,
        headers: "",
        parameters: []
      },
      workflow: {
        id: "",
        description: "",
        cost: 0,
        executed: false
      },

      workflows: []

    }

  },
  mounted() {
    this.enableInterceptor();
    this.getProcessingValuesMain();
    this.getProcessorDetails();
    this.getUserDetails();

    this.setCurrentSections();
  },
  components: {

    Header,
    Sidebar,
    Footer

  },
  watch: {
    '$route'() {
      this.setCurrentSections()
    }
  },
  methods: {

    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        this.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        this.$root.$emit('isLoading', false);

        return response
      }, function(error) {
        console.log(error);
        //this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    setCurrentSections: function() {
      if (this.$route.params) {
        var primarySource = (this.$route.params.primarySource && this.$route.params.primarySource.length) ? this.$route.params.primarySource : "";
        var secondarySource = (this.$route.params.secondarySource && this.$route.params.secondarySource.length) ? this.$route.params.secondarySource : "";
        if (secondarySource && secondarySource.length) {
          if (primarySource === 'Files') {
            this.selectSecondarySourceRaw(secondarySource);
          } else if (primarySource === 'Databases') {
            this.selectSecondarySourceDbRaw(secondarySource);
          } else if (primarySource === 'Cloud') {
            this.selectSecondarySourcCloudRaw(secondarySource);
          } else if (primarySource === 'Oscar') {
            this.selectSecondarySourceOscarRaw(secondarySource);
          }
        } else if (primarySource && primarySource.length && (primarySource === 'Files' || primarySource === 'Databases' || primarySource === 'Cloud' || primarySource === 'Oscar')) {
          this.selectSourceRaw(primarySource);
        }


      }
      if (this.$route.path === '/wizard/process') {
        this.stepNumber = 1;
      }

      //this.$route.path = '/wizard/process/Files/Upload'
      //this.selectSourceRaw('Files');
      //this.selectSecondarySourceRaw('Upload');
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },

    modeChange: function(key) {

      this.mode = key;

      if (this.mode == 'fields') {
        this.getFieldDetails();
      } else {
        this.getCategoryDetails();
      }

      this.groupView = 'select-option';

    },

    selectSourceRaw: function(source) {
      this.selectedSource = source;
      this.selectedSecondarySource = '';
      this.stepNumber = 2;
    },

    selectSource: function(source) {
      this.selectSourceRaw(source);
      this.$router.push("/wizard/process/" + source)
    },

    selectSecondarySourceRaw: function(source) {
      this.selectedSource = 'Files';
      this.selectedSecondarySource = source;
      this.selectedSecondarySourceDb = '';
      this.selectedSecondarySourceCloud = '';
      this.stepNumber = 3;
      this.resetFields();
    },

    selectSecondarySource: function(source) {
      this.selectSecondarySourceRaw(source);
      this.$router.push("/wizard/process/Files/" + source)
    },

    selectSecondarySourceDbRaw: function(source) {
      this.selectedSource = 'Databases';
      this.selectedSecondarySource = source;
      this.selectedSecondarySourceDb = 'Databases';
      this.selectedSecondarySourceCloud = '';
      this.selectedSecondarySourceOscar = '';
      this.stepNumber = 3;
      this.resetFields();
    },

    selectSecondarySourceOscarRaw: function(source) {
      this.selectedSource = 'Oscar';
      this.selectedSecondarySource = source;
      this.selectedSecondarySourceDb = '';
      this.selectedSecondarySourceCloud = '';
      this.selectedSecondarySourceOscar = 'Oscar';
      this.stepNumber = 3;
      this.resetFields();
      this.getOscarRequest();
    },

    selectSecondarySourceOscar: function(source) {
      this.selectSecondarySourceOscarRaw(source);
      this.$router.push("/wizard/process/Oscar/" + source)
    },

    selectSecondarySourceDb: function(source) {
      this.selectSecondarySourceRaw(source);
      this.$router.push("/wizard/process/Databases/" + source)
    },

    resetFields: function() {
      this.source = {}
      this.formcheck = false
    },

    selectSecondarySourcCloudRaw: function(source) {
      this.selectedSource = 'Cloud';
      this.selectedSecondarySource = source;
      this.selectedSecondarySourceDb = '';
      this.selectedSecondarySourceCloud = 'Cloud';
      this.selectedSecondarySourceOscar = '';
      this.stepNumber = 3;
      this.resetFields();
      this.loadExternalProviderAccounts();
    },

    selectSecondarySourcCloud: function(source) {
      this.selectSecondarySourcCloudRaw(source);
      this.$router.push("/wizard/process/Cloud/" + source)
    },

    getMaxFileUploadSize: function() {
      return 70 * 1024 * 1024; //max 70Mb
    },

    getProcessingValuesMain: function() {

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v2/processing';

      this.$http.get(url, config).then((results) => {

        this.processing = results.data;

      });

    },

    getGroupsValues: function() {
      /*
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };
*/
      //var url = '/api/v2/group/search'
      var url = '/json/' + this.$locale + '/groups.json?' + this.$prefix;
      //this.$http.get(url, this.config).then((results) => {
      this.$http({
        method: 'GET',
        url: url,
        baseURL: '/'
      }).then((results) => {

        this.groups = results.data.groups.sort();


      });
    },

    uploadFile: function() {

      //console.log(this.$refs.file);
      this.files = this.$refs.file && this.$refs.file.files && this.$refs.file.files.length ? this.$refs.file.files[0] : null;
      //console.log(this.$refs.file.files);
      if (this.files) {


        this.isError = false;
        this.isProcessing = true;
        var completeCallback = this.cbFinishedUploading;

        var file = this.files;
        var mainFunction = this.setFileUploadProcessingValues;

        let formData = new FormData();

        formData.append('file', file);
        var config = {
          headers: {
            "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
            "Content-Type": "application/json"
          }
        };
        this.$http.post('/api/v1/' + localStorage.token + '/upload-simple', formData, config).then((results) => {
          mainFunction(results.data, completeCallback);
        });
      }
    },

    setFileUploadProcessingValues: function(data, callback) {

      this.setFileUploadProcessingValuesSec(data);
      this.processing = this.getProcessingValues();
      if (callback) {
        callback(data);
      }

    },

    setFileUploadProcessingValuesSec: function(data) {
      this.processing.totalRows = parseInt(data.lines + 1, 10);
      this.processing.totalRowsOk = 0;
      this.processing.userCredits = parseInt(data.credits, 10);
      this.processing.totalCols = parseInt(data.totalCols, 10);
      this.processing.assignedProcessors = [];
      this.processing.allParamsNames = [];
      this.processing.areAllParamsAssigned = false;
      this.processing.assignedCols = [];
      this.processing.assignedValues = [];

      //Set values related to upload
      this.processing.upload = {
        detected: data.detected,
        contents: data.contents,
        fileName: data.name,
        fileNeedsPreview: this.processingFileNeedsPreview()
      };

      var tool = (this.$route.params.tool && this.$route.params.tool.length) ? this.$route.params.tool : "";
      if (tool && tool.length) {
        var parts = tool.split("-");
        var type = parts.shift();
        var field = parts.shift();

        var filteredTools = this.processorOrg.filter(function(item) {
          return item.key === tool;
        });

        if (filteredTools && filteredTools.length) {
          field = filteredTools[0].params[0].name;
        }

        var filteredGroup = this.groups.filter(function(item) {
          return item.name === field;
        });

        if (filteredGroup.length) {
          this.selectCategory(filteredGroup[0].translated, filteredGroup[0].name);
        }

        if (type.length) {
          this.changeType('type-' + type, type);
        }

        if (filteredTools.length) {
          //console.log(filteredTools[0].key, filteredTools[0].description);
          //this.selectProcessor(filteredTools[0].key, filteredTools[0].description);
          //var localProcessorStr = JSON.stringify(filteredTools[0].params);
          //console.log(localProcessorStr)
          //this.processor = JSON.parse(localProcessorStr);
          //console.log(this.processor)
          //this.paramsKey = filteredTools[0].key + new Date().getTime();
          //this.setAssignedCol(0, filteredTools[0].key, 1);
          //this.$forceUpdate();
        }
      }

      //completeProcessingValues();
    },

    processingFileNeedsPreview: function() {

      return this.getFileColsNumber() > 0;

    },

    getFileColsNumber: function() {

      return this.processing.totalCols;

    },

    cbFinishedUploading: function(data) {
      this.option = 'local';
      this.resetSelectedProcessors();
      this.isProcessing = false;
      this.isUploaded = true;
      if (this.getTotalRows() === -1) {
        this.isError = true;
        this.errorMessage = data.status;
      } else if (this.getTotalRows() > 20000 && !this.user.advanced) {
        this.isError = true;
      } else {
        this.stepNumber = 4;
        this.isError = false;
        this.name = data.name;
        this.loadProcessingData();
        this.deductStartRow();
        this.getProcessorDetails();
      }
    },

    cbErrorUploading: function() {
      this.resetSelectedProcessors();
      this.isProcessing = false;
      this.isUploaded = false;
      this.isError = true;
    },

    loadProcessingData: function() {

      this.initGroups();
      this.totalRows = this.getTotalRows();
      this.totalCols = this.getTotalCols();
      this.fileContents = this.getFileContents();
      this.costPerCredit = this.getCostPerCredit();
      this.processing = this.getProcessingValues();

      this.detectedFields = this.getFieldsDetected();
      this.showCustomWizard = this.detectedFields.length === 0;
    },

    deductStartRow: function() {
      //get available fields + locale fields
      var fields = this.getFieldDetail().map(function(f) {
        return f.name.toLowerCase();
      }).concat(['correo', 'nombre', 'apellidos', 'teléfono', 'movil', 'apellido1', 'apellido2', 'apellido']);
      var rowNumber = 1;
      this.startRow = 1;
      var that = this;
      var found = false;
      var titlesmerged = fields.join(";").toLowerCase();
      this.fileContents.forEach(function(row) {
        row.forEach(function(col) {
          //Detect column headers
          if (col.length && titlesmerged.indexOf(col.toLowerCase()) !== -1 && !found) {
            //debug(col, rowNumber + 1);
            found = true;
            that.startRow = rowNumber + 1;
          }
        });
        rowNumber++;
      });
    },

    resetSelectedProcessors: function() {

      this.selectedProcessors = [];

    },

    getTotalRows: function() {

      return this.processing.totalRows + 1;

    },

    getTotalCols: function() {

      return this.processing.totalCols;

    },

    getFileContents: function() {

      return this.processing.upload.contents;

    },

    getFieldsDetected: function() {
      //console.log(this.processing.upload.detected);
      /*
        if (this.processing.upload.detected) {
          var detected = this.processing.upload.detected;
          return Object.keys(detected).map(function(index) {
            return detected[index];
          });
        } else {
          return [];
        }
        */
      return [];
    },

    getCostPerCredit: function() {

      return this.processing.costPerCredit;

    },

    getProcessingValues: function() {

      return this.processing;

    },

    initGroups: function() {

      this.groups = this.mode == 'groups' ? this.getGroupsValues() : this.getFieldDetails();

    },

    getFieldDetails: function() {
      var fields = this.getUniqueAvailableParamNames(this.processor).map(function(item) {
        return {
          name: item,
          translated: item
        }
      });
      this.groups = fields;

      return fields;
    },

    getCategoryDetails: function() {
      /*
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };*/

      //var url = '/api/v2/group/search'
      var url = '/json/' + this.$locale + '/groups.json?' + this.$prefix;
      //this.$http.get(url, this.config).then((results) => {
      this.$http({
        method: 'GET',
        url: url,
        baseURL: '/'
      }).then((results) => {
        this.groups = results.data.groups.sort();


      });

    },

    getFieldDetail: function() {


      return this.getUniqueAvailableParamNames(this.processor).map(function(item) {
        return {
          name: item,
          translated: item
        }
      });

    },

    getUniqueAvailableParamNames: function(items) {

      var params = [];
      if (items && items.forEach) {

        items.forEach(function(p) {

          var paramNames = p.params.map(function(p) {
            return p.name.replace(/[0-9]+/g, '');
          });
          var firstParam = paramNames.length > 0 ? paramNames[0] : "";
          if (params.indexOf(firstParam) === -1) {
            params.push(firstParam);
          }

        });
      }
      return params.sort();

    },

    getProcessorDetails: function() {
      var url = '/json/' + this.$locale + '/processors.json?' + this.$prefix;
      this.loading = true;
      this.$http({
        method: 'GET',
        url: url,
        baseURL: '/'
      }).then((results) => {

        this.processor = results.data.processors;

        this.processorOrg = results.data.processors;

        this.groups = this.getUniqueAvailableParamNames(this.processor).map(function(item) {
          return {
            name: item,
            translated: item
          }
        });
      });

    },

    selectCategory: function(translation, name) {

      this.processor = this.processorOrg;

      this.selectType = 'item-tools disabled';

      this.selectTool = 'item-tools disabled';

      this.selectColumn = 'item-tools brd-none disabled';

      this.typeView = 'select-option';

      this.toolView = 'select-option';

      this.columnView = 'select-column';

      this.groupView = translation;

      this.group = name;

      this.type = '';

      this.selectType = 'item-tools';

      this.filterTypes();

    },

    filterTypes: function() {
      this.availableTypes = [];
      if (this.group.length) {
        var fn = this.mode == 'groups' ? this.getFilteredProcessorsWizard : this.getFilteredProcessorsByParamNameWizard;
        if (fn(this.group, 'normalize', this.processing).length > 0) {
          this.availableTypes.push('normalize');
        }

        if (fn(this.group, 'check', this.processing).length > 0) {
          this.availableTypes.push('check');
        }

        if (fn(this.group, 'get', this.processing).length > 0) {
          this.availableTypes.push('get');
        }

        if (fn(this.group, 'send', this.processing).length > 0) {
          this.availableTypes.push('send');
        }
      }
    },

    getFilteredProcessorsWizard: function(group, type, processing) {
      var finalProcessors = [];
      if (group && group.length && type && type.length) {
        this.processor.forEach(function(processor) {
          if (processor.groups.indexOf(group) !== -1 && processor.type === type) {
            processor.price = processor.cost * processing.costPerCredit;
            finalProcessors.push(processor);
          }
        });
        return finalProcessors.sort(function(item1, item2) {
          return item1.description.localeCompare(item2.description);
        });

      } else {
        finalProcessors = this.processor;
      }

      return finalProcessors;
    },

    getFilteredProcessorsByParamNameWizard: function(param, type, processing) {
      var finalProcessors = [];

      if (param && param.length && type && type.length) {
        if (this.processor && this.processor.forEach) {
          this.processor.forEach(function(processor) {
            var found = processor.params.filter(function(p) {
              return p.name.indexOf(param) !== -1;
            });
            if (found.length && processor.type === type) {

              processor.price = processor.cost * processing.costPerCredit;
              finalProcessors.push(processor);
            }
          });
          finalProcessors.sort(function(item1, item2) {
            return item1.description.localeCompare(item2.description);
          });
        }
      } else {
        finalProcessors = this.processor;
      }

      return finalProcessors;
    },

    changeType: function(translation, type) {
      this.typeView = translation;
      this.selectTool = 'item-tools disabled';
      this.selectColumn = 'item-tools brd-none disabled';
      this.toolView = 'select-option';
      this.columnView = 'select-column';
      this.processor = this.processorOrg;

      if (this.group.length && type.length) {
        var fn = this.mode == 'groups' ? this.getFilteredProcessorsWizard : this.getFilteredProcessorsByParamNameWizard;
        this.processors = fn(this.group, type, this.processing);

        if (this.processors.length && type.length) {
          this.selectTool = 'item-tools';
        }
      }
    },

    selectProcessor: function(pKey, pDescription) {
      this.processorKey = pKey;
      var processor = this.getProcessorByKey();
      if (processor) {
        processor.normalized = false;
        this.processor = this.addProcessor(processor);
        this.loadProcessorData();
        this.toolView = pDescription;
        this.selectColumn = 'item-tools brd-none';
      }
    },

    getProcessorByKey: function() {
      var processor = null;
      if (this.processorKey && this.processorKey.length) {
        var processorKey = this.processorKey;
        var selected = this.processors.filter(function(item) {
          return item.key === processorKey;
        });
        if (selected.length) {
          processor = JSON.parse(JSON.stringify(selected[0]));
        }
      }
      return processor;
    },

    addProcessor: function(processor) {
      this.processing.assignedProcessors.push(processor);
      this.processing.currentIndex = this.processing.assignedProcessors.length - 1;
      this.setCurrentProcessorValues();
      return processor;
    },

    setCurrentProcessorValues: function() {
      if (this.processing.assignedProcessors.length) {
        var curItem = this.processing.assignedProcessors[this.processing.currentIndex];
        this.processing.allParamsNames = this.getAvailableParamNames(curItem);
        curItem.fileAssignedCols = curItem.fileAssignedCols ? curItem.fileAssignedCols : Array(this.processing.totalCols).join(".").split(".");
      } else {
        this.processing.allParamsNames = [];
      }
      this.checkAllParamsAssigned();
      this.completeProcessingValues();
    },

    getAvailableParamNames: function(currentProcessor) {
      var proc = currentProcessor && Object.keys(currentProcessor).length ? currentProcessor : this.getCurrentSelectedProcessor();
      var paramsNames = [];
      if (proc.params) {
        proc.params.forEach(function(param) {
          paramsNames.push(param.name);
        });
      }
      return paramsNames;
    },

    checkAllParamsAssigned: function() {
      var allAssigned = true;
      //Delete other params with same name, not current
      if (this.processing.allParamsNames && this.processing.allParamsNames.length &&
        this.processing.assignedProcessors && this.processing.assignedProcessors.length) {
        var fileAssignedCols = this.processing.assignedProcessors[this.processing.currentIndex].fileAssignedCols;
        var self = this;
        this.processing.allParamsNames.forEach(function(paramName) {
          var found = self.searchInArray(fileAssignedCols, paramName);
          if (!found) {
            allAssigned = false;
          }
        });
      }

      this.processing.areAllParamsAssigned = allAssigned;
      //return this.areAllParamsAssigned();
      return this.processing.areAllParamsAssigned;
    },

    searchInArray: function(arr, string) {
      var matches = arr.filter(function(str) {
        var pos = str.indexOf(string);
        return pos !== -1;
      });

      return matches.length > 0;
    },

    areAllParamsAssigned: function() {
      return this.processing.areAllParamsAssigned;
    },

    completeProcessingValues: function() {
      this.setTableData();
      this.calculateProcessorsCost();
      this.processing.userCredits = this.getCredits();
      this.processing.neededCredits = (this.processing.userCredits - this.processing.processCredits < 0) ? this.processing.processCredits - this.processing.userCredits : 0;
      this.processing.neededCredits = this.processing.neededCredits && this.processing.neededCredits < this.processing.minimumCredits ? this.processing.minimumCredits : this.processing.neededCredits;
      this.processing.neededCost = this.processing.neededCredits * this.processing.costPerCredit;
      this.processing.neededCostWithVat = this.processing.neededCost * (1 + (this.processing.vat / 100));
    },

    setTableData: function() {
      this.processing.dataTable = {
        data: this.prepareTableData()
      };
    },

    prepareTableData: function() {
      var col = 0;

      var data = [];
      if (this.processorP.current.params) {
        var self = this;
        this.processorP.current.params.forEach(function(param) {
          if (param.value && param.value.length) {
            var value = self.formatParamValue(param.value);
            var row = 0;
            self.splitRow(value).forEach(function(cellData) {
              if (self.isArrayUndefined(data[row])) {
                data[row] = [];
              }
              if (self.isArrayUndefined(data[row][col])) {
                data[row][col] = [];
              }
              data[row][col].push(cellData);
              row++;
            });
          }
          col++;
        });
      }


      return data;
    },

    formatParamValue: function(value) {
      return value.replace(/(\r\n|\n|\r)/gm, ";");
    },

    splitRow: function(str) {
      return this.formatParamValue(str).split(";");
    },

    isArrayUndefined: function(arr) {
      return (typeof arr === "undefined" || !(arr instanceof Array));
    },

    calculateProcessorsCost: function() {
      this.processing.processCredits = 0;
      this.processing.processCost = 0;
      //If assigned processors, calculate value
      if (this.processing.assignedProcessors.length) {
        var self = this;
        this.processing.assignedProcessors.forEach(function(processor) {
          self.processing.processCredits += (self.processing.totalRows) * processor.max_cost;
        });
      } else {
        this.processing.processCredits = (this.processing.totalRows) * this.getCurrentSelectedProcessor().max_cost;
      }
      this.processing.processCost = this.processing.processCredits * this.processing.costPerCredit;
    },

    loadProcessorData: function() {
      this.allParamsNames = this.getCurrentParamNames();
      this.areAllParamsAssigned = this.checkAllParamsAssigned();
      this.fileAssignedCols = this.getFileAssignedCols();
      this.totalRows = this.getTotalRows();
      this.showJobScheduled = this.isJobScheduled();
    },

    getCurrentParamNames: function() {
      return this.processing.allParamsNames;
    },

    getFileAssignedCols: function() {
      return this.processing.currentIndex > -1 && this.processing.currentIndex < this.processing.assignedProcessors.length ?
        this.processing.assignedProcessors[this.processing.currentIndex].fileAssignedCols : new Array(this.processing.totalCols);
    },

    isJobScheduled: function() {
      return this.processing.receivedData !== undefined &&
        this.processing.receivedData !== null &&
        this.processing.receivedData.status === 1;
    },

    getUserDetails: function() {
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v2/profile';
      this.$http.get(url, config).then((results) => {
        this.user = results.data;
        if (this.$store.getters.needs_complete) {
          alert(this.$t('profile-complete'))
          this.$router.push('/settings/profile')
        }
      });
    },

    getUserDetailsExternalAPI: function() {

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };


      var url = '/api/v2/profile';

      this.$http.get(url, config).then((results) => {

        this.user = results.data;

        this.loadExternalProviderAccounts();


      });

    },

    getCredits: function() {

      return this.user ? this.user.credits : 0;


    },

    makeRange: function(input) {
      var nums = Array.apply(null, Array(input)).map(function(x, i) {
        return i;
      });
      return nums;
    },

    setAssignedCol: function(colIndex, paramName, item) {
      this.fileAssignedCols = colIndex;
      this.unsetAssignedCol(paramName);
      if (item !== undefined) {
        this.processing.assignedProcessors[this.processing.currentIndex].fileAssignedCols[item - 1] = paramName;
      }

      var mustBeDisabled = false;
      var that = this;
      this.processor.params.forEach(function(p) {
        if (!mustBeDisabled && p.required && that.processing.assignedProcessors[that.processing.currentIndex].fileAssignedCols.indexOf(p.name) === -1) {
          mustBeDisabled = true
        }
      });
      this.addSelection = mustBeDisabled;
      this.columnView = 'COL' + item;
      document.getElementById('dropdown' + colIndex).innerText = 'COL' + item;
    },

    setAssignedColNaN: function(colIndex) {
      document.getElementById('dropdown' + colIndex).innerText = 'Select column';
    },

    unsetAssignedCol: function(name) {
      for (var i = 0; i < this.processing.assignedProcessors[this.processing.currentIndex].fileAssignedCols.length; i++) {
        if (this.processing.assignedProcessors[this.processing.currentIndex].fileAssignedCols[i] === name) {
          this.processing.assignedProcessors[this.processing.currentIndex].fileAssignedCols[i] = "";
        }
      }
    },

    enqueueProcessor: function() {
      this.enqueueProcessorPush();
      this.resetProcessorValues();
      this.stepNumber = 4;
      this.group = "";
      this.type = "";
      this.processorKey = "";
      this.processor = {};
      this.groupView = 'select-option';
      this.selectType = 'item-tools disabled';
      this.typeView = 'select-option';
      this.selectTool = 'item-tools disabled';
      this.selectColumn = 'item-tools brd-none disabled';
      this.toolView = 'select-option';
      this.columnView = 'Select column';
      this.addSelection = true;
      //this.getProcessorDetails();
    },

    enqueueProcessorPush: function() {
      var currentProcessor = this.getCurrentSelectedProcessor();
      this.resetSelectedProcessorsenqueue();
      this.selectedProcessors.push(currentProcessor);
    },

    getCurrentSelectedProcessor: function() {
      return this.processing.assignedProcessors.length ? this.processing.assignedProcessors[this.processing.currentIndex] : {};
    },

    resetSelectedProcessorsenqueue: function() {
      if (this.processing.assignedProcessors) {
        this.processing.assignedProcessors = [];
        this.processing.assignedCols = [];
      }
    },

    getProcessorCost: function(processor) {
      return processor.max_cost * this.processing.costPerCredit;
    },

    deleteProcessor: function(index) {

      this.selectedProcessors.splice(index, 1);

    },

    resetProcessorValues: function() {
      this.processor = {};
      this.group = "";
      this.type = "";
      this.processorKey = "";
      this.fileAssignedCols = [];
      this.areAllParamsAssigned = false;
      this.resetProcessors();
    },

    resetProcessors: function() {
      if (this.processing.assignedProcessors) {
        this.processing.assignedProcessors = [];
        this.processing.assignedCols = [];
      }
    },

    cleanAll: function() {
      this.resetSelectedProcessors();
    },

    resetWizard: function() {
      this.initValues();
    },

    initValues: function() {
      this.option = '';
      this.stepNumber = 1;
      this.isProcessing = false;
      this.isUploaded = false;
      this.selectedProcessors = [];
      this.resetUploadValues();
      this.resetGroupValues();
      this.resetProcessorValues();
      this.maxFileUploadSize = this.getMaxFileUploadSize();
      this.getProcessorDetails();
    },

    resetUploadValues: function() {
      this.isUploaded = false;
    },

    resetGroupValues: function() {
      this.groupProcessors = [];
      this.groups = [];
      this.group = "";
    },

    getTotalCost: function() {
      var total = 0;
      for (var i = 0; i < this.selectedProcessors.length; i++) {
        total += this.getTotalCostPerRow(this.selectedProcessors[i]);
      }

      return total;
    },

    getTotalCostPerRow: function(processor) {
      return processor.normalized ? this.getProcessedCostPerRow(processor) + this.getNormalizedCostPerRow() : this.getProcessedCostPerRow(processor);
    },

    getProcessedCostPerRow: function(processor) {
      return Math.ceil(processor.max_cost * (this.processing.totalRows - this.startRow + 1) * this.processing.costPerCredit * 100) / 100;
    },

    getMinimumRequired: function() {
      var amount = parseInt(Math.ceil((this.processing.processCredits - this.getCredits()) / 10000), 10);
      if (amount < 25) {
        amount = 25;
      }
      return amount;
    },

    checkFile: function() {
      if (this.isUploaded) {
        this.updateProcessingValues();
        if (this.areCreditsNeeded() && !this.hasAutoCredits()) {
          this.purchaseRequired = true
          this.purchaseAmount = this.getMinimumRequired();
        } else if (!this.isJobScheduled()) {
          this.isProcessing = true;
          var startRow = this.startRow !== undefined ? this.startRow : 1;
          if (startRow < 1) {
            startRow = 1;
          }
          this.setProcessingStartRow(startRow);
          console.log(this.selectedSecondarySource);
          var fn = this.selectedSecondarySource.toLowerCase() === 'upload' ? this.processUpload : this.processStream;
          fn();
        }
      }
    },

    cbCheckSuccess: function() {
      this.initValues();
      this.stepNumber = 5;
    },

    updateProcessingValues: function() {
      this.setAssignedProcessors(this.selectedProcessors);
    },

    setAssignedProcessors: function(processors) {
      this.processing.assignedProcessors = processors;
      this.processing.assignedCols = this.getAssignedColsFromProcessors();
      this.completeProcessingValues();
    },

    getAssignedColsFromProcessors: function() {
      var assignedCols = [];
      for (var i = 0, len = this.processing.assignedProcessors.length; i < len; i++) {
        var fileAssignedCols = this.processing.assignedProcessors[i].fileAssignedCols;
        var assignedSubcols = [];
        for (var j = 0, len2 = fileAssignedCols.length; j < len2; j++) {
          var paramName = (fileAssignedCols[j]) ? fileAssignedCols[j] : "";
          assignedSubcols.push(paramName);
        }
        assignedCols.push(assignedSubcols.join(","));
      }
      return assignedCols.join(";");
    },

    hasCards: function() {
      return this.user && this.user.cards && this.user.cards.length;
    },

    hasAutoCredits: function() {
      return this.user && this.user.autocredits !== null ? this.user.autocredits : false;
    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },
    areCreditsNeeded: function() {
      return this.processing.processCredits > this.getCredits();
    },

    setProcessingStartRow: function(startRow) {
      this.processing.startRow = startRow;
    },

    processUpload: function() {
      var finalProcessorName = this.getAssignedProcessors() || this.getCurrentProcessorName();
      var finalAssignedCols = this.getAssignedColsFromProcessors() || this.getAssignedCols();
      var finalAssignedValues = this.getAssignedValuesFromProcessors();
      var finalAssignedNormalized = this.getAssignedNormalized();

      this.processUploadedFile(this.processing.upload.fileName, this.processing.totalRows, this.processing.startRow || 0, this.processing.totalCols, finalProcessorName, finalAssignedCols, finalAssignedValues, finalAssignedNormalized);

    },

    getAssignedProcessors: function() {
      var assignedProcessors = this.processing.assignedProcessors;
      var assignedCols = [];
      if (assignedProcessors) {
        for (var i = 0; i < assignedProcessors.length; i++) {
          var assignedProcessor = assignedProcessors[i];
          var paramName = assignedProcessor.type + '-' + assignedProcessor.module + '-' + assignedProcessor.name;
          assignedCols.push(paramName);
        }
      }
      return assignedProcessors ? assignedCols.join(";") : null;
    },

    getCurrentProcessorName: function(currentProcessor) {
      currentProcessor = currentProcessor || this.getCurrentSelectedProcessor();
      return currentProcessor.type + '-' + currentProcessor.module + '-' + currentProcessor.name;
    },

    getAssignedCols: function() {
      return this.processing.assignedCols;
    },

    filterAssignedValues: function(arr, paramName) {
      return arr.filter(function(item) {
        return item.name === paramName;
      });
    },

    getAssignedValuesFromProcessors: function() {
      var assignedCols = [];
      for (var i = 0, len = this.processing.assignedProcessors.length; i < len; i++) {
        var fileAssignedValues = this.processing.assignedProcessors[i].fileAssignedValues;
        if (fileAssignedValues && fileAssignedValues.length) {
          var processor = this.processing.assignedProcessors[i];
          for (var j = 0, len2 = processor.params.length; j < len2; j++) {
            var paramName = processor.params[j].name;
            var paramFixedValue = this.filterAssignedValues(fileAssignedValues, paramName);
            if (paramFixedValue && paramFixedValue.length) {
              assignedCols.push(paramFixedValue[0].name + "=" + paramFixedValue[0].value);
            }
          }
        }
      }
      return assignedCols.length ? assignedCols.join(";") : "";
    },

    getAssignedNormalized: function() {
      var assignedProcessors = this.processing.assignedProcessors;
      var assignedNormalizeds = [];
      if (assignedProcessors) {
        for (var i = 0; i < assignedProcessors.length; i++) {
          assignedNormalizeds.push(assignedProcessors[i].normalized);
        }
      }
      return assignedNormalizeds ? assignedNormalizeds.join(";") : null;
    },

    processUploadedFile: function(fileName, totalRows, startRow, totalCols, processorName, assignedCols, fixedValues, assignedNormalized) {

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      fixedValues = fixedValues.length ? fixedValues : " ";
      var url = '/api/v2/file/json/' + fileName + "/" + totalRows + '/' +
        totalCols + '/' + processorName + '/' + assignedCols + '/' + assignedNormalized + '/' + fixedValues + '/' + startRow;

      this.$http.get(url, config).then(() => {

        this.cbCheckSuccess();

      });

    },

    processStream: function(callback) {
      var finalProcessorName = this.getAssignedProcessors() || this.getCurrentProcessorName();
      var finalAssignedCols = this.getAssignedColsFromProcessors() || this.getAssignedCols();

      var processors = [];
      var pnames = finalProcessorName.split(";");
      var columns = finalAssignedCols.split(";");
      for (var i = 0; i < pnames.length; i++) {
        var params = columns[i].split(",");
        var p = {
          processor: pnames[i],
          params: {}
        };
        for (var j = 0; j < params.length; j++) {
          if (params[j] && params[j].length) {
            p.params[params[j]] = [j];
          }
        }

        processors.push(p);
      }

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var data = {
        processors: processors,
        stream: this.processing.stream,
        first_row: this.processing.startRow
      };

      var url = "/api/v2/process/stream";
      this.$http.post(url, data, config).then(() => {
        this.cbCheckSuccess();

        if (callback) {
          callback();
        }
      });
    },

    clearSource: function() {
      this.source = {};
      this.errorMessage = '';
      this.stepNumber = 1;
    },

    previewSource: function() {

      var completeCallback = this.cbFinishedUploading;
      var errorCallback = this.cbErrorUploading;
      if (this.source) {
        this.formcheck = false;
        this.isError = false;
        this.source.type = this.selectedSecondarySource.toLowerCase();
        this.isProcessing = true;
        this.previewSourceCore(this.source, completeCallback, errorCallback);
      }

    },

    previewSourceCore: function(data, completeCallback, errorCallback) {
      if (data && Object.keys(data).length) {
        var url = this.getSource(data);
        this.setStream(url);
        this.previewSourceApi(url, completeCallback, errorCallback);
      }

    },

    setSource: function(data) {
      this.d = data;
      this.setStream(this.getSource())
    },

    setStream: function(stream) {
      this.processing.stream = stream;
    },

    getSource: function(data) {
      var buildUrl = function(data) {
        var url = "";
        if (data && data["file_path"]) {
          data["file_path"] = data["file_path"].indexOf("/") === 0 ? data["file_path"] : "/" + data["file_path"];
        }
        if (data.type === 'url') {
          return data.uri;
        } else if (data.type === 'ftp' || data.type === 'sftp') {
          return data.type + "://" + data.username + ":" + data.password + "@" + data.server + data["file_path"];
        } else if (data.type === 's3') {
          return data.type + "://" + data.accesskey + ":" + data.secretkey + "@s3.amazon.com/" + data.bucket + data["file_path"];
        } else if (data.type.indexOf('mysql') !== -1 || data.type.indexOf('postgres') !== -1 || data.type.indexOf('sqlserver') !== -1 || data.type.indexOf('oracle') !== -1) {
          return data.type + "://" + data.username + ":" + data.password + "@" + data.server + "/" + data.database + "/" + data.table;
        } else if (data.type === 'mailchimp') {
          return data.type + "://" + data.id + "/" + data.list;
        } else {
          return url;
        }
      };
      return buildUrl(data);

    },

    previewSourceApi: function(source, successCallback, errorCallback) {
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };
      var url = '/api/v2/process/preview';
      this.$http.post(url, {
        source: source
      }, config).then((results) => {



        this.setFileUploadProcessingValues(results.data, successCallback);
      }, function() {
        errorCallback();

      }); //.then(successCallback, errorCallback);

    },

    sourceCheck: function() {
      this.formcheck = this.source &&
        this.source.server && this.source.server.length > 0 &&
        this.source.username && this.source.username.length > 0 &&
        this.source.password && this.source.password.length > 0 &&
        this.source.file_path && this.source.file_path.length > 0;
    },

    sourceCheckS3: function() {
      this.formcheck = this.source &&
        this.source.accesskey && this.source.accesskey.length > 0 &&
        this.source.secretkey && this.source.secretkey.length > 0 &&
        this.source.bucket && this.source.bucket.length > 0 &&
        this.source.file_path && this.source.file_path.length > 0;
    },

    sourceCheckURL: function() {
      this.formcheck = this.source && this.source.uri && this.source.uri.length > 0
    },

    sourceCheckDB: function() {
      this.formcheck = this.source &&
        this.source.server && this.source.server.length > 0 &&
        this.source.username && this.source.username.length > 0 &&
        this.source.password && this.source.password.length > 0 &&
        this.source.database && this.source.database.length > 0 &&
        this.source.table && this.source.table.length > 0;
    },

    loadExternalProviderAccounts: function() {

      this.externalProviderAccounts = this.getExternalProviderAccounts(this.selectedSecondarySource.toLowerCase().trim());

    },

    getExternalProviderAccounts: function(provider) {
      if (this.user && this.user.memberships && this.user.memberships.length) {
        var mapped = this.user.memberships.filter(function(m) {
          return m.provider === provider && m.account && Object.keys(m.account).length > 0;
        });
        return mapped;
      } else {
        return [];
      }

    },

    loginApp: function() {
      var that = this;
      var openPopup = function(w, h) {

        var top = window.top.outerHeight / 2 + window.top.screenY - (h / 2)
        var left = window.top.outerWidth / 2 + window.top.screenX - (w / 2)
        var win = window.open('/api/v2/auth/mailchimp', "popup", "width=" + w + ",height=" + h + ",top=" + top + ",left=" + left);
        var popupTick = setInterval(function() {
          if (win.closed) {
            clearInterval(popupTick);
            that.getUserDetailsExternalAPI();
          }
        }, 500);
      };
      openPopup(750, 500);
    },

    loadListsByAccount: function(userId) {
      this.isProcessing = true;
      this.selectedExternalProviderAccount = userId;
      this.loadExternalProviderLists(this.selectedSecondarySource.toLowerCase().trim(), userId);
    },

    loadExternalProviderLists: function(provider, user) {
      this.getExternalLists(provider, user);
    },

    getExternalLists: function(provider, userId) {
      if (this.user) {
        var config = {
          headers: {
            "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
            "Content-Type": "application/json"
          }
        };

        var url = '/api/v2/provider/' + provider + '/' + userId + '/lists';

        this.$http.get(url, config).then((results) => {
          this.externalProviderLists = results.data;
        });
      } else {
        this.externalProviderLists = [];
      }
    },

    updateWorkflowCost: function() {
      var company_cost = this.oscar_request.rows * this.workflow.costs.company.base;
      var financial = this.workflow.parameters.filter(function(p) {
        return p.name === 'financial'
      });
      if (financial && financial.length) {
        financial = financial[0].value === "True";
      }
      if (this.workflow.costs.company.financial && financial) {
        company_cost += this.oscar_request.rows * this.workflow.costs.company.financial;
      }

      var prospects = this.workflow.parameters.filter(function(p) {
        return p.name === 'prospects_per_account'
      });
      if (prospects && prospects.length) {
        prospects = prospects[0].value;
      }
      var areas = this.workflow.parameters.filter(function(p) {
        return p.name === 'areas'
      });
      if (areas && areas.length) {
        areas = areas[0].value.split(",").length;
      } else {
        areas = 1;
      }
      var keywords = this.workflow.parameters.filter(function(p) {
        return p.name === 'keywords'
      });
      if (keywords && keywords.length) {
        keywords = keywords[0].value.split(",").length;
      } else {
        keywords = 1;
      }

      var email_verifier = this.workflow.parameters.filter(function(p) {
        return p.name === 'verify'
      });
      if (email_verifier && email_verifier.length) {
        email_verifier = email_verifier[0].value === "True";
      }
      var prospect_cost_unit = this.workflow.costs.prospect.base;
      if (email_verifier) {
        prospect_cost_unit += this.workflow.costs.prospect.verify;
      }
      this.oscar_request.prospects_count = this.oscar_request.rows * prospects * areas * keywords;
      var prospect_cost = prospect_cost_unit * this.oscar_request.prospects_count;
      this.workflow.maximum_cost = (company_cost + prospect_cost) / 10000;
      this.workflow.prospect_cost = prospect_cost_unit / 10000;
      this.$forceUpdate();
    },

    getOscarRequest: function() {
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v2/oscar_requests/' + this.selectedSecondarySource;
      this.$http.get(url, config).then((results) => {
        this.oscar_request = results.data;
        this.oscar_request.headers = this.oscar_request.headers.join(", ")
        this.getWorkflows();
      });
    },

    getWorkflows: function() {
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v2/workflows';
      var that = this;
      this.$http.get(url, config).then((results) => {
        //console.log(results.data);
        if (that.oscar_request.workflow_type === -1) {
          this.workflow = results.data[0];
        } else {
          this.workflow = results.data.filter(function(wf) {
            return that.oscar_request.workflow_type === wf.id;
          })[0]; //Must be user workflows
        }

        this.updateWorkflowCost();
      });
    },

    getWorkflowParameters: function() {
      var params = {};
      this.workflow.parameters.forEach(function(parameter) {
        params[parameter.name] = parameter.value;
      });
      return params;
    },

    executeWorkflow: function() {
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var data = this.getWorkflowParameters();
      data.workflow_type = this.workflow.id || 1;
      data.callback = "https://uproc.free.beeceptor.com";

      var url = '/api/v2/workflows/' + this.selectedSecondarySource + '/execute';
      this.isProcessing = true;
      this.$http.post(url, data, config).then((results) => {
        console.log(results.data);
        this.isProcessing = false;
        this.workflow.executed = true;
        this.$forceUpdate();
      });
    },

    addToParameter: function(parameter, value) {
      var params = parameter.value.length > 0 ? parameter.value.split(",") : [];
      if (value && value.length) {
        if (params.indexOf(value) === -1) {
          params.push(value);
        }
      }
      parameter.value = params.join(",")
      this.updateWorkflowCost();
    },

    previewList: function(listId) {

      this.source = {
        type: this.selectedSecondarySource.toLowerCase(),
        id: this.selectedExternalProviderAccount,
        list: listId
      };
      this.previewSource();

    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/wizards.css';
@import '../../assets/css/global.css';
</style>
