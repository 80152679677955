<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>




      <!-- wizard content start start -->
      <div>
        <div class="total-lists">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item "><a v-bind:href="'#/lists'">{{ $t('top-list' ) }}</a></li>
              <li class="breadcrumb-item "><a v-bind:href="'#/lists/' + this.$route.params.name">{{ $t('list-my' ) }}</a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('report' ) }}</li>
            </ol>
          </nav>

          <div class="row no_side_margin">


            <div class="col-lg-12 no_side_pad">

              <div class="tab-content">
                <div id="report" class="tab-pane container active">
                  <div class="row">
                    <div class="col-sm-12 col-12 col-title ">
                      <h3>{{ $t('summary' ) }}</h3>
                    </div>
                  </div>




                  <div class="row smry-status no_side_margin" style="overflow-x: auto;">
                    <table class="table table-striped list-table" style="margin-top: 0px">
                      <thead>
                        <tr>
                          <th class="t-head text-left"><strong>{{ $t('report-entries' ) }}</strong></th>

                          <th class="t-head text-right"><strong>{{ $t('quantity' ) }}</strong></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-left">{{ $t('total-rows-basic' ) }}</td>
                          <td class="text-right"> {{list.elementsFiltered.length}} </td>

                        </tr>




                      </tbody>
                    </table>




                  </div>



                  <div class="row no_side_margin">
                    <div class="col-sm-12 col-12 col-title no_side_pad ">
                      <h3>{{ $t('graph' ) }}</h3>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-4 text-center" v-for="entry in this.graphWrap" v-bind:key="entry.key">
                      <p class="check-list"> <strong>{{entry.key}}</strong></p>
                      <pie-chart class="text-center" :chartData="entry" :options="chartOptions"></pie-chart>
                    </div>
                  </div>

                  <div class="panel-heading ">
                    <div class="panel-title ">
                      <h4 translate class="list-result">{{$t('result-list')}}</h4>
                    </div>
                  </div>
                  <div v-if="list.elements.length > 0">
                    <table class="table list-table">
                      <thead>
                        <tr>
                          <th class=" text-left t-head"><strong>{{$t('property')}}</strong></th>
                          <th class=" text-left t-head"><strong>{{$t('value')}}</strong></th>
                          <th class=" text-right t-head"><strong>{{$t('count')}}</strong></th>
                          <th class=" text-right t-head">%</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(res, index) in summaries.results" v-bind:key="res.key + '-' + index">
                          <td class=" level-2" v-if="index === 0"><i>{{res.key}}</i></td>
                          <td class=" level-2" v-if="index > 0"><i>{{index > 0 && summaries.results[index - 1].key !== res.key ? res.key : '' }}</i></td>
                          <td class="">{{res.message}}</td>
                          <td class=" text-right">{{res.count}} </td>
                          <td class=" text-right">{{((res.count / list.elementsFiltered.length)  * 100).toFixed(2) | numberOnlyNaM}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>


                </div>





              </div>
            </div>

          </div>
        </div>

      </div>

      <!-- wizard content start end -->




      <Footer />

    </div>


  </div>

</div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/Sidebar.vue'
import PieChart from "@/components/Charts/chart.js";

import axios from 'axios';


export default {
  name: 'lists',
  data() {
    return {

      isActive: false,
      isActiveIcon: '<',
      lists: [],
      pendingRequest: false,
      editRow: -1,
      editMode: false,
      loading: true,
      list: {
        elementsFiltered: '',
        elements: ''
      },
      search: {},
      Emails: '',
      processing: [],
      downloaded: false,
      chartOptions: {
        maintainAspectRatio: true,
        responsive: true,
        elements: {
            center: {
                text: '50%'  //set as you wish
            }
        },
      },
      chartData: {

        labels: ["True"],
        datasets: [{

          backgroundColor: ["#3177b4"],
          data: [1, 2, 3, 4]
        }]
      },
      graphWrap: [],
      summaries: []

    }

  },
  mounted() {
    this.$root.$emit('isLoading', true);
    this.enableInterceptor();
    this.getUserDetails();
    this.updateListData(true);
    this.getProcessingValues();
  },
  components: {

    Header,
    Sidebar,
    Footer,
    PieChart

  },
  methods: {

    enableInterceptor() {
      var that = this;
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        that.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        that.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        that.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        that.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      //console.log(this.isActive);
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },


    getUserDetails: function() {

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };


      var url = '/api/v2/profile';

      this.$http.get(url, config).then((results) => {

        this.user = results.data;


      });

    },

    getCredits: function() {

      return this.user ? this.user.credits : 0;


    },


    hasCards: function() {
      return this.user && this.user.cards.length;
    },

    hasAutoCredits: function() {
      return this.user && this.user.autocredits !== null ? this.user.autocredits : false;
    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },

    areCreditsNeeded: function() {
      return this.processing.processCredits > this.getCredits();
    },

    getBalance: function() {

      return this.user ? this.getCredits() * this.getCostPerCredit() : 0;

    },

    getCostPerCredit: function() {

      return this.processing.costPerCredit;

    },

    getProcessingValues: function() {

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v2/processing';

      this.$http.get(url, config).then((results) => {

        this.processing = results.data;

      });

    },





    //FROM HERE CURRENT LIST AREA FUNCTIONS

    updateListData: function(forced) {

      this.getListReport(this.$route.params.name, forced);

    },

    getCapitalizedText: function(name) {
      var newName = name && name.length > 0 ? name.charAt(0).toUpperCase() + name.slice(1) : "";

      return newName.replace(/_/g, " ")
    },

    getListReport: function(name, forced) {
      if (!this.pendingRequest && forced) { //console.log(name);
        this.pendingRequest = true;
        this.loading = true;

        var config = {
          headers: {
            "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
            "Content-Type": "application/json"
          }
        };

        let that = this;

        var url = '/api/v2/list/' + name + '/report';

        this.$http.get(url, config).then((results) => { //console.log(this.getCurrentReport(results.data));
          this.$root.$emit('isLoading', true);

          this.list = this.getCurrentReport(results.data); //console.log(this.list);
          this.pendingRequest = false;
          this.loading = false;

          if (this.list && this.list.elements) {
            //var filters = this.search;
            this.list.elementsFiltered = this.list.elements;
          }


          that.summaries = {
             "count":13,
             "normalized":0,
             "ok":1,
             "percentage":0,
             "results":[],
             "_id":"5e74af675408835e24da45aa",
             "name":"get-mobile-hlr-lookup"
          }



          var final = {}
          results.data.graphs.forEach(item => {
            if (!final[item.key]) {
              final[item.key] = {}
            }
            var containers = new Array();

            var itemTitle = new Array();

            item.data.forEach(items => {
              if (!final[item.key][items.key]) {
                final[item.key][items.key] = 0
              }
              final[item.key][items.key] += parseInt(items.value, 10)
              itemTitle.push(that.getCapitalizedText(items.key));
              containers.push(items.value);
            });


            that.chartData = {
              key: that.getCapitalizedText(item.key),
              labels: itemTitle,
              datasets: [{
                title: itemTitle,
                backgroundColor: ["rgb(49, 119, 180)", "rgb(174, 199, 232)", "rgb(255, 127, 14)", "rgb(255, 187, 120)", "#003f5c", "#2f4b7c", "#665191", "#a05195", "#d45087", "#f95d6a", "#ff7c43", "#ffa600", "#0300e2", "#24dbe1", "#F00CE1", "#890CFA", "#0400E3", "#0C7DFA", "#24DBE1", "#020063", "#4845E8", "#0400E3", "#1F1E63", "#0300B0", "#110F96", "#E35E17", "#0400E3", "#42E317", "#269608", "#6B12B0", "#5C327D", "#0400E3", "#E6B73A", "#B07412"],
                data: containers
              }]
            };
            that.graphWrap.push(that.chartData)
          });

          Object.keys(final).forEach(function(k) {
            Object.keys(final[k]).forEach(function(sk) {
              var data = {
                 "normalized":0,
                 "count": final[k][sk],
                 "ok":0,
                 "percentage":0,
                 "_id":"5e74af675408835e24da45ab",
                 "key": k,
                 "message": sk
              };
              that.summaries.results.push(data)
            });
          });

          that.summaries.results.sort(function(a, b) {
            if (a.key > b.key) return -1
            if (a.key < b.key) return 1
            if (a.count > b.count) return 1
            if (a.count < b.count) return -1
            return 0;
          }).reverse();
          this.$root.$emit('isLoading', false);

        });
      } else {
        //return list;
      }

    },

    getCurrentReport: function(l) {

      var graphs = [];
      var self = this;
      l.elements.forEach(function(e) {
        graphs = self.updateGraphs(graphs, e, 'area');
        graphs = self.updateGraphs(graphs, e, 'seniority');
        graphs = self.updateGraphs(graphs, e, 'is_decision_maker');
        graphs = self.updateGraphs(graphs, e, 'is_c_level');
        //graphs = self.updateGraphs(graphs, e, 'city');
        graphs = self.updateGraphs(graphs, e, 'gender');
        graphs = self.updateGraphs(graphs, e, 'country');
        graphs = self.updateGraphs(graphs, e, 'address');
        graphs = self.updateGraphs(graphs, e, 'website');
        graphs = self.updateGraphs(graphs, e, 'email');
        graphs = self.updateGraphs(graphs, e, 'confidence');
        graphs = self.updateGraphs(graphs, e, 'personal_email');
        graphs = self.updateGraphs(graphs, e, 'phone');
        graphs = self.updateGraphs(graphs, e, 'personal_phone');

      });
      l.graphs = graphs;

      return l;

    },

    updateGraphs: function(graphs, element, name) {
      var currentGraph = graphs.filter(function(i) {
        return i.key == name;
      });
      var newGraphs = graphs.filter(function(i) {
        return i.key != name;
      });
      var currentGraphItem = null;
      if (!currentGraph.length) {
        currentGraphItem = {
          key: name,
          data: []
        };
      } else {
        currentGraphItem = currentGraph[0];
      }

      var currentValue = element && element[name] !== undefined && (element[name]+"").length > 0 ? (element[name] + "").toLowerCase() : "unknown";

      if (name === 'email' || name === 'phone' || name === 'website' || name === 'address') {
        currentValue = currentValue === 'unknown' ? 'unknown' : currentValue;
      }

      var currentData = currentGraphItem.data.filter(function(i) {
        return i.key == currentValue;
      });
      var oldData = currentGraphItem.data.filter(function(i) {
        return i.key !== currentValue;
      });

      var item = {};
      if (!currentData.length) {
        item = {
          key: currentValue,
          value: 0
        };
      } else {
        item = currentData[0];
      }

      item.value++;
      oldData.push(item);

      currentGraphItem.data = oldData;
      newGraphs.push(currentGraphItem);

      return newGraphs;
    },

    resetFilters: function() {
      this.search = {};
      this.updateListData(true);
    },

    download: function() {
      var filters = this.search;
      filters.confidence = filters.email.indexOf("-") !== -1 ? filters.email.split("-")[1] : "";
      filters.email = filters.email.indexOf("-") !== -1 ? filters.email.split("-")[0] : ""
      this.loading = true;
      this.downloaded = false;

      var filtersStr = Object.keys(filters).map(function(key) {
        return key + "=" + filters[key];
      }).join("&");

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v2/list/' + this.$route.params.name + '/download?' + filtersStr;
      this.$http.get(url, config).then((results) => {
        var csv = results.data;
        this.loading = false;
        this.downloaded = true;
        var pom = document.createElement('a');
        var blob = new Blob([csv], {
          type: 'text/csv;charset=utf-8;'
        });
        var url = URL.createObjectURL(blob);
        pom.href = url;
        pom.setAttribute('download', 'uproc_records.csv');
        pom.click();
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/list.css';
@import '../../assets/css/global.css';
</style>
