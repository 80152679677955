import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    needs_complete: false,
    processors: []
  },
  actions: {
    loadProcessors : async (context) => {
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };
      let { data } = await axios.get('/api/v2/processor/search', config)
      //console.log(data);
      if (data.status == 200){
        context.dispatch('updateProcessors', data.processors)
      }
    }
  },
  mutations: {
    updatePosts(state, posts) {
      state.posts = posts
    },
    changeLoadingState(state, loading) {
      state.loading = loading
    },
    setNeedsComplete(state, value) {
      state.needs_complete = value
    },
    change(state, {key, value}) {
      state[key] = value
    }
  },
  getters: {
    processors: state => state.processors,
    needs_complete: state => state.needs_complete
  }
})
