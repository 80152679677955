<template>
  <div>
     <Header />
     <div class="row no_side_margin content">
        <Sidebar />
        <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">
           <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>
           <!-- wizard content start start -->
           <div class="">
              <div class="total-tools">
                 <div class="row no_side_margin" v-if="this.filteredProcessors.length">
                    <div class="col-12 no_side_pad">
                       <nav aria-label="breadcrumb" >
                          <ol class="breadcrumb">
                             <li class="breadcrumb-item "><a v-bind:href="'#/tools/'">{{$t('tools')}}</a></li>
                             <li class="breadcrumb-item " v-if="$route.params.field"><a v-bind:href="'#/tools/category/' + this.$route.params.field">{{$t(this.$route.params.field)}}</a></li>
                             <li class="breadcrumb-item " v-if="$route.params.category"><a v-bind:href="'#/tools/category/' + this.$route.params.category">{{$t('group-' + this.$route.params.category + '-name')}}</a></li>
                             <li class="breadcrumb-item ">{{$t('type-'+this.$route.params.type)}}</li>
                          </ol>
                       </nav>
                    </div>
                 </div>
                 <div class="no_side_margin">
                    <!-- Page Content -->
                    <div class="row no_side_margin sales-tools" v-if="this.filteredProcessors.length">
                       <div class="col-lg-3 wrapper " v-for="(processor) in this.filteredProcessors" v-bind:key="processor.key" v-bind:href="'#/tools/processor/'+processor.type+'/'+processor.module+'/'+processor.name">
                        <a v-bind:href="'#/tools/processor/'+processor.type+'/'+processor.module+'/'+processor.name">
                          <div class="all-tool-item">
                              <IconGroup :group="processor.groups[0]"/>
                              <span>{{ $t("type-"+ processor.type + "-verb" ) }}</span>
                              <h6><strong>{{processor.description}}</strong></h6>
                          </div>
                        </a>
                      </div>
                      <CustomProcessor/>
                    </div>
                 </div>
              </div>
           </div>
           <Footer />
        </div>
     </div>
  </div>
</template>

<script>
import axios from 'axios';
import IconGroup from '@/components/Tools/IconsGroup.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/Sidebar.vue'
import CustomProcessor from '@/components/Tools/CustomProcessor.vue'

export default {
  name: 'toolscategoryprocessors',
  data() {
    return {

      isActive: false,
      isActiveIcon: '<',
      file: {},
      processor: {},
      firstParam: "",
      processing: null,
      hasToBeSent: false,
      inProcess: false,
      uploadedFile: false,
      apiShown: false,
      user: null,
      email: "",
      languageMode: 'curl',
      productionMode: 'real',
      apiKey: "",
      apiMethod: 'one',
      selectedGroup: "",
      selectedType: "",
      selectedGroupIcon: "",
      selectedTypeSlug: "",
      categories: null,
      relatedProcessors: null,
      mode: 'table',
      hasMaskedValues: false,
      isImage: false,
      isQrImage: false,
      isUrl: false,
      isOutputExpanded: false,
      tabIndex: '',
      isError: false,
      isSubmitted: false,
      isAdvancedInput: false,
      showInput: false,
      isUploaded: false,
      showJobScheduled: false,
      areParamsAssigned: false,
      fileAssignedCols: [],
      processors: [],
      sendingRequestProcessors: false,
      paramNames: [],
      lastProcessors: [],
      groups: '',
      received: '',
      feedbackResult: '',
      responseStr: '',
      message: '',
      needsMultiline: '',
      sending: false,
      profile: '',
      isSearchByValue: false,
      tempFilterText: '',
      filterTextTimeout: '',
      normalizersCount: 0,
      checkersCount: 0,
      gettersCount: 0,
      searchSent: true,
      hasTypeNormalize: true,
      hasTypeCheck: true,
      hasTypeGet: true,
      filterType: 'category',
      filterValue: '',
      routeParams: [],
      query: '',
      filteredProcessors: [],
      config: {},


    }

  },
  created: function() {
    if (!localStorage.token) {

      this.$router.push("/login");

    } else {

      this.config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      this.filtered = {
        all: [],
        checkers: [],
        getters: [],
        favorites: [],
        own: []
      };

      this.getProcessorDetails();
      this.getProcessors();
    }
  },
  mounted() {
    this.enableInterceptor();
  },
  watch: {
    processors: function() {
      if (this.processors.length > 0) {
        this.loadBusinessValues();
      }
    },
    filteredProcessors: function() {
      this.$root.$emit('isLoading', false);
    }
  },
  components: {
    Header,
    Sidebar,
    Footer,
    IconGroup,
    CustomProcessor
  },
  methods: {
    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {
        this.$root.$emit('isLoading', true);
        return config
      }, (error) => {
        this.$root.$emit('isLoading', false);
        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {
        this.$root.$emit('isLoading', false);
        return response
      }, function(error) {
        this.$root.$emit('isLoading', false);
        return Promise.reject(error)
      })
    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      //console.log(this.isActive);
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },

    filterByParamName: function(processors, paramName) {
      return processors.filter(function(processor) {
        var found = false;
        processor.params.forEach(function(param) {
          found = found || param.name.indexOf(paramName) === 0;
        });
        return found;
      });
    },

    getProcessorDetails: function() {

      //var url = '/api/v2/processor/search';
      var url = '/json/' + this.$locale + '/processors.json?' + this.$prefix;
      this.loading = true;
      //this.$http.get(url, this.config).then((results) => {
      this.$http({method: 'GET', url: url, baseURL: '/'}).then((results) => {
        this.processors = results.data.processors;
      });

    },

    searchProcessors: function() {

      //var url = '/api/v2/processor/search';
      var url = '/json/' + this.$locale + '/processors.json?' + this.$prefix;
      this.loading = true;
      //this.$http.get(url, this.config).then((results) => {
      this.$http({method: 'GET', url: url, baseURL: '/'}).then((results) => {
        this.sendingRequestProcessors = false;
        this.processors = results.data.processors; //alert(this.processors.length);
        this.paramNames = this.getUniqueAvailableParamNames(this.processors); //console.log(this.paramNames);
        this.setLastProcessors();
        this.updateFilteredProcessors();
      });

    },

    setLastProcessors: function() {

      var compareDates = function(a, b) {
        if (a.date.toString() < b.date.toString()) return 1;
        if (a.date.toString() > b.date.toString()) return -1;
        return 0;
      }

      this.lastProcessors = this.processors.filter(function(processor) {
        return processor.date !== null;
      }).sort(compareDates).slice(0, 12);

    },

    getUniqueAvailableParamNames: function(items) {
      //alert('ds');
      var params = [];
      if (items) {
        items.forEach(function(p) {
          var paramNames = p.params.map(function(p) {
            return p.name.replace(/[0-9]+/g, '');
          });
          var firstParam = paramNames.length > 0 ? paramNames[0] : "";
          if (params.indexOf(firstParam) === -1) {
            params.push(firstParam);
          }
        });
      }
      return params.sort();

    },

    searchCategories: function() {

      //var url = '/api/v2/category/search'
      var url = '/json/' + this.$locale + '/groups.json?' + this.$prefix;
      //this.$http.get(url, this.config).then((results) => {
      this.$http({method: 'GET', url: url, baseURL: '/'}).then((results) => {

        this.sendingRequestCategories = false;
        this.categories = results.data.categories; //.sort(sortWithLocaleCompare);

      });

    },

    searchGroups: function() {

      //var url = '/api/v2/group/search'
      var url = '/json/' + this.$locale + '/groups.json?' + this.$prefix;
      //this.$http.get(url, this.config).then((results) => {
      this.$http({method: 'GET', url: url, baseURL: '/'}).then((results) => {
        this.sendingRequestGroups = false;
        this.groups = results.data.groups.sort();


      });

    },

    predictFieldName: function(value) {

      var url = '/api/v2/field/search/' + value;

      this.$http.get(url, this.config).then((results) => {

        this.updateFilteredProcessors(results.data.name, true);





      });

    },


    setProfile: function(profile) {

      this.profile = profile;

      localStorage.toolsProfile = this.profile;

      this.profileSelectedProcessors = this.getProfileSelectedProcessors(this.profile);



    },

    getProfileSelectedProcessors: function(profile) {

      if (this.processors) {
        return this.processors.filter(function(p) {
          return p.targets.indexOf(profile) !== -1;
        });
      } else {
        return [];
      }

    },

    hasProfile: function() {

      return this.profile !== '';

    },

    resetProfile: function() {

      this.profile = '';

    },

    isTechnicalProfile: function() {

      return this.profile === 'technical';

    },

    hasView: function() {

      return this.mode !== '';

    },

    toggleViewMode: function() {

      this.mode = this.mode === 'fields' ? 'groups' : 'fields';
      if (this.mode === 'fields') {
        this.query = "";
        this.params = this.getParamNames();
      }

    },

    onQueryChanged: function() {

      this.searchSent = this.query.length === 0;
      this.mode = "groups"; //alert('sd');

    },

    resetQueryText: function() {

      this.query = "impossible_value_to_be_found";
      this.oldQuery = "";
      this.filterProcessors(this.query);

    },

    updatedFilteredCallback: function() {

      this.sending = false;
      this.loadBusinessValues();
      if (this.filtered && !this.filtered.all.length) {
        this.isSearchByValue = true;
        this.sending = true;
        this.doPredictiveSearch(this.query);
      } else {
        this.sending = false;

      }

    },

    doPredictiveSearch: function(predictiveText) {

      this.oldPredictive = this.predictive;
      this.predictive = predictiveText;
      if (this.predictive.length && this.isNewPredictiveSearch()) {
        this.predictFieldName(encodeURIComponent(predictiveText));
      }

    },

    getCategories: function() {

      if (this.categories && !this.categories.length && !this.sendingRequestCategories) {
        this.sendingRequestCategories = true;
        this.searchCategories();
        return this.categories;
      } else {
        return this.categories;
      }

    },

    getGroups: function() {

      if (this.groups && !this.groups.length && !this.sendingRequestGroups) {
        this.sendingRequestGroups = true;
        this.searchGroups();
        return this.groups;
      } else {
        return this.groups;
      }

    },

    filterByCategory: function(processors, category) {
      return processors.filter(function(item) {
        return item.groups[0] === category;
      });
    },

    filterByParam: function(processors, param) {
      return processors.filter(function(item) {
        return item.params.filter(function(p) {
          return p.name.indexOf(param) === 0;
        }).length > 0;
      });
    },

    loadBusinessValues: function() {
      this.isProcessorType = this.getProcessorType();
      this.isExpandedView = this.getExpandedView();
      this.query = this.$route.params.key && this.$route.params.key.length ? this.$route.params.key : this.getQueryText();
      this.setQueryText(this.query);
      this.categories = this.getCategories();
      this.groups = this.getGroups();
      this.processors = this.getProcessors();
      this.lastProcessors = this.getLastProcessors();
      this.filtered = this.getFiltered();
      this.filteredProcessors = this.filtered.all;
      this.params = this.getParamNames();

      var category = this.$route.params.category;
      var type = this.$route.params.type;
      var field = this.$route.params.field;

      this.filterValue = this.$route.params.category;

      var filterValue = category && category.length && category !== 'all' ? category : (field || ""); //console.log('filterValue.length');console.log(filterValue);
      if (this.filteredProcessors && this.filteredProcessors !== undefined && filterValue.length) { //alert('yue');
        var fn = category && category.length ? this.filterByCategory : this.filterByParam;
        this.filteredProcessors = fn(this.filteredProcessors, filterValue); //console.log('type'+type);console.log(this.filteredProcessors);
      }

      //Filter by type
      if (this.filteredProcessors && this.filteredProcessors !== undefined && type && type.length) {
        this.filteredProcessors = this.filterByType(this.filteredProcessors, type);
      }

      this.countFilteredProcessorsByType();
      this.isSearchByValue = false;

    },

    filterByType: function(processorss, type) {
      return processorss.filter(function(item) {
        return type === 'normalize' ? item.name.indexOf('normalize') !== -1 : item.type === type && item.name.indexOf('normalize') === -1;
      });
    },

    countFilteredProcessorsByType: function() {
      this.normalizersCount = this.filterByType(this.filteredProcessors, 'normalize').length;
      this.checkersCount = this.filterByType(this.filteredProcessors, 'check').length;
      this.gettersCount = this.filterByType(this.filteredProcessors, 'get').length; //console.log('this.gettersCount');console.log(this.gettersCount);

    },

    getProcessors: function(forced) {

      forced = forced || false;
      if (!this.sendingRequestProcessors && (!this.processors.length || forced)) {
        this.sendingRequestProcessors = true;
        this.searchProcessors();
        return this.processors;
      } else {
        return this.processors;
      }

    },

    getProcessorType: function() {

      return this.isProcessorType;

    },

    isGroupsView: function() {

      return this.mode === 'groups';

    },

    isFieldsView: function() {

      return this.mode === 'fields';

    },

    getExpandedView: function() {

      return this.isExpandedView;

    },

    getQueryText: function() {

      return this.query;

    },

    getLastProcessors: function() {

      return this.lastProcessors;

    },

    getFiltered: function() {

      return this.filtered;

    },

    getParamNames: function() {

      return this.paramNames;

    },

    isNewPredictiveSearch: function() {

      return this.oldPredictive !== this.predictive;

    },

    searchByKey: function() {

      this.onQueryChanged();
      this.isSearchByValue = false;
      this.hasToBeSent = false;
      this.sending = true;
      this.setQueryText(this.query);
      this.searchSent = true;

    },

    setQueryText: function(txt) {
      //resetQueryText();
      this.oldQuery = this.query;
      this.query = txt;

      if (this.isNewQueryText()) {
        this.updateFilteredProcessors(txt);
      }

    },

    isNewQueryText: function() {

      return this.oldQuery !== this.query;

    },

    updateFilteredProcessors: function(txt, byParam) {

      txt = txt || this.query;
      byParam = byParam || false; //console.log(this.processors);
      if (this.processors && this.processors.length) {
        this.filterProcessors(txt, byParam);
      }

    },

    filterProcessors: function(txt, byParam) { //alert('ddd');

      var sortProcessorByModuleAndName = function(processorA, processorB) {
        var processorModuleAndNameA = processorA.description ? processorA.description.toLowerCase() : processorA.name.toLowerCase();
        var processorModuleAndNameB = processorB.description ? processorB.description.toLowerCase() : processorB.name.toLowerCase();
        if (processorModuleAndNameA > processorModuleAndNameB) {
          return 1;
        } else if (processorModuleAndNameA < processorModuleAndNameB) {
          return -1;
        } else {
          return 0;
        }
      }


      function isCheck(fruit) {
        return fruit.type === 'check';
      }

      function isGet(fruit) {
        return fruit.type === 'get';
      }

      txt = txt || this.query;
      byParam = byParam || false;

      var filteringcheck = [];
      var filteringget = [];

      if (txt.length) {
        var nonSorted = [];
        if (!byParam) {
          nonSorted = this.filterMultiple(this.processors, {
            module: txt,
            name: txt,
            cleaned_description: txt
          }, 'or');
        } else {
          nonSorted = this.filterByParamName(this.processors, txt);
        }

        this.filtered.all = this.cleanDuplicatedResults(nonSorted).sort(sortProcessorByModuleAndName);
        //console.log('this.filtered.all');console.log(this.filtered.all);
        filteringcheck = this.filtered.all.filter(isCheck);
        filteringget = this.filtered.all.filter(isGet);

        this.filtered.checkers = this.filtered.all.length ? filteringcheck.sort(sortProcessorByModuleAndName) : [];
        this.filtered.getters = this.filtered.all.length ? filteringget.sort(sortProcessorByModuleAndName) : [];

      } else {
        this.filtered.all = this.processors.sort(sortProcessorByModuleAndName);
        filteringcheck = this.filtered.all.filter(isCheck);
        filteringget = this.filtered.all.filter(isGet);

        this.filtered.checkers = this.filtered.all.length ? filteringcheck.sort(sortProcessorByModuleAndName) : [];
        this.filtered.getters = this.filtered.all.length ? filteringget.sort(sortProcessorByModuleAndName) : [];
      }

      this.filtered.favorites = [];
      this.filtered.own = [];
      this.isProcessorType = this.hasNoProcessorsInSelectedProcessorType() ?
        0 :
        this.isProcessorType;

    },

    cleanDuplicatedResults: function(items) {

      var newItems = [];
      for (var index in items) {
        var item = items[index];
        var key = item.type + "-" + item.module + "-" + item.name;
        newItems[key] = item;
      }

      var finalArray = [];
      var i = 0;
      for (var key2 in newItems) {
        finalArray[i++] = newItems[key2];
      }
      return finalArray;

    },

    hasNoProcessorsInSelectedProcessorType: function() {

      return (
        (this.isProcessorType === 0 && this.filtered.all.length === 0) ||
        (this.isProcessorType === 1 && this.filtered.checkers.length === 0) ||
        (this.isProcessorType === 2 && this.filtered.getters.length === 0) ||
        (this.isProcessorType === 3 && this.filtered.favorites.length === 0) ||
        (this.isProcessorType === 4 && this.filtered.own.length === 0)
      );

    },

    filterMultiple: function(items, keyObj, operand) {



      operand = operand || 'or';

      var removeAccents = function(value) {
        return value
          .replace(/á/g, 'a')
          .replace(/â/g, 'a')
          .replace(/é/g, 'e')
          .replace(/è/g, 'e')
          .replace(/ê/g, 'e')
          .replace(/í/g, 'i')
          .replace(/ï/g, 'i')
          .replace(/ì/g, 'i')
          .replace(/ó/g, 'o')
          .replace(/ô/g, 'o')
          .replace(/ú/g, 'u')
          .replace(/ü/g, 'u')
          .replace(/ç/g, 'c')
          .replace(/ß/g, 's');
      };

      //var cleanedItems = removeAccentsFromItems(items);
      var filterObj = {
        operand: operand,
        data: items,
        filteredData: [],
        applyFilter: function(obj, key) { //console.log(obj);console.log(key);
          var fData = [];
          this.filteredData = (!this.filteredData.length && operand === 'and') ? this.data : this.filteredData;
          if (obj) {
            var fObj = {};
            if (!Array.isArray(obj)) {
              fObj[key] = obj;
              fData = fData.concat(this.data.filter(this.isCheck));
            } else if (Array.isArray(obj)) {
              if (obj.length) {
                for (var i = 0; i < obj.length; i++) {
                  if (obj[i] !== undefined) {
                    fObj[key] = obj[i];
                    fData = fData.concat(this.data.filter(this.isCheck)); //fData.concat($filter('filter')(this.data, fObj));
                  }
                }
              }
            }
            if (fData.length) {
              this.filteredData = (operand === 'or') ? fData.concat(this.filteredData) : fData;
            }
          }
        }
      };
      if (keyObj) {
        Object.keys(keyObj).forEach(function(key) { //console.log(keyObj[key]);console.log('-');console.log(key);
          var valueWithoutAccent = removeAccents(keyObj[key]);
          filterObj.applyFilter(valueWithoutAccent, key);
          if (!filterObj.filteredData.length) {
            filterObj.applyFilter(keyObj[key], key);
          }
        });
      }
      return filterObj.filteredData;
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/tools.css';
@import '../../assets/css/global.css';
</style>
