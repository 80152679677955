<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>

      <div class="">
        <div class="total-lists">
          <div class="row no_side_margin">
            <div class="col-lg-12">
              <div class="create-new-list">
                <h3>{{ $t('wizard-welcome-reason' ) }}</h3>
                <br>
                <WelcomeEmail v-if="reason === 'my-email-marketing-campaigns'"/>
                <WelcomeSms v-if="reason === 'my-sms-marketing-campaigns'"/>
                <WelcomeSales v-if="reason === 'my-sales-funnel'"/>
                <WelcomeRecruitment v-if="reason === 'my-recruitment-process'"/>
                <WelcomeData v-if="reason === 'my-existing-data' || reason === 'my-e-shop-catalog' || reason === 'my-forms' || reason === 'generic'"/>
                <br>
                {{ $t('wizard-welcome-cases' ) }}
                <ul>
                  <li v-for="localReason in reasons" v-bind:key="localReason.key" v-on:click="reason = localReason.key"><a  v-bind:href="'#/welcome/' + localReason.key">{{localReason.text}}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

    </div>


  </div>

</div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/Sidebar.vue'
import axios from 'axios';
import i18n from '@/plugins/i18n';

import WelcomeEmail from '@/components/Wizards/welcomes/Email.vue'
import WelcomeSms from '@/components/Wizards/welcomes/Sms.vue'
import WelcomeData from '@/components/Wizards/welcomes/Data.vue'
import WelcomeSales from '@/components/Wizards/welcomes/Sales.vue'
import WelcomeRecruitment from '@/components/Wizards/welcomes/Recruitment.vue'
//import WelcomeCatalog from '@/components/Wizards/welcomes/Catalog.vue'
//import WelcomeForms from '@/components/Wizards/welcomes/Forms.vue'

export default {
  name: 'welcome',
  data() {
    return {
      isActive: false,
      isActiveIcon: '<',
      reason: '',
      source: "",
      reasons: ["My sales funnel", "My recruitment process", "My email marketing campaigns", "My sms marketing campaigns", "My forms", "My existing data", "My e-shop catalog"].map(function(reason) {
        return {
          key: reason.replace(/ /g, "-").toLowerCase(),
          text: i18n.t('feedback-reason-' + reason)
        }
      }).sort((a, b) => (a.text > b.text) ? 1 : -1),
    }
  },
  async created() {

  },
  mounted() {
    this.enableInterceptor();
    this.$root.$emit('isLoading', false);
    this.detectTour();
  },
  components: {

    Header,
    Sidebar,
    Footer,

    WelcomeEmail,
    WelcomeSms,
    WelcomeData,
    WelcomeSales,
    WelcomeRecruitment
    //WelcomeCatalog,
    //WelcomeForms

  },
  methods: {

    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        this.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        this.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      //console.log(this.isActive);
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },

    async detectTour() {
      //this.getUser();
      this.reason = this.$route.params.reason && this.$route.params.reason.length ? this.$route.params.reason : "generic";
      var reasonStorage = localStorage.getItem('signupReason');
      //console.log(reasonStorage)
      if (this.reason === 'generic') {
        this.reason = reasonStorage;
      }

      if (this.reason !== 'my-email-marketing-campaigns' &&
          this.reason !== 'my-sms-marketing-campaigns' &&
          this.reason !== 'my-existing-data' &&
          this.reason !== 'my-sales-funnel' &&
          this.reason !== 'my-recruitment-process') {
        this.reason = 'generic';
      }
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/wizards.css';
@import '../../assets/css/global.css';
</style>
