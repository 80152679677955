<template>
<div>
  <div v-if="language === 'en'">
    I see that you want to <strong>send email marketing campaigns</strong> ;)<br><br>
    Do you know that with uProc you can <strong>avoid delivery problems</strong> by <strong>verifying emails</strong> previously?
    <br><br>
    Just follow the next steps:
    <ul>
      <li><strong><a href="/#/settings/profile" target="_blank">Identify</a></strong> yourself for legal purposes.</li>
      <li><strong><a href="/#/tools/processor/check/email/exists-extended" target="_blank">Test</a></strong> some emails for free and check the results.</li>
      <li>Verify <strong>1000 emails</strong> costs <strong>2.5€</strong>. <strong><a href="/#/purchase/packs" target="_blank">Choose</a></strong> the pack that suits your needs.</li>
      <li><strong><a href="/#/wizard/process/Files/Upload/check-email-exists" target="_blank">Upload</a></strong> your file, choose "Check if Email exists (simple)", the email column and start the process.</li>
      <li>You will receive the results file by email.</li>
    </ul>
    <br>
    You can also verify your emails from
    <strong><a href="https://uproc.io/blog/how-to-check-if-email-exists-extended#sheets" target="_blank">Google Sheets</a></strong>,
    <strong><a href="https://uproc.io/blog/how-to-check-if-email-exists-extended#n8n" target="_blank">n8n</a></strong>,
    <strong><a href="https://uproc.io/blog/how-to-check-if-email-exists-extended#integromat" target="_blank">Make</a></strong>,
    <strong><a href="https://uproc.io/blog/how-to-check-if-email-exists-extended#zapier" target="_blank">Zapier</a></strong> or
    <strong><a href="https://uproc.io/blog/how-to-check-if-email-exists-extended#api" target="_blank">API</a></strong>.

    <br>
    Choose the best method that suits your needs!
    <br><br>
    <div class="row">
      <div class="col-lg-4">
      Looking for a fast sample? Check this video to view the tool in action using Google Sheets:
      </div>
      <div class="col-lg-8">
        <div class="video-responsive">
        <iframe width="100%" height="360" src="https://www.youtube.com/embed/f2IudtgqDRE?VQ=HD720" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
        </div>
      </div>
    </div>

  </div>
  <div v-if="language === 'es'">
    He visto que quieres <strong>enviar campañas de correo</strong> ;)<br><br>
    ¿Sabes que con uProc puedes <strong>evitar los rebotes</strong> si <strong>verificas tus correos</strong> previamente?
    <br><br>
    Solo necesitas seguir los siguientes pasos:
    <ul>
      <li><strong><a href="/#/settings/profile" target="_blank">Identifícate</a></strong> para utilizar la plataforma de forma legal.</li>
      <li><strong><a href="/#/tools/processor/check/email/exists-extended" target="_blank">Comprueba</a></strong> los correos gratuitamente y los resultados obtenidos.</li>
      <li>Comprobar <strong>1000 correos</strong> cuesta <strong>2.5€</strong>. <strong><a href="/#/purchase/packs" target="_blank">Selecciona</a></strong> el pack que mejor se ajusta a tus necesidades.</li>
      <li><strong><a href="/#/wizard/process/Files/Upload/check-email-exists-extended" target="_blank">Sube</a></strong> tu fichero, selecciona "Comprueba si Correo existe (simple)", elije la columna del correo y empieza el procesado.</li>
      <li>Recibirás los resultados por correo.</li>
    </ul>
    <br>
    También puedes comprobar tus correos desde
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists-extended#sheets" target="_blank">Google Sheets</a></strong>,
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists-extended#n8n" target="_blank">n8n</a></strong>,
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists-extended#integromat" target="_blank">Make</a></strong>,
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists-extended#zapier" target="_blank">Zapier</a></strong> o
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists-extended#api" target="_blank">API</a></strong>.

    <br>
    Elije el método que mejor se adapta a tus necesidades.
    <br><br>
    <div class="row">
      <div class="col-lg-4">
      ¿Quieres ver un ejemplo rápido? Revisa este videotutorial para ver la herramienta en acción utilizando Google Sheets:
      </div>
      <div class="col-lg-8">
        <div class="video-responsive">
        <iframe width="100%" height="360" src="https://www.youtube.com/embed/f2IudtgqDRE?VQ=HD720" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'WelcomeEmail',
  data() {
    return {
      language: this.$i18n.locale
    };
  },
  created() {

  },
  mounted() {}
}
</script>
