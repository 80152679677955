<template>
  <div>
    <div class="icon" v-if="param === 'sector' || param === 'company' || param === 'cif' || param === 'duns' || param === 'isin' || param === 'taxid'">
      <font-awesome-icon icon="building" size="2x"/>
    </div>

    <div class="icon" v-if="param === 'role' || param === 'name' || param === 'firstname' || param === 'surname' || param === 'date' || param === 'fullname' || param === 'gender'">
      <font-awesome-icon icon="user" size="2x"/>
    </div>

    <div class="icon" v-if="param === 'email' || param === 'email_from' || param === 'phone' || param === 'mobile' || param === 'imei'">
      <font-awesome-icon icon="comments" size="2x"/>
    </div>

    <div class="icon" v-if="param === 'iban' || param === 'account' || param === 'bic' || param === 'credit_card' || param === 'amount'">
      <font-awesome-icon icon="money-check-alt" size="2x"/>
    </div>

    <div class="icon"
      v-if="param === 'coordinates' || param === 'province' || param === 'city' || param === 'locality' || param === 'street' || param === 'country' || param === 'country_code' || param === 'address' || param === 'zipcode' || param === 'isocode' || param === 'iso_code'">
      <font-awesome-icon icon="map-marked-alt" size="2x"/>
    </div>

    <div class="icon" v-if="param === 'nif' || param === 'dni' || param === 'nie'">
      <font-awesome-icon icon="id-card" size="2x"/>
    </div>

    <div class="icon" v-if="param === 'domain' || param === 'url' || param === 'ip' || param === 'profile' || param === 'source' || param === 'host' || param === 'useragent'">
      <font-awesome-icon icon="globe" size="2x" />
    </div>

    <div class="icon" v-if="param === 'title' || param === 'author' || param === 'publisher' || param === 'isbn'">
      <font-awesome-icon icon="book" size="2x"/>
    </div>

    <div class="icon" v-if="param === 'luhn' || param === 'password' || param === 'uuid'">
      <font-awesome-icon icon="shield-alt" size="2x" />
    </div>

    <div class="icon" v-if="param == 'text' || param === 'list' || param === 'keywords'">
      <font-awesome-icon icon="align-justify" size="2x"/>
    </div>

    <div class="icon" v-if="param === 'asin' || param === 'ean' || param === 'upc' || param === 'sku'">
      <font-awesome-icon icon="tags" size="2x"/>
    </div>

    <div class="icon" v-if="param === 'category' || param === 'number' || param === 'field' || param === 'regex'">
      <font-awesome-icon icon="cog" size="2x"/>
    </div>
  </div>
</template>
<script>
export default {
  props: ['param']
};
</script>
