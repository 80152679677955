<template>
  <div class="icon">
    <font-awesome-icon icon="volume-up" size="2x" v-if="group == 'audio'"/>
    <font-awesome-icon icon="building" size="2x" v-if="group == 'company'"/>
    <font-awesome-icon icon="comments" size="2x" v-if="group == 'communication' || group == 'email'"/>
    <font-awesome-icon icon="money-check-alt" size="2x" v-if="group == 'finance'"/>
    <font-awesome-icon icon="map-marked-alt" size="2x" v-if="group == 'geographic'"/>
    <font-awesome-icon icon="user" size="2x" v-if="group == 'personal' || group == 'profile'"/>
    <font-awesome-icon icon="shield-alt" size="2x" v-if="group == 'security'"/>
    <font-awesome-icon icon="tags" size="2x" v-if="group == 'product'"/>
    <font-awesome-icon icon="images" size="2x" v-if="group == 'image'"/>
    <font-awesome-icon icon="align-justify" size="2x" v-if="group == 'text' || group === 'list'"/>
    <font-awesome-icon icon="globe" size="2x" v-if="group == 'internet'"/>
  </div>
</template>
<script>
export default {
  props: ['group']
};
</script>
