<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>

      <div class="row no_side_margin">
        <div class="total col-lg-12 ">
          <ul class="nav nav-tabs d_summary">
            <li class="nav-item active"><a data-toggle="tab" href="#home" class="nav-link active">
                <font-awesome-icon icon="chart-line" size="lg" />

                {{ $t('summary' ) }}</a>
            </li>
            <li class="nav-item"><a data-toggle="tab" href="#menu1" class="nav-link">
                <font-awesome-icon icon="list" size="lg" />
                {{ $t('list' ) }}</a></li>
            <li class="nav-item"><a data-toggle="tab" href="#menu2" class="nav-link">
                <font-awesome-icon icon="history" size="lg"/>
                {{ $t('history' ) }}</a></li>
          </ul>

          <div class="tab-content">
            <div id="home" class="tab-pane container active">
              <div class="row">
                <div class="col-sm-9 col-7 col-title ">
                  <h3>{{ $t('summary' ) }}</h3>
                </div>
                <div class="col-sm-3 col-5  select_period col-text">
                  <!-- <div class="text">Select Period:</div> -->
                  <p class="text">{{ $t('select-period') }}:</p>
                  <div class="btn-group ">

                    <div class="clearfix"></div>
                    <a class="btn btn-primary dropdown-toggle drp_btn" data-toggle="dropdown">{{ $t(this.periodDisplay) }} <span class="caret"></span></a>
                    <ul class="dropdown-menu">
                      <li class="dropdown-item"><a v-on:click="changePeriod('select-period-month' , 'month')">{{ $t('select-period-month') }}</a></li>
                      <li class="dropdown-item"><a v-on:click="changePeriod('select-period-day' , 'day')">{{ $t('select-period-day') }}</a></li>
                      <li class="dropdown-item"><a v-on:click="changePeriod('select-period-30days' , '30days')">{{ $t('select-period-30days') }}</a></li>
                      <li class="dropdown-item"><a v-on:click="changePeriod('select-period-7days' , '7days')"> {{ $t('select-period-7days') }}</a></li>
                      <li class="dropdown-item"><a v-on:click="changePeriod('select-period-1day' , '1day')">{{ $t('select-period-1day') }}</a></li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="row smry-status my-5">
                <div class="col-lg-3 col-md-4  col-sm-6 col-6 mt-3 pr-1">
                  <div class="item">
                    <div class="item-num">{{this.kpisAll.total_day | numFormat(0) | minimizeNumber }}</div>
                    <div class="item-text" v-if="this.isCostByHour">{{ $t('requests-average-hour' ) }}</div>
                    <div class="item-text" v-if="!this.isCostByHour">{{ $t('requests-average-day' ) }}</div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-6 mt-3 pr-1">
                  <div class="item">
                    <div class="item-num">{{this.kpisAll.total | numFormat(0) | minimizeNumber }}</div>
                    <div class="item-text">{{ $t('requests-totals' ) }}</div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-6 mt-3 pr-1">
                  <div class="item">
                    <div class="item-num">{{this.kpisAll.real | numFormat(0) | minimizeNumber }}</div>
                    <div class="item-text">{{ $t('requests-billed' ) }}</div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-6 mt-3  pr-1">
                  <div class="item">
                    <div class="item-num">{{this.kpisAll.dqs * 10 | numFormat(0) }}</div>
                    <div class="item-text">{{ $t('requests-scoring' ) }}</div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-6 mt-3 pr-1">
                  <div class="item">
                    <div class="item-num">{{this.kpisAll.cost_day | numFormat(0) | minimizeNumber }}€</div>
                    <div class="item-text" v-if="this.isCostByHour">{{ $t('cost-hour' ) }}</div>
                    <div class="item-text" v-if="!this.isCostByHour">{{ $t('cost-day' ) }}</div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6  col-6 mt-3 pr-1">
                  <div class="item">
                    <div class="item-num">{{this.kpisAll.cost | numFormat(0) | minimizeNumber }}€</div>
                    <div class="item-text">{{ $t('cost-total' ) }}</div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-6 mt-3 pr-1">
                  <div class="item">
                    <div class="item-num">{{this.kpisAll.savings | numFormat(0) | minimizeNumber }}€</div>
                    <div class="item-text">{{ $t('cost-saving' ) }}</div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-6 mt-3  pr-1">
                  <div class="item">
                    <div class="item-num" v-if="this.kpisAll.forecast !== '-'">{{this.kpisAll.forecast | numFormat(0) | minimizeNumber }}€</div>
                    <div class="item-num" v-if="this.kpisAll.forecast === '-'">-</div>
                    <div class="item-text">{{ $t('cost-forecast' ) }}</div>
                  </div>
                </div>


              </div>
              <line-chart :chart-data="datacollection" :options="graphOptions"></line-chart>


            </div>


            <div id="menu1" class="container tab-pane fade">
              <div class="row">
                <div class="col-sm-9 col-7 col-title ">
                  <h3>{{ $t('list' ) }}</h3>
                </div>
                <div class="col-sm-3 col-5  select_period col-text">
                  <!-- <div class="text">Select Period:</div> -->
                  <p class="text">{{ $t('select-period') }}:</p>
                  <div class="btn-group ">

                    <div class="clearfix"></div>
                    <a class="btn btn-primary dropdown-toggle drp_btn" data-toggle="dropdown">{{ $t(this.periodDisplay) }}<!-- {{this.periodDisplay}} --> <span class="caret"></span></a>
                    <ul class="dropdown-menu">
                      <li class="dropdown-item"><a v-on:click="changePeriod('select-period-month' , 'month')">{{ $t('select-period-month') }}</a></li>
                      <li class="dropdown-item"><a v-on:click="changePeriod('select-period-day' , 'day')">{{ $t('select-period-day') }}</a></li>
                      <li class="dropdown-item"><a v-on:click="changePeriod('select-period-30days' , '30days')">{{ $t('select-period-30days') }}</a></li>
                      <li class="dropdown-item"><a v-on:click="changePeriod('select-period-7days' , '7days')"> {{ $t('select-period-7days') }}</a></li>
                      <li class="dropdown-item"><a v-on:click="changePeriod('select-period-1day' , '1day')">{{ $t('select-period-1day') }}</a></li>
                    </ul>
                  </div>
                </div>
              </div>

              <table class="table table-striped list-table mt-5 pt-5">
                <thead>
                  <tr>
                    <th class="t-head">{{ $t('period') }}</th>
                    <th class="t-head">{{ $t('calls') }}</th>
                    <th class="t-head">{{ $t('cost') }}</th>
                    <th class="t-head">{{ $t('saving') }}</th>
                  </tr>
                </thead>
                <tbody v-if="this.orderedList.length">
                  <tr v-for="item in this.orderedList" v-bind:key="item.key">
                    <td class="table-col">{{item.key}}</td>
                    <td class="table-col">{{item.count ? item.count : 0}}</td>
                    <td class="table-col">{{item.cost.toFixed(4) }}€</td>
                    <td class="table-col">{{ (item.savings ? item.savings : 0).toFixed(4) }}€</td>
                  </tr>

                  <tr v-if="kpisList.length">
                    <td class="table-col"></td>
                    <td class="table-col">{{this.kpisTotal.all.count}}</td>
                    <td class="table-col">{{this.kpisTotal.all.cost.toFixed(4) }}€</td>
                    <td class="table-col">{{this.kpisTotal.all.savings.toFixed(4) }}€</td>
                  </tr>
                </tbody>

                <tbody v-if="!this.orderedList.length">

                  <tr>
                    <td colspan="4" translate>{{ $t('no-results') }}</td>
                  </tr>

                </tbody>
              </table>
            </div>

            <div id="menu2" class="container tab-pane fade">
              <div class="row">
                <div class="col-sm-9 col-7 col-title ">
                  <h3>{{ $t('history' ) }}</h3>
                </div>
                <div class="col-sm-3 col-5  select_period col-text">
                  <p class="text ml-100">{{ $t('select-period' ) }}:</p>
                  <div class="btn-group ">

                    <div class="clearfix"></div>

                    <a class="btn btn-primary dropdown-toggle drp_btn" data-toggle="dropdown">{{ $t(this.periodDisplay) }}<span class="caret"></span></a>
                    <ul class="dropdown-menu">
                      <li class="dropdown-item"><a v-on:click="changePeriod('select-period-month' , 'month')">{{ $t('select-period-month') }}</a></li>
                      <li class="dropdown-item"><a v-on:click="changePeriod('select-period-day' , 'day')">{{ $t('select-period-day') }}</a></li>
                      <li class="dropdown-item"><a v-on:click="changePeriod('select-period-30days' , '30days')">{{ $t('select-period-30days') }}</a></li>
                      <li class="dropdown-item"><a v-on:click="changePeriod('select-period-7days' , '7days')"> {{ $t('select-period-7days') }}</a></li>
                      <li class="dropdown-item"><a v-on:click="changePeriod('select-period-1day' , '1day')">{{ $t('select-period-1day') }}</a></li>
                      <!-- <li class="dropdown-item"><a v-on:click="changePeriod('Current month' , 'month')" >Current month</a></li>
                                          <li class="dropdown-item"><a v-on:click="changePeriod('Current day' , 'day')" >Current day</a></li>
                                          <li class="dropdown-item"><a v-on:click="changePeriod('Last 30 days' , '30days')" >Last 30 days</a></li>
                                          <li class="dropdown-item"><a v-on:click="changePeriod('Last 7 days' , '7days')" > Last 7 days</a></li>
                                          <li class="dropdown-item"><a v-on:click="changePeriod('Last 24 hours' , '1day')" >Last 24 hours</a></li> -->
                    </ul>
                  </div>
                </div>
              </div>


              <div class="col-lg-12 no_side_pad request">
                <div class="page-nav">
                  <div class="row no_side_margin">
                    <div class="col-lg-3 col-sm-4 col-4 no_side_pad" v-on:click="moveNext('H')">
                      <div class="nav-item"><a :class="{'disabled': isLastPage(), '': !isLastPage() && !this.loading}" v-on:click="moveNext('H')"><span class="nav-icon">←</span> {{ $t('older' ) }}</a></div>
                    </div>
                    <div class="col-lg-6 col-sm-4 col-4 no_side_pad">
                      <div class="btn-group ">
                      </div>
                    </div>
                    <div class="col-lg-3 text-right" v-on:click="movePrevious('H')">
                      <div class="nav-item"><a :class="{'disabled': isFirstPage() || !this.loading, '': !isLastPage() && this.loading}" v-on:click="movePrevious('H')">{{ $t('newer' ) }} <span class="nav-icon">→</span></a></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row smry-status no_side_margin" style="overflow-x: auto;">
                <table class="table table-striped list-table" style="margin-top: 0px">
                  <thead>
                    <tr>


                      <th class="t-head">{{ $t('date' ) }}</th>
                      <th class="t-head">{{ $t('source' ) }}</th>
                      <th class="t-head">{{ $t('mode' ) }}</th>
                      <th class="t-head">{{ $t('entrypoint' ) }}</th>
                      <th class="t-head">{{ $t('processor' ) }}</th>
                      <th class="t-head">{{ $t('rows' ) }}</th>
                      <th class="t-head">{{ $t('scoring' ) }}</th>
                      <th class="t-head">{{ $t('cost' ) }}</th>


                    </tr>
                  </thead>

                  <tbody v-if="!this.requests.length">
                    <tr>
                      <td colspan="8" class="text-center no-list">{{ $t('no-results' ) }}</td>
                    </tr>
                  </tbody>

                  <tbody v-if="this.requests.length">

                    <tr v-for="request in this.requests" v-bind:key="request.id">
                      <td class="table-col">{{request.date | formatDate}}</td>
                      <td class="table-col">{{request.source }}</td>
                      <td class="table-col">{{request.mode }}</td>
                      <td class="table-col">{{request.entrypoint }}</td>
                      <td class="table-col">
                        <div v-for="(uri , index) in request.validator.split(',')" v-bind:key="index">
                          <a v-bind:href="'#/tools/processor/'+uri.replace('-', '/').replace('-', '/')">{{uri}}</a>
                        </div>
                      </td>
                      <td class="table-col">{{request.totalRows}}</td>
                      <td class="table-col">{{parseInt((request.totalRowsOk/request.totalRows) * 100, 10)}}</td>
                      <td class="table-col">{{request.price.toFixed(4) | numberOnly}}€</td>
                    </tr>



                  </tbody>


                </table>




              </div>
            </div>


          </div>
        </div>
      </div>

      <Footer />

    </div>


  </div>

</div>
</template>

<script>
import Header from '@/components/Header.vue'

import Footer from '@/components/Footer.vue'

import Sidebar from '@/components/Sidebar.vue'

//import Chart from "./Chart.vue";
import LineChart from './LineChart.vue'

import axios from 'axios';

import _ from 'lodash';

import $ from 'jquery'

export default {
  name: 'Dashboard',
  data() {
    return {
      isActive: false,
      isActiveIcon: '<',
      period: '1day',
      periodDisplay: 'select-period-1day',
      kpisAll: [],
      kpisList: [],
      kpisTotal: [],
      isCostByHour: true,
      startDate: '',
      endDate: '',
      graphOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      datacollection: {
        datasets: []
      },
      toggleClass: "ani1",
      state: [],
      config: {},
      graph: {
        data: [],
        options: {}
      },

      loading: false,
      totalPages: 0,
      currentPage: 0,
      prevStartDate: null,
      prevEndDate: null,
      requests: []

    };
  },
  mounted() {

    this.enableInterceptor();
    this.startDate = new Date(Date.now() + -1 * 24 * 3600 * 1000);
    this.endDate = new Date();

    this.config = {
      headers: {
        "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
        "Content-Type": "application/json"
      }
    };
    this.changePeriod('select-period-month' , 'month');
    this.getRequestsTrigger();
  },
  components: {
    Header,
    Sidebar,
    Footer,
    //Chart,
    LineChart
  },
  methods: {

    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        this.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        this.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },


    areDatesDifferents: function(setDates) {

      setDates = setDates || false;
      var resStart = this.isStartDateDifferent(),
        resEnd = this.isEndDateDifferent();
      if (setDates) {
        if (resStart) {
          this.prevStartDate = new Date(this.startDate);
        }
        if (resEnd) {
          this.prevEndDate = new Date(this.endDate);
        }
      }
      return resStart || resEnd;

    },

    isStartDateDifferent: function() {
      return (this.prevStartDate === null || this.startDate.toLocaleString() !== this.prevStartDate.toLocaleString());
    },

    isEndDateDifferent: function() {
      return (this.prevEndDate === null || this.endDate.toLocaleString() !== this.prevEndDate.toLocaleString());
    },

    isFirstPage: function() {
      return this.isFirstPageCheck();
    },

    isFirstPageCheck: function() {
      return this.currentPage === 0;
    },

    getCurrentPage: function() {
      return this.currentPage;
    },

    getTotalPages: function() {
      return this.totalPages;
    },

    isLastPage: function() {
      return (this.totalPages > 0 && this.currentPage === this.totalPages - 1) || (this.totalPages === 0 && this.currentPage === 0);
    },

    moveNext: function(from) {

      if (!this.isLastPage()) {
        this.loading = true;
      }

      if (from == 'B') {
        this.moveNextProcess();
      } else {
        this.moveNextProcessHistory();
      }
      this.updatePaginationValues();

    },

    moveNextProcess: function() { //alert('moveNextProcess'+this.currentPage);
      let currentPage = this.currentPage;
      if (currentPage + 1 < this.totalPages) {
        this.currentPage++; //alert('yes'+this.currentPage);
        this.getJobs(true);
      }

    },

    moveNextProcessHistory: function() { //alert('hx');
      let currentPage = this.currentPage;
      if (currentPage + 1 < this.totalPages) {
        this.currentPage++;
        this.getRequests(true);
      }

    },


    movePrevious: function(from) {

      if (this.isFirstPage()) {
        this.loading = true;
      }
      if (from == 'B') {
        this.movePreviousProcess();
      } else {
        this.movePreviousProcessHistory();
      }

      this.updatePaginationValues();

    },

    movePreviousProcess: function() {
      let currentPage = this.currentPage;
      if (currentPage - 1 >= 0) {
        this.currentPage--;
        this.getJobs(true);
      }
    },

    movePreviousProcessHistory: function() {
      let currentPage = this.currentPage;
      if (currentPage - 1 >= 0) {
        this.currentPage--;
        this.getRequests(true);
      }
    },

    getRequestsTrigger: function() {
      this.period = '1day';
      this.periodDisplay = 'select-period-1day';
      this.currentPage = 0;
      this.totalPages = 0;
      this.getRequests(true);
    },

    getRequests: function(forced) {
      forced = this.areDatesDifferents() || forced || !this.requests[this.currentPage] || false;
      if (!this.pendingRequest && forced) {
        this.pendingRequest = true;

        var url = '/api/v2/requests_period/' + this.period + "/" + new Date().getTime() + "/" + new Date().getTime() + "/" + this.currentPage;

        this.$http.get(url, this.config).then((results) => {

          this.requests[this.currentPage] = results.data.results;
          this.requests = this.requests[this.currentPage];
          this.totalRecords = results.data.count;
          this.totalPages = results.data.totalPages;
          this.rowsPerPage = results.data.rowsPerPage;
          this.pendingRequest = false;
          this.areDatesDifferents(true);

        });


      } else {
        //return requests[currentPage];
      }

    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      //console.log(this.isActive);
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },

    fillData() {
      var days = [];
      var costs = [];
      var savings = [];
      this.dayData.map(function(element) {
        var type = element['key'];
        element['values'].map(function(elements) {
          var conv = elements[0];
          var actualDate = parseInt(conv, 10);
          var date = new Date(actualDate);

          var months_arr = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

          // Hours part from the timestamp

          var year = date.getFullYear();

          // Month
          var month = months_arr[date.getMonth()];

          // Day
          var day = date.getDate();

          // Hours
          //var hours = date.getHours();

          // Minutes
          //var minutes = "0" + date.getMinutes();

          // Seconds
          //var seconds = "0" + date.getSeconds();

          // Display date time in MM-dd-yyyy h:m:s format
          var convdataTime = day + '/' + month + '/' + year;
          elements.date = convdataTime;
          elements.value = elements[1];
          if (elements.date && days.indexOf(elements.date) === -1) {
            days.push(elements.date)
          }

          if (type === 'cost') {
            costs.push(elements[1])
          } else if (type === 'saving') {
            savings.push(elements[1])
          }
        })
      })

      this.datacollection = {
        labels: days,
        datasets: [
          {
            label: this.$t('cost') + " (€)",
            backgroundColor: "rgb(49, 119, 180)",
            data: costs,
            fill: 'origin'
          },
          {
            label:  this.$t('savings') + " (€)",
            backgroundColor: "rgb(174, 199, 232)",
            data: savings,
            fill: 'origin'
          }
        ]
      }
    },

    changePeriod: function(item, period) {
      this.datacollection = {
        datasets: []
      };

      this.periodDisplay = item;
      this.period = period;
      this.endDate = new Date();
      this.startDate = new Date();
      //console.log(this.period, this.startDate, this.endDate);
      if (this.period === 'day') { //current day
        this.startDate.setHours(0)
        this.startDate.setMinutes(0)
        this.startDate.setSeconds(0)
        this.endDate.setHours(23)
        this.endDate.setMinutes(59)
        this.endDate.setSeconds(59)
      } else if (this.period === '1day') { //last 24
        this.startDate.setDate(this.startDate.getDate() - 1)
      } else if (this.period === 'month') { //current month
        this.startDate.setDate(1);
        this.startDate.setHours(0);
        this.startDate.setMinutes(0);
        this.startDate.setSeconds(0);
      } else if (this.period === '7days') { //last 7 days
        this.startDate.setDate(this.startDate.getDate() - 7)
      } else if (this.period === '30days') { //last 30 days
        this.startDate.setDate(this.startDate.getDate() - 30)
      }

      //console.log(this.startDate, this.endDate);

      this.isCostByHour = (this.period === 'day' || this.period === '1day');
      var aux = this.period;
      /*if (aux === '1day') {
        aux = 'day';
      }*/
      var url = '/api/v2/stats_period/' + aux + '/' + this.startDate.getTime() + '/' + this.endDate.getTime();
      //console.log(url);
      this.$http.get(url, this.config).then((results) => {
        this.state = results.data.all.series.cost;
        this.kpisAll = results.data.all.kpi;
        this.kpisList = results.data.all.values;

        let that = this;

        $.each(results.data, function(key) {
          that.kpisTotal[key] = {
            cost: 0,
            count: 0,
            savings: 0
          };
          $.each(results.data[key].total, function(keys, value) {
            that.kpisTotal[key].cost += value.cost;
            that.kpisTotal[key].savings += value.savings;
            that.kpisTotal[key].count += value.count;
          });
        });
        this.fillData();
        this.getRequests();
      });
    }
  },
  watch: {

    state: function() {

      //this.lineChartProps;

    }

  },
  computed: {

    orderedList: function() {
      return _.orderBy(this.kpisList, ['key'], ['desc'])
    },

    dayData() {
      if (this.state != 0) {
        return this.state;
      } else {
        return [{
          "key": "cost",
          "values": [
            [Date.now(), 0]
          ]
        }, {
          "key": "saving",
          "values": [
            [Date.now(), 0]
          ]
        }];
      }
    }
  }
}
</script>

<style scoped>
#secure {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  padding: 20px;
  margin-top: 10px;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../assets/css/global.css';
</style>
