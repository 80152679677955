import Vue from 'vue'
import Vue2Filters from 'vue2-filters'
import VueRouter from 'vue-router'
import VueDrift from 'vue-drift'
import VueAnalytics from 'vue-analytics'
import axios from 'axios'
import VueTour from 'vue-tour'
import Vuelidate from 'vuelidate'
import numFormat from 'vue-filter-number-format';
import moment from 'moment'
import VModal from 'vue-js-modal'
import VueClipboard from 'vue-clipboard2';
import Notifications from 'vue-notification'
import VTooltip from 'v-tooltip'
import Hotjar from 'vue-hotjar'
import VueSimpleMarkdown from 'vue-simple-markdown'
import { VuePlausible } from 'vue-plausible'


import App from './App.vue'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
require('vue-tour/dist/vue-tour.css')
import 'vue-simple-markdown/dist/vue-simple-markdown.css'
import "vue-notion/src/styles.css";

import {
  library
} from '@fortawesome/fontawesome-svg-core'
import {
  faEnvelope,
  faKey,
  faUser,
  faCog,
  faGift,
  faHandshake,
  faCode,
  faReceipt,
  faVolumeUp,
  faCubes,
  faFileInvoiceDollar,
  faHome,
  faShoppingCart,
  faMagic,
  faLightbulb,
  faList,
  faTasks,
  faChartLine,
  faChartPie,
  faTags,
  faFileCode,
  faBuilding,
  faComments,
  faMoneyCheckAlt,
  faMapMarkedAlt,
  faShieldAlt,
  faImages,
  faAlignJustify,
  faGlobe,
  faInfoCircle,
  faPaperPlane,
  faExpandArrowsAlt,
  faCheck,
  faEraser,
  faIdCard,
  faBook,
  faFileExcel,
  faFile,
  faDatabase,
  faCloud,
  faUpload,
  faServer,
  faHistory,
  faCloudDownloadAlt,
  faTrash,
  faDownload,
  faCreditCard,
  faMoneyCheck,
  faEdit,
  faTimes,
  faPhone,
  faBullseye,
  faInfo
} from '@fortawesome/free-solid-svg-icons'

import {
  faWpforms,
  faMailchimp,
  faPaypal,
  faTelegram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'

import {
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'


library.add(
  faCog,
  faEnvelope,
  faUser,
  faGift,
  faHandshake,
  faCode,
  faReceipt,
  faVolumeUp,
  faWpforms,
  faCubes,
  faFileInvoiceDollar,
  faHome,
  faShoppingCart,
  faMagic,
  faLightbulb,
  faList,
  faTasks,
  faChartLine,
  faChartPie,
  faTags,
  faFileCode,
  faBuilding,
  faComments,
  faMoneyCheckAlt,
  faMapMarkedAlt,
  faShieldAlt,
  faImages,
  faAlignJustify,
  faGlobe,
  faInfoCircle,
  faPaperPlane,
  faExpandArrowsAlt,
  faCheck,
  faTimes,
  faEraser,
  faIdCard,
  faBook,
  faFileExcel,
  faFile,
  faDatabase,
  faCloud,
  faMailchimp,
  faUpload,
  faServer,
  faHistory,
  faCloudDownloadAlt,
  faTrash,
  faDownload,
  faCreditCard,
  faPaypal,
  faMoneyCheck,
  faEdit,
  faYoutube,
  faTelegram,
  faPhone,
  faBullseye,
  faInfo)

import Login from './components/Login/Login.vue'
import SignUp from './components/Login/SignUp.vue'
import Forgot from './components/Login/Forgot.vue'
import Confirm from './components/Login/Confirm.vue'

import Dashboard from './components/Dashboard/Dashboard.vue'
import Purchase from './components/Purchase/Purchase.vue'
import Result from './components/Purchase/Result.vue'
import Wizard from './components/Wizards/Wizard.vue'
import WizardProcess from './components/Wizards/WizardProcess.vue'
import WizardList from './components/Wizards/WizardList.vue'
import WizardAnalyze from './components/Wizards/WizardAnalyze.vue'
import Welcome from './components/Wizards/Welcome.vue'

import Bulks from './components/Bulks/Bulks.vue'
import BulksReport from './components/Bulks/BulksReport.vue'
import BulksRawReport from './components/Bulks/BulksRawReport.vue'

import Forms from './components/Forms/Forms.vue'
import FormsView from './components/Forms/FormsView.vue'

import Lists from './components/Lists/Lists.vue'
import ListsView from './components/Lists/ListsView.vue'
import ListsViewReport from './components/Lists/ListsViewReport.vue'

import Settings from './components/Settings/Settings.vue'

import Tools from './components/Tools/Tools.vue'
import ToolsProcessor from './components/Tools/ToolsProcessor.vue'
import ToolsCategory from './components/Tools/ToolsCategory.vue'
import ToolsCategoryProcessors from './components/Tools/ToolsCategoryProcessors.vue'
import Contact from './components/Contact/Contact.vue'

/*import Chart from './components/Chart.vue'*/


import i18n from '@/plugins/i18n';

var base = 'https://api.uproc.io';
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'development2prod') {
  base = 'http://uproc-api';
} else if (process.env.NODE_ENV === 'staging') {
  base = 'http://127.0.0.1:4000';
}

axios.defaults.baseURL = base;
axios.defaults.headers = {
  'Content-Type': 'application/json'
};

var getPreferredLanguage = function(langs) {
  var detectedEs = langs.split(";").map(function(l) {
    return l.toLowerCase().trim();
  }).filter(function(l) {
    return l.indexOf("es") !== -1;
  });
  return (detectedEs && detectedEs.length > 0) ? "es" : "en";
}

Vue.prototype.$prefix = new Date().getTime();
Vue.prototype.$locale = getPreferredLanguage(navigator.language);
Vue.prototype.$http = axios
Vue.prototype.$apiBase = base;
Vue.prototype.$defaultKey = 'bf572286a9ea25a28b9c896b03b7176e'
Vue.use(VueRouter)
Vue.use(VModal, {
  dialog: true
})
Vue.use(VueClipboard)
Vue.use(Vuelidate)
Vue.use(Notifications)
Vue.use(VTooltip);
Vue.use(Vue2Filters);
Vue.use(VueTour);
Vue.use(VueSimpleMarkdown);
Vue.use(VuePlausible, {
  domain: 'app.uproc.io',
  apiHost: 'https://plausible.hiveagile.dev',
});

const routes = [{
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login/:email?',
    component: Login
  },
  {
    path: '/signup/:referral?',
    component: SignUp
  },
  {
    path: '/forgot/:email?',
    component: Forgot
  },
  {
    path: '/invitation/:referral',
    redirect: '/signup/:referral'
  },
  {
    path: '/confirm/signup/:id',
    component: Confirm
  },
  {
    path: '/dashboard',
    component: Dashboard
  },
  {
    path: '/purchase/result/:result',
    component: Result
  },
  {
    path: '/purchase',
    component: Purchase
  },
  {
    path: '/purchase/:modePurchase',
    component: Purchase
  },
  {
    path: '/purchase/:modePurchase/:amount',
    component: Purchase
  },
  {
    path: '/wizard',
    component: Wizard
  },
  {
    path: '/wizard/process/:primarySource?/:secondarySource?/:tool?',
    component: WizardProcess
  },
  {
    path: '/wizard/list',
    component: WizardList
  },
  {
    path: '/wizard/analyze',
    component: WizardAnalyze
  },
  {
    path: '/bulks',
    component: Bulks
  },
  {
    path: '/queue',
    component: Bulks
  },
  {
    path: '/bulks/:task/report',
    component: BulksReport
  },
  {
    path: '/reports/:file',
    component: BulksRawReport
  },
  {
    path: '/forms',
    component: Forms
  },
  {
    path: '/forms/:name',
    component: FormsView
  },
  {
    path: '/lists',
    component: Lists
  },
  {
    path: '/lists/:name',
    component: ListsView
  },
  {
    path: '/lists/:name/report',
    component: ListsViewReport
  },
  {
    path: '/settings/profile',
    component: Settings
  },
  {
    path: '/settings/billing',
    component: Settings
  },
  {
    path: '/settings/integration',
    component: Settings
  },
  {
    path: '/settings/integration/:method/:provider/:app',
    component: Settings
  },
  {
    path: '/settings/agreements',
    component: Settings
  },
  {
    path: '/settings/invite',
    component: Settings
  },
  {
    path: '/settings/billing/invoice/:year/:number',
    component: Settings
  },
  {
    path: '/welcome/:reason?',
    component: Welcome
  },
  {
    path: '/tools',
    component: Tools
  },
  {
    path: '/tools/processor/:type/:module/:name/:query',
    component: ToolsProcessor
  },
  {
    path: '/tools/processor/:type/:module/:name',
    component: ToolsProcessor
  },
  {
    path: '/tools/category/:category',
    component: ToolsCategory
  },
  {
    path: '/tools/category/:category/type/:type',
    component: ToolsCategoryProcessors
  },
  {
    path: '/tools/field/:field',
    component: ToolsCategory
  },
  {
    path: '/tools/field/:field/type/:type',
    component: ToolsCategoryProcessors
  },
  {
    path: '/contact',
    component: Contact
  },
  {
    path: '/history',
    redirect: '/dashboard'
  },
  {
    path: '/settings',
    redirect: '/settings/profile'
  },
  {
    path: '/payments',
    redirect: '/settings/billing'
  },
  {
    path: '*',
    redirect: '/login'
  }
  //{ path: '/header', component: Header },
  /*{ path: '/Chart', component: Chart }*/
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  var token = localStorage && localStorage.token && localStorage.token !== 'undefined' ? localStorage.token : "";
  var isLogged = token && token.length > 0;
  if (isLogged) {
    if (to.path == '/' || to.path == '/login' || to.path == '/forgot' || to.path == '/signup') {
      next("/dashboard");
    } else {
      next();
    }
  } else {
    if (to.path != '/login' && to.path != '/forgot' && to.path != '/signup' &&
      to.path.indexOf("signup/") === -1 && to.path.indexOf("confirm/") === -1 && to.path.indexOf("invitation/") === -1 && to.path.indexOf("login/") === -1) {
      //this.destination = to.path;
      next({
        path: "/login",
        query: {
          redirect: to.path
        }
      });
    } else {
      next();
    }
  }
})

library.add(faEnvelope, faKey)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.filter('numFormat', numFormat);

Vue.filter('minimizeNumber', function(input) {
  if (input !== undefined && input !== null) {
    var inputStr = input.replace(".", "").replace(",", ".");
    var inputNumber = parseFloat(inputStr);
    if (inputStr.indexOf(".") === -1) {
      inputStr += ".00";
    }
    if (inputStr.indexOf(".") > 6) {
      inputNumber = inputNumber / 1000000;
      return parseFloat(inputNumber).toFixed(1) + "m";
    } else if (inputStr.indexOf(".") > 3) {
      inputNumber = inputNumber / 1000;
      return parseFloat(inputNumber).toFixed(0) + "k";
    } else if (inputStr.indexOf(".") > 1) {
      inputNumber = Math.ceil(inputNumber);
      return parseFloat(inputNumber).toFixed(0);
    } else {
      return input;
    }
  }
  return input;
});

Vue.filter('uppercase', function(value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})


Vue.filter('numberOnly', function(value) {
  if (isNaN(value)) {
    return ''
  } else {
    return value
  }
})

Vue.filter('toInt', function(value) {
  if (isNaN(value)) {
    return ''
  } else {
    return parseInt(value)
  }
})


Vue.filter('numberOnlyNaM', function(value) {
  if (isNaN(value)) {
    return 0
  } else {
    return value
  }
})

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD HH:mm')
  }
})

Vue.filter('formatDateSeconds', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD HH:mm:ss')
  }
})


Vue.filter('formatDateShort', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD')
  }
})

Vue.filter('makeRange', function(input) {

  var lowBound, highBound;
  switch (input.length) {
    case 1:
      lowBound = 0;
      highBound = parseInt(input[0], 10) - 1;
      break;
    case 2:
      lowBound = parseInt(input[0], 10);
      highBound = parseInt(input[1], 10);
      break;
    default:
      return input;
  }
  var result = [];
  for (var i = lowBound; i <= highBound; i++) {
    result.push(i);
  }
  return result;

});

Vue.filter('typeFilter', function(input) {

  var returned = '';

  if (input == 'type-normalize') {
    returned = 'Clean';
  } else if (input == 'type-normalize-description') {
    returned = 'Clean and format your data by field';
  } else if (input == 'type-check') {
    returned = 'Verify';
  } else if (input == 'type-check-description') {
    returned = 'Check validity of your data against rules';
  } else if (input == 'type-get') {
    returned = 'Enrich';
  } else if (input == 'type-get-description') {
    returned = 'Complete data with related information';
  } else if (input == 'type-unify') {
    returned = 'Unify';
  } else if (input == 'type-unify-description') {
    returned = 'Detect duplicated leads and unify them';
  } else if (input == 'type-get-verb') {
    returned = 'Get';
  } else if (input == 'type-check-verb') {
    returned = 'Check if';
  } else if (input == 'type-send-verb' || input == 'type-send') {
    returned = 'Send';
  } else if (input == 'group-all-description') {
    returned = 'Full tool list';
  } else if (input == 'group-communication-description') {
    returned = 'Email, Phone, Mobile';
  } else if (input == 'group-audio-description') {
    returned = 'Text2Speech';
  } else if (input == 'group-company-description') {
    returned = 'Cif, Name, Employee';
  } else if (input == 'group-editorial-description') {
    returned = 'Isbn, Author, Editor';
  } else if (input == 'group-finance-description') {
    returned = 'Credit card, Account, Currency';
  } else if (input == 'group-geographic-description') {
    returned = 'Country, City, Route';
  } else if (input == 'group-image-description') {
    returned = 'Screenshot, EXIF, QR, Barcode';
  } else if (input == 'group-internet-description') {
    returned = 'Domain, Ip, Uri';
  } else if (input == 'group-lead-description') {
    returned = 'Full record';
  } else if (input == 'group-personal-description') {
    returned = 'Name, Surname, Gender';
  } else if (input == 'group-product-description') {
    returned = 'Ean, Upc, Asin, Gtin, Isbn';
  } else if (input == 'group-security-description') {
    returned = 'Password, Md5, Luhn';
  } else if (input == 'group-text-description') {
    returned = 'Uppercase, Lowercase, Split';
  } else if (input == 'group-realestate-description') {
    returned = 'Reference, Address';
  } else if (input == 'group-audio-name') {
    returned = 'Audio';
  } else if (input == 'group-image-name') {
    returned = 'Image';
  } else if (input == 'group-communication-name') {
    returned = 'Communication';
  } else if (input == 'group-company-name') {
    returned = 'Company';
  } else if (input == 'group-editorial-name') {
    returned = 'Editorial';
  } else if (input == 'group-finance-name') {
    returned = 'Finance';
  } else if (input == 'group-geographic-name') {
    returned = 'Geographic';
  } else if (input == 'group-internet-name') {
    returned = 'Internet';
  } else if (input == 'group-lead-name') {
    returned = 'Lead';
  } else if (input == 'group-product-name') {
    returned = 'Product';
  } else if (input == 'group-personal-name') {
    returned = 'Personal';
  } else if (input == 'group-security-name') {
    returned = 'Security';
  } else if (input == 'group-text-name') {
    returned = 'Text';
  } else if (input == 'group-realestate-name') {
    returned = 'Real Estate';
  } else {
    returned = "select-option";
  }


  return returned;

})

if (process.env.NODE_ENV === 'production') {
  Vue.use(VueDrift, {
    appId: '5hv8nz32nbr4',
    backgroundColor: '#0300e2',
    locale: 'en', //this.$i18n.locale.toLowerCase().indexOf("es") !== -1 ? "es" : "en",
  })
  Vue.use(VueAnalytics, {
    id: 'UA-42919027-5',
    router
  });
  Vue.use(Hotjar, {
    id: '2311327'
  });
}
/*
window.OneSignal = window.OneSignal || [];
window.OneSignal.push(() => {
	window.OneSignal.init({
		appId: process.env.VUE_APP_ONESIGNAL_APPID,
		allowLocalhostAsSecureOrigin: process.env.NODE_ENV !== 'production',
	});
});
*/


import {
  store
} from './store/store'

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
