<template>
<div>
  <div v-if="language === 'en'">
    I see that you want to <strong>send SMS marketing campaigns</strong> ;)<br><br>
    Do you know that with uProc you can <strong>avoid delivery problems</strong> by <strong>verifying mobiles</strong> previously?
    <br><br>
    Just follow the next steps:
    <ul>
      <li><strong><a href="/#/settings/profile" target="_blank">Identify</a></strong> yourself for legal purposes.</li>
      <li><strong><a href="/#/tools/processor/get/mobile/hlr-lookup" target="_blank">Test</a></strong> some mobiles for free and check the results.</li>
      <li>Verify <strong>1000 mobiles</strong> costs <strong>6€</strong>. <strong><a href="/#/purchase/packs" target="_blank">Choose</a></strong> the pack that suits your needs.</li>
      <li><strong><a href="/#/wizard/process/Files/Upload/get-mobile-hlr-lookup" target="_blank">Upload</a></strong> your file, choose "Get HLR Mobile Lookup", the mobile column and start the process.</li>
      <li>You will receive the results file by email.</li>
    </ul>
    <br>
    You can also verify your mobiles from
    <strong><a href="https://uproc.io/blog/how-to-get-hlr-mobile-lookup#sheets" target="_blank">Google Sheets</a></strong>,
    <strong><a href="https://uproc.io/blog/how-to-get-hlr-mobile-lookup#n8n" target="_blank">n8n</a></strong>,
    <strong><a href="https://uproc.io/blog/how-to-get-hlr-mobile-lookup#integromat" target="_blank">Make</a></strong>,
    <strong><a href="https://uproc.io/blog/how-to-get-hlr-mobile-lookup#zapier" target="_blank">Zapier</a></strong> or
    <strong><a href="https://uproc.io/blog/how-to-get-hlr-mobile-lookup#api" target="_blank">API</a></strong>.

    <br>
    Choose the best method that suits your needs!
    <br><br>
    <div class="row">
      <div class="col-lg-4">
        Looking for a fast sample? Check this video to view the tool in action using Google Sheets:
      </div>
      <div class="col-lg-8">
        <div class="video-responsive">
          <iframe width="100%" height="320" src="https://www.youtube.com/embed/gLgsnolLGfA?VQ=HD720" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
        </div>
      </div>
    </div>
  </div>
  <div v-if="language === 'es'">
    He visto que quieres <strong>enviar campañas de SMS</strong> ;)<br><br>
    ¿Sabes que con uProc <strong>evitas los problemas de entrega</strong> si <strong>verificas los móviles</strong> previamente?
    <br><br>
    Solo necesitas seguir los siguientes pasos:
    <ul>
      <li><strong><a href="/#/settings/profile" target="_blank">Identifícate</a></strong> para utilizar la plataforma de forma legal.</li>
      <li><strong><a href="/#/tools/processor/get/mobile/hlr-lookup" target="_blank">Comprueba</a></strong> algunos móviles gratuitamente y los resultados obtenidos.</li>
      <li>Comprobar <strong>1000 móviles</strong> cuesta <strong>6€</strong>. <strong><a href="/#/purchase/packs" target="_blank">Elije</a></strong> el pack que mejor se adapta a tus necesidades.</li>
      <li><strong><a href="/#/wizard/process/Files/Upload/get-mobile-hlr-lookup" target="_blank">Sube</a></strong> tu fichero, selecciona "Obtener Búsqueda HLR móvil", la columna del móvil y empieza el proceso.</li>
      <li>Recibirás los resultados por correo.</li>
    </ul>
    <br>
    También puedes comprobar tus móviles desde
    <strong><a href="https://uproc.io/blog/es/how-to-get-hlr-mobile-lookup#sheets" target="_blank">Google Sheets</a></strong>,
    <strong><a href="https://uproc.io/blog/es/how-to-get-hlr-mobile-lookup#n8n" target="_blank">n8n</a></strong>,
    <strong><a href="https://uproc.io/blog/es/how-to-get-hlr-mobile-lookup#integromat" target="_blank">Make</a></strong>,
    <strong><a href="https://uproc.io/blog/es/how-to-get-hlr-mobile-lookup#zapier" target="_blank">Zapier</a></strong> o
    <strong><a href="https://uproc.io/blog/es/how-to-get-hlr-mobile-lookup#api" target="_blank">API</a></strong>.

    <br>
    Elije el método que mejor se adapta a tus necesidades.
    <br><br>
    ¿Quieres ver un ejemplo rápido? Revisa este videotutorial para ver la herramienta en acción utilizando Google Sheets:
    <div class="video-responsive">
      <iframe width="100%" height="360" src="https://www.youtube.com/embed/gLgsnolLGfA?VQ=HD720" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'WelcomeSms',
  data() {
    return {
      language: this.$i18n.locale
    };
  },
  created() {

  },
  mounted() {}
}
</script>
