<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>




      <!-- wizard content start start -->

      <div class="">
        <div class="total-wizard">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item active">{{ $t('menu-wizard' ) }}</li>
              <!-- <li class="breadcrumb-item active" aria-current="page">Library</li> -->
            </ol>
          </nav>
          <div class="row no_side_margin">
            <div class="col-lg-6">
              <a v-bind:href="'#/wizard/process/Files/Upload'">
                <div class="item">
                  <div class="icon">
                    <font-awesome-icon icon="file-excel" size="3x"/>
                  </div>
                  <h3>{{ $t('wizard-process' ) }}</h3>
                  <p>{{ $t('wizard-process-description' ) }}</p>
                </div>
              </a>
            </div>

            <div class="col-lg-6">
              <a v-bind:href="'#/wizard/list'">
                <div class="item">
                  <div class="icon">
                    <font-awesome-icon icon="list" size="3x"/>
                  </div>
                  <h3>{{ $t('wizard-list' ) }}</h3>
                  <p>{{ $t('wizard-list-description' ) }}</p>
                </div>
              </a>
            </div>

            <!--div class="col-lg-4">
              <a v-bind:href="'#/wizard/analyze'">
                <div class="item">
                  <div class="icon">
                    <font-awesome-icon :icon="['fab', 'wpforms']" size="3x"/>
                  </div>
                  <h3>{{ $t('wizard-form' ) }}</h3>
                  <p>{{ $t('wizard-form-description' ) }}</p>
                </div>
              </a>
            </div-->
          </div>
        </div>
      </div>

      <!-- wizard content start end -->




      <Footer />

    </div>


  </div>

</div>
</template>

<script>
import Header from '@/components/Header.vue'

import Footer from '@/components/Footer.vue'

import Sidebar from '@/components/Sidebar.vue'

import axios from 'axios';

export default {
  name: 'wizard',
  data() {
    return {

      isActive: false,
      isActiveIcon: '<',

    }

  },
  mounted() {
      this.enableInterceptor();
      this.$root.$emit('isLoading', false);
  },
  components: {

    Header,
    Sidebar,
    Footer

  },
  methods: {

    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        this.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        this.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      //console.log(this.isActive);
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    }

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/wizards.css';
@import '../../assets/css/global.css';
</style>
