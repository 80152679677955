<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>




      <!-- wizard content start start -->

      <div class="">
        <div class="total-wizard">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb" v-if="this.stepNumber == 1">
              <li class="breadcrumb-item "><a v-bind:href="'#/wizard'">  {{$t('wizard-form-section')}}</a></li>
              <li class="breadcrumb-item active" aria-current="page">{{$t('wizard-form')}}</li>
            </ol>


            <ol class="breadcrumb" v-if="this.stepNumber == 2">
              <li class="breadcrumb-item "><a v-bind:href="'#/wizard'">{{$t('wizard-form-section')}}</a></li>
              <li class="breadcrumb-item" aria-current="page">{{$t('wizard-form')}}</li>
            </ol>

            <ol class="breadcrumb" v-if="this.stepNumber >= 3">
              <li class="breadcrumb-item "><a v-bind:href="'#/wizard'">{{$t('wizard-form-section')}}</a></li>
              <li class="breadcrumb-item" aria-current="page">Create List</li>
              <li class="breadcrumb-item" aria-current="page">{{selectedSource}}</li>
              <li class="breadcrumb-item active" aria-current="page">{{selectedSecondarySource}}</li>
            </ol>

          </nav>


          <div class="row no_side_margin">
            <h2>{{$t('wizard-form')}}</h2>
            <div class="col-lg-12">
              <div class="analyze-field">
                <div class="row">
                  <div class="col-lg-9">
                    <div class="input-total">
                      <p class="label-text">{{$t('wizard-form-write')}}</p>
                      <div class="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12.5333 3.5H11.4667C7.20551 3.78063 4.14083 7.34886 4 11.5005C4.14083 15.6523 7.2057 19.2194 11.4667 19.5H12.5333C16.7994 19.219 19.8509 15.6519 20 11.5005C19.8509 7.34926 16.7996 3.78097 12.5333 3.5ZM9.008 5.2299C8.4928 5.9163 8.03173 7.01851 7.72027 8H6.1224C6.81787 6.91266 7.83893 5.79121 9.008 5.2299ZM5.59787 9H7.47307C7.33013 9.67571 7.23947 10.2548 7.21067 11H5.08693C5.14347 10.2473 5.32053 9.66609 5.59787 9ZM5.08693 12H7.21067C7.23947 12.7452 7.35707 13.3243 7.5 14H5.6248C5.34747 13.3339 5.14347 12.7527 5.08693 12ZM6.1224 15H7.7192C8.03067 15.9815 8.49173 17.0848 9.008 17.7712C7.83893 17.2099 6.81787 16.0884 6.1224 15ZM11.4667 18.3817C10.3701 18.0876 9.3928 16.6647 8.82 15H11.4333L11.4667 18.3817ZM11.4936 14H8.58053C8.43013 13.3339 8.30827 12.7527 8.27733 12H11.4667L11.4936 14ZM11.4667 11H8.27733C8.30827 10.2473 8.4032 9.66609 8.5536 9H11.4667V11ZM11.4333 8H8.82C9.3928 6.33532 10.3701 4.91343 11.4667 4.62048L11.4333 8ZM17.8109 8H16.2152C15.9027 7.01851 15.5093 5.9163 14.992 5.2299C16.16 5.79121 17.1155 6.91266 17.8109 8ZM12.5333 4.62048C13.6288 4.91343 14.5405 6.33532 15.1133 8H12.5L12.5333 4.62048ZM12.5333 9H15.4453C15.5968 9.66609 15.6896 10.2473 15.7227 11H12.5333V9ZM12.5333 12H15.7227C15.6917 12.7527 15.6237 13.3339 15.4723 14H12.5603L12.5333 12ZM12.5333 18.3817L12.5 15H15.1133C14.5405 16.6647 13.6288 18.0876 12.5333 18.3817ZM14.992 17.7712C15.5072 17.0837 15.9027 15.9815 16.2152 15H17.8109C17.1155 16.0884 16.16 17.2099 14.992 17.7712ZM18.4291 14H16.5539C16.6968 13.3243 16.7605 12.7452 16.7893 12H18.912C18.8565 12.7527 18.7064 13.3339 18.4291 14ZM16.7893 11C16.7605 10.2548 16.6699 9.67571 16.5269 9H18.4021C18.6795 9.66609 18.8565 10.2473 18.9131 11H16.7893Z"
                            fill="#0300e2" />
                        </svg>

                      </div>
                      <input type="text" name="" v-model="url" class="mail-id" v-on:keyup="onKeyUp" v-on:change="resetForm()">
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <button type="button" class="analyse-btn" v-on:click="analyzeForm()">{{$t('wizard-form-analyze')}}</button>

                  </div>

                </div>
              </div>

              <div v-if="this.stepNumber == 2 && this.processed">

                <div v-for="entry in this.form" v-bind:key="entry.name">

                  <h6>{{$t('field')}}: {{entry.name}}</h6>
                  <div style="overflow-x: auto;">
                    <table class="table table-striped list-table" style="margin-top: 0px;">
                      <thead>
                        <tr>
                          <th class="t-head">{{$t('wizard-tool-apply')}}</th>
                          <th class="t-head">{{$t('wizard-returned-fields')}}</th>
                          <th class="t-head">{{$t('wizard-price')}}</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr v-for="processor in entry.processors" v-bind:key="processor.key">
                          <td class="table-col">
                          {{$t("group-" + processor.category.toLowerCase() + "-name")}} - {{$t('type-' + processor.type)}} - {{processor.description}}
                          </td>
                          <td class="table-col">{{processor.properties.join(", ")}}</td>
                          <td class="table-col">{{processor.price}}€</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>

                </div>



              </div>


            </div>
          </div>







        </div>
      </div>

      <!-- wizard content start end -->




      <Footer />

    </div>


  </div>

</div>
</template>

<script>
import Header from '@/components/Header.vue'

import Footer from '@/components/Footer.vue'

import Sidebar from '@/components/Sidebar.vue'

import axios from 'axios';

export default {
  name: 'wizard_analyze',
  data() {
    return {

      isActive: false,
      isActiveIcon: '<',
      stepNumber: 1,
      selectedSource: '',
      selectedSecondarySource: '',
      selectedSecondarySourceDb: '',
      sending: false,
      processed: false,
      url: "https://www.coregistros.com/contacto/",
      form: {},


    }

  },
  mounted() {
      this.enableInterceptor();
      this.getUserDetails();
  },
  components: {
    Header,
    Sidebar,
    Footer
  },
  methods: {

    enableInterceptor() {
      axios.interceptors.response.use((response) => {
        this.$root.$emit('isLoading', false);
        return response
      }, function(error) {
        this.$root.$emit('isLoading', false);
        return Promise.reject(error)
      })
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      //console.log(this.isActive);
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },

    selectSource: function(source) {
      this.selectedSource = source;
      this.selectedSecondarySource = '';
      this.stepNumber = 2;
    },

    getUserDetails: function() {
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v2/profile';
      this.$http.get(url, config).then((results) => {
        this.user = results.data;
      });
    },

    getCredits: function() {
      return this.user ? this.user.credits : 0;
    },

    hasCards: function() {
      return this.user && this.user.cards.length;
    },

    hasAutoCredits: function() {
      return this.user && this.user.autocredits !== null ? this.user.autocredits : false;
    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },

    areCreditsNeeded: function() {
      return this.processing.processCredits > this.getCredits();
    },

    //FROM HERE FORM AREA FUNCTIONS
    onKeyUp: function(event) {
      if (event.keyCode === 13) {
        this.analyzeForm();
      }
    },

    resetForm: function() {
      this.stepNumber = 1;
      this.processed = false;
      this.sending = false;
      this.form = {};
    },

    analyzeForm: function() {
      this.resetForm();
      this.stepNumber++;
      this.sending = true;
      this.analyze(this.url);
    },

    analyze: function(uri) {
      uri = uri || "http://killia.com";
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v2/form_analise/' + encodeURIComponent(uri);
      this.$http.get(url, config).then((results) => {
        this.form = results.data;
        this.processed = true;
        this.sending = false;
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/wizards.css';
@import '../../assets/css/global.css';
</style>
