<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>




      <!-- wizard content start start -->

      <div class="">
        <div class="total-lists">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item "><a v-bind:href="'#/forms'">{{ $t('top-form' ) }}</a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('my-form' ) }}</li>
            </ol>
          </nav>

          <div class="row no_side_margin">
            <div class="col-lg-12 no_side_pad">
              <div class="list-people" style="margin-bottom: 0px;border-radius: 0px; box-shadow: none;   border-bottom: 1px solid #e3e7eb">
                <div class="row ">
                  <div class="col-md-9 col-md-9">
                    <h2>{{ $t('form' ) }}: {{form.name}}</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-sm-12 col-md-12">
                    {{ $t('form-add' ) }}<br><br>
                    <pre class="ng-binding">
&lt;script&gt;
const FORM_ID = '{{this.form.id}}';
&lt;/script&gt;
&lt;script type='text/javascript' src='https://form.uproc.io/bundle.js'&gt;&lt;/script&gt;
                    </pre>
                  </div>
                  <div class="col-sm-12 col-12">
                    <h4>{{ $t('form-settings' ) }}</h4>
                    <p>{{ $t('form-settings-description' ) }}</p>

                    <div class="text col-sm-12 col-12 no_side_pad">
                      <label class="col-sm-1 col-1">
                        <input type="checkbox" name="optradio" v-model='form.check_recipient' v-on:change="hasToBeSaved()" />
                      </label>
                      {{ $t('form-settings-recipient' ) }}
                    </div>


                    <div class="text col-sm-12 col-12 no_side_pad">
                      <label class="col-sm-1 col-1">
                        <input type="checkbox" name="optradio" v-model='form.accept_free' v-on:change="hasToBeSaved()" />
                      </label>
                      {{ $t('form-settings-free' ) }}
                    </div>

                    <div class="text col-sm-12 col-12 no_side_pad">
                      <label class="col-sm-1 col-1">
                        <input type="checkbox" name="optradio" v-model='form.accept_temporary' v-on:change="hasToBeSaved()" />
                      </label>
                      {{ $t('form-settings-temporary' ) }}
                    </div>

                    <div class="text col-sm-12 col-12 no_side_pad">
                      <label class="col-sm-1 col-1">
                        <input type="checkbox" name="optradio" v-model='form.accept_catchall' v-on:change="hasToBeSaved()" />
                      </label>
                      {{ $t('form-settings-catchall' ) }}
                    </div>

                    <div class="text col-sm-12 col-12 no_side_pad">
                      <label class="col-sm-1 col-1">
                        <input type="checkbox" name="optradio" v-model='form.accept_softbounce' v-on:change="hasToBeSaved()" />
                      </label>
                      {{ $t('form-settings-softbounce' ) }}
                    </div>

                    <div class="text col-sm-12 col-12 no_side_pad">
                      <label class="col-sm-1 col-1">
                        <input type="checkbox" name="optradio" v-model='form.accept_robinson' v-on:change="hasToBeSaved()" />
                      </label>
                      {{ $t('form-settings-robinson' ) }}
                    </div>

                    <div class="text col-sm-12 col-12 no_side_pad">
                      <label class="col-sm-1 col-1">
                        <input type="checkbox" name="optradio" v-model='form.enrichment' v-on:change="hasToBeSaved()" />
                      </label>
                      {{ $t('form-settings-enrichment' ) }}
                    </div>

                    <div class="text col-sm-12 col-12 no_side_pad">
                      <br>
                      {{ $t('form-settings-save' ) }}

                      <select class="form-control col-lg-3" v-model="form.list" v-on:change="hasToBeSaved()">
                      <option v-for="l in this.lists" :value="l.id" v-bind:key="l._id">{{l.name}}</option>
                      </select>
                    </div>

                    <div class="fileld">
                      &nbsp;
                      <div class="row">
                        <div class="col-lg-offset-4 col-lg-8"><button data-v-8c36869e="" :disabled="!this.hasToBeSent" type="button" class="btn btn-primary btn-lg btn-block add-selection add" v-on:click="saveForm()">{{ $t('save-changes' ) }}</button></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

    </div>


  </div>

</div>
</template>

<script>
import Header from '@/components/Header.vue'

import Footer from '@/components/Footer.vue'

import Sidebar from '@/components/Sidebar.vue'

import axios from 'axios';

export default {
  name: 'lists',
  data() {
    return {
      form_html: "",
      isActive: false,
      isActiveIcon: '<',
      lists: [],
      pendingRequest: false,
      editRow: -1,
      editMode: false,
      loading: true,
      form: {
        name: '',
        enrichment: false,
        list: ''
      },
      formOriginal: null,
      search: {},
      Emails: '',
      processing: [],
      downloaded: false,
      hasToBeSent: false,


    }

  },
  mounted() {
      this.enableInterceptor();
      this.getUserDetails();
      this.updateListData(true);
      this.getProcessingValues();
  },
  components: {

    Header,
    Sidebar,
    Footer

  },
  methods: {

    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        this.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        this.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    hasToBeSaved: function() { //console.log(this.user);
      if (JSON.stringify(this.form) === JSON.stringify(this.formOriginal)) {
        this.hasToBeSent = false;
      } else {
        this.hasToBeSent = true;
      }
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      //console.log(this.isActive);
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },


    getUserDetails: function() {

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };


      var url = '/api/v2/profile';

      this.$http.get(url, config).then((results) => {

        this.user = results.data;


      });
    },

    getCredits: function() {

      return this.user ? this.user.credits : 0;


    },


    hasCards: function() {
      return this.user && this.user.cards.length;
    },

    hasAutoCredits: function() {
      return this.user && this.user.autocredits !== null ? this.user.autocredits : false;
    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },

    areCreditsNeeded: function() {
      return this.processing.processCredits > this.getCredits();
    },

    getBalance: function() {

      return this.user ? this.getCredits() * this.getCostPerCredit() : 0;

    },

    getCostPerCredit: function() {

      return this.processing.costPerCredit;

    },

    getProcessingValues: function() {

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v2/processing';

      this.$http.get(url, config).then((results) => {

        this.processing = results.data;

      });

    },
    //FROM HERE CURRENT LIST AREA FUNCTIONS

    updateListData: function(forced) {
      this.getForm(this.$route.params.name, forced);
    },

    saveForm: function() {
      if (!this.pendingRequest) {
        this.pendingRequest = true;
        this.loading = true;
        var config = {
          headers: {
            "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
            "Content-Type": "application/json"
          }
        };

        var url = '/api/v2/form/' + this.form.id;
        this.$http.post(url, this.form, config).then((results) => {
          this.form = results.data
          this.formOriginal = JSON.parse(JSON.stringify(results.data))
          this.pendingRequest = false
          this.loading = false
        });
      }
    },

    getPeopleLists: function() {

      if (!this.pendingRequest) {
        this.pendingRequest = true
        var config = {
          headers: {
            "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
            "Content-Type": "application/json"
          }
        };


        var url = '/api/v2/lists/people';

        this.$http.get(url, config).then((results) => {

          this.lists = results.data;
          this.pendingRequest = false;
          this.loading = false;

        });


      } else {
        //return lists;
      }

    },

    setList: function(id) {
      this.form.list = id
    },

    getForm: function(name, forced) {

      if (!this.pendingRequest && forced) { //console.log(name);
        this.pendingRequest = true;
        this.loading = true;

        var config = {
          headers: {
            "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
            "Content-Type": "application/json"
          }
        };


        var url = '/api/v2/form/' + name;

        this.$http.get(url, config).then((results) => { //console.log(this.getCurrentReport(results.data));

          this.form = results.data
          this.formOriginal = JSON.parse(JSON.stringify(results.data))
          this.pendingRequest = false
          this.loading = false

          this.getPeopleLists();

        });
      }

    },
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/list.css';
@import '../../assets/css/global.css';
</style>
