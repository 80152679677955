<template>
<div>
  <a name="top"></a>

  <Header />

  <div class="row no_side_margin content">
    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>




      <!-- wizard content start start -->

      <div class="">


        <div class="total-tools">
          <div class="row no_side_margin" v-if="isTechnicalProfile() && this.processor.name">
            <div class="col-12 no_side_pad">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item "><a v-bind:href="'#/tools/'">{{$t('tools')}}</a></li>
                  <li class="breadcrumb-item "><a v-bind:href="'#/tools/category/' + this.processor.groups[0]">{{$t('group-' + this.processor.groups[0] + '-name')}}</a></li>
                  <li class="breadcrumb-item "><a v-bind:href="'#/tools/category/' + this.processor.groups[0]+'/type/' + this.selectedTypeSlug">{{$t(this.selectedType)}}</a></li>

                </ol>
              </nav>
            </div>
          </div>

          <div class="row no_side_margin" v-if="hasProfile() && !isTechnicalProfile() && this.processor.name">
            <div class="col-12 no_side_pad">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item "><a v-bind:href="'#/tools/'" v-on:click="resetProfile()">{{$t('tools')}}</a></li>
                  <li class="breadcrumb-item "><a v-bind:href="'#/tools/'">{{$t("search-profile-" + this.profile)}}</a></li>
                </ol>
              </nav>
            </div>
          </div>





          <div class="row no_side_margin sales-tools" v-if="this.processor.name">
            <div class="col-lg-10  col-sm-12 col-xs-12 no_side_pad">

              <label class="label label-warning" v-if="this.processor.version === 'beta'">beta</label>

              <h2>{{$t("type-"+ this.processor.type + "-verb")}} {{this.processor.description}}</h2>


            </div>
            <!--div class="col-lg-5 col-sm-12 col-xs-12 no_side_pad share-tools" v-if="this.processor.version === 'live'">
              <ul>
                <li>Share tool to friends:</li>
                <li class="share-icon">
                  <a class="hidden-xs" v-if="this.processor.version === 'live'">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#3B5998" />
                      <path d="M15.0163 12.4694H13V19.9998H10V12.5L8.08789 12.4694V9.71256H10V8C10 6.72423 10.208 4.92676 12.875 4.92676L15.2781 4.93681V7.61284H13.5345C13.2485 7.61284 13 7.89142 13 8.5V9.71512H15.2997L15.0163 12.4694Z"
                        fill="white" />
                    </svg>
                  </a>

                </li>
                <li class="share-icon">
                  <a class="hidden-xs" v-if="this.processor.version === 'live'">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#007AB9" />
                      <path
                        d="M19 12.9651V18.001H16.3014V12.9651C16.3014 12.1471 15.8873 11.3462 14.8488 11.3462C14.0562 11.3462 13.5854 11.8791 13.3775 12.395C13.302 12.5794 13.2825 12.8355 13.2825 13.0941V18.001H10.4139C10.4139 18.001 10.4525 9.8102 10.4139 9.00098H13.2827V10.5079C13.2769 10.5175 13.2688 10.5269 13.2637 10.5361H13.2827V10.5079C13.6639 9.92135 14.3437 9.08282 15.8678 9.08282C17.755 9.08282 19 10.3158 19 12.9651ZM7.4132 5.12695C6.43199 5.12695 5.79004 5.77104 5.79004 6.61727C5.79004 7.44554 6.41338 8.10824 7.37555 8.10824H7.39416C8.39462 8.10824 9.01668 7.44554 9.01668 6.61727C8.99764 5.77104 8.39462 5.12695 7.4132 5.12695ZM5.96053 18.001H9.01668V9.00098H5.96053V18.001Z"
                        fill="#F1F2F2" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div-->
          </div>

          <div class="row no_side_margin" v-if="this.processor.name">
            <div class="col-lg-12 discover-valid-num no_side_pad">
              <div class="title-text">
                <p v-html="this.processor.extended_description" class="break-word"></p>
              </div>
              <div class="title-text" v-if="this.processor.linkedin" v-html="$t('linkedin-description')"></div>
              <div class="title-text" v-if="this.processor.lopd" v-html="$t('lopd-description')"></div>
              <div class="total-support">
                <div class="support">
                  <p>{{$t('tool-supports')}}: </p>
                  <div class="icon">
                    <ul>
                      <li>
                        <a href="https://uproc.io" target="_blank" title="uProc">
                          <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M26.3292 8.822C26.3292 21.2607 19.2066 28.1442 7.19465 28.1442C5.05458 28.1442 3.0697 27.9257 1.25265 27.4962C4.2564 34.8318 11.4647 39.9992 19.8794 39.9992C30.9916 39.9992 39.9999 30.9879 39.9999 19.8719C39.9999 11.0126 34.2779 3.49013 26.3292 0.80097V8.822ZM0 16.7467C1.61621 18.8608 4.09002 19.8115 7.25501 19.8115C13.2308 19.8115 16.6714 16.4905 16.6714 8.4597V-0.000976562C8.10064 1.37275 1.33794 8.16129 0 16.7467Z"
                              fill="#0300e2" />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a href="https://uproc.io/uproc_for_sheets" target="_blank" title="Google Sheets">
                          <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M34.4852 10.9559V37.4231C34.4852 38.8465 33.3307 39.9999 31.9084 39.9999H8.09145C6.66809 39.9999 5.51465 38.8465 5.51465 37.4231V2.5768C5.51457 1.15344 6.66801 0 8.09145 0H23.5293L34.4852 10.9559Z"
                              fill="#28B446" />
                            <path d="M25.0244 10.7179L34.4855 14.6156V10.9559L29.1214 9.37207L25.0244 10.7179Z" fill="#219B38" />
                            <path d="M34.4852 10.9559H26.1061C24.6827 10.9559 23.5293 9.80242 23.5293 8.37906V0L34.4852 10.9559Z" fill="#6ACE7C" />
                            <path
                              d="M26.3369 19.917H13.662C13.2073 19.917 12.8389 20.2855 12.8389 20.7401V31.7689C12.8389 32.2235 13.2073 32.5921 13.662 32.5921H26.3369C26.7916 32.5921 27.16 32.2235 27.16 31.7689V20.7401C27.16 20.2856 26.7916 19.917 26.3369 19.917ZM14.4851 25.2089H19.1764V27.3005H14.4851V25.2089ZM20.8225 25.2089H25.5138V27.3005H20.8225V25.2089ZM25.5139 23.5627H20.8226V21.5632H25.5139V23.5627ZM19.1764 21.5632V23.5626H14.4851V21.5632H19.1764ZM14.4851 28.9467H19.1764V30.946H14.4851V28.9467ZM20.8225 30.946V28.9466H25.5138V30.946H20.8225Z"
                              fill="white" />
                          </svg>
                        </a>
                      </li>

                      <li v-if="this.processor.zapier">
                        <a href="https://n8n.io/integrations/n8n-nodes-base.uproc" target="_blank" title="n8n.io">
                          <img width="32" height="32" src="img/n8n.png"  title="n8n.io"/>
                        </a>
                      </li>

                      <li v-if="this.processor.zapier">
                        <a href="https://www.integromat.com/en/integrations/uproc" target="_blank" title="Make">
                          <img width="34" height="34" src="img/integromat.png"  title="Make"/>
                        </a>
                      </li>
                      <li v-if="this.processor.zapier">
                        <a href="https://zapier.com/apps/uproc/integrations" target="_blank" title="Zapier">
                          <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M25 20.0087C24.999 21.4949 24.7273 22.9177 24.232 24.2311C22.9188 24.7268 21.4954 24.9991 20.0086 25.0001H19.9914C18.5054 24.9991 17.0824 24.7276 15.7692 24.2321C15.2738 22.9191 15.001 21.4957 15 20.0091V19.9918C15.001 18.5056 15.2732 17.0828 15.7678 15.7695C17.0816 15.2741 18.5048 15.0015 19.9914 15.0005H20.0086C21.4954 15.0015 22.9188 15.2741 24.2319 15.7695C24.7269 17.0829 24.9989 18.5057 24.9999 19.9919V20.0092V20.009L25 20.0087ZM39.722 16.6667H28.0474L36.3024 8.4117C35.6543 7.50149 34.9314 6.64692 34.1412 5.8569L34.1408 5.8561C33.3514 5.06683 32.4977 4.34466 31.5884 3.6971L23.3334 11.9521V0.2782C22.2353 0.0936923 21.1239 0.000639593 20.0104 0L19.9892 0C18.8759 0.000594715 17.7645 0.0935805 16.6666 0.278V11.9527L8.4116 3.6977C7.50189 4.34549 6.64776 5.06799 5.8581 5.8577L5.8551 5.8604C5.06652 6.64947 4.34499 7.50282 3.698 8.4116L11.953 16.6666H0.278C0.278 16.6666 0.0004 18.8596 0 19.9926V20.0071C0.000416602 21.1217 0.0934028 22.2344 0.278 23.3337H11.9526L3.6976 31.5887C4.99578 33.4113 6.58899 35.0045 8.4116 36.3027L16.6666 28.0479V39.722C17.7635 39.9062 18.8738 39.9992 19.986 40H20.0146C21.1267 39.9991 22.2368 39.9061 23.3336 39.722V28.0473L31.5886 36.3023C32.498 35.6547 33.3519 34.9325 34.1414 34.1431L34.1434 34.1416C34.9326 33.3521 35.6548 32.4982 36.3024 31.5889L28.0474 23.3337H39.722C39.9062 22.2369 39.9992 21.1268 40 20.0147V19.986C39.9991 18.8739 39.9061 17.7638 39.722 16.667V16.6667Z"
                              fill="#FF4A00" />
                          </svg>
                        </a>
                      </li>



                      <li v-if="this.processor.zapier">
                        <a href="https://www.appypie.com/connect/apps/uproc/integrations" target="_blank" title="AppyPie"><img width="36" height="36" src="@/assets/img/appypie.png" alt="AppyPie"></a>
                      </li>

                      <li v-if="this.processor.wordpress">
                        <a href="https://uproc.io/uproc_for_wordpress" target="_blank" title="Wordpress">
                          <img src="@/assets/img/wordpress.svg" width="32" height="32">
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>

                <div class="output-parms">
                  <p>{{$t('input-params')}}:</p>
                  <div class="btns" v-if="this.processor.params && this.processor.params.length">
                    <button type="button" class="btn-output" v-for="param in this.processor.params" v-bind:key="param.name">{{param.name}}</button>
                  </div>
                  <br>
                </div>

                <div class="output-parms">
                  <p>{{$t('output-params')}}<span v-if="this.processor.output && !this.isOutputExpanded && this.processor.output[0].properties.length"> (<a v-on:click="isOutputExpanded = true">+</a>)</span>
                    <span v-if="this.processor.output && this.isOutputExpanded && this.processor.output[0].properties.length"> (<a v-on:click="isOutputExpanded = false">-</a>)</span>:</p>
                  <div class="btns" v-if="processor.output &&  !this.isOutputExpanded">

                    <div v-if="processor.output[0].properties.length">
                      <button type="button" class="btn-output" v-for="property in this.processor.output[0].properties" v-bind:key="property.name">{{property.name}}</button>
                    </div>
                    <button v-if="!processor.output.length || !processor.output[0].properties.length"  type="button" class="btn-output">result</button>
                  </div>

                  <div class="btns" v-if="this.isOutputExpanded">

                    <ul class="list" v-if="processor.output[0].properties.length">
                      <li class="dispy-blo" v-for="property in orderBy(processor.output[0].properties, 'name')" v-bind:key="property.name"><strong style="font-weight: bold">{{property.name}}:</strong> <span v-html="property.description || property.name"></span></li>
                    </ul>

                    <ul class="list" v-if="!processor.output.length || !processor.output[0].properties.length">
                      <li><strong style="font-weight: bold">result:</strong> {{$t('property-result')}}</li>
                    </ul>

                  </div>
                </div>



              </div>
              <div class="total-support">
                <h4>{{$t('need-help')}}</h4>
                <div v-if="!this.processor.videos || !this.processor.videos.sheets || !this.processor.videos.sheets.length" v-html="$t('processor-view-more', {url: this.processor.blog_url})"></div>
                <div v-if="this.processor.videos && this.processor.videos.sheets && this.processor.videos.sheets.length" v-html="$t('processor-view-video', {url: this.processor.blog_url, url2: this.processor.video_url})"></div>
                <div class="video-responsive" v-if="videoShown && this.processor.video_url && this.processor.video_url.length > 0"><iframe width="100%" height="720" :src="this.processor.video_url" frameborder="0" allowfullscreen="allowfullscreen"></iframe></div>
              </div>
            </div>

            <div class="col-lg-12 live-test no_side_pad">
              <h3>{{$t('test-live')}}</h3>
              <div class="wrap ">
                <div class="col-lg-12 total-test no_side_pad">
                  <div class="title-text">{{$t('test-complete-params')}}</div>
                </div>
                <div class="col-lg-12 test-area">
                  <div class="row" v-for='(param , indexed) in this.processor.params' v-bind:key="param.name">
                    <div class="col-lg-2 domain no_side_pad">{{param.name}}<span v-if="param.required">*</span></div>
                    <div class="col-lg-7 field no_side_pad">
                      <select v-if="param.accepted.length" class="form-control" type="text" v-model="param.value" v-on:click="monitorParams();" v-bind:id="'paramValue_'+indexed">
                        <option value="">{{$t('select-option')}}</option>
                        <option v-for="value in param.accepted" :value="value" v-bind:key="value">{{value}}</option>
                      </select>
                      <input type="text" class="form-control" v-if="!param.accepted.length" v-model="param.value" v-on:keyup="monitorParams()" v-on:keyup.enter="checkValue()" v-on:click="$event.target.select()" v-on:change="monitorParams()"
                        v-on:focusout="monitorParams()">
                      <div class="clickable-link break-word" v-if="param.ok_sample.indexOf('{') === -1">{{$t('sample')}}: <span  v-for="(value) in param.ok_sample.split('|')" v-bind:key="value">&nbsp;<a class="clickableWrap"
                            v-on:click="param.value = value + ''; monitorParams()">{{value}}</a>&nbsp;</span></div>
                      <div class="clickable-link break-word" v-if="param.ok_sample.indexOf('{') !== -1">{{$t('sample')}}: <span v-bind:key="value">&nbsp;<a class="clickableWrap"
                            v-on:click="param.value = param.ok_sample + ''; monitorParams()">{{param.ok_sample}}</a>&nbsp;</span></div>
                    </div>

                  </div>
                  <br><br>
                  <div class="row">
                    <div class="col-lg-2 domain no_side_pad">{{$t('test-webhook')}}</div>
                    <div class="col-lg-7 field no_side_pad"><input type="text" class="form-control" v-model="callback" v-on:keyup="monitorParams()" v-on:click="$event.target.select()" v-on:change="monitorParams()"
                        v-on:focusout="monitorParams()" placeholder="https://uproc.free.beeceptor.com">
                        <small class="help" v-html="$t('test-webhook-help')"></small>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12 btn-holder no_side_pad">
                      <button type="button" v-bind:class="{'notDisabled': isDisabledC}" class="test-btn" v-on:click="checkValue()" v-bind:disabled="isDisabledCD">{{$t('test-now')}}</button>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 test-result" v-if="this.isSubmitted">
                  <div class="row">
                    <div class="col-lg-3 col-md-3 col-5">
                      <h3>{{$t('results')}}</h3>
                    </div>
                    <div class="col-lg-6 col-md-4 no_side_pad col-7">
                      <div class="tools-apply result-toggle">
                        <div class="switch switch-blue">
                          <input type="radio" class="switch-input input-one" name="view2" value="week2" id="week2" checked v-on:click="toggleDataMode('table')">
                          <label for="week2" class="switch-label switch-label-off">{{$t('result-view')}}</label>
                          <input type="radio" class="switch-input input-two" name="view2" value="month2" id="month2" v-on:click="toggleDataMode('code')">
                          <label for="month2" class="switch-label switch-label-on">{{$t('request-view')}}</label>
                          <span class="switch-selection"></span>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="row" v-if="mode === 'table'">
                    <div class="col-lg-4">
                      <div class="result-item">
                        <div class="catgry">{{$t('status')}}</div>
                        <h5 v-bind:class="{'text-success': this.data.result, 'text-danger': !this.data.result}">{{this.data.result}}</h5>
                        <div class="icon">
                          <font-awesome-icon :icon="['fas', 'info-circle']" size="sm" :title="$t('tool-result-tooltip')" />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="result-item">
                        <div class="catgry">{{$t('rows')}}</div>
                        <h5 v-bind:class="{'text-success': this.data.total_rows !== 0, 'text-danger': !this.data.total_rows === 0}">{{this.data.total_rows}}</h5>
                        <div class="icon">
                          <font-awesome-icon :icon="['fas', 'info-circle']" size="sm" :title="$t('tool-rows-tooltip')" />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="result-item">
                        <div class="catgry">{{$t('price')}}</div>
                        <h5 v-bind:class="{'text-success': this.data.total_rows !== 0, 'text-danger': !this.data.total_rows === 0}">{{(this.data.total_rows > 0 ? this.data.real_price : 0) }}<span>€</span></h5>
                        <div class="icon">
                          <font-awesome-icon :icon="['fas', 'info-circle']" size="sm" :title="$t('tool-price-tooltip')" />
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

                <div class="col-lg-12 Output-params overflow-auto" v-if="this.isSubmitted && mode === 'table'">
                  <h3>{{$t('output-params')}}:</h3>
                  <div>
                    <span v-if="processor && processor.output[0].type === 'object' && !processing.receivedData.callback">
                      <span class="row property-result" v-for="property in orderBy(processor.output[0].properties, 'name')" v-bind:key="property.name">
                        <strong class="col-lg-3 col-xs-12 text-primary" v-bind:title="property.description || property.name">{{property.name}}</strong>
                        <span class="col-lg-8 col-xs-12 overflow-auto">{{message[property.name]}}&nbsp;</span>
                      </span>
                    </span>
                    <span class="row" v-if="processor && processor.output[0].type === 'string' && !processing.receivedData.callback">
                      <strong class="text-primary col-lg-3 col-xs-12" :title="$t('property-result-description')">result</strong>
                      <span v-if="!isUrl" class="col-lg-8 col-xs-12" v-bind:class="{'angular-with-newlines scroll-y-processor': needsMultiline}">{{message['result']}}&nbsp;</span>
                      <a v-if="isUrl && !isImage && !isAudio" class="col-lg-8 col-xs-12" v-bind:href="resultUri" target="_blank">{{message['result']}}</a>
                      <div v-if="isUrl && isAudio" class="col-lg-8 col-xs-12">
                        <audio autoplay controls :src="message['result']">
                          <a :href="message['result']" target="_blank">download audio</a>
                        </audio>
                        <br>
                        <small>{{$t('tool-audio-turn-on')}}</small>
                      </div>
                      <div v-if="isUrl && isImage && !isQrImage" class="scroll-y-image text-center col-lg-12 ">
                        <img v-bind:src="resultUri" style="max-width: 100%">
                      </div>
                      <div class="col-lg-12 text-center" v-if="isUrl && isImage && isQrImage">
                        <img v-bind:src="resultUri">
                      </div>
                    </span>
                    <span v-if="processing.receivedData && processing.receivedData.callback">
                      <strong class="col-lg-3 col-xs-12 text-primary">result</strong>
                      <span class="col-lg-8 col-xs-12 overflow-auto">{{processing.receivedData.message.result}}</span>
                    </span>
                    <table class="table" style="margin-top: 17px;" v-if="processor && processor.output[0].type === 'array' && !processing.receivedData.callback">
                      <thead>
                        <tr>
                          <th v-for="prop in processor.output[0].properties" class="table-col" style="width: 25%; color:#0300e2;" v-bind:key="prop.name"><span v-bind:title="prop.description">{{prop.name}}</span></th>
                        </tr>
                      </thead>
                      <tbody v-if="message.length && !processing.receivedData.callback">
                        <tr v-for="(row, index) in message" v-bind:key="index">
                          <td class="table-col" v-for="prop in processor.output[0].properties" v-bind:key="prop.name">{{row[prop.name]}}</td>
                        </tr>
                      </tbody>
                      <tbody v-if="!message.length">
                        <tr>
                          <td class="table-co" v-bind:colspan="processor.output[0].properties.length">{{$t('no-results')}}</td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                </div>

                <div class="col-lg-12 Output-params" v-if="this.isSubmitted && mode === 'code'">

                  <div class="col-lg-12">
                    <span>{{$t('api-request')}}</span>
                    <p>
                      <code class="small ng-binding">curl -X POST -u "{{getEmail()}}:{{apiKey}}" -H "Content-Type: application/json" -d '{"processor": "{{processor.key}}", "params": {{response.params}}{{callbackStr}} }'
                        {{getApiEndpoint()}}</code>
                    </p>
                  </div>
                  <div class="col-lg-12">
                    <br>
                    <span>{{$t('api-response')}}</span><br>
                    <p>
                      <pre class="small ng-binding">{{response }}</pre>
                      <!--| json-->
                    </p>
                  </div>

                </div>

                <div class="col-lg-12 msg" v-if="this.isSubmitted">
                  <p>{{$t('result-ko')}}</p>
                  <input v-if="!apiKey" type="text" alt="Optional email" title="Optional email" v-model="this.email" placeholder="Optional email" class="form-control">
                  <textarea type="text" alt="Reason" title="Reason" v-model="reason" :placeholder="$t('tool-feedback-why')" class="form-control"></textarea>

                  <img class="img" v-if="this.sending" src="images/loading.gif" height="64" width="64">
                  <div class="col-lg-12" v-if="!this.sending && this.received">
                    <div v-if="!this.feedbackResult" class="msg-dt">{{$t('contact-ko')}}</div>
                    <div v-if="this.feedbackResult" class="msg-dt">{{$t('contact-ok')}}</div>
                  </div>

                  <button type="button" class="send-btn" v-if="reason.length > 0" v-on:click="!sending && sendFeedback()">{{$t('send')}}</button>
                </div>


              </div>
            </div>

            <div class="col-lg-12 cost no_side_pad" v-if="loginChecker()">
              <h3>{{$t('cost')}}</h3>

              <div class="wrap " v-if="this.processor.tiers">

                <div class="col-lg-12 total-cost no_side_pad" v-if="!this.processor.tiers.length && this.processor.max_cost > 0">
                  <span>{{(this.processor.max_cost * this.processing.costPerCredit).toFixed(4) }}€/{{$t('cost-unit')}} {{$t('cost-unit-or')}} {{parseInt(this.processor.max_cost * this.processing.costPerCredit * 10000, 10)/10 }}€/cpm</span>
                </div>

                <!--div class="col-lg-12 no_side_pad" v-if="!this.processor.tiers.length && this.processor.max_cost > 0">
                  <span>Choose your pack to get the desired amount of queries  </span>
                </div-->

                <div class="col-lg-12 total-cost no_side_pad" v-if="!this.processor.tiers.length && this.processor.max_cost === 0">
                  <span>n/a</span>
                </div>

                <div class="col-lg-12 total-cost no_side_pad" v-if="this.processor.tiers.length && this.processor.max_cost > 0">
                  <span>{{(this.processor.max_cost * this.processing.costPerCredit).toFixed(4) }}€/{{$t('cost-unit')}} - {{(this.processor.min_cost * this.processing.costPerCredit).toFixed(4) }}€/{{$t('cost-unit')}}
                    {{parseInt(this.processor.max_cost * this.processing.costPerCredit * 1000, 10) }}€/cpm - {{parseInt(this.processor.min_cost * this.processing.costPerCredit * 1000, 10) }}€/cpm

                  </span>


                </div>
              </div>
            </div>

            <div class="col-lg-12 text-center" v-if="loginChecker()">
              <a v-bind:href="'/#/wizard/process/Files/Upload/' + this.processor.key" class="btn btn-primary btn-lg btn-block">{{$t('process-file-now')}}</a>
              <a v-on:click="downloadTemplate()" class="pointer">{{$t('process-get-template')}}</a>
            </div>

            <div class="col-lg-12 api-inform" v-if="!apiShown && isTechnicalProfile()">
              <button v-on:click="apiShownTrigger()" data-v-8c36869e="" type="button" class="btn btn-primary btn-lg btn-block api-btn">{{$t('show-api-integration')}}</button>
            </div>

            <div class="btn btn-default btn-primary" v-if="!isTechnicalProfile()" v-on:click="setTechnicalProfile()">{{$t('show-tool-details')}}</div>


            <div class="col-lg-12 api-details no_side_pad" v-if="this.apiShown">
              <h3>API</h3>
              <div class="total-wrap col-lg-12 no_side_pad">
                <div class="btn-group col-lg-12 no_side_pad">
                  <a class="btn btn-primary dropdown-toggle select-country" data-toggle="dropdown" href="#">{{this.languageModeText}}<span class="caret"></span></a>
                  <ul class="dropdown-menu">
                    <li class="dropdown-item"><a v-on:click="languageModeChange('Curl')">Curl</a></li>
                    <li class="dropdown-item"><a v-on:click="languageModeChange('CSharp')">CSharp</a></li>
                    <li class="dropdown-item"><a v-on:click="languageModeChange('Go')">Go</a></li>
                    <li class="dropdown-item"><a v-on:click="languageModeChange('GoogleSheets')">GoogleSheets</a></li>
                    <li class="dropdown-item"><a v-on:click="languageModeChange('Java')">Java</a></li>
                    <li class="dropdown-item"><a v-on:click="languageModeChange('JQuery')">JQuery</a></li>
                    <li class="dropdown-item"><a v-on:click="languageModeChange('NodeJs')">NodeJs</a></li>
                    <li class="dropdown-item"><a v-on:click="languageModeChange('Php')">Php</a></li>
                    <li class="dropdown-item"><a v-on:click="languageModeChange('Python')">Python</a></li>
                    <li class="dropdown-item"><a v-on:click="languageModeChange('Ruby')">Ruby</a></li>
                  </ul>
                </div>
                <div class="cntnt-text" v-html="$t('api-description')"></div>

                <div class="col-lg-12 no_side_pad total-api-tabs">
                  <ul class="nav nav-tabs d_tab">
                    <li class="nav-item active" v-bind:class="{'active': this.apiMethod === 'one'}" v-on:click="apiMethodChange('one')"><a data-toggle="tab" href="#home" class="nav-link active">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M5 3H13.7071L19 8.29289V21H5V3ZM6 4V20H18V9H13V4H6ZM14 4.70711L17.2929 8H14V4.70711Z" fill="" />
                        </svg>
                        One</a>
                    </li>
                    <li class="nav-item" v-bind:class="{'active': this.apiMethod === 'multiple'}" v-if="this.languageMode !== 'googlesheets'" v-on:click="apiMethodChange('multiple')"><a data-toggle="tab" href="#menu1" class="nav-link">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M16.5 20.5H4.5C3.94772 20.5 3.5 20.0523 3.5 19.5V4L6 6L8 4L10 6L12 4L14 6L16.5 4V9.5M16.5 20.5V9.5M16.5 20.5H17.5C19.1569 20.5 20.5 19.1569 20.5 17.5V9.5H16.5M6 9.5H14M6 12.5H12M6 15.5H14" stroke="" />
                        </svg>
                        Multiple</a></li>

                    <li class="nav-item" v-bind:class="{'active': this.apiMethod === 'stream'}" v-if="this.languageMode !== 'googlesheets'" v-on:click="apiMethodChange('stream')"><a data-toggle="tab" href="#stream" class="nav-link">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.64652 7.64636L7.35363 8.35346L3.70718 11.9999L7.35363 15.6464L6.64652 16.3535L2.29297 11.9999L6.64652 7.64636Z" fill="" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4744 3.15802L9.47442 21.158L8.52573 20.8418L14.5257 2.8418L15.4744 3.15802Z" fill="" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3536 7.64636L16.6465 8.35346L20.293 11.9999L16.6465 15.6464L17.3536 16.3535L21.7072 11.9999L17.3536 7.64636Z" fill="" />
                        </svg>
                        Stream</a></li>
                  </ul>
                  <div class="tab-content">

                    <div id="home" class="tab-pane container active">
                      <div class="row smry-status no_side_margin">
                        <div class="col-lg-12 no_side_pad">



                          <div class="col-lg-12">
                            <br><span translate>{{$t('use')}}</span>
                            <pre><code v-if="this.languageMode === 'curl'" class="small ng-binding">curl -X POST -u "{{getEmail()}}:{{apiKey}}" -H "Content-Type: application/json" -d '{{processor.code.one.json}}' {{getApiEndpoint()}}</code><code v-if="this.languageMode === 'nodejs'" class="small ng-binding">var request = require("request");
var auth = new Buffer('{{getEmail()}}:{{apiKey}}').toString("base64");
var options = {
 method: 'POST',
 url: '{{getApiEndpoint()}}',
 headers: {
    'content-type': 'application/json',
    'authorization': 'Basic ' + auth
  },
  json: {{processor.code.one.json}}
};

request(options, function (error, response, body) {
  if (error) {throw new Error(error);}
  console.log(body.result);
  console.log(body.message);
});</code><code v-if="this.languageMode === 'jquery'" class="small ng-binding">var auth = btoa("{{getEmail()}}:{{apiKey}}");

$.ajax({
  type: "POST",
  headers: {
    'authorization': 'Basic ' + auth
  },
  url: "{{getApiEndpoint()}}",
  data: {{processor.code.one.json}}
})
.done(function(body) {
  console.log(body.result);
  console.log(body.message);
});</code><code v-if="this.languageMode === 'go'" class="small ng-binding">
package main
import (
    "net/http"
    "fmt"
    "bytes"
    "io/ioutil"
)

func main() {
  url := "{{getApiEndpoint()}}"
  fmt.Println("URL:>", url)

  var jsonStr = []byte(`{{processor.code.one.json}}`)
  req, err := http.NewRequest("POST", url, bytes.NewBuffer(jsonStr))
  req.Header.Set("Content-Type", "application/json")
  req.SetBasicAuth("{{getEmail()}}", "{{apiKey}}")

  client := &http.Client{}
  resp, err := client.Do(req)
  if err != nil {
      panic(err)
  }
  defer resp.Body.Close()

  fmt.Println("response Status:", resp.Status)
  fmt.Println("response Headers:", resp.Header)
  body, _ := ioutil.ReadAll(resp.Body)
  fmt.Println("response Body:", string(body))
}
</code><code v-if="this.languageMode === 'googlesheets'" class="small ng-binding">/**
Please, add next formula to your cell: "={{processor.key.replace(/-/g, "")}}({{prepareGoogleSheetsParametersFormula()}})"
**/
function {{processor.key.replace(/-/g, "")}}({{prepareGoogleSheetsParameters()}}) {
  var data = {{prepareGoogleSheetsJson()}};
  var options = {
    'url': '{{getApiEndpoint()}}',
    'method': 'post',
    'contentType': 'application/json',
    'headers': {
      'authorization': "Basic " + Utilities.base64Encode('{{getEmail()}}' + ":" + '{{apiKey}}'),
    },
    'muteHttpExceptions': true,
    'payload': JSON.stringify(data)
  };
  var request = UrlFetchApp.fetch(options.url, options);
  var response = request.getContentText();
  var obj = JSON.parse(response);
  var data = obj.message;
  return data;
}
</code><code v-if="this.languageMode === 'php'" class="small ng-binding">&lt;?php
$auth = base64_encode('{{getEmail()}}:{{apiKey}}');
$data_string = json_decode(json_encode('{{processor.code.one.json}}'), true);

$curl = curl_init();
curl_setopt_array($curl, array(
   CURLOPT_URL =&gt; "{{getApiEndpoint()}}",
   CURLOPT_RETURNTRANSFER =&gt; true,
   CURLOPT_TIMEOUT =&gt; 10,
   CURLOPT_FAILONERROR =&gt; true,
   CURLOPT_HTTP_VERSION =&gt; CURL_HTTP_VERSION_1_1,
   CURLOPT_SSL_VERIFYPEER =&gt; false,
   CURLOPT_POST =&gt; true,
   CURLOPT_POSTFIELDS =&gt; $data_string,
   CURLOPT_HTTPHEADER =&gt; array(
     "Authorization: Basic " . $auth,
     "Content-Type: application/json",
     "Content-Length: " . strlen($data_string)
  )
));

$response = curl_exec($curl);
$err = curl_error($curl);
curl_close($curl);
if ($err) {
  echo "cURL Error #:" . $err;
} else {
  $obj = json_decode($response);
  var_dump($obj);
}</code><code v-if="this.languageMode === 'python'" class="small ng-binding">import requests
import simplejson as json

url = "{{getApiEndpoint()}}"
data = {{processor.code.one.json}}
headers = {'Content-type': 'application/json'}
r = requests.post(url, data=json.dumps(data), headers=headers, auth=('{{getEmail()}}', '{{apiKey}}'))
data = r.content
obj = json.loads(r.content)
print obj['result']
print obj['message']</code><code v-if="this.languageMode === 'csharp'" class="small ng-binding">var client = new RestClient("{{getApiEndpoint()}}");
var request = new RestRequest(Method.POST);
request.AddHeader("content-type", "application/json");
request.AddHeader("accept", "application/json");
request.DefaultRequestHeaders.Authorization =
    new AuthenticationHeaderValue(
        "Basic",
        Convert.ToBase64String(
            System.Text.ASCIIEncoding.ASCII.GetBytes(
                string.Format("{0}:{1}", "{{getEmail()}}", "{{apiKey}}"))));
request.AddParameter("application/json", new JavaScriptSerializer().Serialize({{processor.code.one.json}}), ParameterType.RequestBody);
IRestResponse response = client.Execute(request);
print response.Content;</code><code v-if="this.languageMode === 'ruby'" class="small ng-binding">require 'net/http'
require 'openssl'
require 'json'

uri = URI('{{getApiEndpoint()}}')
http = Net::HTTP.new(uri.host, uri.port, OpenSSL::SSL::VERIFY_NONE)
req = Net::HTTP::Post.new(uri.path, 'Content-Type' =&gt; 'application/json')
req.basic_auth '{{getEmail()}}', '{{apiKey}}'
req.body = '{{processor.code.one.json}}'
res = http.request(req)
obj = JSON.parse(res.body)

puts obj['result']
puts obj['message']</code><code v-if="this.languageMode === 'java'" class="small ng-binding">/*
 * Download next jars and add to your project:
 * okhttp3: http://repo1.maven.org/maven2/com/squareup/okhttp3/okhttp/3.9.1/okhttp-3.9.1.jar
 * okio: https://search.maven.org/remote_content?g=com.squareup.okio&a=okio&v=LATEST
 */
import okhttp3.*;

public class TestVFCons {
	public static void main(String []args) throws Exception {
		OkHttpClient client = new OkHttpClient();
		String credential = Credentials.basic("{{getEmail()}}", "{{apiKey}}");
		MediaType mediaType = MediaType.parse("application/json");
		String json = "{{prepareJavaString(processor.code.one.json)}}";
		RequestBody body = RequestBody.create(mediaType, json);
		Request request = new Request.Builder()
    .url("{{getApiEndpoint()}}")
    .post(body)
    .addHeader("authorization", credential)
    .addHeader("content-type", "application/json")
    .build();

		Response response = client.newCall(request).execute();
		System.out.println(response.body().string());
	}
}
</code></pre>
                          </div>
                          <div v-if="!apiKey" class="col-lg-12">
                            <p class="text-warning" translate>Attention: <a href='#/login'>Register or Login</a> into the system if you want to get real data in the CURL request!</p>
                          </div>





                        </div>
                      </div>
                    </div>

                    <div id="menu1" class="container tab-pane fade">
                      <div class="row no_side_margin">

                        <div v-if="!this.apiKey && this.isMultipleRowsProcessor()" class="col-lg-12">
                          <p class="text-warning" translate>Attention: This method is not supported!</p>
                        </div>

                        <div v-if="!this.apiKey && !this.isMultipleRowsProcessor()" class="col-lg-12">
                          <p class="text-warning" translate>Attention: <a href='#/login'>Register or Login</a> into the system to use this method!</p>
                        </div>
                        <div v-if="this.apiKey && this.isMultipleRowsProcessor()" class="col-lg-12">
                          <p class="text-warning" translate>Attention: This method is not supported!</p>
                        </div>

                        <div v-if="this.apiKey && !this.isMultipleRowsProcessor()" class="col-lg-12">
                          <br><span translate>{{$t('use')}}</span>
                          <pre><code v-if="this.languageMode === 'curl'" class="small ng-binding">curl -X POST -u "{{getEmail()}}:{{apiKey}}" -H "Content-Type: application/json" -d '{{processor.code.multiple.json}}' {{getApiEndpoint()}}/multiple
</code><code v-if="this.languageMode === 'go'" class="small ng-binding">
package main
import (
    "net/http"
    "fmt"
    "bytes"
    "io/ioutil"
)

func main() {
  url := "{{getApiEndpoint()}}/multiple"
  fmt.Println("URL:>", url)

  var jsonStr = []byte(`{{processor.code.multiple.json}}`)
  req, err := http.NewRequest("POST", url, bytes.NewBuffer(jsonStr))
  req.Header.Set("Content-Type", "application/json")
  req.SetBasicAuth("{{getEmail()}}", "{{apiKey}}")

  client := &http.Client{}
  resp, err := client.Do(req)
  if err != nil {
      panic(err)
  }
  defer resp.Body.Close()

  fmt.Println("response Status:", resp.Status)
  fmt.Println("response Headers:", resp.Header)
  body, _ := ioutil.ReadAll(resp.Body)
  fmt.Println("response Body:", string(body))
}
</code><code v-if="this.languageMode === 'nodejs'" class="small ng-binding ng-hide">var request = require("request");
var auth = new Buffer('{{getEmail()}}:{{apiKey}}').toString("base64");
var options = {
 method: 'POST',
 url: '{{getApiEndpoint()}}/multiple',
 headers: {
    'content-type': 'application/json',
    'authorization': 'Basic ' + auth
  },
  json: {{processor.code.multiple.json}}
};

request(options, function (error, response, body) {
  if (error) {throw new Error(error);}
  console.log(body.result);
  console.log(body.message);
});
</code><code v-if="this.languageMode === 'jquery'" class="small ng-binding">var auth = btoa("{{getEmail()}}:{{apiKey}}");

$.ajax({
  type: "POST",
  headers: {
    'authorization': 'Basic ' + auth
  },
  url: "{{getApiEndpoint()}}",
  data: {{processor.code.multiple.json}}
})
.done(function(body) {
  console.log(body.result);
  console.log(body.message);
});</code><code v-if="this.languageMode === 'php'" class="small ng-binding ng-hide">&lt;?php
$auth = base64_encode('{{getEmail()}}:{{apiKey}}');
$data_string = json_decode(json_encode('{{processor.code.multiple.json}}'), true);

$curl = curl_init();
curl_setopt_array($curl, array(
   CURLOPT_URL =&gt; "{{getApiEndpoint()}}/multiple",
   CURLOPT_RETURNTRANSFER =&gt; true,
   CURLOPT_TIMEOUT =&gt; 10,
   CURLOPT_FAILONERROR =&gt; true,
   CURLOPT_HTTP_VERSION =&gt; CURL_HTTP_VERSION_1_1,
   CURLOPT_SSL_VERIFYPEER =&gt; false,
   CURLOPT_POST =&gt; true,
   CURLOPT_POSTFIELDS =&gt; $data_string,
   CURLOPT_HTTPHEADER =&gt; array(
     "Authorization: Basic " . $auth,
     "Content-Type: application/json",
     "Content-Length: " . strlen($data_string)
  )
));

$response = curl_exec($curl);
$err = curl_error($curl);
curl_close($curl);
if ($err) {
  echo "cURL Error #:" . $err;
} else {
  $obj = json_decode($response);
  var_dump($obj);
}
</code><code v-if="this.languageMode === 'python'" class="small ng-binding ng-hide">import requests
import simplejson as json

url = "{{getApiEndpoint()}}/multiple"
data = {{processor.code.multiple.json}}
headers = {'Content-type': 'application/json'}
r = requests.post(url, data=json.dumps(data), headers=headers, auth=('{{getEmail()}}', '{{apiKey}}'))
obj = json.loads(r.content)
print obj['result']
print obj['message']</code><code v-if="this.languageMode === 'csharp'" class="small ng-binding">var client = new RestClient("{{getApiEndpoint()}}/multiple");
var request = new RestRequest(Method.POST);
request.AddHeader("content-type", "application/json");
request.AddHeader("accept", "application/json");
request.DefaultRequestHeaders.Authorization =
    new AuthenticationHeaderValue(
        "Basic",
        Convert.ToBase64String(
            System.Text.ASCIIEncoding.ASCII.GetBytes(
                string.Format("{0}:{1}", "{{getEmail()}}", "{{apiKey}}"))));
request.AddParameter("application/json", new JavaScriptSerializer().Serialize({{processor.code.multiple.json}}), ParameterType.RequestBody);
IRestResponse response = client.Execute(request);
print response.Content;</code><code v-if="this.languageMode === 'ruby'" class="small ng-binding ng-hide">require 'net/http'
require 'openssl'
require 'json'

uri = URI('{{getApiEndpoint()}}/multiple')
http = Net::HTTP.new(uri.host, uri.port, OpenSSL::SSL::VERIFY_NONE)
req = Net::HTTP::Post.new(uri.path, 'Content-Type' =&gt; 'application/json')
req.basic_auth '{{getEmail()}}', '{{apiKey}}'
req.body = '{{processor.code.multiple.json}}'
res = http.request(req)
obj = JSON.parse(res.body)

puts obj['result']
puts obj['message']</code><code v-if="this.languageMode === 'java'" class="small ng-binding">/*
 * Download next jars and add to your project:
 * okhttp3: http://repo1.maven.org/maven2/com/squareup/okhttp3/okhttp/3.9.1/okhttp-3.9.1.jar
 * okio: https://search.maven.org/remote_content?g=com.squareup.okio&a=okio&v=LATEST
 */
import okhttp3.*;

public class TestVFCons {
  public static void main(String []args) throws Exception {
    OkHttpClient client = new OkHttpClient();
    String credential = Credentials.basic("{{getEmail()}}", "{{apiKey}}");
    MediaType mediaType = MediaType.parse("application/json");
    String json = "{{prepareJavaString(processor.code.multiple.json)}}";
    RequestBody body = RequestBody.create(mediaType, json);
    Request request = new Request.Builder()
      .url("{{getApiEndpoint()}}")
      .post(body)
      .addHeader("authorization", credential)
      .addHeader("content-type", "application/json")
      .build();

    Response response = client.newCall(request).execute();
    System.out.println(response.body().string());
  }
}
</code>
</pre>
                        </div>





                      </div>
                    </div>

                    <div id="stream" class="container tab-pane fade">
                      <div class="row no_side_margin">
                        <div v-if="!this.apiKey && this.isMultipleRowsProcessor()" class="col-lg-12">
                          <p class="text-warning">Attention: This method is not supported!</p>
                        </div>
                        <div v-if="!this.apiKey && !this.isMultipleRowsProcessor()" class="col-lg-12">
                          <p class="text-warning">Attention: <a href='#/login'>Register or Login</a> into the system to use this method!</p>
                        </div>
                        <div v-if="this.apiKey && this.isMultipleRowsProcessor()" class="col-lg-12">
                          <p class="text-warning">Attention: This method is not supported!</p>
                        </div>
                        <div v-if="this.apiKey && !this.isMultipleRowsProcessor()" class="col-lg-12">
                          <br><span translate>{{$t('use')}}</span>
                          <pre v-if="this.apiKey" class=""><code v-if="this.languageMode === 'curl'" class="small ng-binding">curl -X POST -u "{{getEmail()}}:{{apiKey}}" -H "Content-Type: application/json" -d '{{processor.code.stream.json}}' {{getApiEndpoint()}}/stream</code><code v-if="this.languageMode === 'nodejs'" class="small ng-binding ng-hide">var request = require("request");
var auth = new Buffer('{{getEmail()}}:{{apiKey}}').toString("base64");
var options = {
 method: 'POST',
 url: '{{getApiEndpoint()}}/stream',
 headers: {
    'content-type': 'application/json',
    'authorization': 'Basic ' + auth
  },
  json: {{processor.code.stream.json}}
};

request(options, function (error, response, body) {
  if (error) {throw new Error(error);}
  console.log(body.result);
  console.log(body.message);
});</code><code v-if="this.languageMode === 'jquery'" class="small ng-binding">var auth = btoa("{{getEmail()}}:{{apiKey}}");

$.ajax({
  type: "POST",
  headers: {
    'authorization': 'Basic ' + auth
  },
  url: "{{getApiEndpoint()}}",
  data: {{processor.code.stream.json}}
})
.done(function(body) {
  console.log(body.result);
  console.log(body.message);
});</code><code v-if="this.languageMode === 'go'" class="small ng-binding">
package main
import (
    "net/http"
    "fmt"
    "bytes"
    "io/ioutil"
)

func main() {
  url := "{{getApiEndpoint()}}/stream"
  fmt.Println("URL:>", url)

  var jsonStr = []byte(`{{processor.code.stream.json}}`)
  req, err := http.NewRequest("POST", url, bytes.NewBuffer(jsonStr))
  req.Header.Set("Content-Type", "application/json")
  req.SetBasicAuth("{{getEmail()}}", "{{apiKey}}")

  client := &http.Client{}
  resp, err := client.Do(req)
  if err != nil {
      panic(err)
  }
  defer resp.Body.Close()

  fmt.Println("response Status:", resp.Status)
  fmt.Println("response Headers:", resp.Header)
  body, _ := ioutil.ReadAll(resp.Body)
  fmt.Println("response Body:", string(body))
}
</code><code v-if="this.languageMode === 'php'" class="small ng-binding ng-hide">&lt;?php
$auth = base64_encode('{{getEmail()}}:{{apiKey}}');
$data_string = json_decode(json_encode('{{processor.code.stream.json}}'), true);

$curl = curl_init();
curl_setopt_array($curl, array(
   CURLOPT_URL =&gt; "{{getApiEndpoint()}}/stream",
   CURLOPT_RETURNTRANSFER =&gt; true,
   CURLOPT_TIMEOUT =&gt; 10,
   CURLOPT_FAILONERROR =&gt; true,
   CURLOPT_SSL_VERIFYPEER =&gt; false,
   CURLOPT_HTTP_VERSION =&gt; CURL_HTTP_VERSION_1_1,
   CURLOPT_POST =&gt; true,
   CURLOPT_POSTFIELDS =&gt; $data_string,
   CURLOPT_HTTPHEADER =&gt; array(
     "Authorization: Basic " . $auth,
     "Content-Type: application/json",
     "Content-Length: " . strlen($data_string)
  )
));

$response = curl_exec($curl);
$err = curl_error($curl);
curl_close($curl);
if ($err) {
  echo "cURL Error #:" . $err;
} else {
  $obj = json_decode($response);
  var_dump($obj);
}</code><code v-if="this.languageMode === 'python'" class="small ng-binding ng-hide">import requests
import simplejson as json

url = "{{getApiEndpoint()}}/stream"
data = {{processor.code.stream.json}}
headers = {'Content-type': 'application/json'}
r = requests.post(url, data=json.dumps(data), headers=headers, auth=('{{getEmail()}}', '{{apiKey}}'), verify=False)
obj = json.loads(r.content)
print obj['result']
print obj['message']</code><code v-if="this.languageMode === 'csharp'" class="small ng-binding">var client = new RestClient("{{getApiEndpoint()}}/stream");
var request = new RestRequest(Method.POST);
request.AddHeader("content-type", "application/json");
request.AddHeader("accept", "application/json");
request.DefaultRequestHeaders.Authorization =
    new AuthenticationHeaderValue(
        "Basic",
        Convert.ToBase64String(
            System.Text.ASCIIEncoding.ASCII.GetBytes(
                string.Format("{0}:{1}", "{{getEmail()}}", "{{apiKey}}"))));
request.AddParameter("application/json", new JavaScriptSerializer().Serialize({{processor.code.stream.json}}), ParameterType.RequestBody);
IRestResponse response = client.Execute(request);
print response.Content;</code><code v-if="this.languageMode === 'ruby'" class="small ng-binding ng-hide">require 'net/http'
require 'openssl'
require 'json'

uri = URI('{{getApiEndpoint()}}/stream')
http = Net::HTTP.new(uri.host, uri.port, OpenSSL::SSL::VERIFY_NONE)
req = Net::HTTP::Post.new(uri.path, 'Content-Type' =&gt; 'application/json')
req.basic_auth '{{getEmail()}}', '{{apiKey}}'
req.body = '{{processor.code.stream.json}}'
res = http.request(req)
obj = JSON.parse(res.body)

puts obj['result']
puts obj['message']</code><code v-if="this.languageMode === 'java'" class="small ng-binding">/*
 * Download next jars and add to your project:
 * okhttp3: http://repo1.maven.org/maven2/com/squareup/okhttp3/okhttp/3.9.1/okhttp-3.9.1.jar
 * okio: https://search.maven.org/remote_content?g=com.squareup.okio&a=okio&v=LATEST
 */
import okhttp3.*;

public class TestVFCons {
  public static void main(String []args) throws Exception {
    OkHttpClient client = new OkHttpClient();
    String credential = Credentials.basic("{{getEmail()}}", "{{apiKey}}");
    MediaType mediaType = MediaType.parse("application/json");
    String json = "{{prepareJavaString(processor.code.stream.json)}}";
    RequestBody body = RequestBody.create(mediaType, json);
    Request request = new Request.Builder()
      .url("{{getApiEndpoint()}}")
      .post(body)
      .addHeader("authorization", credential)
      .addHeader("content-type", "application/json")
      .build();

    Response response = client.newCall(request).execute();
    System.out.println(response.body().string());
  }
}
</code></pre>

                        </div>








                      </div>
                    </div>


                  </div>


                </div>

              </div>


              <a href="https://docs.uproc.io/api/#api-Process-OneRow" v-if="this.apiMethod === 'one'" target="_blank">{{$t('view-all-options')}}</a>
              <a href="https://docs.uproc.io/api/#api-Process-MultipleRows" target="_blank" v-if="this.apiMethod === 'multiple'">{{$t('view-all-options')}}</a>
              <a href="https://docs.uproc.io/api/#api-Process-Stream" target="_blank" v-if="this.apiMethod === 'stream'">{{$t('view-all-options')}}</a>

            </div>

            <div class="text-center row other-tool no_side_margin" v-if="this.relatedProcessors && this.relatedProcessors.length && isTechnicalProfile()">
              <div class="col-lg-12">
                <h3>{{$t('processor-related')}} {{this.firstParam}}</h3>
              </div>

              <div class="col-lg-3 wrapper no_side_pad" v-for="processor in this.relatedProcessors" v-bind:key="processor.key">
                <a v-bind:href="'#/tools/processor/' + processor.type + '/' + processor.module + '/' + processor.name">
                  <div class="all-tool-item">
                    <IconGroup :group="processor.groups[0]" />
                    <span>{{ $t("type-"+ processor.type + "-verb" ) }}</span>
                    <h6>{{processor.description}}</h6>
                  </div>
                </a>

              </div>
              <CustomProcessor />
            </div>
          </div>
        </div>
      </div>


      <v-dialog />

      <Footer />

    </div>


  </div>


</div>
</template>

<script>
import axios from 'axios';
import Vue2Filters from 'vue2-filters'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/Sidebar.vue'
import IconGroup from '@/components/Tools/IconsGroup.vue'
import CustomProcessor from '@/components/Tools/CustomProcessor.vue'

export default {
  name: 'toolsprocessor',
  mixins: [Vue2Filters.mixin],
  data() {
    return {
      callback: "",
      isActive: false,
      isActiveIcon: '<',
      file: {},
      processor: {
        groups: [""]
      },
      firstParam: "",
      processing: null,
      hasToBeSent: false,
      inProcess: false,
      uploadedFile: false,
      apiShown: false,
      user: null,
      email: "",
      languageModeText: 'Curl',
      languageMode: 'curl',
      productionMode: 'real',
      apiKey: "",
      apiMethod: 'one',
      selectedGroup: "",
      selectedGroupL: "",
      selectedType: "",
      selectedGroupIcon: "",
      selectedTypeSlug: "",
      categories: null,
      relatedProcessors: null,
      mode: 'table',
      hasMaskedValues: false,
      isImage: false,
      isAudio: false,
      isQrImage: false,
      isUrl: false,
      isOutputExpanded: true,
      tabIndex: '',
      isError: false,
      isSubmitted: false,
      isAdvancedInput: false,
      showInput: false,
      isUploaded: false,
      showJobScheduled: false,
      areParamsAssigned: false,
      fileAssignedCols: [],
      processors: [],
      sendingRequestProcessors: false,
      paramNames: [],
      lastProcessors: [],
      groups: '',
      received: '',
      feedbackResult: '',
      responseStr: '',
      message: '',
      reason: '',
      needsMultiline: '',
      sending: false,
      profile: '',
      isDisabledC: false,
      isDisabledCD: false,
      videoShown: false,



    }

  },
  created: function() {
    if (!localStorage.token) {

      this.$router.push("/login");

    } else {

      this.fetchData();


    }
  },
  mounted() {

    this.enableInterceptor();
  },
  watch: {
    '$route'() {
      this.initProcessor();
      this.fetchData();
    },

    processors: function() {
      //alert('proc');
      if (this.processors.length > 0) {

        this.loadBasicData();
        this.loadProcessingValues();

      }

    },

    processor: function() {

      this.initSelectedItems();

    }

  },
  components: {
    IconGroup,
    Header,
    Sidebar,
    Footer,
    CustomProcessor

  },
  computed: {

  },
  methods: {

    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        this.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        this.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    onRefresh: function(url) {

      location.href = url; //"https://www.w3schools.com";
      window.location.reload();

    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      //console.log(this.isActive);
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },

    initProcessor() {
      this.file = {},
        this.processor = {},
        this.firstParam = "",
        this.processing = null,
        this.hasToBeSent = false,
        this.inProcess = false,
        this.uploadedFile = false,
        this.apiShown = false,
        this.user = null,
        this.email = "",
        this.languageMode = 'curl',
        this.languageModeText = 'Curl',
        this.productionMode = 'real',
        this.apiKey = "",
        this.apiMethod = 'one',
        this.selectedGroup = "",
        this.selectedGroupL = "",
        this.selectedType = "",
        this.selectedGroupIcon = "",
        this.selectedTypeSlug = "",
        this.categories = null,
        this.relatedProcessors = null,
        this.mode = 'table',
        this.hasMaskedValues = false,
        this.isImage = false,
        this.isAudio = false,
        this.isQrImage = false,
        this.isUrl = false,
        this.isOutputExpanded = true,
        this.tabIndex = '',
        this.isError = false,
        this.isSubmitted = false,
        this.isAdvancedInput = false,
        this.showInput = false,
        this.isUploaded = false,
        this.showJobScheduled = false,
        this.areParamsAssigned = false,
        this.fileAssignedCols = [],
        this.processors = [],
        this.sendingRequestProcessors = false,
        this.paramNames = [],
        this.lastProcessors = [],
        this.groups = '',
        this.received = '',
        this.feedbackResult = '',
        this.responseStr = '',
        this.message = '',
        this.needsMultiline = '',
        this.sending = false,
        this.email = '';
    },

    resetValues: function() {

      this.tabIndex = 0;
      this.isError = false;
      this.isSubmitted = false;
      this.inProcess = false;
      this.isAdvancedInput = false;
      this.showInput = false;
      this.hasToBeSent = false;
      this.isUploaded = false;
      this.showJobScheduled = false;
      this.areParamsAssigned = false;
      this.fileAssignedCols = [];


    },

    fetchData: function() {
      //alert('fetch');
      this.getProcessors();
      this.searchGroups();
      this.getUserDetails();

    },

    loadBasicData: function() {

      this.resetFileUploadProcessingValues();
      this.load(this.$route.params.type, this.$route.params.module, this.$route.params.name);
      this.processor = this.getProcessor();
      console.log(this.processor);
      var url = "https://uproc.io/blog/";
      if (this.$i18n.locale === 'es') {
        url += this.$i18n.locale + "/";
      }
      var type = this.processor.type === 'check' ? 'check-if' : this.processor.type;
      var name = this.processor.description_en ? this.processor.description_en : this.processor.description;

      this.processor.blog_url = url + "how-to-" + type + "-" + name.toLowerCase().replace(/ /g, "-").replace(/(\(|\))/g, "");
      this.processor.blog_url += "?utm_source=webapp&utm_medium=referral&utm_campaign=tool&utm_content=more";
      if (this.$i18n.locale === 'es') {
        this.processor.blog_url += "-es";
      }

      if (this.processor && this.processor.videos && this.processor.videos.sheets && this.processor.videos.sheets.length) {
        this.processor.video_url = this.processor.videos.sheets.replace("watch?v=", "embed/") + "?VQ=HD720";
        //this.processor.video_url += "&utm_source=webapp&utm_medium=referral&utm_campaign=tool&utm_content=more";
        this.videoShown = true;
      }

      this.processor.params[0].value = this.$route.params.query; //alert();

      this.firstParam = this.processor && this.processor.params ? this.processor.params[0].name.replace(/[0-9]*/g, '') : "";
      this.processing = this.getProcessingValues();
      this.apiKey = localStorage.token;
      this.profile = localStorage.toolsProfile;

      this.processor.extended_description = this.processor.extended_description.trim();
      var substr = this.processor.extended_description.substring(this.processor.extended_description.length  - 10);
      if (substr.indexOf('.') === -1 && substr.indexOf('!') === -1) {
        this.processor.extended_description += '.'
      }

      this.monitorParams();

    },

    initSelectedItems: function() {

      this.selectedTypeSlug = this.processor && this.processor.name && this.processor.name.indexOf('normalize') !== -1 ? 'normalize' : this.processor.type;
      this.selectedGroup = this.processor.groups ? this.getTranslatedGroupText(this.processor.groups[0]) : "";
      this.selectedGroupL = this.selectedGroupLOW(this.selectedGroup);
      this.selectedGroupIcon = this.processor.groups ? this.getIconByGroup(this.processor.groups[0]) : "";
      this.selectedType = this.processor !== undefined && this.processor !== null ? this.getTranslatedTypeText(this.selectedTypeSlug) : "";
      this.selectedTypeIcon = this.processor !== undefined && this.processor !== null ? this.getIconByType(this.selectedTypeSlug) : "";

    },

    selectedGroupLOW: function(value) {
      value = value.toString();
      return value.charAt(0).toLowerCase() + value.slice(1)
    },

    isTechnicalProfile: function() {

      return this.profile === 'technical' || this.profile === "";

    },

    setTechnicalProfile: function() {
      this.profile = 'technical';
      //this.SearchBusiness.setProfile(this.profile);
    },

    hasProfile: function() {

      return this.profile !== '';

    },

    getTranslatedGroupText: function(name) {

      var translatedText = "";
      name = name || 'all'; //console.log(this.groups);
      if (name && name.length && this.groups) {
        var items = this.groups.filter(function(g) {
          return g.name === name
        });
        translatedText = items.length === 1 ? items[0].translated : translatedText;
      } //console.log(translatedText);
      return translatedText;

    },

    getTranslatedTypeText: function(name) {

      var translatedText = "";

      if (name && name.length) {
        //var currentLanguage = this.getLanguage();
        translatedText = 'type-' + name //$filter('translate')('type-' + name);

      }

      return translatedText;

    },

    getLanguage: function() {

      return this.getFirstBrowserLanguage();

    },

    getFirstBrowserLanguage: function() {

      var nav = window.navigator,
        browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
        i,
        language;

      // support for HTML 5.1 "navigator.languages"
      if (Array.isArray(nav.languages)) {
        for (i = 0; i < nav.languages.length; i++) {
          language = nav.languages[i];
          if (language && language.length) {
            return language;
          }
        }
      }

      // support for other well known properties in browsers
      for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
        language = nav[browserLanguagePropertyKeys[i]];
        if (language && language.length) {
          return language;
        }
      }

      return 'en';

    },

    getIconByGroup: function(name) {
      var icon = "fa-search-plus";
      if (name === 'geographic') {
        icon = "fa-map-marker";
      } else if (name === 'personal') {
        icon = "fa-user";
      } else if (name === 'company') {
        icon = "fa-building";
      } else if (name === 'finance') {
        icon = "fa-credit-card";
      } else if (name === 'book' || name === 'editorial') {
        icon = "fa-book";
      } else if (name === 'internet') {
        icon = "fa-globe";
      } else if (name === 'text') {
        icon = "fa-file-text";
      } else if (name === 'security') {
        icon = "fa-shield";
      } else if (name === 'communication') {
        icon = "fa-bullhorn";
      } else if (name === 'product') {
        icon = "fa-shopping-cart";
      } else if (name === 'lead') {
        icon = "fa-database";
      }

      return icon;
    },

    getIconByType: function(name) {

      var icon = "fa-eraser";
      if (name === 'check') {
        icon = "fa-check-square-o";
      } else if (name === 'get') {
        icon = "fa-magic";
      } else if (name === 'unify') {
        icon = "fa-magic";
      }

      return icon;

    },

    searchGroups: function() {
      //var url = '/api/v2/group/search'
      var url = '/json/' + this.$locale + '/groups.json?' + this.$prefix;
      //this.$http.get(url, this.config).then((results) => {
      this.$http({method: 'GET', url: url, baseURL: '/'}).then((results) => {

        this.sendingRequestGroups = false;
        this.groups = results.data.groups.sort();
        //$rootScope.$broadcast('search.groups.loaded');





      });

    },

    resetFileUploadProcessingValues: function() {

      var processingUpload = {
        totalRows: 1,
        totalRowsOk: 0,
        userCredits: 0,
        totalCols: 0,
        currentIndex: -1,
        assignedProcessors: [],
        allParamsNames: [],
        areAllParamsAssigned: false,
        assignedCols: [],
        assignedValues: [],
        upload: {
          contents: [],
          fileName: '',
          file: null,
          fileNeedsPreview: false
        },
        stream: "",
        dataTable: {
          data: [],
          results: []
        },
        receivedData: null,
        neededCredits: 0,
        costPerCredit: 0.0001,
      };

      //var processing = this.processing;console.log('yep');console.log(processingUpload);
      //processing.push(processingUpload);console.log('contact');console.log(processing);
      this.processing = processingUpload;
      //this.processing = Vue.extend(this.processing, processingUpload);

    },

    composeJsonRequest: function(processor) {
      var getProcessorParamsJsonV2 = function(processor, glue) {
        glue = glue || ",";
        var params = [];
        for (var i = 0; i < processor.params.length; i++) {
          params.push('"' + processor.params[i].name + '": "' + (processor.params[i].ok_sample.split("|")[0]).replace(new RegExp('"', 'g'), '\\"') + '"');
        }
        return params.join(glue);
      };

      var params = getProcessorParamsJsonV2(processor);
      var processorKey = processor.key;
      var str = '{"processor": "' + processorKey + '", "params": {' + params + '}}';
      return str.replace(/\n/g, "\\n");
    },


    generateJsonByMethod: function(processor, method) {
      var obj = JSON.parse(this.composeJsonRequest(processor));
      var item = {};
      var params = {};
      var keys = [];
      if (method === 'one') {
        item = obj;
        //item.normalized = false;
      } else if (method === 'multiple') {
        keys = Object.keys(obj.params);
        keys.forEach(function(key) {
          params[key] = [obj.params[key], obj.params[key]];
        });
        item = {
          "processor": obj.processor,
          "params": params,
          //"callback": {
          // "start": "https://mydomain.com/my-start-callback",
          // "progress": "https://mydomain.com/my-progress-callback",
          // "end": "https://mydomain.com/my-end-callback"
          //}
        };

      } else {
        keys = Object.keys(obj.params);
        var col = 0;
        keys.forEach(function(key) {
          params[key] = [col++];
        });

        item = {
          "processors": [
          {
                 "processor": obj.processor,
                 "params": params
          //       "normalized": false
             }
          ],
          "stream": "ftp://username:password@myservername.com/my/path/to/filename.csv.zip",
          //"callback": {
          // "start": "https://mydomain.com/my-start-callback",
          // "progress": "https://mydomain.com/my-progress-callback",
          // "end": "https://mydomain.com/my-end-callback"
          //}
        };
      }

      return JSON.stringify(item, undefined, 2);
    },

    prepareJson: function() {
      this.processor.current.code = {
        one: {
          json: this.generateJsonByMethod(this.processor.current, 'one')
        },
        multiple: {
          json: this.generateJsonByMethod(this.processor.current, 'multiple')
        },
        stream: {
          json: this.generateJsonByMethod(this.processor.current, 'stream')
        }
      }
    },

    load: function(typeValue, moduleValue, nameValue) {
      this.processor.current = this.getProcessorByBasicParams(typeValue, moduleValue, nameValue);
      this.prepareJson();
      this.processor.relatedProcessors = [];
      if (this.processor.current && this.processor.current.params) {
        this.processor.defaultParams = this.processor.current.params;
        this.getRelatedProcessors();
      }

    },

    getProcessorByBasicParams: function(typeValue, moduleValue, nameValue) {

      //var processorsFound = $filter("filter")(processors, {type: typeValue, name: nameValue, module: moduleValue});
      var processorsFound = this.filterProcessorsByBasicParams(typeValue, moduleValue, nameValue);
      return processorsFound && processorsFound.length ? processorsFound[0] : {};

    },

    filterProcessorsByBasicParams: function(type, module, name) {

      return this.processors && this.processors.length ? this.processors.filter(function(p) {
        return p.type === type && p.module === module && p.name === name
      }) : [];

    },

    getRelatedProcessors: function() {

      if (this.currentProcessorLoaded() && !this.processor.relatedProcessors.length) {
        this.prepareRelatedProcessors(this.getRelatedProcessorsByParamName(this.processor.current));
      } else {
        return this.processor.relatedProcessors;
      }
    },

    currentProcessorLoaded: function() {

      if (this.processor.current) {
        return Object.keys(this.processor.current).length > 0;
      }
    },

    sortByPropertyName: function(arr, name) {
      return arr ? arr.sort(function(a, b) {
        var isString = (typeof a[name]).toLowerCase() !== 'number';
        //avoid problems with non string properties
        var nameA = isString ? (a[name] + "").toLowerCase() : a[name],
          nameB = isString ? (b[name] + "").toLowerCase() : b[name];
        if (nameA < nameB) //sort string ascending
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      }) : [];
    },

    prepareRelatedProcessors: function(processors) {
      if (processors && processors.length > 0) {
        for (var i = 0; i < processors.length; i++) {
          if (processors[i].name !== this.processor.current.name) {
            this.processor.relatedProcessors.push(processors[i]);
            this.relatedProcessors = this.processor.relatedProcessors;
          }
        }

        this.relatedProcessors = this.sortByPropertyName(this.relatedProcessors, 'description');
      }
    },

    getRelatedProcessorsByParamName: function(processor) {
      var that = this;
      var paramName = processor.params[0].name.replace(/[0-9]*/g, '');
      return this.processors && this.processors.length ?
        this.processors.filter(function(p) {
          return p.params.filter(function(p1) {
            return p1.name === paramName;
          }).length > 0 && p.name !== that.processor.name;
        }) : [];

    },

    getProcessors: function(forced) {

      forced = forced || false;
      if (!this.sendingRequestProcessors && (!this.processors.length || forced)) {
        this.sendingRequestProcessors = true;
        this.searchProcessors();
        return this.processors;
      } else {
        return this.processors;
      }

    },

    getProcessor: function() {
      if (this.processor.current.extended_description.replace) {
        this.processor.current.extended_description = this.processor.current.extended_description.replace(/\/app\//g, "/#/")
        this.processor.current.extended_description = this.processor.current.extended_description.replace(/\/catalog\//g, "/tools/")
        if (this.processor.current.extended_description.indexOf("/settings/integration") === -1 && this.processor.current.extended_description.indexOf("/integration") !== -1) {
          this.processor.current.extended_description = this.processor.current.extended_description.replace(/\/integration/g, "/settings/integration")
        }
        this.processor.current.extended_description = this.processor.current.extended_description.replace(/app.uproc.io/g, location.hostname + ":" + location.port)
        this.processor.current.extended_description = this.processor.current.extended_description.replace(/https:/g, location.protocol)
      }
      return this.processor.current;

    },

    //getProcessingValues: function(force) {
    getProcessingValues: function() {

      //force = force || false;
      //if (this.processing === null || force) {
      if (this.processing === null) {
        //this.loadProcessingValues(force);
        this.loadProcessingValues();
      } else {
        return this.processing;
      }

    },

    //loadProcessingValues: function(force) {
    loadProcessingValues: function() {

      //force = force || false;
      this.completeBasicProcessingValues();
      this.getProcessingValuesAPI(function() {

        //$rootScope.$broadcast('processing.reloaded');
      }, function() {

      });

    },

    resetSelectedProcessors: function() {

      if (this.processing.assignedProcessors) {
        this.processing.assignedProcessors = [];
        this.processing.assignedCols = [];
      }

    },

    completeBasicProcessingValues: function() {

      this.processing = {
        maximumRowsInline: 500,
        allParamsNames: [],
        currentIndex: -1,
        assignedCols: [],
        assignedValues: [],
        assignedProcessors: [],
        areAllParamsAssigned: false,
        minimumCredits: 25000,
        userCredits: 0,
        processCredits: 0,
        processCost: 0,
        upload: {},
        dataTable: {
          data: [],
          results: [],
        },
        receivedData: null,
        neededCredits: 0,
        costPerCredit: 0.0001,
        vat: 21,
        totalRows: 1,
        totalRowsOk: 0
      };

    },

    //getProcessingValuesAPI: function(successCallback, errorCallback) {
    getProcessingValuesAPI: function(successCallback) {
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v2/processing';

      this.$http.get(url, config).
      then((results) => {
        this.resetSelectedProcessors();

        this.processing = Object.assign({}, this.processing, results.data);
        if (successCallback) {
          successCallback()
        }
      })

    },

    searchProcessors: function() {

    /*
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };
*/

      //var url = '/api/v2/processor/search';
      var url = '/json/' + this.$locale + '/processors.json?' + this.$prefix;
      this.loading = true;
      //this.$http.get(url, this.config).then((results) => {
      this.$http({method: 'GET', url: url, baseURL: '/'}).then((results) => {

        this.sendingRequestProcessors = false;
        this.processors = results.data.processors; //alert(this.processors.length);
        this.paramNames = this.getUniqueAvailableParamNames(this.processors);
        this.setLastProcessors();
        //$rootScope.$broadcast('search.processors.loaded');
        this.updateFilteredProcessors();





      });

    },

    getUniqueAvailableParamNames: function(items) {
      //alert('ds');
      var params = [];
      if (items) {
        items.forEach(function(p) {
          var paramNames = p.params.map(function(p) {
            return p.name.replace(/[0-9]+/g, '');
          });
          var firstParam = paramNames.length > 0 ? paramNames[0] : "";
          if (params.indexOf(firstParam) === -1) {
            params.push(firstParam);
          }
          /*
          paramNames.forEach(function(param) {
            if (params.indexOf(param) === -1) {
              params.push(param);
            }
          });
          */
        });
      }
      return params.sort();

    },

    setLastProcessors: function() {

      var compareDates = function(a, b) {
        if (a.date.toString() < b.date.toString()) return 1;
        if (a.date.toString() > b.date.toString()) return -1;
        return 0;
      }

      this.lastProcessors = this.processors.filter(function(processor) {
        return processor.date !== null;
      }).sort(compareDates).slice(0, 12);

    },

    //updateFilteredProcessors: function(txt) {
    updateFilteredProcessors: function() {

      //txt = txt || this.query;
      //byParam = byParam || false;
      if (this.processors && this.processors.length) {
        //this.filterProcessors(txt, byParam);
        //$rootScope.$broadcast('search.updatedFiltered');
      }

    },

    changeInputValue: function(a, b, i) {

      this.processor.params[i].value = '';
      this.processor.params[i].value = b;
      return;
      /*let el = document.getElementById("paramValue_"+i);
		      el.value = b;

		      document.getElementById("paramValue_"+i).focus();

		      el.dispatchEvent(new Event('input'));*/
      /*function setSelectedIndex(s, v) {

    for ( var i = 0; i < s.options.length; i++ ) {

        if ( s.options[i].text == v ) {

            s.options[i].selected = true;



            return;

        }

    }

}

setSelectedIndex(document.getElementById("paramValue_"+i),b);*/


    },

    monitorParams: function() {
      //let that = this;

      this.$forceUpdate();
      if (this.processor.params) {

        for (var i = 0; i < this.processor.params.length; i++) {

          if (this.processor.params[i].required == true && (this.processor.params[i].value == '' || this.processor.params[i].value == undefined)) {

            this.isDisabledC = false;
            this.isDisabledCD = true;
            break;

          } else {
            this.isDisabledC = true;
            this.isDisabledCD = false;
          }

        }


      }

    },

    cbCheckSuccess: function() {
      var isUrl = function(uri) {
        if (uri && uri.length) {
          uri = uri.toLowerCase();
          return uri.indexOf("http") === 0;
        } else {
          return false;
        }
      }

      var isAudio = function(uri) {
        if (uri && uri.length) {
          uri = uri.toLowerCase();
          return uri.indexOf(".mp3") !== -1 || uri.indexOf(".wav") !== -1;
        } else {
          return false;
        }
      };

      var isImage = function(uri) {
        if (uri && uri.length) {
          uri = uri.toLowerCase();
          return uri.indexOf(".jpg") !== -1 || uri.indexOf(".jpeg") !== -1 || uri.indexOf(".png") !== -1 || uri.indexOf(".gif") !== -1;
        } else {
          return false;
        }
      };

      var isQrImage = function(uri) {
        if (uri && uri.length) {
          uri = uri.toLowerCase();
          return uri.indexOf("qr_") !== -1 && (uri.indexOf(".jpg") !== -1 || uri.indexOf(".jpeg") !== -1 || uri.indexOf(".png") !== -1 || uri.indexOf(".gif") !== -1);
        } else {
          return false;
        }
      };

      var isPdf = function(uri) {
        if (uri && uri.length) {
          uri = uri.toLowerCase();
          return uri.indexOf(".pdf") !== -1;
        } else {
          return false;
        }
      };

      this.inProcess = false;
      this.received = false;
      this.feedbackResult = false;

      this.processing = this.getProcessingValues();
      this.isError = this.hasErrorResultData();
      this.response = this.getData();
      this.responseStr = JSON.stringify(this.response, null, " ");
      this.message = this.getMessage();
      this.needsMultiline = false;
      if (this.message && this.message.result && this.message.result.split) {
        if (this.message.result.indexOf(";") !== -1) {
          this.needsMultiline = true;
          //this.message.result = this.message.result.split(';').map(function(line){return line.trim();}).join("\n");
        } else if (this.message.result.indexOf("\n") !== -1 || this.message.result.indexOf("<br>") !== -1) {
          this.message.result = this.message.result;
          this.needsMultiline = true;
        }

        this.isUrl = isUrl(this.message.result);
        this.isImage = isUrl && isImage(this.message.result);
        this.isAudio = isUrl && isAudio(this.message.result);
        this.isPdf = isUrl && isPdf(this.message.result);
        this.isQrImage = isUrl && isQrImage(this.message.result);


        if (this.isUrl || this.isImage || this.isAudio || this.isPdf) {
          this.resultUri = this.message.result;
        } else {
          this.resultUri = "";
        }
      }
      this.data = this.getData();
      this.showJobScheduled = this.isJobScheduled();
      this.setValuesAfterSent();
      this.hasMaskedValues = JSON.stringify(this.message).indexOf("*") !== -1;

      //this.location.hash("results");
      //console.debug(this.anchorScroll);
      //this.anchorScroll();
      //this.openModalProcessorResult();
    },

    getUserDetails: function() {

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };


      var url = '/api/v2/profile';

      this.$http.get(url, config).then((results) => {

        this.user = results.data;


      });

    },

    hasCards: function() {
      return this.user && this.user.cards.length;
    },

    hasAutoCredits: function() {
      return this.user && this.user.autocredits !== null ? this.user.autocredits : false;
    },

    areCreditsNeeded: function() {

      return this.processing.processCredits > this.getCredits();

    },

    getCredits: function() {

      return this.user ? this.user.credits : 0;


    },

    checkValue: function() {

      this.inProcess = true;
      this.setCurrentProcessor(this.processor);
      if (this.isFreeQuery()) {
        this.setValuesBeforeSending();
        this.checkValueBase();
      } else if (this.hasRequiredValues()) {
        if (!this.hasCards() && !this.hasAutoCredits()) {
          this.inProcess = false;
          this.openModalAddCard();
        } else if (this.areCreditsNeeded() && !this.hasAutoCredits()) {
          this.inProcess = false;
          this.openModalCredits('value');
        } else if (!this.isJobScheduled()) {
          //Convert input to table
          this.setValuesBeforeSending();
          this.checkValueBase();
        }
      }

    },

    hasRequiredValues: function() {

      var hasAll = true;

      this.processor.current.params.forEach(function(param) {
        if (param.required && !param.value.length) {
          hasAll = false;
        }
      });

      return hasAll;

    },

    setCurrentProcessor: function(newProcessor) {

      this.processor.current = newProcessor;

    },

    isFreeQuery: function() {
      //alert(this.processing.maximumRowsFree);
      return this.processing.totalRows <= this.processing.maximumRowsFree;

    },

    setValuesBeforeSending: function() {

      this.resetValues();
      this.showInput = false;
      this.inProcess = true;

    },

    checkValueBase: function() {

      var processorName = this.getCurrentProcessorName();
      var params = this.getParamsObject(this.processor.current.params);
      this.resetCheckedData();
      this.completeProcessingValues();
      this.processOneWithExtra(processorName, params);

    },

    getParamsObject: function(params) {

      var obj = {};

      var that = this;

      this.processing.totalRows = 0;
      if (params) {
        params.forEach(function(param) {
          if (param.value && param.value.length) {
            var value = that.formatParamValue(param.value);
            var paramTotalRows = that.getParamTotalRows(value);
            that.processing.totalRows = paramTotalRows > that.processing.totalRows ? paramTotalRows : that.processing.totalRows;
            obj[param.name] = param.value;
          } else {
            obj[param.name] = "";
          }
        });
      }

      return obj;

    },

    getParamTotalRows: function(value) {

      return value.split(";").length;

    },

    formatParamValue: function(value) {

      return value.replace(/(\r\n|\n|\r)/gm, ";");

    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },

    processOneWithExtra: function(processorName, params, options) { //, successCallback, errorCallback) {
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };
      var data = {
        processor: processorName,
        params: params
      };

      if (this.callback && this.callback.length) {
        data.callback = {
          data: this.callback
        };
        this.callbackStr = ", \"callback\": {\"data\": \"" + this.callback + "\"}";
      }

      if (options) {
        Object.keys(options).forEach(function(key) {
          data[key] = options[key];
        });
      }

      var url = '/api/v2/process';
      this.$http.post(url, data, config).then((results) => {

        this.completeProcessingResults(results.data);
        if (this.processing.totalRows > this.processing.maximumRowsFree) {
          this.getUser(true);
        }

        this.cbCheckSuccess();

      }); //then(successCallback, errorCallback);
    },

    getUser: function(forced) {

      forced = forced || false;
      if ((this.user === null || forced) && this.apiKey !== localStorage.token) {

        var config = {
          headers: {
            "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
            "Content-Type": "application/json"
          }
        };


        var url = '/api/v2/profile';

        this.$http.get(url, config).then((results) => {

          this.user = results.data;
          this.user.authorization = btoa(this.user.email + ":" + this.user.apiKey);
          if (this.user !== null) {
            return this.user;
            // $rootScope.$broadcast('user.reload');
          }


        });


      } else {
        return this.user; //angular.copy(user);
      }

    },

    completeProcessingResults: function(data) {

      this.completeProcessingData(data);
      this.processing.dataTable.results = this.prepareResultData(data);
      this.processing.totalRowsOk = this.getTotalRowsOkByResultData();

    },

    completeProcessingData: function(data) {

      this.processing.receivedData = this.cleanReceivedData(data);
      if (this.isJobScheduled()) {
        this.substractCredits(this.processing.processCredits);
        /*$rootScope.$broadcast('user.reload');
        $rootScope.$broadcast('jobs.update');*/
      }
    },

    substractCredits: function(amount) {
      this.user.credits -= amount;
    },

    cleanReceivedData: function(data) {
      delete data.normalized;
      delete data.criteria;
      delete data.time;
      //delete data.price;


      if (data && data.callback && data.callback.data && data.callback.data.length) {
        data.message = {
          result: data.callback.data
        };
        data.total_rows = 1;
        data.result = true;
        data.real_price = 0;
      }

      return data;
    },

    prepareResultData: function(data) {

      var results = [];

      if (data.length === undefined) {
        for (var prop1 in data) {
          if (prop1 === "result") {
            results.push(data);
          }
        }
      } else {
        for (var row in data) {
          var obj = data[row];
          for (var prop2 in obj) {
            if (prop2 === "result") {
              results.push(obj);
            }
          }
        }
      }

      return results;

    },

    getTotalRowsOkByResultData: function() {

      var totalRowsOk = 0;

      for (var row in this.processing.dataTable.results) {
        var obj = this.processing.dataTable.results[row];
        for (var prop2 in obj) {
          if (prop2 === "result") {
            if (obj.result) {
              totalRowsOk++;
            }
          }
        }
      }

      return totalRowsOk;

    },

    completeProcessingValues: function() {

      this.setTableData();
      this.calculateProcessorsCost();
      this.processing.userCredits = this.getCredits();
      this.processing.neededCredits = (this.processing.userCredits - this.processing.processCredits < 0) ? this.processing.processCredits - this.processing.userCredits : 0;
      this.processing.neededCredits = this.processing.neededCredits && this.processing.neededCredits < this.processing.minimumCredits ? this.processing.minimumCredits : this.processing.neededCredits;
      this.processing.neededCost = this.processing.neededCredits * this.processing.costPerCredit;
      this.processing.neededCostWithVat = this.processing.neededCost * (1 + (this.processing.vat / 100));

    },

    calculateProcessorsCost: function() {

      var that = this;
      this.processing.processCredits = 0;
      this.processing.processCost = 0;
      //If assigned processors, calculate value
      if (this.processing.assignedProcessors.length) {
        this.processing.assignedProcessors.forEach(function(processor) {
          that.processing.processCredits += that.processing.totalRows * processor.max_cost;
        });
      } else {
        this.processing.processCredits = this.processing.totalRows * this.processor.current.max_cost;
      }
      this.processing.processCost = this.processing.processCredits * this.processing.costPerCredit;

    },

    setTableData: function() {

      this.processing.dataTable = {
        data: this.prepareTableData()
      };

    },

    prepareTableData: function() {

      var col = 0;

      var that = this;

      var data = [];
      if (this.processor.current.params) {
        this.processor.current.params.forEach(function(param) {
          if (param.value && param.value.length) {
            var value = that.formatParamValue(param.value);
            var row = 0;
            that.splitRow(value).forEach(function(cellData) {
              if (that.isArrayUndefined(data[row])) {
                data[row] = [];
              }
              if (that.isArrayUndefined(data[row][col])) {
                data[row][col] = [];
              }
              data[row][col].push(cellData);
              row++;
            });
          }
          col++;
        });
      }


      return data;

    },

    splitRow: function(str) {

      return this.formatParamValue(str).split(";");

    },

    isArrayUndefined: function(arr) {

      return (typeof arr === "undefined" || !(arr instanceof Array));

    },

    resetCheckedData: function() {

      this.processing.dataTable.data = [];
      this.processing.dataTable.results = {};
      this.processing.upload = {
        fileName: ''
      };
      this.processing.neededCredits = 0;
      this.processor.relatedProcessors = [];

    },

    getCurrentProcessorName: function(currentProcessor) {

      currentProcessor = currentProcessor || this.processor.current;
      return currentProcessor.type + '-' + currentProcessor.module + '-' + currentProcessor.name;

    },

    hasErrorResultData: function() {

      var hasError = false;

      for (var row in this.processing.dataTable.results) {
        var obj = this.processing.dataTable.results[row];
        for (var prop2 in obj) {
          if (prop2 === "result") {
            if (!obj.result) {
              hasError = true;
              break;
            }
          }
        }
      }

      return hasError;
    },

    getData: function() {

      return (this.processing.receivedData !== undefined && this.processing.receivedData !== null) ? this.processing.receivedData : "";

    },

    getMessage: function() {
      var message = (this.processing.receivedData !== undefined && this.processing.receivedData !== null) ? this.processing.receivedData.message : "";
      if (message.result && typeof message.result === 'string') {
        message.result = message.result.replace(/,/g, ", ");
      }
      return message;
    },

    isJobScheduled: function() {

      return this.processing.receivedData !== undefined &&
        this.processing.receivedData !== null &&
        this.processing.receivedData.status === 1;

    },

    setValuesAfterSent: function() {

      this.inProcess = false;
      this.isSubmitted = true;
      this.hasToBeSent = false;

    },

    toggleDataMode: function(item) {

      this.mode = item;

    },

    downloadTemplate: function() {
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };
      var url = '/api/v2/download_template_wizard/' + this.processor.key;
      this.$http.get(url, config).then((results) => {
        this.downloadTemplateCallback(null, results.data);
      }); //then(successCallback, errorCallback);
    },

    downloadTemplateCallback: function(err, data) {
      var downloadCsv = function(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      };

      downloadCsv(this.processor.key + '-template.csv', data);

    },

    downloadResultsCallback: function(err, data) {

      var downloadCsv = function(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      };
      console.debug('downloadResultsCallback');
      downloadCsv('sample.csv', data);

    },

    processOneWithExtraDownload: function(processorName, params, options) { //, successCallback, errorCallback) {
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };
      var data = {
        processor: processorName,
        params: params
      };

      if (options) {
        Object.keys(options).forEach(function(key) {
          data[key] = options[key];
        });
      }

      var url = '/api/v2/process';
      this.$http.post(url, data, config).then((results) => {

        this.completeProcessingResults(results.data);
        if (this.processing.totalRows > this.processing.maximumRowsFree) {
          this.getUser(true);
        }

        this.downloadResultsCallback(null, results.data);

      }); //then(successCallback, errorCallback);
    },

    getApiEndpoint: function() {
      return this.$apiBase + '/api/v2/process';
    },

    sendFeedback: function() {
      this.sending = true;
      var user = this.getUser();
      var name = user ? user.name : "anonymous";
      var email = user ? user.email : (this.email.length ? this.email : 'test@uproc.io');
      var message = "User " + email + " has sent next data to processor <b>" + this.processor.key + "</b>";
      message += "<br><br>Parameters:<br><pre>" + JSON.stringify(this.processor.params) + "</pre><br>";
      message += "Response:<br><pre>" + JSON.stringify(this.message) + "</pre><br>";
      message += "<br><br>Reason:<br>" + this.reason;
      var subject = 'Disclaim processor result ' + this.processor.key;
      var data = {
        name: name,
        email: email,
        subject: subject,
        comment: message
      };

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v1/contact';

      this.$http.post(url, data, config).then((results) => {

        this.sending = false;
        this.received = true;
        this.feedbackResult = results;
        this.reason = "";




      });

    },

    apiShownTrigger: function() {

      this.apiShown = true;

    },

    languageModeChange: function(item) {

      this.languageModeText = item;
      this.languageMode = item.toLowerCase();
      if (this.languageMode === 'googlesheets') {
        this.apiMethodChange('one')
      }
    },

    apiMethodChange: function(item) {

      this.apiMethod = item;

    },

    prepareGoogleSheetsParametersFormula: function() {
      var parameters = this.processor.params.map(function(p) {
        return p.name;
      }).join("; ");
      return parameters;
    },

    prepareGoogleSheetsParameters: function() {
      var parameters = this.processor.params.map(function(p) {
        return p.name;
      }).join(", ");
      return parameters;
    },

    prepareGoogleSheetsJson: function() {
      var str = [];

      this.processor.params.forEach(function(p) {
        str.push('"' + p.name + '": ' + p.name);
      })

      var item = "{processor: '" + this.processor.key + "', params: {"+str.join(",\n")+"}}";
      return item;
    },

    prepareJavaString: function(json = null) {

      return json ? json.toString().replace(/"/g, '\\"').replace(/\n/g, '" + \n"') : "";

    },

    isMultipleRowsProcessor: function() {

      return this.processor && this.processor.output && this.processor.output.length > 0 && this.processor.output[0].type && this.processor.output[0].type === 'array';

    },

    loginChecker() {
      return localStorage.token && localStorage.token != this.$defaultKey ? true : false;

    },

    resetProfile: function() {

      this.profile = '';

      localStorage.toolsProfile = this.profile;

    }

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/tools.css';
@import '../../assets/css/global.css';
</style>
