<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">
      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>
      <div class="total-wizard">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item active">
              <router-link to="/purchase">{{ $t('menu-purchase' ) }}</router-link> / {{ $t('purchase-result' ) }}
            </li>
          </ol>
        </nav>
        <div class="row">
          <div class="col-lg-8 offset-lg-2 text-center">
            <br>
            <div class="alert alert-success" v-if="result === 'success'" v-html="$t('purchase-ok')"></div>
            <div class="alert alert-danger" v-if="result === 'cancel'" v-html="$t('purchase-ko')"></div>
          </div>
        </div>

      </div>
      <Footer />
    </div>
  </div>
</div>
</template>


<script>
import Header from '@/components/Header.vue'
import Sidebar from '@/components/Sidebar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Result',
  data() {
    return {
      isActive: false,
      isActiveIcon: '<',
      result: ""
    };
  },
  components: {
    Header,
    Sidebar,
    Footer
  },
  mounted: function() {
    this.$root.$emit('isLoading', false);
    if (this.$route.params.result && (this.$route.params.result === 'success' || this.$route.params.result === 'cancel')) {
      this.result = this.$route.params.result;
    } else {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    menuCollapse: function() {
      this.isActive = !this.isActive;

      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../assets/css/purchase.css';
@import '../../assets/css/global.css';
</style>
