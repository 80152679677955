<template>
  <ol class="list-items">
    <li><strong>SERVER.DATETIME</strong></li>
    <li><strong>VISIT.HEADERS</strong></li>
    <li><strong>VISIT.IP</strong></li>
    <li><strong>VISIT.IS_NEW</strong></li>
    <li><strong>VISIT.LIST</strong></li>
    <li><strong>VISIT.PAGE</strong></li>
    <li><strong>VISIT.CLIENT</strong></li>
    <li><strong>VISIT.SITE</strong></li>
    <li><strong>VISIT.USER_AGENT</strong></li>
    <li><strong>STATS.LAST_VISIT</strong></li>
    <li><strong>STATS.PREVIOUS_VISIT</strong></li>
    <li><strong>STATS.TOTAL</strong></li>
    <li><strong>STATS.UNIQUE</strong></li>
    <li><strong>COMPANY.ADDRESS</strong></li>
    <li><strong>COMPANY.CITY</strong></li>
    <li><strong>COMPANY.COUNTRY</strong></li>
    <li><strong>COMPANY.EMAIL</strong></li>
    <li><strong>COMPANY.NAME</strong></li>
    <li><strong>COMPANY.PHONE</strong></li>
    <li><strong>COMPANY.STATE</strong></li>
    <li><strong>COMPANY.WEBSITE</strong></li>
    <li><strong>COMPANY.ZIPCODE</strong></li>
    <li><strong>GEOGRAPHICAL.CITY</strong></li>
    <li><strong>GEOGRAPHICAL.COUNTRY</strong></li>
    <li><strong>GEOGRAPHICAL.COUNTY</strong></li>
    <li><strong>GEOGRAPHICAL.LATITUDE</strong></li>
    <li><strong>GEOGRAPHICAL.LONGITUDE</strong></li>
    <li><strong>GEOGRAPHICAL.ZIPCODE</strong></li>
    <li><strong>LOCALE.CURRENCY_CODE</strong></li>
    <li><strong>LOCALE.CURRENCY_NAME</strong></li>
    <li><strong>LOCALE.LANGUAGE_CODE</strong></li>
    <li><strong>LOCALE.LANGUAGE_NAME</strong></li>
    <li><strong>NETWORK.HOSTNAME</strong></li>
    <li><strong>NETWORK.IS_STATIC</strong></li>
    <li><strong>NETWORK.PROVIDER</strong></li>
    <li><strong>NETWORK.RANGE</strong></li>
    <li><strong>REPUTATION.IS_BLACKLISTED</strong></li>
    <li><strong>REPUTATION.IS_PROXY</strong></li>
    <li><strong>REPUTATION.IS_TOR</strong></li>
    <li><strong>TIMEZONE.DATE</strong></li>
    <li><strong>TIMEZONE.DATETIME</strong></li>
    <li><strong>TIMEZONE.DAY</strong></li>
    <li><strong>TIMEZONE.HOUR</strong></li>
    <li><strong>TIMEZONE.MINUTE</strong></li>
    <li><strong>TIMEZONE.MONTH</strong></li>
    <li><strong>TIMEZONE.QUARTER</strong></li>
    <li><strong>TIMEZONE.WEEKDAY</strong></li>
    <li><strong>TIMEZONE.YEAR</strong></li>
    <li><strong>TIMEZONE.ZONE</strong></li>
  </ol>
</template>
<script>
export default {
  props: ['list_type_web']
};
</script>
