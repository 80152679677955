//import VueCharts from 'vue-chartjs'

import {
  Pie,
  mixins
} from 'vue-chartjs';

export default {
  extends: Pie,
  props: ["chartData", "options"],
  mixins: [mixins.reactiveProp],
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, {
      borderWidth: "10px",
      hoverBackgroundColor: "red",
      hoverBorderWidth: "10px"
    });
  }

}
