<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>




      <!-- wizard content start start -->

      <div class="row no_side_margin">
        <div class="total col-lg-12 request">
          <ul class="nav nav-tabs d_summary">
            <li class="nav-item active">
              <a data-toggle="tab" href="#home" class="nav-link" v-bind:class="{ active: section === 'bulks' }" v-on:click="setSection('bulks')">
                <font-awesome-icon icon="tasks" size="lg"/>
                {{ $t('bulks' ) }}</a></li>
            <li class="nav-item">
              <a data-toggle="tab" href="#home" class="nav-link" v-bind:class="{ active: section === 'queue' }" v-on:click="setSection('queue')">
                <font-awesome-icon icon="history" size="lg"/>
                {{ $t('queue' ) }}</a></li>

          </ul>
          <div class="tab-content" >
            <div id="home" class="tab-pane container" v-if="section === 'bulks'"  v-bind:class="{ active: section === 'bulks' }">
              <div class="row">
                <div class="col-sm-12 col-12 col-title ">
                  <h3>{{ $t('bulks' ) }}</h3>
                </div>
              </div>


              <div class="col-lg-12 no_side_pad">
                <div class="page-nav">
                  <div class="row no_side_margin">
                    <div class="col-lg-3 col-sm-4 col-4 no_side_pad pointer" v-on:click="moveNext('B')">
                      <div class="nav-item"><a :class="{'disabled': isLastPage() , '': !isLastPage() && this.loading}" v-on:click="moveNext('B')"><span class="nav-icon">←</span> {{ $t('older' ) }}</a></div>
                    </div>
                    <div class="col-lg-6 col-sm-4 col-4 no_side_pad">
                      <div class="btn-group ">
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-4 col-4 no_side_pad pointer" v-on:click="movePrevious('B')">
                      <div class="nav-item"><a :class="{'disabled': isFirstPage() || !this.loading, '': !isFirstPage() && this.loading}" v-on:click="movePrevious('B')"> {{ $t('newer' ) }} </a><span class="nav-icon">→</span></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row smry-status no_side_margin" style="overflow-x: auto;">
                <table class="table table-striped list-table" style="margin-top: 0px">
                  <thead>
                    <tr>
                      <th class="t-head">{{ $t('date' ) }}</th>
                      <th class="t-head">{{ $t('status' ) }}</th>
                      <th class="t-head">{{ $t('processor' ) }}</th>
                      <th class="t-head">{{ $t('rows' ) }}</th>
                      <th class="t-head">{{ $t('scoring' ) }}</th>
                      <th class="t-head">{{ $t('cost' ) }}</th>
                      <th class="t-head">{{ $t('actions' ) }}</th>
                    </tr>
                  </thead>

                  <tbody v-if="!this.jobs.length">

                    <tr>
                      <td colspan="7" class="text-center no-list">{{ $t('no-results' ) }}</td>
                    </tr>

                  </tbody>

                  <tbody v-if="this.jobs.length">

                    <tr v-for='job in this.jobs' v-bind:key="job.startDate">

                      <td class="table-col" v-if="job.status == 2">{{job.startDate | formatDate}}</td>
                      <td class="table-col" v-if="job.status == 1">{{job.date | formatDate}}</td>
                      <td class="table-col" v-if="job.status > 2 && job.status < 4">{{job.endDate | formatDate}}</td>
                      <td class="table-col" v-if="job.status === 4">{{job.date | formatDate}}</td>

                      <td class="table-col" v-bind:class="{ 'text-danger': job.status === 4, 'text-warning': job.status === 1 || job.status === 2, 'text-success': job.status === 3 }">

                        <span v-if="job.status < 3">
                          {{$t('status-running')}}
                        </span>
                        <span v-if="job.status === 3">
                          {{$t('status-success')}}
                        </span>
                        <span v-if="job.status === 4" :title="job.statusMessage">
                          {{$t('status-error')}}
                        </span>

                      </td>
                      <td class="table-col">
                        <div v-for="(uri , index) in job.uri" v-bind:key="index">
                          <a v-bind:href="'#/'+uri.replace('catalog', 'tools/processor')">{{job.processor[index]}}</a>
                        </div>
                      </td>
                      <td class="table-col" v-if="job.status === 3">{{job.totalRowsProcessed}}</td>
                      <td class="table-col" v-if="job.status === 4">{{job.totalRows}}</td>
                      <td class="table-col" v-if="job.status < 3">{{job.progress}}%</td>
                      <td class="table-col" v-if="job.status > 2 && job.status < 4">{{parseInt((job.totalRowsOk/job.totalRowsProcessed) * 100, 10) }}</td>
                      <td class="table-col" v-if="job.status === 4">-</td>
                      <td class="table-col" v-if="job.status < 3">-</td>
                      <td class="table-col" v-if="job.status < 3">-</td>
                      <td class="table-col" v-if="job.status === 3">{{job.price.toFixed(4)}}€</td>
                      <td class="table-col" v-if="job.status === 4">-</td>


                      <td class="table-col">
                        <div class="total-icon">

                          <!--div class="icon">

                            <a href="#/bulks" v-if="job.status >= 2" v-on:click="downloadOriginalSigned(job.id)" :alt="$t('download-original')" :title="$t('download-original')">
                              <font-awesome-icon icon="download" size="lg"/>
                            </a>

                          </div-->

                          <div class="icon">

                            <a href="#/bulks" v-if="job.status === 3 && job.totalRowsProcessed > 0" v-on:click="downloadResultsSigned(job.id)" :alt="$t('download-results')" :title="$t('download-results')">
                              <font-awesome-icon icon="cloud-download-alt" size="lg"/>
                            </a>

                          </div>

                          <div class="icon">

                            <a v-if="job.status === 3 && job.totalRowsProcessed > 0" v-bind:href="'#/bulks/'+job.id + '/report'" :alt="$t('view-report')" :title="$t('view-report')">

                              <font-awesome-icon icon="chart-pie" size="lg"/>

                            </a>


                          </div>
                        </div>
                      </td>
                    </tr>



                  </tbody>

                </table>




              </div>
              <div class="col-lg-12 duration">{{ $t('last-60days-period' ) }}</div>
              <div class="col-lg-12 text-center"><small>{{ $t('jobs-pending-refresh' ) }}</small></div>
            </div>


            <div id="home" class="tab-pane container" v-if="section === 'queue'"  v-bind:class="{ active: section === 'queue' }">
              <div class="row">
                <div class="col-sm-12 col-12 col-title ">
                  <h3>{{ $t('queue' ) }}</h3>
                </div>
              </div>


              <div class="col-lg-12 no_side_pad">
                <div class="page-nav">
                  <div class="row no_side_margin">
                    <div class="col-lg-3 col-sm-4 col-4 no_side_pad pointer" v-on:click="moveNext('B')">
                      <div class="nav-item"><a :class="{'disabled': isLastPage() , '': !isLastPage() && this.loading}" v-on:click="moveNext('B')"><span class="nav-icon">←</span> {{ $t('older' ) }}</a></div>
                    </div>
                    <div class="col-lg-6 col-sm-4 col-4 no_side_pad">
                      <div class="btn-group ">
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-4 col-4 no_side_pad pointer" v-on:click="movePrevious('B')">
                      <div class="nav-item"><a :class="{'disabled': isFirstPage() || !this.loading, '': !isFirstPage() && this.loading}" v-on:click="movePrevious('B')"> {{ $t('newer' ) }} </a><span class="nav-icon">→</span></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row smry-status no_side_margin" style="overflow-x: auto;">
                <table class="table table-striped list-table" style="margin-top: 0px">
                  <thead>
                    <tr>
                      <th class="t-head">{{ $t('tool' ) }}</th>
                      <th class="t-head">{{ $t('parameters' ) }}</th>
                      <th class="t-head">{{ $t('reason' ) }}</th>
                      <th class="t-head">{{ $t('date_added' ) }}</th>
                      <th class="t-head">{{ $t('executed_at' ) }}</th>
                      <th class="t-head"></th>
                    </tr>
                  </thead>

                  <tbody v-if="!this.tasks || !this.tasks.length">

                    <tr>
                      <td colspan="5" class="text-center no-list">{{ $t('no-results' ) }}</td>
                    </tr>

                  </tbody>

                  <tbody v-if="this.tasks && this.tasks.length">
                    <tr v-for='task in this.tasks' v-bind:key="task.id">
                      <td class="table-col">{{task.tool}}</td>
                      <td class="table-col" v-html="task.parameters.join('<br>')"></td>
                      <td class="table-col">{{task.reason}}</td>
                      <td class="table-col">{{task.createdAt | formatDate}}</td>
                      <td class="table-col">{{task.executedAt | formatDate}}</td>
                      <td class="table-col">
                        <div class="total-icon">
                          <div class="icon">
                            <a href="#/queue" v-on:click="deleteTask(task.id)" :alt="$t('delete')" :title="$t('delete')">
                              <font-awesome-icon icon="trash" size="lg"/>
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>

                </table>
              </div>
              <div class="col-lg-12 duration">{{ $t('queue-pending-reexecute' ) }}</div>

            </div>
          </div>
        </div>
      </div>

      <!-- wizard content start end -->




      <Footer />

    </div>


  </div>

</div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '../Sidebar.vue'

import axios from 'axios'

export default {
  name: 'bulks',
  data() {
    return {

      isActive: false,
      isActiveIcon: '<',
      dateOptions: {
        'format-year': 'yy',
        'starting-day': 1,
        'show-weeks': false,
        'show-button-bar': false
      },
      format: 'dd/MM/yyyy',
      orderByField: 'startDate',
      reverseSort: true,
      startDate: '',
      endDate: '',
      pendingRequest: false,
      currentPage: 0,
      totalRecords: 0,
      totalPages: 0,
      totalRows: 0,
      totalRowsOk: 0,
      totalCost: 0,
      totalCredits: 0,
      totalSavings: 0,
      prevStartDate: null,
      prevEndDate: null,
      jobs: [],
      tasks: [],
      loading: false,
      requests: [],
      period: '1day',
      periodDisplay: 'select-period-1day',
      config: {},

      pendingJobs: false,

      section: 'bulks'

    }

  },
  mounted() {
      this.enableInterceptor();
      this.config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };
      this.startDate = new Date(Date.now() + -60 * 24 * 3600 * 1000);
      this.endDate = new Date();

      var path = this.$route.path

      if (path.indexOf("bulks") !== -1) {
        this.section = 'bulks'
      } else if (path.indexOf("history") !== -1) {
        this.section = 'history'
      } else if (path.indexOf("queue") !== -1) {
        this.section = 'queue'
      }

      this.setSection(this.section)
  },
  components: {

    Header,
    Sidebar,
    Footer

  },
  methods: {

    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        this.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        this.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },


    //FROM HERE BULKS AREA FUNCTIONS

    getJobsTrigger: function() {
      this.period = 'day';
      this.currentPage = 0;
      this.totalPages = 0;
      this.getJobs(true);
    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },

    getQueue: function(forced) { //alert(

      forced = this.areDatesDifferents() || forced || false;
      if (!this.pendingRequest && forced) {
        this.pendingRequest = true;

        var url = '/api/v2/queue/' + this.startDate.getTime() + '/' + this.endDate.getTime() + '/' + this.currentPage;

        this.$http.get(url, this.config).then((results) => {

          var result = results.data;
          this.tasks = result.results
          this.totalRecords = result.count;
          this.totalPages = result.totalPages;
          this.rowsPerPage = result.rowsPerPage;
          this.pendingRequest = false;
          //this.areDatesDifferents(true);
        });
      }
    },

    getJobs: function(forced) { //alert(

      forced = this.areDatesDifferents() || forced || false;
      if (!this.pendingRequest && forced) {
        this.pendingRequest = true;

        var url = '/api/v2/jobs/' + this.startDate.getTime() + '/' + this.endDate.getTime() + '/' + this.currentPage;

        this.$http.get(url, this.config).then((results) => {

          var result = results.data;
          this.jobs = result.results
          this.totalRecords = result.count;
          this.totalPages = result.totalPages;
          this.rowsPerPage = result.rowsPerPage;
          this.pendingRequest = false;
          this.areDatesDifferents(true);
          this.calculateTotals();

          this.pendingJobs = this.jobs.filter(function(j) {
            return j.status === 1 || j.status === 2;
          })
          if (this.pendingJobs.length > 0) {
            clearTimeout(this.timer);
            var fn = this.getJobs;
            this.timer = setTimeout(function(){ fn(true); }, 60000);
          } else if (this.timer) {
            clearTimeout(this.timer);
          }
        });

      } else {
        this.calculateTotals();
      }

    },

    areDatesDifferents: function(setDates) {

      setDates = setDates || false;
      var resStart = this.isStartDateDifferent(),
        resEnd = this.isEndDateDifferent();
      if (setDates) {
        if (resStart) {
          this.prevStartDate = new Date(this.startDate);
        }
        if (resEnd) {
          this.prevEndDate = new Date(this.endDate);
        }
      }
      return resStart || resEnd;

    },

    isStartDateDifferent: function() {
      return (this.prevStartDate === null || this.startDate.toLocaleString() !== this.prevStartDate.toLocaleString());
    },

    isEndDateDifferent: function() {
      return (this.prevEndDate === null || this.endDate.toLocaleString() !== this.prevEndDate.toLocaleString());
    },

    calculateTotals: function() {

      if (this.jobs.length) {
        this.resetTotalsData();
        var self = this;

        this.jobs.forEach(function(job) {
          //only finished jobs

          self.totalRows += job.totalRows;
          self.totalRowsOk += job.totalRowsOk;
          self.totalCredits += job.cost;
          //If we don't have finalPrice, used, estimated one
          job.price = job.price === 0 ? job.estimatedPrice : job.price;
          self.totalCost += job.price;
          self.totalSavings += (job.estimatedPrice - job.price);
        });


      }

    },

    resetTotalsData: function() {
      this.totalRows = 0;
      this.totalRowsOk = 0;
      this.totalCost = 0;
      this.totalCredits = 0;
      this.totalSavings = 0;
    },

    jobTotals: function() {

      this.updateTotalsData();
      //this.getSortingValues();

    },

    updateTotalsData: function() {

      this.totalRows = this.getTotalRows();
      this.totalRowsOk = this.getTotalRowsOk();
      this.totalCost = this.getTotalCost();
      this.totalSavings = this.getTotalSavings();
      this.totalCredits = this.getTotalCredits();

    },

    getTotalRows: function() {
      return this.totalRows;
    },

    getTotalRowsOk: function() {
      return this.totalRowsOk;
    },

    getTotalCost: function() {
      return this.totalCost;
    },

    getTotalSavings: function() {
      return this.totalSavings;
    },

    getTotalCredits: function() {
      return this.totalCredits;
    },

    downloadOriginal: function(id) {
      window.location.href = this.getOriginalFileUrl(id);
    },

    downloadOriginalSigned: function(id) {
      this.getOriginalFileSignedUrl(id);
    },

    getOriginalFileSignedUrl: function(jobId) {
      var url = this.$apiBase + "/api/v2/job/" + jobId + "/downloadsigned/original";
      this.$http.get(url, this.config).then((results) => {
        if (results && results.data && results.data.url && results.data.url.length) {
          window.location.href = results.data.url;
        }
      });
    },

    getOriginalFileUrl: function(jobId) {
      return this.$apiBase + "/api/v1/" + localStorage.token + "/job/download/" + jobId + "/original";
    },

    downloadResults: function(id) {
      window.location.href = this.getResultsFileUrl(id);
    },

    getResultsFileUrl: function(jobId) {
      return this.$apiBase + "/api/v1/" + localStorage.token + "/job/download/" + jobId + "/results-final";
    },

    downloadResultsSigned: function(id) {
      this.getResultsFileSignedUrl(id);
    },

    getResultsFileSignedUrl: function(jobId) {
      var url = this.$apiBase + "/api/v2/job/" + jobId + "/downloadsigned/results-final";
      this.$http.get(url, this.config).then((results) => {
        if (results && results.data && results.data.url && results.data.url.length) {
          window.location.href = results.data.url;
        }
      });
    },

    isFirstPage: function() {
      return this.isFirstPageCheck();
    },

    isFirstPageCheck: function() {
      return this.currentPage === 0;
    },

    movePrevious: function(from) {

      if (this.isFirstPage()) {
        this.loading = true;
      }
      if (from == 'B') {
        this.movePreviousProcess();
      } else {
        this.movePreviousProcessHistory();
      }

      this.updatePaginationValues();

    },

    movePreviousProcess: function() {
      let currentPage = this.currentPage;
      if (currentPage - 1 >= 0) {
        this.currentPage--;
        this.getJobs(true);
      }
    },

    movePreviousProcessHistory: function() {
      let currentPage = this.currentPage;
      if (currentPage - 1 >= 0) {
        this.currentPage--;
        this.getRequests(true);
      }
    },

    updatePaginationValues: function() {
      //this.currentPage = this.getCurrentPage() + 1;
      this.totalPages = this.getTotalPages();
    },

    getCurrentPage: function() {
      return this.currentPage;
    },

    getTotalPages: function() {
      return this.totalPages;
    },

    isLastPage: function() {
      return (this.totalPages > 0 && this.currentPage === this.totalPages - 1) || (this.totalPages === 0 && this.currentPage === 0);
    },

    moveNext: function(from) {

      if (!this.isLastPage()) {
        this.loading = true;
      }

      if (from == 'B') {
        this.moveNextProcess();
      } else {
        this.moveNextProcessHistory();
      }
      this.updatePaginationValues();

    },

    moveNextProcess: function() { //alert('moveNextProcess'+this.currentPage);
      let currentPage = this.currentPage;
      if (currentPage + 1 < this.totalPages) {
        this.currentPage++; //alert('yes'+this.currentPage);
        this.getJobs(true);
      }

    },

    moveNextProcessHistory: function() { //alert('hx');
      let currentPage = this.currentPage;
      if (currentPage + 1 < this.totalPages) {
        this.currentPage++;
        this.getRequests(true);
      }

    },

    getRequestsTrigger: function() {
      this.period = '1day';
      this.periodDisplay = 'select-period-1day';
      this.currentPage = 0;
      this.totalPages = 0;
      this.getRequests(true);
    },

    getRequests: function(forced) {
      forced = this.areDatesDifferents() || forced || !this.requests[this.currentPage] || false;
      if (!this.pendingRequest && forced) {
        this.pendingRequest = true;

        var url = '/api/v2/requests_period/' + this.period + "/" + new Date().getTime() + "/" + new Date().getTime() + "/" + this.currentPage;

        this.$http.get(url, this.config).then((results) => {

          this.requests[this.currentPage] = results.data.results;
          this.requests = this.requests[this.currentPage];
          this.totalRecords = results.data.count;
          this.totalPages = results.data.totalPages;
          this.rowsPerPage = results.data.rowsPerPage;
          this.pendingRequest = false;
          this.areDatesDifferents(true);

        });


      } else {
        //return requests[currentPage];
      }

    },

    setSection: function(section) {
      if (this.$route.path.indexOf(section) === -1) {
        this.$router.push("/" + section)
      }
      this.section = section;
      if (section === 'bulks') {
        this.getJobsTrigger();
      } else if (section === 'queue') {
          this.getQueue(true);
      } else if (section === 'history') {
        this.getRequestsTrigger()
      }
    },

    changePeriod: function(item, period) {

      this.pendingRequest = true;
      this.currentPage = 0;
      this.periodDisplay = item;
      this.period = period;
      this.endDate = new Date();


      this.startDate = new Date(Date.now());
      if (this.period === 'day') {//current day
        this.startDate.setHours(0)
        this.startDate.setMinutes(0)
        this.startDate.setSeconds(0)
        this.endDate.setHours(23)
        this.endDate.setMinutes(59)
        this.endDate.setSeconds(59)
      } else if (this.period === '1day') {//last 24
        this.startDate.setDate(this.startDate.getDate() - 1)
      } else if (this.period === 'month') {//current month
        this.startDate.setDate(this.startDate.getMonth() - 1)
      } else if (this.period === '7days') {//last 7 days
        this.startDate.setDate(this.startDate.getDate() - 7)
      } else if (this.period === '30days') {//last 30 days
        this.startDate.setDate(this.startDate.getDate() - 30)
      }

      this.isCostByHour = (this.period === 'day' || this.period === '1day');

      var url = '/api/v2/requests_period/' + this.period + '/' + this.startDate.getTime() + '/' + this.endDate.getTime() + "/" + this.currentPage;

      this.$http.get(url, this.config).then((results) => {

        this.requests[this.currentPage] = results.data.results;
        this.requests = this.requests[this.currentPage];
        this.totalRecords = results.data.count;
        this.totalPages = results.data.totalPages;
        this.rowsPerPage = results.data.rowsPerPage;
        this.pendingRequest = false;
        this.areDatesDifferents(true);
      });
    },

    deleteTask: function(id) {
      var that = this;
      that.deleteTaskProcess(id);
      /*
      this.$modal.show('dialog', {
        title: that.$t('task-delete'),
        text: that.$t('task-delete-question'),
        buttons: [
          {
            title: that.$t('accept'),
            handler: () => {
              that.pendingRequest = true;
              that.deleteTaskProcess(id);
              that.$modal.hide('dialog');
            }
          },
          {
            title: that.$t('cancel')
          }
        ]
      });*/
    },

    deleteTaskProcess: function(id) {
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };
      var url = '/api/v2/queue/' + id + '/delete';
      this.$http.get(url, config).then((results) => {
        this.tasks = results.data.results;
        console.log(this.tasks);
        this.pendingRequest = false;
        this.$forceUpdate();
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/wizards.css';
@import '../../assets/css/global.css';
</style>
