<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>


      <div class="row no_side_margin" style="margin-left: 2px;">


        <div class="total col-lg-12 contact-wrap">
          <ul class="nav nav-tabs d_summary">
            <li class="nav-item active"><a data-toggle="tab" href="#home" class="nav-link active">
                <font-awesome-icon icon="envelope" size="lg" />
                {{$t('contact-tab')}}</a>
            </li>
            <li class="nav-item"><a data-toggle="tab" href="#menu1" class="nav-link">
                <font-awesome-icon icon="info-circle" size="lg" />
                {{$t('faq-tab')}}</a></li>
            <li class="nav-item"><a data-toggle="tab" href="#videos" class="nav-link">
                <font-awesome-icon :icon="['fab', 'youtube']" size="lg" />
                {{$t('videos-tab')}}</a></li>
          </ul>
          <div class="tab-content">
            <div id="home" class="tab-pane container active">
              <div class="row no_side_margin">
                <div class=" col-12 col-title ">
                  <h3>{{$t('contact-tab')}}</h3>
                </div>
                <div class="col-lg-12 total-contact-form">
                  <div class="col-lg-9">
                    <div class="col-lg-12 total-bg no_side_pad">

                      <div class="profile-settings">
                        <div class="fileld">
                          <div class="row">
                            <label class="col-lg-3">{{$t('name')}}</label>
                            <div class="col-lg-9"><input type="text" class="form-control" id="name" v-model='name' readonly v-bind:disabled="!this.apiKey && this.name.length" required></div>
                          </div>
                        </div>

                        <div class="fileld">
                          <div class="row">
                            <label class="col-lg-3">{{$t('email')}}</label>
                            <div class="col-lg-9"><input type="email" class="form-control" id="email" v-model='email' readonly v-bind:disabled="!this.apiKey && this.email.length" required></div>
                          </div>
                        </div>

                        <div class="fileld">
                          <div class="row">
                            <label class="col-lg-3">{{$t('subject')}}</label>
                            <div class="col-lg-9">
                              <select class="form-control" type="text" name="subject" v-model='subject' v-on:change="checkForm()">
                                <option value="">{{$t('contact-placeholder-subject')}}</option>
                                <option :value="$t('contact-subject-0')">{{$t('contact-subject-0')}}</option>
                                <option :value="$t('contact-subject-1')">{{$t('contact-subject-1')}}</option>
                                <option :value="$t('contact-subject-2')">{{$t('contact-subject-2')}}</option>
                                <option :value="$t('contact-subject-3')">{{$t('contact-subject-3')}}</option>
                                <option :value="$t('contact-subject-4')">{{$t('contact-subject-4')}}</option>
                                <option :value="$t('contact-subject-5')">{{$t('contact-subject-5')}}</option>
                                <option :value="$t('contact-subject-6')">{{$t('contact-subject-6')}}</option>
                                <option :value="$t('contact-subject-8')">{{$t('contact-subject-8')}}</option>
                                <option :value="$t('contact-subject-9')">{{$t('contact-subject-9')}}</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div class="fileld">
                          <div class="row">
                            <label class="col-lg-3">{{$t('comments')}}</label>
                            <div class="col-lg-9"> <textarea class="form-control" rows="5" id="comment" v-model='comment' v-on:keydown="checkForm()"></textarea></div>
                          </div>
                        </div>
                        <div class="fileld">
                          <div class="row">
                            <label class="col-lg-3"></label>
                            <div class="col-lg-9">
                              <button data-v-8c36869e="" type="button" class="btn btn-primary btn-lg btn-block add-selection Send-message" v-on:click="sendFeedback()" :disabled="!canBeSent">Send message</button>
                              <div class="col-lg-12 text-center text-success" v-if="this.received && this.resultFeedback">
                                <br>
                                <p>{{$t('contact-ok')}}</p>
                              </div>
                              <div class="col-lg-12 text-center text-danger" v-if="this.received && !this.resultFeedback">
                                <br>
                                <p>{{$t('contact-ko')}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div id="videos" class="container tab-pane fade">
              <div class=" col-12 col-title ">
                <h3>{{$t('videos-tab')}}</h3>
              </div>

              <iframe src="//www.youtube.com/embed/?listType=user_uploads&list=mcolomer1975&vq=hd720" allowfullscreen style='width:100%; height:650px;' width="100%" height="650" frameborder="0"></iframe>
            </div>
            <div id="menu1" class="container tab-pane fade">
              <div class=" col-12 col-title ">
                <h3>{{$t('faq-tab')}}</h3>
              </div>

              <div class="row">
                <div class="col-lg-11">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                    <div class="panel panel-default panel-box">
                      <div class="panel-heading" role="tab" id="headingOne">
                        <h4 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <div class="panel-items">{{$t('faq1-question')}}</div>
                          </a>
                        </h4>

                      </div>
                      <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                        <div class="panel-body panel-content">{{$t('faq1-answer')}}</div>
                      </div>
                    </div>

                    <div class="panel panel-default panel-box">
                      <div class="panel-heading" role="tab" id="headingTwo">
                        <h4 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <div class="panel-items">{{$t('faq2-question')}}</div>
                          </a>
                        </h4>

                      </div>
                      <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                        <div class="panel-body panel-content" v-html="$t('faq2-answer')"></div>
                      </div>
                    </div>

                    <div class="panel panel-default panel-box">
                      <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <div class="panel-items">{{$t('faq3-question')}}</div>
                          </a>
                        </h4>

                      </div>
                      <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                        <div class="panel-body panel-content">
                          <p class="ng-scope" v-html="$t('faq3-answer')"></p>
                        </div>
                      </div>
                    </div>

                    <div class="panel panel-default panel-box">
                      <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            <div class="panel-items">{{$t('faq4-question')}}</div>
                          </a>
                        </h4>

                      </div>
                      <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                        <div class="panel-body panel-content">
                          <p v-html="$t('faq4-answer')"></p>
                        </div>
                      </div>
                    </div>

                    <div class="panel panel-default panel-box">
                      <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            <div class="panel-items">{{$t('faq5-question')}}</div>
                          </a>
                        </h4>

                      </div>
                      <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                        <div class="panel-body panel-content">{{$t('faq5-answer')}}</div>
                      </div>
                    </div>

                    <div class="panel panel-default panel-box">
                      <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            <div class="panel-items">{{$t('faq6-question')}}</div>
                          </a>
                        </h4>

                      </div>
                      <div id="collapseSix" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                        <div class="panel-body panel-content">
                          <p class="ng-scope" v-html="$t('faq6-answer')"></p>
                        </div>
                      </div>
                    </div>

                    <div class="panel panel-default panel-box">
                      <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                            <div class="panel-items">{{$t('faq7-question')}}</div>
                          </a>
                        </h4>

                      </div>
                      <div id="collapseSeven" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                        <div class="panel-body panel-content">
                          <div class="ng-scope" v-html="$t('faq7-answer')"></div>
                        </div>
                      </div>
                    </div>

                    <div class="panel panel-default panel-box">
                      <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                            <div class="panel-items">{{$t('faq8-question')}}</div>
                          </a>
                        </h4>

                      </div>
                      <div id="collapseEight" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                        <div class="panel-body panel-content">{{$t('faq8-answer')}}</div>
                      </div>
                    </div>

                    <!--div class="panel panel-default panel-box">
                      <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                            <div class="panel-items">{{$t('faq9-question')}}</div>
                          </a>
                        </h4>

                      </div>
                      <div id="collapseNine" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                        <div class="panel-body panel-content">
                          <div class="ng-scope" v-html="$t('faq9-answer')"></div>
                        </div>
                      </div>
                    </div-->

                    <div class="panel panel-default panel-box">
                      <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                            <div class="panel-items">{{$t('faq10-question')}}</div>
                          </a>
                        </h4>

                      </div>
                      <div id="collapseTen" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                        <div class="panel-body panel-content">
                          <p class="ng-scope" v-html="$t('faq10-answer')"></p>
                        </div>
                      </div>
                    </div>

                    <!--div class="panel panel-default panel-box">
                      <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseEleven" aria-expanded="false" aria-controls="collapseTen">
                            <div class="panel-items">What is a retention period?</div>
                          </a>
                        </h4>

                      </div>
                      <div id="collapseEleven" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                        <div class="panel-body panel-content">
                          <p translate="" class="ng-scope">This is a temporary storage in the system, that <b>optimize the expenses of your account </b>.<br><br>Repeated queries during your retention period <b>won't be charged</b>.<br><br>After
                            retention period, <b>ALL</b> processed data will be erased from service automatically. You can achieve more retention days getting a better plan.</p>
                        </div>
                      </div>
                    </div-->

                    <!--div class="panel panel-default panel-box">
                      <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapsetwelve" aria-expanded="false" aria-controls="collapseEleven">
                            <div class="panel-items">{{$t('faq12-question')}}</div>
                          </a>
                        </h4>

                      </div>
                      <div id="collapsetwelve" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                        <div class="panel-body panel-content">
                          <p translate="" class="ng-scope">If you have selected <b>Professional</b> or <b>Enterprise</b> plans, every time you do a payment in the system - manual or automatically -, <b>uProc</b> will <b>gift you</b> a percent of the
                            payment amount, that will be added to your account. The gift percent depends on your plan, and will be bigger in Enterprise plan.<br><br>There is no promoted payments in <b>Basic</b> plan.</p>
                        </div>
                      </div>
                    </div-->

                  </div>
                </div>

              </div>


            </div>


          </div>
        </div>



      </div>



      <Footer />

    </div>


  </div>

</div>
</template>

<script>
import Header from '@/components/Header.vue'

import Footer from '@/components/Footer.vue'

import Sidebar from '@/components/Sidebar.vue'

import axios from 'axios';

export default {
  name: 'Contact',
  data() {
    return {
      isActive: false,
      isActiveIcon: '<',
      name: '',
      logined: false,
      email: '',
      apiKey: '',
      subject: '',
      sent: true,
      comment: '',
      resultFeedback: '',
      sending: false,
      received: false,
      canBeSent: false


    };
  },
  mounted() {

    this.enableInterceptor();
    this.apiKey = localStorage.token;
    this.logined = true;
    this.getUserDetails();
  },
  components: {
    Header,
    Sidebar,
    Footer
  },
  watch: {



  },
  methods: {

    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        this.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        this.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    checkForm: function() {
      this.canBeSent = this.subject.length && this.comment.length && this.name.length && this.email.length;
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;

      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },

    getUserDetails: function() {
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };
      var url = '/api/v2/profile';
      this.$http.get(url, config).then((results) => {
        this.name = results.data.name;
        this.email = results.data.email;

      });
    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },

    sendFeedback: function() {
      this.sending = true;
      this.received = false;
      var data = {
        name: this.name,
        email: this.email,
        subject: this.subject,
        comment: this.comment
      };
      //console.log(data);
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v1/contact'
      this.$http.post(url, data, config).then((results) => {
        this.sending = false;
        this.received = true;
        this.subject = "";
        this.comment = "";
        this.resultFeedback = results;
        this.checkForm();
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/global.css';
</style>
