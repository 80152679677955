<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>

      <!-- wizard content start start -->

      <div class="">
        <div class="total-lists">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item active">{{ $t('top-form' ) }}</li>
              <!-- <li class="breadcrumb-item active" aria-current="page">Library</li> -->
            </ol>
          </nav>
          <div class="row no_side_margin">
            <div class="col-lg-12">
              <div class="create-new-list">
                <p v-html="$t('forms-description' )">
                </p>
                <div class="row">
                  <div class=" col-lg-12 text-center">
                    <button type="button" class="btn btn-primary btn-lg add-selection add" :disabled="loading" v-on:click="addNew()">+ {{ $t('wizard-form-add' ) }}</button>
                  </div>
                </div>
              </div>

              <h3 class="h3title pt-5">{{ $t('form-title' ) }}</h3>

              <div style="overflow-x: auto;">
                <table class="table table-striped list-table" style="margin-bottom: 0px">
                  <thead>
                    <tr>
                      <th class="t-head">{{$t('name')}}</th>
                      <th class="t-head">{{$t('form-recipient')}}</th>
                      <th class="t-head">{{$t('form-free')}}</th>
                      <th class="t-head">CatchAll</th>
                      <th class="t-head">{{$t('form-temporary')}}</th>
                      <th class="t-head">Softbounce</th>
                      <th class="t-head">{{$t('form-robinson')}}</th>
                      <th class="t-head">{{$t('enrichment')}}</th>
                      <th class="t-head">{{$t('list')}}</th>
                      <th class="t-head">{{$t('date')}}</th>
                      <th class="t-head">{{$t('actions')}}</th>

                    </tr>
                  </thead>
                  <tbody v-if="this.forms.length">

                    <tr v-for="(form , index) in this.forms" v-bind:key="index">

                      <td class="table-col">{{form.name}}</td>

                      <td class="table-col" v-if="editMode && editRow === index">
                        <input v-model="form.name" class="drop-down" value="Enter name of new form...">
                        <svg v-on:click="saveNames()" class="tick" width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 5.21895L1.26512 4L4 6.5621L10.7349 0L12 1.21895L4 9L0 5.21895Z" fill="#0300e2" />
                        </svg>
                      </td>
                      <td class="table-col text-center">
                        <a v-on:click="toggleOption(form, 'check_recipient')" role="button">
                          <Status name="accept_free" :value="form.check_recipient"/>
                        </a>
                      </td>
                      <td class="table-col text-center">
                        <a v-on:click="toggleOption(form, 'accept_free')" role="button">
                          <Status name="accept_free" :value="form.accept_free"/>
                        </a>
                      </td>
                      <td class="table-col text-center">
                        <a v-on:click="toggleOption(form, 'accept_catchall')" role="button">
                          <Status name="accept_catchall" :value="form.accept_catchall"/>
                        </a>
                      </td>
                      <td class="table-col text-center">
                        <a v-on:click="toggleOption(form, 'accept_temporary')" role="button">
                          <Status name="accept_temporary" :value="form.accept_temporary"/>
                        </a>
                      </td>
                      <td class="table-col text-center">
                        <a v-on:click="toggleOption(form, 'accept_softbounce')" role="button">
                          <Status name="accept_softbounce" :value="form.accept_softbounce"/>
                        </a>
                      </td>
                      <td class="table-col text-center">
                        <a v-on:click="toggleOption(form, 'accept_robinson')" role="button">
                          <Status name="accept_robinson" :value="form.accept_robinson"/>
                        </a>
                      </td>
                      <td class="table-col text-center">
                        <a v-on:click="toggleOption(form, 'enrichment')" role="button">
                          <Status name="enrichment" :value="form.enrichment"/>
                        </a>
                      </td>
                      <td class="table-col text-center"><a v-bind:href="'/#/lists/' + form.list">{{form.listName}}</a></td>
                      <td class="table-col text-center">{{form.updatedAt | formatDate}}</td>
                      <td class="table-col">
                        <div class="total-icon">
                          <div class="icon">

                            <a title="Edit Form" v-bind:href="'#/forms/'+form.id">

                              <font-awesome-icon icon="edit" size="lg"/>

                            </a>
                          </div>

                          <div class="icon">

                            <a href="#/forms" title="Delete Form" v-on:click="deleteForm(form.id)">

                              <font-awesome-icon icon="trash" size="lg"/>

                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>

                  </tbody>
                </table>

                <div class="col-lg-12 no-list" v-if="!this.forms.length">
                  {{$t('no-forms')}}
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>


      <v-dialog />

      <Footer />

    </div>

  </div>

</div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/Sidebar.vue'
import Status from '@/components/Status.vue'

import axios from 'axios';

export default {
  name: 'forms',
  data() {
    return {

      isActive: false,
      isActiveIcon: '<',
      forms: [],
      pendingRequest: false,
      editRow: -1,
      editMode: false,
      loading: true,

    }

  },
  mounted() {
      this.enableInterceptor();
      this.getUserDetails();
      this.getForms(true);
  },
  components: {

    Header,
    Sidebar,
    Footer,
    Status

  },
  methods: {

    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        this.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        this.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      //console.log(this.isActive);
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },


    getUserDetails: function() {

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };


      var url = '/api/v2/profile';

      this.$http.get(url, config).then((results) => {

        this.user = results.data;


      });
    },

    getCredits: function() {

      return this.user ? this.user.credits : 0;


    },


    hasCards: function() {
      return this.user && this.user.cards.length;
    },

    hasAutoCredits: function() {
      return this.user && this.user.autocredits !== null ? this.user.autocredits : false;
    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },

    areCreditsNeeded: function() {
      return this.processing.processCredits > this.getCredits();
    },





    //FROM HERE BULKS AREA FUNCTIONS


    getForms: function(forced) {

      if (!this.pendingRequest && forced) {
        this.pendingRequest = true;



        var config = {
          headers: {
            "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
            "Content-Type": "application/json"
          }
        };


        var url = '/api/v2/forms';

        this.$http.get(url, config).then((results) => {

          this.forms = results.data;
          this.pendingRequest = false;
          this.loading = false;


        });


      }

    },

    deleteForm: function(listId) {
      var that = this;
      this.$modal.show('dialog', {
        title: that.$t('form-delete'),
        text: that.$t('form-delete-question'),
        buttons: [
          {
            title: that.$t('accept'),
            handler: () => {
              that.pendingRequest = true;
              that.deleteFormProcess(listId);
              that.$modal.hide('dialog');
            }
          },
          {
            title: that.$t('cancel')
          }
        ]
      })
    },

    deleteFormProcess: function(name) {
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v2/form/' + name + '/delete';
      this.$http.get(url, config).then((results) => {

        this.forms = results.data;
        this.pendingRequest = false;
        this.$modal.hide('dialog');
      });
    },

    addNew: function() {
      this.loading = true;
      var name = "form-" + new Date().getTime();
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };
      var url = '/api/v2/form';
      this.$http.post(url, {
        name: name
      }, config).then((results) => {
        this.getForms(true);
        this.loading = false;
        this.$router.push("/forms/" + results.data._id)
      });
    },

    listEdit: function(name, index) {

      if (name !== 'default') {
        this.editMode = true;
        this.editRow = index;
      }
    },

    toggleOption: function(form, option) {
      form[option] = !form[option];
      this.saveForm(form);
    },

    saveForm: function(form) {
      if (!this.pendingRequest) {
        this.pendingRequest = true;
        this.loading = true;
        var config = {
          headers: {
            "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
            "Content-Type": "application/json"
          }
        };

        var url = '/api/v2/form/' + form.id;
        this.$http.post(url, form, config).then((results) => {
          this.form = results.data
          this.formOriginal = JSON.parse(JSON.stringify(results.data))
          this.pendingRequest = false
          this.loading = false
        });
      }
    },

    saveNames: function() {
      this.loading = true;
      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };

      var url = '/api/v2/forms';
      this.$http.post(url, {
        forms: this.forms
      }, config).then((results) => {

        this.loading = false;
        this.editMode = false;
        this.editRow = -1;
        this.forms = results.data;
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/list.css';
@import '../../assets/css/global.css';
</style>
